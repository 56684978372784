import React from 'react';

const OfferInf = () => {

    return (
        <>
            <h3>
                <p>
                    Оферта о заключении договора оказания услуг
                </p>
            </h3>
            <div className={"paragraph"}>
                <div className={"paragraph"}>
                    Настоящий документ является предложением ИП. Найденова Р.А. основателя портала БИЗНЕС MY LIFE (далее
                    – «<b className={"d-inline-block"}>Компания</b>») которая расположена на доменном имени
                    business-mylife.ru (а также его субдоменах) в адрес зарегистрированных пользователей Бизнес my life
                    (далее – «<b className={"d-inline-block"}>Пользователи</b>») заключить договор оказания рекламных
                    услуг (далее – «<b className={"d-inline-block"}>Договор</b>»,) на указанных ниже условиях (далее – «<b
                    className={"d-inline-block"}>Оферта</b>»).
                </div>
                <p></p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>1. Предмет Договора</b>
                <p>
                    • 1.1. Договор определяет общие условия обязательственных отношений, возникающих между Компанией и
                    пользователем при оказании Компанией услуг информационно-технического характера (далее – «услуги»).
                    Данные условия могут быть дополнены и уточнены в дальнейшем путем заключения сторонами на основании
                    Договора отдельных соглашений (сделок) об оказании рекламных или иных услуг на сайте Бизнес my life
                    или других сайтах, на которых Компания предоставляет услуги.
                </p>
                <p>
                    • 1.2. Заключаемый Договор представляет собой договор с открытыми условиями. Существенные условия
                    каждой совершаемой на Бизнес my life сделки по оказанию услуг формируются онлайн индивидуально для
                    пользователя с помощью интерфейса Бизнес my life, посредством которого пользователь выбирает услугу
                    и ее параметры, сторонами согласовываются условия сделки.
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>2. Заключение Договора. Заказ услуг</b>
                <p>
                    • 2.1. Оферта считается акцептованной пользователем, а Договор между Компанией и Пользователем
                    заключенным с момента оплаты пользователем услуг по соответствующей сделке (в этом случае она
                    совершается одновременно с заключением Договора) или с момента внесения аванса в счет оплаты
                    заказываемых в будущем услуг. Условия заключенного Договора применяются ко всем последующим сделкам
                    между Пользователем и Компанией. Договор может быть заключен только с Пользователем, являющимся
                    дееспособным физическим лицом либо юридическим лицом или индивидуальным предпринимателем,
                    зарегистрированным в Российской Федерации.
                </p>
                <p>
                    • 2.2. Каждый заказ конкретных услуг в рамках Договора является самостоятельной сделкой по оказанию
                    услуг информационно-технического характера. Сделка во исполнение Договора может быть заключена в
                    отношении услуг, представленных на Бизнес my life и доступных для заказа и оплаты в момент обращения
                    пользователя к тому или иному сервису Бизнес my life.
                </p>
                <p>
                    • 2.3. Оплата заказа признается акцептом Пользователя предложения о заключении сделки на
                    согласованных сторонами условиях.
                </p>
                <p>
                    • 2.4. Пользователь оформляет каждый заказ услуг в соответствии с условиями действующей редакции
                    оферты и условиями соответствующего сервиса. Условия всех сервисов Бизнес my life, которые размещены
                    на Бизнес my life в момент заказа услуг, являются неотъемлемой частью оферты. Заказ оформляется в
                    режиме онлайн.
                </p>
                <p>
                    • 2.5. Совершая акцепт оферты, вы выражаете согласие с тем, что факт выполнения определенных
                    действий на Бизнес my life, в том числе с использованием профессиональных технических инструментов,
                    выполнение команд через интерфейс Бизнес my life (нажатие кнопок, клики), совершение платежа и иные
                    подобные действия означают ваше волеизъявление в отношении заказа и/или активации услуг в
                    соответствии с указанными на Бизнес my life параметрами услуг и их ценой.
                </p>
                <p>
                    • 2.6. За рекламные данные, материалы и прочие информационные размещения на сайте Бизнес my life
                    Пользователями, Компания ответственности не несет и придерживается только правил и законов
                    Российской Федерации.
                </p>


            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>3. Общие условия оказания услуг</b>
                <p>
                    • 3.1. Услуги оказываются на территории Российской Федерации.
                </p>
                <p>
                    • 3.2. Обязательства Компании по предоставлению услуг согласно заказам Пользователя являются
                    встречными по отношению к обязательствам пользователя по соблюдению правил Бизнес my life и оплате
                    услуг. При невыполнении пользователем данных обязательств Компания может приостановить или
                    отказаться от оказания услуг в соответствующей части на основании правомочий, предусмотренных
                    гражданским законодательством Российской Федерации.
                </p>
                <p>
                    • 3.3. Компания оказывает услуги при наличии возможности их предоставления, которая определяется, в
                    том числе, соблюдением пользователями правил Бизнес my life. Несоблюдение Правил Бизнес my life
                    может привести к невозможности исполнения обязательств Компании по оказанию соответствующих услуг.
                </p>
                <p>
                    • 3.4. В силу характера предлагаемых Компанией услуг, направленных на удовлетворение индивидуальных
                    потребностей пользователей по продвижению товаров, работ, услуг пользователей и иных предложений, в
                    рамках Договора Компания не принимает на себя обязанности по оказанию услуг каждому лицу, которое к
                    ней обратится и может отказать в оказании услуг пользователю. Например, в случаях когда
                    предоставление услуг не соответствует правилам Бизнес my life, его тематике, категориям и пр.
                </p>
                <p>
                    • 3.5. Некоторые услуги на Бизнес my life могут быть доступны только для отдельных категорий
                    пользователей, например, использующих Бизнес my life для осуществления своей предпринимательской
                    деятельности.
                </p>
                <p>
                    • 3.6. Условия предоставления конкретных услуг в рамках Договора, в том числе их содержание, объем и
                    сроки определяются в соответствующих Условиях сервисов и других правилах Бизнес my life.
                </p>
                <p>
                    • 3.7. Предоставляемые на Бизнес my life услуги могут изменяться, дополняться, обновляться, в связи
                    с чем их использование предлагается в режиме «как есть», то есть в том виде и объеме, в каком они
                    предоставляются Компанией в момент обращения пользователей к услугам и сервисам Бизнес my life.
                </p>
                <p>
                    • 3.8. Компания вправе направлять вам по имеющимся в вашем профиле контактным данным уведомления,
                    связанные с исполнением настоящего Договора или любой из заключенных в рамках Договора сделок,
                    включая оповещения о статусе оказания услуги, способах продления, изменениях условий оказания услуг
                    и иные информационные сообщения, посредством смс- и push-сообщений, электронной почты и телефонных
                    звонков.
                </p>
                <p>
                    • 3.9. Вы обязуетесь соблюдать при пользовании услугами требования действующего законодательства
                    Российской Федерации, положения Договора, соответствующие условия выбранной услуги или сервиса и все
                    иные правила Бизнес my life.
                </p>
                <p>
                    • 3.10. Вы обязуетесь соблюдать при пользовании услугами требования действующего законодательства
                    Российской Федерации, положения Договора, соответствующие условия выбранной услуги или сервиса и все
                    иные правила Бизнес my life.
                </p>
                <p>
                    • 3.11. По требованию Компании вы обязаны предоставить информацию и документы, необходимые для
                    идентификации пользователя в качестве стороны Договора, в частности при направлении в адрес Компании
                    заявлений, запросов и прочих обращений, связанных с оказанием услуг на Бизнес my life. В целях
                    идентификации частных пользователей Компанией могут быть запрошены ФИО, серия и номер паспорта,
                    скан-копия второй и третьей страницы паспорта (второй разворот), адрес электронной почты и номер
                    телефона пользователя, используемые для доступа к профилю. Кроме того, Компания вправе
                    самостоятельно проводить верификацию пользователя в качестве стороны по Договору путем проведения
                    сверки указанных вами в профиле реквизитов с реквизитами плательщика по Договору, а также иными
                    способами.
                </p>
                <p>
                    • 3.12. Стороны признают действия, совершенные с использованием логина и пароля пользователя,
                    действиями, совершенными пользователем и имеющими силу простой электронной подписи.
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>4. Стоимость услуг и порядок расчетов</b>
                <p>
                    • 4.1. Стоимость выбранной пользователем услуги при заключении сделки в рамках Договора определяется
                    исходя из характеристик услуги (вид услуги, состав пакета услуг и иные параметры). Стоимость
                    выбранной услуги отображается в рублях в интерфейсе Бизнес my life при оформлении заказа, если иное
                    не предусмотрено условиями соответствующего сервиса или услуги. Стоимость идет без НДС. Оплата услуг
                    осуществляется по ценам, действующим на момент совершения платежа.
                </p>
                <p>
                    • 4.2. Вы можете совершить оплату услуг одним из способов, представленных в интерфейсе Бизнес my
                    life в момент оформления заказа услуг, в том числе за счет ранее перечисленного аванса.
                </p>
                <p>
                    • 4.3. Пользователь с профилем юридического лица или индивидуального предпринимателя может
                    осуществлять оплату услуг по Договору безналичным переводом денежных средств на расчетный счет
                    Компании на основании выставленного счета. Допускается также оплата с использованием корпоративной
                    банковской карты, оформленной на соответствующее юридическое лицо или индивидуального
                    предпринимателя.
                </p>
                <p>
                    • 4.4. Компания предоставляет пользователю информацию обо всех совершаемых им платежах на Бизнес my
                    life в специальном информационно-аналитическом разделе профиля (далее – «Кошелек»). Кошелек
                    предназначен для учета поступающих от пользователя в качестве аванса денежных средств, которые могут
                    быть использованы для оплаты заказываемых в будущем услуг Компании, а также для предоставления иной,
                    связанной с услугами Компании информации, в том числе о наличии бонусов, которые могут включаться в
                    общий баланс Кошелька, о платежах, поступивших от пользователя в счет оплаты услуг Компании (в том
                    числе в целях подтверждения оплаты).
                </p>
                <p>
                    • 4.5. При оплате услуг за счет ранее внесенного аванса, отраженного в Кошельке, с баланса Кошелька
                    осуществляется списание соответствующей суммы денежных средств с учетом бонусов/скидок (при их
                    наличии), если условия соответствующего сервиса допускают их использование. Совершение такой оплаты
                    возможно только при наличии на балансе Кошелька суммы равной или большей суммарной стоимости
                    заказанных услуг.
                </p>
                <p>
                    • 4.6. Оплата безналичным переводом на основании выставленного счета для пользователей с профилем
                    юридического лица или индивидуального предпринимателя, и/или сформированной квитанции для частных
                    пользователей должна производиться с указанием номера профиля пользователя, а также иных реквизитов,
                    идентифицирующих платеж, в том числе наименования и ИНН для пользователей с профилем юридического
                    лица или индивидуального предпринимателя.
                </p>
                <p>
                    • 4.7. Заказ услуг должен быть оплачен полностью одним способом платежа.
                </p>
                <p>
                    • 4.8. В случае отмены услуги до начала ее предоставления, прекращения услуги по любому иному
                    основанию, неиспользованная сумма (при её наличии) зачисляется на Кошелек пользователя, независимо
                    от того, каким способом была совершена ранее оплата услуг.
                </p>
                <p>
                    • 4.9. По истечении 12 месяцев с даты последнего пополнения Кошелька неиспользованные пользователем
                    денежные средства не могут быть выведаны на расчетный счет пользователя.
                </p>
                <p>
                    • 4.10. В отношении услуг, которые Компания оказывает пользователям с профилями юридического лица
                    или индивидуального предпринимателя по счету, в целях исполнения требований законодательства
                    Российской Федерации о бухгалтерском учете, Компания по запросу Пользователя может ежеквартально
                    направлять по адресу электронной почты пользователю.
                </p>
                <p>
                    • 4.11. Если Компания не получает письменные мотивированные возражения пользователя относительно
                    оказанных услуг в течение 5 дней с даты завершения услуги, услуги считаются оказанными Компанией
                    надлежащим образом и принятыми пользователем в полном объеме.
                </p>
                <p>
                    • 4.12. Ответственность за получение любых документов (в том числе УПД, сообщений, уведомлений)
                    вышеуказанными способами лежит на пользователе. Компания не несет ответственности за задержку или
                    неполучение пользователем документов, если это явилось результатом неисправности систем связи,
                    действия/бездействия провайдеров, операторов связи, утраты пользователем доступа к профилю или иных
                    обстоятельств, находящихся вне зоны контроля Компании.
                </p>
                <p>
                    • 4.13. Наименование, юридический адрес, почтовый адрес, ИНН, КПП, контактная и прочая информация в
                    платежных документах, УПД и других документах, формируемых Компанией, указываются в соответствии с
                    реквизитами пользователя, указанными в его профиле.
                </p>
                <p>
                    • 4.14. Пользователь самостоятельно несет ответственность за правильность данных, указанных в
                    профиле пользователя на Бизнес my life. При изменении реквизитов, вы обязаны незамедлительно внести
                    соответствующие изменения в вашем профиле. Если вы не можете самостоятельно изменить данные в
                    профиле, вы обязаны уведомить Компанию о таких изменениях по установленной Компанией форме с
                    приложением, по запросу Компании, подтверждающих изменения документов.
                </p>
                <p>
                    • 4.15. Компания вправе проверять правильность указанных в профиле реквизитов пользователя путем
                    сверки с данными, содержащимися в едином государственном реестре юридических лиц (индивидуальных
                    предпринимателей) и, при наличии расхождений, корректировать соответствующие данные, используя в
                    качестве идентификатора пользователя указанный им ИНН.
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>5. Ответственность</b>
                <p>
                    • 5.1. Компания обязуется предпринимать все разумные усилия для надлежащего оказания услуг, однако
                    не отвечает и не компенсирует убытки пользователя в случае, если пользователь не может
                    воспользоваться услугами по следующим причинам:
                    <p>
                        - технологические неисправности каналов связи общего пользования, посредством которых
                        осуществляется доступ к услугам, утраты пользователем доступа в интернет по любой причине,
                        ошибки, пропуски, перерывы в работе или передаче данных, дефекты линий связи и иные технические
                        сбои или при наступлении обстоятельств непреодолимой силы;

                    </p>
                    <p>
                        - несанкционированное вмешательство в работу Бизнес my life третьих лиц, включая хакерские,
                        DDoS-атаки, действия вирусных программ и иные нарушения работы Бизнес my life.
                    </p>
                    <p>
                        - утрата пользователем доступа к профилю, в том числе при наличии признаков несанкционированного
                        его использования;
                    </p>
                </p>

                <p>
                    • 5.2. В случае причинения убытков пользователю по вине Компании ответственность определяется в
                    порядке, предусмотренном законодательством Российской Федерации. При этом ответственность Компании
                    перед пользователями, использующими Бизнес my life для предпринимательской и иной профессиональной
                    деятельности, определяется в размере, не превышающем стоимость заказанной и оплаченной
                    пользователем, но не полученной по вине Компании услуги или, если применимо, в сумме, не превышающей
                    баланс Кошелька пользователя на момент причинения убытков и исключительно в случае потери
                    пользователем части или всей суммы денежных средств на балансе Кошелька по вине Компании.
                </p>
                <p>
                    • 5.3. Компания не отвечает перед пользователем за убытки, возникшие у пользователя не по вине
                    Компании, в том числе в связи с нарушением пользователем правил Бизнес my life.
                </p>
                <p>
                    • 5.4. Компания не отвечает за перевод или поступление денежных средств пользователя в оплату услуг.
                    Безопасность, конфиденциальность, а также иные условия использования выбранных вами способов оплаты
                    определяются соглашениями между пользователем и соответствующими организациями по приему платежей.
                </p>
                <p>
                    • 5.5. Пользователь отвечаете за любые действия, совершенные на Бизнес my life с использованием
                    ваших данных для входа на Бизнес my life (в том числе за действия работников и третьих лиц) и за
                    убытки, которые могут возникнуть по причине несанкционированного использования вашего профиля и
                    простой электронной подписи.
                </p>
                <p>
                    • 5.6. В случае кражи, утери данных для входа вы самостоятельно предпринимаете необходимые меры для
                    смены пароля для доступа к профилю. Компания не несет ответственность за действия третьих лиц,
                    повлекшие кражу, утерю ваших данных для входа, а также любые расходы, ущерб или упущенную выгоду,
                    возникшие у пользователя в связи с несанкционированным доступом третьих лиц к профилю пользователя.
                </p>
                <p>
                    • 5.7. Компания не гарантирует соответствие сервисов Бизнес my life ожиданиям пользователя и
                    получение пользователем отклика посетителей на размещенные им объявлений или рекламных материалов в
                    связи с получением услуг на Бизнес my life.
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>7. Прочие условия</b>
                <p>
                    • 7.1. Договор вступает в силу с момента его заключения в порядке, указанном в разделе 2 оферты, и
                    действует в течение неопределенного срока до момента его прекращения в соответствии с Договором и
                    законодательством.
                </p>
                <p>
                    • 7.2. Компания вправе изменять и/или дополнять оферту в той мере, в какой это допускается
                    законодательством, а также отозвать оферту в любое время. Датой изменения оферты является дата
                    опубликования на Бизнес my life новой редакции оферты. Вы должны ознакомиться с действующей
                    редакцией оферты, размещенной на Бизнес my life, и с условиями каждой сделки до заключения Договора
                    и совершения сделки.
                </p>
                <p>
                    • 7.3. Заказ услуг и совершение пользователем сделки в рамках Договора после вступления в силу
                    изменений в оферте означает, что стороны пришли к согласию о внесении соответствующих изменений
                    и/или дополнений в Договор. Такие изменения в Договоре не применяются к услугам, заказанным и
                    оплаченным пользователем до вступления в силу изменений в оферте.
                </p>
                <p>
                    • 7.4. Компания вправе расторгнуть Договор в порядке, предусмотренном законодательством, в том числе
                    в случае однократного нарушения пользователем законодательства, условий Договора или правил Бизнес
                    my life, с уведомлением об этом пользователя через интерфейс Бизнес my life, по адресу электронной
                    почты или иным способом.
                </p>
                <p>
                    • 7.5. Компания вправе указывать наименование, товарный знак и общее описание услуг пользователя с
                    профилем юридического лица или индивидуального предпринимателя в своих маркетинговых материалах.
                </p>
                <p>
                    • 7.6. Договор представляет все условия, согласованные между сторонами в отношении его предмета, и
                    заменяет собой все прежние договоренности, заверения и любого рода соглашения между сторонами в
                    отношении его предмета, если иное прямо не согласовано сторонами.
                </p>
                <p>
                    • 7.7. Обязательства сторон по Договору, которые в силу своей природы должны продолжать действовать
                    (включая обязательства в отношении предоставленных гарантий, конфиденциальности, проведения
                    взаиморасчетов, но, не ограничиваясь указанным), остаются в силе после окончания срока действия
                    Договора до полного исполнения.
                </p>
                <p>
                    • Компания можете отказать в размещении объявлений или рекламных материалов без объяснения причин, в
                    одностороннем порядке. Средства пользователя вернутся на его кошелек.
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>8. Реквизиты Компании</b>
                <p>
                    Загружено: 01 февраля 2023 года.<br/>
                    <b className={"d-inline-block"}>БИЗНЕС MY LIFE</b> (business-mylife.ru)<br/>
                    Создатель и владелец - Индивидуальный предприниматель Найденов Роман Александрович<br/>
                    ИНН: 890604287041<br/>
                    ОГРНИП: 313502929500012
                </p>
            </div>
        </>
    )
};

export default OfferInf;