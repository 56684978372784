import React from "react"

const PrivacyPolicy: React.FC = () => {

    return (
        <>
            <h3>
                <p>
                    Политика конфиденциальности персональных данных портала БИЗНЕС MY LIFE (business-mylife.ru)
                </p>
            </h3>
            <div className={"paragraph"}>
                <div className={"paragraph"}>
                    Настоящая политика конфиденциальности персональных данных портала БИЗНЕС MY LIFE
                    (http://business-mylife.ru) действует в отношении всех разделов и информации, которая расположена на
                    доменном имени business-mylife.ru (а также его субдоменах).
                    Пользуясь нашими продуктами, вы доверяете нам свою личную информацию.
                    <p></p>
                </div>
            </div>
            <div className={"paragraph"}>
                1. Общие положения
                <p>
                    • 1. Использование портала (сайта) бизнес тематики “БИЗНЕС MY LIFE”
                    Пользователем означает согласие с настоящей Политикой конфиденциальности и условиями обработки
                    персональных данных Пользователя.
                </p>
                <p>
                    • 2. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить
                    использование портала (сайта) business-mylife.ru.
                </p>
                <p>
                    • 3. Настоящая Политика конфиденциальности применяется к порталу (сайту) business-mylife.ru. “БИЗНЕС
                    MY LIFE” не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь
                    может перейти по ссылкам рекламы или информации доступной на сайте Портала (сайта) “БИЗНЕС MY LIFE”.
                </p>
                <p>
                    • 4. Администрация портала “БИЗНЕС MY LIFE” не проверяет достоверность персональных данных,
                    предоставляемых Пользователем.
                </p>
            </div>
            <div className={"paragraph"}>
                2. Предмет политики конфиденциальности
                <p>
                    • 1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайта по
                    неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые
                    пользователь решили скрыть, от других сервисов и пользователей на портале (сайте) “БИЗНЕС MY LIFE”.
                </p>
                <p>
                    • 2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности,
                    предоставляются Пользователем путём заполнения форм на портале (сайте) “БИЗНЕС MY LIFE” и включают в
                    себя следующую информацию:
                </p>
                <p>
                    • 2.1 Название ИП, ООО
                </p>
                <p>
                    • 2.2. фамилию, имя, отчество Пользователя;
                </p>
                <p>
                    • 2.3. контактный телефон Пользователя;
                </p>
                <p>
                    • 2.4. адрес электронной почты (e-mail);
                </p>
                <p>
                    • 2.5. место жительство Пользователя, адрес регистрации юридического лица (при необходимости)
                </p>
                <p>
                    • 2.6. адрес доставки Товара (при необходимости)
                </p>
                <p>
                    • 2.7 фотографии (при необходимости).
                </p>
                <p>
                    • 3. “БИЗНЕС MY LIFE” защищает Данные, которые автоматически передаются при посещении страниц:
                </p>
                <p>
                    • - IP адрес, информация из cookies, информация о браузере, время доступа, реферер (адрес предыдущей
                    страницы).
                </p>
                <p>
                    • 4. Отключение cookies может повлечь невозможность доступа к частям сайта, требующим авторизации.
                </p>
                <p>
                    • 5. Любая конфиденциальная персональная информация Пользователя, о которой Пользователь предупредил
                    Администрацию портала (сайта) “БИЗНЕС MY LIFE” как закрытую подлежит надежному хранению и не
                    распространять, за исключением случаев, предусмотренных в п.п. 6 данного раздела, настоящей Политики
                    конфиденциальности.
                </p>
                <p>
                    • 6. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
                    власти Российской Федерации только по основаниям и в порядке, установленным законодательством
                    Российской Федерации.
                </p>
            </div>
            <div className={"paragraph"}>
                3. Цели сбора персональной информации пользователя
                <p>
                    • 1. Персональные данные Пользователя Администрация может использовать в целях:
                </p>
                <p>
                    • 1.1. Идентификации Пользователя, зарегистрированного на портале (сайте) для его дальнейшей
                    авторизации, оформления заказа и других действий.</p>
                <p>
                    • 1.2. Предоставления Пользователю доступа к персонализированным данным портала (сайта) “БИЗНЕС MY
                    LIFE”
                </p>
                <p>
                    • 1.3. Установления с Пользователем обратной связи, включая направление уведомлений, запросов,
                    касающихся использования Портала (сайта) “БИЗНЕС MY LIFE”, оказания услуг и обработки запросов и
                    заявок от Пользователя.
                </p>
                <p>
                    • 1.4. Определения места нахождения Пользователя для обеспечения безопасности, предотвращения
                    мошенничества.
                </p>
                <p>
                    • 1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
                </p>
                <p>
                    • 1.6. Создания учетной записи для использования частей и разделов портала (сайта) “БИЗНЕС MY LIFE”,
                    если Пользователь дал согласие на создание учетной записи.
                </p>
                <p>
                    • 1.7. Уведомления Пользователя по электронной почте.
                </p>
                <p>
                    • 1.8. Предоставления Пользователю с его согласия специальных предложений, информации о ценах,
                    новостной рассылки и иных сведений от имени портала (сайта) “БИЗНЕС MY LIFE”
                </p>
                <p>
                    • 1.9. Осуществления рекламной деятельности с согласия Пользователя.
                </p>
            </div>
            <div className={"paragraph"}>
                4. Способы и сроки обработки персональной информации
                <p>
                    • 1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным
                    способом, в том числе в информационных системах персональных данных с использованием средств
                    автоматизации или без использования таких средств.
                </p>
                <p>
                    • 2. При утрате или разглашении персональных данных Администрация вправе не информировать
                    Пользователя об утрате или разглашении персональных данных.
                </p>
                <p>
                    • 3. Администрация принимает необходимые организационные и технические меры для защиты персональной
                    информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения,
                    блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.
                </p>
                <p>
                    • 4. Администрация совместно с Пользователем принимает все необходимые меры по предотвращению
                    убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных
                    Пользователя.
                </p>
            </div>
            <div className={"paragraph"}>
                5. Права и обязанности сторон
                <p>
                    • 1. Пользователь вправе:
                </p>
                <p>
                    • 1.1. Принимать свободное решение о предоставлении своих персональных данных, необходимых для
                    использования портала (сайта) “БИЗНЕС MY LIFE”, и давать согласие на их обработку.
                </p>
                <p>
                    • 1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения
                    данной информации.
                </p>
                <p>
                    • 2. Администрация обязана:
                </p>
                <p>
                    • 2.1. Использовать полученную информацию исключительно для целей, указанных в п. 3 настоящей
                    Политики конфиденциальности.
                </p>
                <p>
                    • 2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного
                    письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо
                    разглашение иными возможными способами переданных персональных данных Пользователя.
                </p>
                <p>
                    • 2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных
                    Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем
                    деловом обороте.
                </p>
            </div>
            <div className={"paragraph"}>
                6. Ответственность сторон
                <p>
                    • 1. В случае утраты или разглашения Конфиденциальной информации Администрация не несёт
                    ответственность, если данная конфиденциальная информация:
                </p>
                <p>
                    • 1.1. Стала публичным достоянием до её утраты или разглашения.
                </p>
                <p>
                    • 1.2. Была получена от третьей стороны до момента её получения Администрацией Ресурса.
                </p>
                <p>
                    • 1.3. Была разглашена с согласия Пользователя.
                </p>
                <p>
                    • 2. Пользователь несет полную ответственность за размещенную информацию в своих объявлениях, за
                    соблюдение требований законодательства РФ, в том числе законов о рекламе, о защите авторских и
                    смежных прав, об охране товарных знаков и знаков обслуживания, но не ограничиваясь перечисленным,
                    включая полную ответственность за содержание и форму материалов.
                </p>
                <p>
                    • 2.1. Пользователь признает, что ответственность за любую информацию (в том числе, но не
                    ограничиваясь: файлы с данными, тексты и т. д.), к которой он может иметь доступ как к части портала
                    (сайта) “БИЗНЕС MY LIFE”, несет лицо, предоставившее такую информацию.
                </p>
                <p>
                    • 2.2. Пользователь соглашается, что информация, предоставленная ему на портале (сайте) “БИЗНЕС MY
                    LIFE”, может являться объектом интеллектуальной собственности, права на который защищены и
                    принадлежат другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию
                    на портале (сайте) “БИЗНЕС MY LIFE”
                </p>
                <p>
                    • 3. В отношение всех материалов (статей, публикаций, фотографий и т.д.) находящихся в свободном
                    публичном доступе на портале (сайте) “БИЗНЕС MY LIFE” допускается их распространение при условии,
                    что будет дана ссылка на “БИЗНЕС MY LIFE”
                </p>
                <p>
                    • 4. Администрация не несет ответственности перед Пользователем за любой убыток или ущерб,
                    понесенный Пользователем в результате использования персональных данных, статей, материалов, рекламы
                    и прочих разделов на портале (сайте) “БИЗНЕС MY LIFE”.
                </p>
                <p>
                    • 5. Администрация “БИЗНЕС MY LIFE” не несет ответственности за любые прямые или косвенные убытки,
                    произошедшие из-за: использования либо невозможности использования сайта, либо отдельных сервисов;
                    несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего
                    лица на сайте.
                </p>
                <p>
                    • 6. Администрация не несет ответственность за какую-либо информацию, размещенную пользователем на
                    портале (сайте) “БИЗНЕС MY LIFE”, включая, но не ограничиваясь: информацию, защищенную авторским
                    правом, без прямого согласия владельца авторского права.
                </p>
            </div>
            <div className={"paragraph"}>
                7. Разрешение споров
                <p>
                    • 1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и
                    Администрацией, обязательным является предъявление претензии (письменного предложения или
                    предложения в электронном виде о добровольном урегулировании спора).
                </p>
                <p>
                    • 2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно или в
                    электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.
                </p>
                <p>
                    • 3. При не достижении соглашения спор будет передан на рассмотрение Арбитражного суда г. Москва.
                </p>
                <p>
                    • 4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией
                    применяется действующее законодательство Российской Федерации.
                </p>
            </div>
            <div className={"paragraph"}>
                8. Дополнительные условия
                <p>
                    • 1. Администрация вправе вносить изменения в настоящую Политику конфиденциальности без согласия
                    Пользователя.
                </p>
                <p>
                    • 2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на портале (сайте)
                    “БИЗНЕС MY LIFE”, если иное не предусмотрено новой редакцией Политики конфиденциальности.
                </p>
                <p>
                    • 3. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать
                    по адресу: <a href={"mailto:info-hd1@mail.ru"}>info-hd1@mail.ru</a>
                </p>
            </div>
            <div className={"paragraph"}>
                <b className={"d-inline-block"}>БИЗНЕС MY LIFE</b> (business-mylife.ru)
                Создатель и владелец - Индивидуальный предприниматель Найденов Роман Александрович
                ИНН: 890604287041
                ОГРНИП: 313502929500012
            </div>

        </>
    )
}

export default PrivacyPolicy