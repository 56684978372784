interface City {
  address: string,
  postal_code: string | number,
  country: string,
  federal_district: string,
  region_type: string,
  region: string,
  area_type: string,
  area: string,
  city_type: string,
  city: string,
  settlement_type: string,
  settlement: string,
  kladr_id: string | number,
  fias_id: string | number,
  fias_level: number,
  capital_marker: number,
  okato: string | number,
  oktmo: string | number,
  tax_office: string | number,
  timezone: string,
  geo_lat: number,
  geo_lon: number,
  population: number,
  foundation_year: number
}

const citiesObj: City[] = [
  {
    "address": "Респ Адыгея, г Адыгейск",
    "postal_code": 385200,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Адыгея",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Адыгейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0100000200000",
    "fias_id": "ccdfd496-8108-4655-aadd-bd228747306d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 79403000000,
    "oktmo": 79703000001,
    "tax_office": "0107",
    "timezone": "UTC+3",
    "geo_lat": 44.878414,
    "geo_lon": 39.190289,
    "population": 12689,
    "foundation_year": 1969
  },
  {
    "address": "г Майкоп",
    "postal_code": 385000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Адыгея",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Майкоп",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0100000100000",
    "fias_id": "8cfbe842-e803-49ca-9347-1ef90481dd98",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 79401000000,
    "oktmo": 79701000001,
    "tax_office": "0105",
    "timezone": "UTC+3",
    "geo_lat": 44.6098268,
    "geo_lon": 40.1006606,
    "population": 144055,
    "foundation_year": 1857
  },
  {
    "address": "г Горно-Алтайск",
    "postal_code": 649000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Алтай",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Горно-Алтайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0400000100000",
    "fias_id": "0839d751-b940-4d3d-afb6-5df03fdd7791",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 84401000000,
    "oktmo": 84701000001,
    "tax_office": "0400",
    "timezone": "UTC+7",
    "geo_lat": 51.9581028,
    "geo_lon": 85.9603235,
    "population": 62861,
    "foundation_year": 1830
  },
  {
    "address": "Алтайский край, г Алейск",
    "postal_code": 658125,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Алейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000200000,
    "fias_id": "ae716080-f27b-40b6-a555-cf8b518e849e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01403000000",
    "oktmo": "01703000001",
    "tax_office": 2201,
    "timezone": "UTC+7",
    "geo_lat": 52.4922513,
    "geo_lon": 82.7793606,
    "population": 28528,
    "foundation_year": 1913
  },
  {
    "address": "г Барнаул",
    "postal_code": 656000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Барнаул",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000100000,
    "fias_id": "d13945a8-7017-46ab-b1e6-ede1e89317ad",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "01401000000",
    "oktmo": "01701000001",
    "tax_office": 2200,
    "timezone": "UTC+7",
    "geo_lat": 53.3479968,
    "geo_lon": 83.7798064,
    "population": 635585,
    "foundation_year": 1730
  },
  {
    "address": "Алтайский край, г Белокуриха",
    "postal_code": 659900,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Белокуриха",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000300000,
    "fias_id": "e4edca96-9b86-4cac-8c7f-cc93d9ba4cd1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01404000000",
    "oktmo": "01704000001",
    "tax_office": 2204,
    "timezone": "UTC+7",
    "geo_lat": 51.996152,
    "geo_lon": 84.9839604,
    "population": 15072,
    "foundation_year": 1846
  },
  {
    "address": "Алтайский край, г Бийск",
    "postal_code": 659300,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000400000,
    "fias_id": "52f876f6-cb1d-4f23-a22f-b692609fc1e0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01405000000",
    "oktmo": "01705000001",
    "tax_office": 2204,
    "timezone": "UTC+7",
    "geo_lat": 52.5393864,
    "geo_lon": 85.2138453,
    "population": 203826,
    "foundation_year": 1709
  },
  {
    "address": "Алтайский край, г Горняк",
    "postal_code": 658420,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "р-н",
    "area": "Локтевский",
    "city_type": "г",
    "city": "Горняк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2202700100000,
    "fias_id": "094b3627-2699-4782-8492-4d82aac71958",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "01225501000",
    "oktmo": "01625101001",
    "tax_office": 2209,
    "timezone": "UTC+7",
    "geo_lat": 50.9979622,
    "geo_lon": 81.4643358,
    "population": 13040,
    "foundation_year": 1942
  },
  {
    "address": "Алтайский край, г Заринск",
    "postal_code": 659100,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Заринск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200001100000,
    "fias_id": "142e04ef-dec1-44fa-b553-fac215764374",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01406000000",
    "oktmo": "01706000001",
    "tax_office": 2208,
    "timezone": "UTC+7",
    "geo_lat": 53.7063476,
    "geo_lon": 84.9315081,
    "population": 47035,
    "foundation_year": 1748
  },
  {
    "address": "Алтайский край, г Змеиногорск",
    "postal_code": 658480,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "р-н",
    "area": "Змеиногорский",
    "city_type": "г",
    "city": "Змеиногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2201500100000,
    "fias_id": "e7001b8f-d104-4873-96d4-66339f5e530a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "01214501000",
    "oktmo": "01614101001",
    "tax_office": 2209,
    "timezone": "UTC+7",
    "geo_lat": 51.1581094,
    "geo_lon": 82.1872547,
    "population": 10569,
    "foundation_year": 1736
  },
  {
    "address": "Алтайский край, г Камень-на-Оби",
    "postal_code": 658700,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "р-н",
    "area": "Каменский",
    "city_type": "г",
    "city": "Камень-на-Оби",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2201800100000,
    "fias_id": "810ca9c7-f10e-4def-9c48-f0aa83168ca7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "01216501000",
    "oktmo": "01616101001",
    "tax_office": 2207,
    "timezone": "UTC+7",
    "geo_lat": 53.7913974,
    "geo_lon": 81.3545053,
    "population": 41787,
    "foundation_year": 1751
  },
  {
    "address": "Алтайский край, г Новоалтайск",
    "postal_code": 658041,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новоалтайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000800000,
    "fias_id": "aa288d9f-4b2a-42a6-97f0-3502dddfa383",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01413000000",
    "oktmo": "01713000001",
    "tax_office": 2208,
    "timezone": "UTC+7",
    "geo_lat": 53.4119759,
    "geo_lon": 83.9311069,
    "population": 73134,
    "foundation_year": 1736
  },
  {
    "address": "Алтайский край, г Рубцовск",
    "postal_code": 658200,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Рубцовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200000900000,
    "fias_id": "65db5c88-c65c-43f0-9c21-13e15a032d4a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01416000000",
    "oktmo": "01716000001",
    "tax_office": 2209,
    "timezone": "UTC+7",
    "geo_lat": 51.5012067,
    "geo_lon": 81.2078695,
    "population": 146386,
    "foundation_year": 1892
  },
  {
    "address": "Алтайский край, г Славгород",
    "postal_code": 658820,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Славгород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200001000000,
    "fias_id": "94ae181d-b153-4dd1-8a2f-12852919875f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01419000000",
    "oktmo": "01719000001",
    "tax_office": 2210,
    "timezone": "UTC+7",
    "geo_lat": 52.999463,
    "geo_lon": 78.6459232,
    "population": 30370,
    "foundation_year": 1910
  },
  {
    "address": "Алтайский край, г Яровое",
    "postal_code": 658837,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Алтайский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Яровое",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2200001200000,
    "fias_id": "e07d57ed-22ce-4020-b59c-f7aae6f091bf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "01430000000",
    "oktmo": "01730000001",
    "tax_office": 2210,
    "timezone": "UTC+7",
    "geo_lat": 52.9252146,
    "geo_lon": 78.5729775,
    "population": 18085,
    "foundation_year": 1943
  },
  {
    "address": "Амурская обл, г Белогорск",
    "postal_code": 676805,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Белогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000300000,
    "fias_id": "c528e99b-7e81-4290-9cda-8713884472a5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10410000000,
    "oktmo": 10710000001,
    "tax_office": 2804,
    "timezone": "UTC+9",
    "geo_lat": 50.9213415,
    "geo_lon": 128.4739471,
    "population": 68220,
    "foundation_year": 1860
  },
  {
    "address": "г Благовещенск",
    "postal_code": 675000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Благовещенск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000100000,
    "fias_id": "8f41253d-6e3b-48a9-842a-25ba894bd093",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 10401000000,
    "oktmo": 10701000001,
    "tax_office": 2801,
    "timezone": "UTC+9",
    "geo_lat": 50.290659,
    "geo_lon": 127.527198,
    "population": 214397,
    "foundation_year": 1856
  },
  {
    "address": "Амурская обл, г Завитинск",
    "postal_code": 676870,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "р-н",
    "area": "Завитинский",
    "city_type": "г",
    "city": "Завитинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800500100000,
    "fias_id": "7b39476f-0edb-4bc3-bb31-6cedfb2aa695",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 10221501000,
    "oktmo": 10621101001,
    "tax_office": 2813,
    "timezone": "UTC+9",
    "geo_lat": 50.1064111,
    "geo_lon": 129.4391813,
    "population": 11481,
    "foundation_year": 1906
  },
  {
    "address": "Амурская обл, г Зея",
    "postal_code": 676151,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Зея",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000400000,
    "fias_id": "581855f4-f0bc-44a5-a36e-a298279f9ec4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10412000000,
    "oktmo": 10712000001,
    "tax_office": 2815,
    "timezone": "UTC+9",
    "geo_lat": 53.7340088,
    "geo_lon": 127.265787,
    "population": 25042,
    "foundation_year": 1879
  },
  {
    "address": "Амурская обл, г Райчихинск",
    "postal_code": 676770,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Райчихинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000200000,
    "fias_id": "981b5f3a-f0b6-444f-a6fb-c2bc10804b31",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10420000000,
    "oktmo": 10720000001,
    "tax_office": 2813,
    "timezone": "UTC+9",
    "geo_lat": 49.7941615,
    "geo_lon": 129.4112492,
    "population": 20499,
    "foundation_year": 1932
  },
  {
    "address": "Амурская обл, г Свободный",
    "postal_code": 676450,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Свободный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000500000,
    "fias_id": "dd560dfa-3feb-48e2-aa3b-b71213e7441e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10430000000,
    "oktmo": 10730000001,
    "tax_office": 2807,
    "timezone": "UTC+9",
    "geo_lat": 51.3614103,
    "geo_lon": 128.1219729,
    "population": 58594,
    "foundation_year": 1912
  },
  {
    "address": "Амурская обл, г Сковородино",
    "postal_code": 676011,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "р-н",
    "area": "Сковородинский",
    "city_type": "г",
    "city": "Сковородино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2801700100000,
    "fias_id": "260c545f-883c-440a-852c-c39a65fe4104",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 10249501000,
    "oktmo": 10649101001,
    "tax_office": 2808,
    "timezone": "UTC+9",
    "geo_lat": 53.9871095,
    "geo_lon": 123.9437205,
    "population": 9561,
    "foundation_year": 1908
  },
  {
    "address": "Амурская обл, г Тында",
    "postal_code": 676280,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тында",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000600000,
    "fias_id": "007e010f-e110-4a55-90a7-c4acac623c9b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10432000000,
    "oktmo": 10732000001,
    "tax_office": 2808,
    "timezone": "UTC+9",
    "geo_lat": 55.1546441,
    "geo_lon": 124.7468904,
    "population": 35574,
    "foundation_year": 1907
  },
  {
    "address": "Амурская обл, г Циолковский",
    "postal_code": 676470,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Циолковский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800001000000,
    "fias_id": "f9ce3255-3f79-4457-919c-d180fa813b6a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10545000000,
    "oktmo": 10770000001,
    "tax_office": 2807,
    "timezone": "UTC+9",
    "geo_lat": 51.762481,
    "geo_lon": 128.1219846,
    "population": 6208,
    "foundation_year": 1961
  },
  {
    "address": "Амурская обл, г Шимановск",
    "postal_code": 676301,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Амурская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шимановск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2800000700000,
    "fias_id": "809f0591-bb18-40a2-9fac-7d3d0a22c0bf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 10440000000,
    "oktmo": 10740000001,
    "tax_office": 2807,
    "timezone": "UTC+9",
    "geo_lat": 52.0051886,
    "geo_lon": 127.7005458,
    "population": 19815,
    "foundation_year": 1910
  },
  {
    "address": "г Архангельск",
    "postal_code": 163000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Архангельск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900000100000,
    "fias_id": "06814fb6-0dc3-4bec-ba20-11f894a0faf5",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 11401000000,
    "oktmo": 11701000001,
    "tax_office": 2901,
    "timezone": "UTC+3",
    "geo_lat": 64.5394289,
    "geo_lon": 40.5169606,
    "population": 348716,
    "foundation_year": 1584
  },
  {
    "address": "Архангельская обл, г Вельск",
    "postal_code": 165150,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Вельский",
    "city_type": "г",
    "city": "Вельск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900200100000,
    "fias_id": "71051070-fce3-4a4d-a9d9-cd50f6dc6310",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11205501000,
    "oktmo": 11605101001,
    "tax_office": 2907,
    "timezone": "UTC+3",
    "geo_lat": 61.066,
    "geo_lon": 42.1032789,
    "population": 23885,
    "foundation_year": 1137
  },
  {
    "address": "Архангельская обл, г Каргополь",
    "postal_code": 164110,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Каргопольский",
    "city_type": "г",
    "city": "Каргополь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900600100000,
    "fias_id": "a670f317-f3c4-4dd6-9d27-fa68a8b28b6f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11218501000,
    "oktmo": 11518000001,
    "tax_office": 2918,
    "timezone": "UTC+3",
    "geo_lat": 61.5009724,
    "geo_lon": 38.9636966,
    "population": 10148,
    "foundation_year": 1447
  },
  {
    "address": "Архангельская обл, г Коряжма",
    "postal_code": 165650,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Коряжма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900000500000,
    "fias_id": "782acb50-ddfa-4483-bcbd-a5e667976036",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 11408000000,
    "oktmo": 11708000001,
    "tax_office": 2904,
    "timezone": "UTC+3",
    "geo_lat": 61.2885948,
    "geo_lon": 47.1003015,
    "population": 39629,
    "foundation_year": 1535
  },
  {
    "address": "Архангельская обл, г Котлас",
    "postal_code": 165300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Котласский",
    "city_type": "г",
    "city": "Котлас",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900800100000,
    "fias_id": "93adb246-540e-40a2-a0fd-e96ca6796589",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11410000000,
    "oktmo": 11710000001,
    "tax_office": 2904,
    "timezone": "UTC+3",
    "geo_lat": 61.2528972,
    "geo_lon": 46.633242,
    "population": 60562,
    "foundation_year": 1617
  },
  {
    "address": "Архангельская обл, г Мезень",
    "postal_code": 164750,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Мезенский",
    "city_type": "г",
    "city": "Мезень",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2901200100000,
    "fias_id": "8a49f67f-6c48-4160-bd1d-ebc188a69269",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11242501000,
    "oktmo": 11642101001,
    "tax_office": 2932,
    "timezone": "UTC+3",
    "geo_lat": 65.8398078,
    "geo_lon": 44.2532273,
    "population": 3599,
    "foundation_year": 1500
  },
  {
    "address": "Архангельская обл, г Мирный",
    "postal_code": 164170,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мирный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900000200000,
    "fias_id": "977c7606-1b37-4424-84f7-b0c66c3e4b94",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 11525000000,
    "oktmo": 11725000001,
    "tax_office": 2920,
    "timezone": "UTC+3",
    "geo_lat": 62.7645265,
    "geo_lon": 40.3360076,
    "population": 30259,
    "foundation_year": 1957
  },
  {
    "address": "Архангельская обл, г Новодвинск",
    "postal_code": 164900,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новодвинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900000300000,
    "fias_id": "3b0ef6c2-9bcd-44a5-afec-2723bd8190bc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 11415000000,
    "oktmo": 11715000001,
    "tax_office": 2903,
    "timezone": "UTC+3",
    "geo_lat": 64.4136851,
    "geo_lon": 40.8208143,
    "population": 40612,
    "foundation_year": 1936
  },
  {
    "address": "Архангельская обл, г Няндома",
    "postal_code": 164200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Няндомский",
    "city_type": "г",
    "city": "Няндома",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2901300100000,
    "fias_id": "491b0861-0da5-4781-9c2c-521df0ea7af6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11244501000,
    "oktmo": 11644101001,
    "tax_office": 2918,
    "timezone": "UTC+3",
    "geo_lat": 61.6654674,
    "geo_lon": 40.2062947,
    "population": 22354,
    "foundation_year": 1898
  },
  {
    "address": "Архангельская обл, г Онега",
    "postal_code": 164840,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Онежский",
    "city_type": "г",
    "city": "Онега",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2901400100000,
    "fias_id": "53e6ddd5-a1fb-4444-89a4-3952ef6f114b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11420000000,
    "oktmo": 11646101001,
    "tax_office": 2920,
    "timezone": "UTC+3",
    "geo_lat": 63.9162928,
    "geo_lon": 38.0805031,
    "population": 21359,
    "foundation_year": 1137
  },
  {
    "address": "Архангельская обл, г Северодвинск",
    "postal_code": 164500,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Северодвинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900000400000,
    "fias_id": "32747c72-c08b-4440-b340-91f48d72f9db",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 11430000000,
    "oktmo": 11730000001,
    "tax_office": 2932,
    "timezone": "UTC+3",
    "geo_lat": 64.5625385,
    "geo_lon": 39.8180934,
    "population": 192265,
    "foundation_year": 1936
  },
  {
    "address": "Архангельская обл, Котласский р-н, г Сольвычегодск",
    "postal_code": 165330,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Котласский",
    "city_type": "г",
    "city": "Сольвычегодск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2900800300000,
    "fias_id": "a56a44ee-f79d-4e51-b219-c3c828c7e0c0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 11227504000,
    "oktmo": 11627104001,
    "tax_office": 2904,
    "timezone": "UTC+3",
    "geo_lat": 61.3319616,
    "geo_lon": 46.920441,
    "population": 2460,
    "foundation_year": 1492
  },
  {
    "address": "Архангельская обл, г Шенкурск",
    "postal_code": 165160,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Архангельская",
    "area_type": "р-н",
    "area": "Шенкурский",
    "city_type": "г",
    "city": "Шенкурск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2901900100000,
    "fias_id": "74a26d75-38a6-485a-9468-52dd7c6711fa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 11258501000,
    "oktmo": 11658101001,
    "tax_office": 2907,
    "timezone": "UTC+3",
    "geo_lat": 62.1057272,
    "geo_lon": 42.8996973,
    "population": 5702,
    "foundation_year": 1137
  },
  {
    "address": "г Астрахань",
    "postal_code": 414000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Астрахань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3000000100000,
    "fias_id": "a101dd8b-3aee-4bda-9c61-9df106f145ff",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 12401000000,
    "oktmo": 12701000001,
    "tax_office": 3000,
    "timezone": "UTC+4",
    "geo_lat": 46.3655652,
    "geo_lon": 48.0559236,
    "population": 520662,
    "foundation_year": 1558
  },
  {
    "address": "Астраханская обл, г Ахтубинск",
    "postal_code": 416500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "р-н",
    "area": "Ахтубинский",
    "city_type": "г",
    "city": "Ахтубинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3000200100000,
    "fias_id": "8d7acd62-cef4-4d5c-8057-644ee454ce6c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 12205501000,
    "oktmo": 12605101001,
    "tax_office": 3022,
    "timezone": "UTC+4",
    "geo_lat": 48.2752034,
    "geo_lon": 46.1906462,
    "population": 41898,
    "foundation_year": 1768
  },
  {
    "address": "Астраханская обл, г Знаменск",
    "postal_code": 416540,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Знаменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3000000200000,
    "fias_id": "54ecd5a8-83d9-4a85-ae2c-6fe6976ab716",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 12519000000,
    "oktmo": 12719000001,
    "tax_office": 3022,
    "timezone": "UTC+4",
    "geo_lat": 48.5866291,
    "geo_lon": 45.7368019,
    "population": 29357,
    "foundation_year": 1948
  },
  {
    "address": "Астраханская обл, г Камызяк",
    "postal_code": 416340,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "р-н",
    "area": "Камызякский",
    "city_type": "г",
    "city": "Камызяк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3000600100000,
    "fias_id": "bc643e13-dcc5-4fa4-8cb1-94f71c5c18f4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 12225501000,
    "oktmo": 12625101001,
    "tax_office": 3023,
    "timezone": "UTC+4",
    "geo_lat": 46.110579,
    "geo_lon": 48.07333,
    "population": 16291,
    "foundation_year": 1560
  },
  {
    "address": "Астраханская обл, г Нариманов",
    "postal_code": 416111,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "р-н",
    "area": "Наримановский",
    "city_type": "г",
    "city": "Нариманов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3000900100000,
    "fias_id": "46ce55dd-e3e2-4c31-ab3c-273423c2f782",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 12240501000,
    "oktmo": 12640101001,
    "tax_office": 3023,
    "timezone": "UTC+4",
    "geo_lat": 46.6916565,
    "geo_lon": 47.8502476,
    "population": 11386,
    "foundation_year": 1963
  },
  {
    "address": "Астраханская обл, г Харабали",
    "postal_code": 416010,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Астраханская",
    "area_type": "р-н",
    "area": "Харабалинский",
    "city_type": "г",
    "city": "Харабали",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3001100100000,
    "fias_id": "08373220-e187-4094-82de-b8e0864c5f38",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 12245501000,
    "oktmo": 12645101001,
    "tax_office": 3022,
    "timezone": "UTC+4",
    "geo_lat": 47.408999,
    "geo_lon": 47.2525345,
    "population": 18209,
    "foundation_year": 1789
  },
  {
    "address": "Респ Башкортостан, г Агидель",
    "postal_code": 452920,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Агидель",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000200000",
    "fias_id": "8294b97c-7553-499e-8369-821d6eb0fdc7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80403000000,
    "oktmo": 80703000001,
    "tax_office": "0264",
    "timezone": "UTC+5",
    "geo_lat": 55.8999056,
    "geo_lon": 53.9220144,
    "population": 16365,
    "foundation_year": 1980
  },
  {
    "address": "Респ Башкортостан, г Баймак",
    "postal_code": 453630,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Баймакский",
    "city_type": "г",
    "city": "Баймак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200600100000",
    "fias_id": "874d4c58-31cd-4309-80db-8f14863efdbb",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80404000000,
    "oktmo": 80606101001,
    "tax_office": "0267",
    "timezone": "UTC+5",
    "geo_lat": 52.5912896,
    "geo_lon": 58.3110998,
    "population": 17710,
    "foundation_year": 1850
  },
  {
    "address": "Респ Башкортостан, г Белебей",
    "postal_code": 452000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Белебеевский",
    "city_type": "г",
    "city": "Белебей",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200900100000",
    "fias_id": "11e85c3e-c276-43b3-94e8-6b7f6cde5ac1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80405000000,
    "oktmo": 80609101001,
    "tax_office": "0269",
    "timezone": "UTC+5",
    "geo_lat": 54.1034582,
    "geo_lon": 54.1113129,
    "population": 60183,
    "foundation_year": 1757
  },
  {
    "address": "Респ Башкортостан, г Белорецк",
    "postal_code": 453500,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Белорецкий",
    "city_type": "г",
    "city": "Белорецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0201100100000",
    "fias_id": "dd65b1de-c076-4126-9b56-c4b4979e2b0f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80410000000,
    "oktmo": 80611101001,
    "tax_office": "0256",
    "timezone": "UTC+5",
    "geo_lat": 53.9676488,
    "geo_lon": 58.4100419,
    "population": 68804,
    "foundation_year": 1762
  },
  {
    "address": "Респ Башкортостан, г Бирск",
    "postal_code": 452450,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Бирский",
    "city_type": "г",
    "city": "Бирск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0201300100000",
    "fias_id": "8c049a5c-f080-4052-93a9-89decf401900",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80415000000,
    "oktmo": 80613101001,
    "tax_office": "0277",
    "timezone": "UTC+5",
    "geo_lat": 55.4155753,
    "geo_lon": 55.5582214,
    "population": 41637,
    "foundation_year": 1663
  },
  {
    "address": "Респ Башкортостан, г Благовещенск",
    "postal_code": 453430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Благовещенский",
    "city_type": "г",
    "city": "Благовещенск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0201500100000",
    "fias_id": "da7ef573-cdbe-458e-a790-b29ad3a3d140",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80417000000,
    "oktmo": 80615101001,
    "tax_office": "0277",
    "timezone": "UTC+5",
    "geo_lat": 55.0499867,
    "geo_lon": 55.9553186,
    "population": 34246,
    "foundation_year": 1756
  },
  {
    "address": "Респ Башкортостан, г Давлеканово",
    "postal_code": 453400,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Давлекановский",
    "city_type": "г",
    "city": "Давлеканово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0205900100000",
    "fias_id": "d820f602-8763-4f63-be89-10077a2f025b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80419000000,
    "oktmo": 80622101001,
    "tax_office": "0273",
    "timezone": "UTC+5",
    "geo_lat": 54.2226707,
    "geo_lon": 55.0312373,
    "population": 24040,
    "foundation_year": 1730
  },
  {
    "address": "Респ Башкортостан, г Дюртюли",
    "postal_code": 452320,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Дюртюлинский",
    "city_type": "г",
    "city": "Дюртюли",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0206000100000",
    "fias_id": "d4ac608f-5096-4529-bab2-68a26c64272e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80418000000,
    "oktmo": 80624101001,
    "tax_office": "0278",
    "timezone": "UTC+5",
    "geo_lat": 55.4848318,
    "geo_lon": 54.8524765,
    "population": 31272,
    "foundation_year": 1795
  },
  {
    "address": "Респ Башкортостан, г Ишимбай",
    "postal_code": 453200,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Ишимбайский",
    "city_type": "г",
    "city": "Ишимбай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0202600100000",
    "fias_id": "b3d2e10f-752d-4b90-b54b-9d6545f38ae0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80420000000,
    "oktmo": 80631101001,
    "tax_office": "0261",
    "timezone": "UTC+5",
    "geo_lat": 53.4545764,
    "geo_lon": 56.0438751,
    "population": 66259,
    "foundation_year": 1932
  },
  {
    "address": "Респ Башкортостан, г Кумертау",
    "postal_code": 453300,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кумертау",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000700000",
    "fias_id": "48e38991-07fd-4aaa-b240-a7280e4a823f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80423000000,
    "oktmo": 80723000001,
    "tax_office": "0261",
    "timezone": "UTC+5",
    "geo_lat": 52.7564939,
    "geo_lon": 55.7970197,
    "population": 62854,
    "foundation_year": 1947
  },
  {
    "address": "Респ Башкортостан, г Межгорье",
    "postal_code": 453570,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Межгорье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000800000",
    "fias_id": "5fd3a551-8553-4571-bfc8-2bfb211686f5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80507000000,
    "oktmo": 80707000001,
    "tax_office": "0256",
    "timezone": "UTC+5",
    "geo_lat": 54.2397689,
    "geo_lon": 57.9614547,
    "population": 17353,
    "foundation_year": 1979
  },
  {
    "address": "Респ Башкортостан, г Мелеуз",
    "postal_code": 453853,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Мелеузовский",
    "city_type": "г",
    "city": "Мелеуз",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0203500100000",
    "fias_id": "c516337d-f590-439b-83ad-6f15a9325740",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80425000000,
    "oktmo": 80641101001,
    "tax_office": "0261",
    "timezone": "UTC+5",
    "geo_lat": 52.9589532,
    "geo_lon": 55.9282838,
    "population": 61408,
    "foundation_year": 1766
  },
  {
    "address": "Респ Башкортостан, г Нефтекамск",
    "postal_code": 452680,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нефтекамск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000300000",
    "fias_id": "2c9997d2-ce94-431a-96c9-722d2238d5c8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80427000000,
    "oktmo": 80727000001,
    "tax_office": "0264",
    "timezone": "UTC+5",
    "geo_lat": 56.088377,
    "geo_lon": 54.2483061,
    "population": 121757,
    "foundation_year": 1957
  },
  {
    "address": "Респ Башкортостан, г Октябрьский",
    "postal_code": 452601,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Октябрьский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000400000",
    "fias_id": "abd1bc35-ec51-437a-abee-76a4f620f662",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80435000000,
    "oktmo": 80735000001,
    "tax_office": "0269",
    "timezone": "UTC+5",
    "geo_lat": 54.4815311,
    "geo_lon": 53.4655972,
    "population": 109379,
    "foundation_year": 1937
  },
  {
    "address": "Респ Башкортостан, г Салават",
    "postal_code": 453250,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Салават",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000500000",
    "fias_id": "fc14353f-5a1d-4f22-94ea-b900bad9a9f1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80439000000,
    "oktmo": 80739000001,
    "tax_office": "0261",
    "timezone": "UTC+5",
    "geo_lat": 53.3616974,
    "geo_lon": 55.9245224,
    "population": 156085,
    "foundation_year": 1948
  },
  {
    "address": "Респ Башкортостан, г Сибай",
    "postal_code": 453830,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сибай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000600000",
    "fias_id": "d7825646-76f7-4322-a88c-42cc90cbd2b2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 80443000000,
    "oktmo": 80743000001,
    "tax_office": "0267",
    "timezone": "UTC+5",
    "geo_lat": 52.7204651,
    "geo_lon": 58.6663783,
    "population": 62732,
    "foundation_year": 1925
  },
  {
    "address": "Респ Башкортостан, г Стерлитамак",
    "postal_code": 453100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Стерлитамак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200001400000",
    "fias_id": "84e0b23d-82fe-40a8-8739-55e679780dc3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80445000000,
    "oktmo": 80745000001,
    "tax_office": "0268",
    "timezone": "UTC+5",
    "geo_lat": 53.6300864,
    "geo_lon": 55.9317089,
    "population": 273432,
    "foundation_year": 1766
  },
  {
    "address": "Респ Башкортостан, г Туймазы",
    "postal_code": 452750,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Туймазинский",
    "city_type": "г",
    "city": "Туймазы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0204400100000",
    "fias_id": "511a0136-f60c-451b-a2eb-3402103f1223",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80450000000,
    "oktmo": 80651101001,
    "tax_office": "0269",
    "timezone": "UTC+5",
    "geo_lat": 54.5999224,
    "geo_lon": 53.6950623,
    "population": 66849,
    "foundation_year": 1912
  },
  {
    "address": "г Уфа",
    "postal_code": 450000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Уфа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0200000100000",
    "fias_id": "7339e834-2cb4-4734-a4c7-1fca2c66e562",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 80401000000,
    "oktmo": 80701000001,
    "tax_office": "0200",
    "timezone": "UTC+5",
    "geo_lat": 54.734944,
    "geo_lon": 55.9578468,
    "population": 1062300,
    "foundation_year": 1574
  },
  {
    "address": "Респ Башкортостан, г Учалы",
    "postal_code": 453700,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Учалинский",
    "city_type": "г",
    "city": "Учалы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0204600100000",
    "fias_id": "7b2b052a-49df-4aff-a36f-fb82957da2ea",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80455000000,
    "oktmo": 80653101001,
    "tax_office": "0256",
    "timezone": "UTC+5",
    "geo_lat": 54.3067375,
    "geo_lon": 59.4125461,
    "population": 37771,
    "foundation_year": 1750
  },
  {
    "address": "Респ Башкортостан, г Янаул",
    "postal_code": 452800,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Башкортостан",
    "area_type": "р-н",
    "area": "Янаульский",
    "city_type": "г",
    "city": "Янаул",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0205200100000",
    "fias_id": "21de303b-205d-4d37-81c9-63d10246e1af",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 80460000000,
    "oktmo": 80659101001,
    "tax_office": "0264",
    "timezone": "UTC+5",
    "geo_lat": 56.2650146,
    "geo_lon": 54.929907,
    "population": 26988,
    "foundation_year": 1730
  },
  {
    "address": "Белгородская обл, г Алексеевка",
    "postal_code": 309850,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Алексеевский",
    "city_type": "г",
    "city": "Алексеевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100200100000,
    "fias_id": "94969f84-fff4-4ddf-9677-b912b4b2aa3e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14410000000,
    "oktmo": 14710000001,
    "tax_office": 3122,
    "timezone": "UTC+3",
    "geo_lat": 50.6299647,
    "geo_lon": 38.6880342,
    "population": 39026,
    "foundation_year": 1685
  },
  {
    "address": "г Белгород",
    "postal_code": 308000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Белгород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100000100000,
    "fias_id": "02e9c019-ab4d-4fa0-928e-d6c0a41dc256",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 14401000000,
    "oktmo": 14701000001,
    "tax_office": 3123,
    "timezone": "UTC+3",
    "geo_lat": 50.5976472,
    "geo_lon": 36.5856652,
    "population": 356426,
    "foundation_year": 1596
  },
  {
    "address": "Белгородская обл, г Бирюч",
    "postal_code": 309920,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Красногвардейский",
    "city_type": "г",
    "city": "Бирюч",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3101200100000,
    "fias_id": "eaaf5809-d1e2-4d93-8f05-85dd598cc403",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14242501000,
    "oktmo": 14642151001,
    "tax_office": 3122,
    "timezone": "UTC+3",
    "geo_lat": 50.6484585,
    "geo_lon": 38.4005083,
    "population": 7842,
    "foundation_year": 1705
  },
  {
    "address": "Белгородская обл, г Валуйки",
    "postal_code": 309990,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Валуйский",
    "city_type": "г",
    "city": "Валуйки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100400100000,
    "fias_id": "fe7eea4a-875a-4235-aa61-81c2a37a0440",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14420000000,
    "oktmo": 14720000001,
    "tax_office": 3126,
    "timezone": "UTC+3",
    "geo_lat": 50.2111207,
    "geo_lon": 38.0998772,
    "population": 35322,
    "foundation_year": 1593
  },
  {
    "address": "Белгородская обл, г Грайворон",
    "postal_code": 309370,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Грайворонский",
    "city_type": "г",
    "city": "Грайворон",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100700100000,
    "fias_id": "51c31ef5-10b3-4011-8e1c-7e7d452afd1b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14232501000,
    "oktmo": 14725000001,
    "tax_office": 3116,
    "timezone": "UTC+3",
    "geo_lat": 50.4862958,
    "geo_lon": 35.6663877,
    "population": 6234,
    "foundation_year": 1678
  },
  {
    "address": "Белгородская обл, г Губкин",
    "postal_code": 309180,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Губкин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100000400000,
    "fias_id": "98b1ec30-6107-4f99-ae3a-9d82492f30a1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14430000000,
    "oktmo": 14730000001,
    "tax_office": 3127,
    "timezone": "UTC+3",
    "geo_lat": 51.2837123,
    "geo_lon": 37.5347759,
    "population": 88562,
    "foundation_year": 1939
  },
  {
    "address": "Белгородская обл, г Короча",
    "postal_code": 309210,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Корочанский",
    "city_type": "г",
    "city": "Короча",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3101000100000,
    "fias_id": "76fecead-fc02-459a-bce4-848698e645fe",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14240501000,
    "oktmo": 14640101001,
    "tax_office": 3120,
    "timezone": "UTC+3",
    "geo_lat": 50.8129041,
    "geo_lon": 37.1896436,
    "population": 5877,
    "foundation_year": 1637
  },
  {
    "address": "Белгородская обл, г Новый Оскол",
    "postal_code": 309640,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Новооскольский",
    "city_type": "г",
    "city": "Новый Оскол",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3101400100000,
    "fias_id": "f565b329-4041-4fd2-aa0a-debd5f7c3857",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14244501000,
    "oktmo": 14735000001,
    "tax_office": 3114,
    "timezone": "UTC+3",
    "geo_lat": 50.7633747,
    "geo_lon": 37.8775484,
    "population": 19530,
    "foundation_year": 1637
  },
  {
    "address": "Белгородская обл, г Старый Оскол",
    "postal_code": 309500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Старый Оскол",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100000200000,
    "fias_id": "9f5e86b1-b893-43dc-9dd4-ac66573b17d1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14440000000,
    "oktmo": 14740000001,
    "tax_office": 3128,
    "timezone": "UTC+3",
    "geo_lat": 51.2967101,
    "geo_lon": 37.8350182,
    "population": 221163,
    "foundation_year": 1593
  },
  {
    "address": "Белгородская обл, г Строитель",
    "postal_code": 309070,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "р-н",
    "area": "Яковлевский",
    "city_type": "г",
    "city": "Строитель",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3102100100000,
    "fias_id": "19c9f6de-45cd-4c76-9839-abd571698ced",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14258501000,
    "oktmo": 14755000001,
    "tax_office": 3130,
    "timezone": "UTC+3",
    "geo_lat": 50.7845099,
    "geo_lon": 36.4887648,
    "population": 23933,
    "foundation_year": 1958
  },
  {
    "address": "Белгородская обл, г Шебекино",
    "postal_code": 309290,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Белгородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шебекино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3100000300000,
    "fias_id": "dc80ddf0-b1ac-4344-98e8-fcfa5295a0a3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 14450000000,
    "oktmo": 14750000001,
    "tax_office": 3120,
    "timezone": "UTC+3",
    "geo_lat": 50.4004883,
    "geo_lon": 36.8877889,
    "population": 44277,
    "foundation_year": 1713
  },
  {
    "address": "г Брянск",
    "postal_code": 241000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Брянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000100000,
    "fias_id": "414b71cf-921e-4bfc-b6e0-f7395d16aaef",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 15401000000,
    "oktmo": 15701000001,
    "tax_office": 3200,
    "timezone": "UTC+3",
    "geo_lat": 53.2419535,
    "geo_lon": 34.3652146,
    "population": 415640,
    "foundation_year": 985
  },
  {
    "address": "Брянская обл, г Дятьково",
    "postal_code": 242600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Дятьковский",
    "city_type": "г",
    "city": "Дятьково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200600100000,
    "fias_id": "fd37b6f0-2406-41ca-86bc-57dd58fd71f4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15216501000,
    "oktmo": 15616104001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 53.5958178,
    "geo_lon": 34.3551812,
    "population": 29438,
    "foundation_year": 1626
  },
  {
    "address": "Брянская обл, г Жуковка",
    "postal_code": 242700,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Жуковский",
    "city_type": "г",
    "city": "Жуковка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200800100000,
    "fias_id": "bd51e883-748a-4c55-ac0a-eee31be1433e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15222501000,
    "oktmo": 15502000001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 53.5340397,
    "geo_lon": 33.7302579,
    "population": 18269,
    "foundation_year": 1871
  },
  {
    "address": "Брянская обл, г Злынка",
    "postal_code": 243600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Злынковский",
    "city_type": "г",
    "city": "Злынка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200900100000,
    "fias_id": "9491aba8-fbc1-4da8-8ede-8d49690c393a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15223501000,
    "oktmo": 15623101001,
    "tax_office": 3241,
    "timezone": "UTC+3",
    "geo_lat": 52.4267015,
    "geo_lon": 31.7360399,
    "population": 5507,
    "foundation_year": 1702
  },
  {
    "address": "Брянская обл, г Карачев",
    "postal_code": 242500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Карачевский",
    "city_type": "г",
    "city": "Карачев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3201000100000,
    "fias_id": "6f39b866-1fed-4add-aa00-e76992516660",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15224501000,
    "oktmo": 15624101001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 53.1296524,
    "geo_lon": 34.9888727,
    "population": 19715,
    "foundation_year": 1146
  },
  {
    "address": "Брянская обл, г Клинцы",
    "postal_code": 243140,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Клинцы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000300000,
    "fias_id": "59cb1f1f-16f9-47eb-9487-14a6c00e8621",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 15415000000,
    "oktmo": 15715000001,
    "tax_office": 3200,
    "timezone": "UTC+3",
    "geo_lat": 52.7529119,
    "geo_lon": 32.233911,
    "population": 62510,
    "foundation_year": 1707
  },
  {
    "address": "Брянская обл, г Мглин",
    "postal_code": 243220,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Мглинский",
    "city_type": "г",
    "city": "Мглин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3201600100000,
    "fias_id": "a4162075-13cb-4f4a-9974-ef042c8be149",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15236501000,
    "oktmo": 15636101001,
    "tax_office": 3253,
    "timezone": "UTC+3",
    "geo_lat": 53.0599771,
    "geo_lon": 32.8468129,
    "population": 7916,
    "foundation_year": 1387
  },
  {
    "address": "Брянская обл, г Новозыбков",
    "postal_code": 243020,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новозыбков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000400000,
    "fias_id": "cf4dd9d7-d152-4140-bed2-59d8be5a4e2c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 15420000000,
    "oktmo": 15720000001,
    "tax_office": 3241,
    "timezone": "UTC+3",
    "geo_lat": 52.537173,
    "geo_lon": 31.9357991,
    "population": 40552,
    "foundation_year": 1701
  },
  {
    "address": "Брянская обл, г Почеп",
    "postal_code": 243400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Почепский",
    "city_type": "г",
    "city": "Почеп",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3202000100000,
    "fias_id": "434c6101-648e-4266-8aca-bbbc80856d7a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15244501000,
    "oktmo": 15644101001,
    "tax_office": 3252,
    "timezone": "UTC+3",
    "geo_lat": 52.9154851,
    "geo_lon": 33.4744058,
    "population": 17933,
    "foundation_year": 1447
  },
  {
    "address": "Брянская обл, г Севск",
    "postal_code": 242440,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Севский",
    "city_type": "г",
    "city": "Севск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3202200100000,
    "fias_id": "263c5f6f-335b-4cfa-a234-3c61488c3729",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15248501000,
    "oktmo": 15648101001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 52.1483358,
    "geo_lon": 34.4918415,
    "population": 7282,
    "foundation_year": 1146
  },
  {
    "address": "Брянская обл, г Сельцо",
    "postal_code": 241550,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сельцо",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000200000,
    "fias_id": "485f4c4d-aced-4b94-b975-88e72b7dcb72",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 15425000000,
    "oktmo": 15725000001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 53.3739884,
    "geo_lon": 34.1059172,
    "population": 17933,
    "foundation_year": 1870
  },
  {
    "address": "Брянская обл, г Стародуб",
    "postal_code": 243240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Стародуб",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000600000,
    "fias_id": "78e19000-cb0d-4e86-8205-242ee9de9e75",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15250501000,
    "oktmo": 15501000001,
    "tax_office": 3253,
    "timezone": "UTC+3",
    "geo_lat": 52.5852257,
    "geo_lon": 32.760403,
    "population": 19010,
    "foundation_year": 1080
  },
  {
    "address": "Брянская обл, г Сураж",
    "postal_code": 243500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Суражский",
    "city_type": "г",
    "city": "Сураж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3202500100000,
    "fias_id": "565317d8-daf3-4df1-bf3d-6703b0d1500f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15254501000,
    "oktmo": 15654101001,
    "tax_office": 3253,
    "timezone": "UTC+3",
    "geo_lat": 53.0170888,
    "geo_lon": 32.3938878,
    "population": 11640,
    "foundation_year": 1600
  },
  {
    "address": "Брянская обл, г Трубчевск",
    "postal_code": 242220,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Трубчевский",
    "city_type": "г",
    "city": "Трубчевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3202600100000,
    "fias_id": "7c080d22-1f3c-43b0-ba63-1e7de99724c9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15256501000,
    "oktmo": 15656101001,
    "tax_office": 3252,
    "timezone": "UTC+3",
    "geo_lat": 52.5791734,
    "geo_lon": 33.7660547,
    "population": 15014,
    "foundation_year": 975
  },
  {
    "address": "Брянская обл, г Унеча",
    "postal_code": 243300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "р-н",
    "area": "Унечский",
    "city_type": "г",
    "city": "Унеча",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3202700100000,
    "fias_id": "6a2d5db9-1871-4f1d-b226-bbc6b8400a44",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 15258501000,
    "oktmo": 15658101001,
    "tax_office": 3253,
    "timezone": "UTC+3",
    "geo_lat": 52.8461199,
    "geo_lon": 32.6757629,
    "population": 26196,
    "foundation_year": 1887
  },
  {
    "address": "Брянская обл, г Фокино",
    "postal_code": 242610,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Брянская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Фокино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3200000500000,
    "fias_id": "8d7c89e7-d9b9-4d7a-907c-a654b3a4d23d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 15216504000,
    "oktmo": 15710000001,
    "tax_office": 3245,
    "timezone": "UTC+3",
    "geo_lat": 53.4554145,
    "geo_lon": 34.4159238,
    "population": 13874,
    "foundation_year": 1899
  },
  {
    "address": "Респ Бурятия, Кабанский р-н, г Бабушкин",
    "postal_code": 671230,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "р-н",
    "area": "Кабанский",
    "city_type": "г",
    "city": "Бабушкин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0300900200000",
    "fias_id": "519731a7-aace-435a-9a1c-1d0051ab7913",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 81224503000,
    "oktmo": 81624103001,
    "tax_office": "0309",
    "timezone": "UTC+8",
    "geo_lat": 51.7112755,
    "geo_lon": 105.8673219,
    "population": 4542,
    "foundation_year": 1760
  },
  {
    "address": "Респ Бурятия, г Гусиноозерск",
    "postal_code": 671160,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "р-н",
    "area": "Селенгинский",
    "city_type": "г",
    "city": "Гусиноозерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0301800100000",
    "fias_id": "30e1ab6c-4ee2-45ce-934a-c3435a015c29",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 81248501000,
    "oktmo": 81648101001,
    "tax_office": "0309",
    "timezone": "UTC+8",
    "geo_lat": 51.2865048,
    "geo_lon": 106.5230319,
    "population": 23280,
    "foundation_year": 1939
  },
  {
    "address": "Респ Бурятия, г Закаменск",
    "postal_code": 671950,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "р-н",
    "area": "Закаменский",
    "city_type": "г",
    "city": "Закаменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0300700100000",
    "fias_id": "0602f259-5de5-48e0-8a2c-427a5e0aef82",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 81221501000,
    "oktmo": 81621101001,
    "tax_office": "0309",
    "timezone": "UTC+8",
    "geo_lat": 50.372713,
    "geo_lon": 103.286699,
    "population": 11249,
    "foundation_year": 1934
  },
  {
    "address": "Респ Бурятия, г Кяхта",
    "postal_code": 671840,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "р-н",
    "area": "Кяхтинский",
    "city_type": "г",
    "city": "Кяхта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0301200100000",
    "fias_id": "5ef858d8-f7e6-4ea9-8276-53a25672bf9f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 81233501000,
    "oktmo": 81633101001,
    "tax_office": "0309",
    "timezone": "UTC+8",
    "geo_lat": 50.346543,
    "geo_lon": 106.4533516,
    "population": 20013,
    "foundation_year": 1727
  },
  {
    "address": "Респ Бурятия, г Северобайкальск",
    "postal_code": 671700,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Северобайкальск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0300000200000",
    "fias_id": "c4ea4370-0720-40a3-9df6-dd8ccf354d15",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 81420000000,
    "oktmo": 81720000001,
    "tax_office": "0309",
    "timezone": "UTC+8",
    "geo_lat": 55.635614,
    "geo_lon": 109.3361505,
    "population": 23673,
    "foundation_year": 1974
  },
  {
    "address": "г Улан-Удэ",
    "postal_code": 670000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Бурятия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Улан-Удэ",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0300000100000",
    "fias_id": "9fdcc25f-a3d0-4f28-8b61-40648d099065",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 81401000000,
    "oktmo": 81701000001,
    "tax_office": "0300",
    "timezone": "UTC+8",
    "geo_lat": 51.8335853,
    "geo_lon": 107.5842223,
    "population": 431922,
    "foundation_year": 1666
  },
  {
    "address": "Владимирская обл, г Александров",
    "postal_code": 601650,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Александровский",
    "city_type": "г",
    "city": "Александров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300200100000,
    "fias_id": "59e55956-69a2-432f-b880-74cf8ec0b9fe",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17205501000,
    "oktmo": 17605101001,
    "tax_office": 3339,
    "timezone": "UTC+3",
    "geo_lat": 56.391819,
    "geo_lon": 38.7111123,
    "population": 61544,
    "foundation_year": 1300
  },
  {
    "address": "г Владимир",
    "postal_code": 600000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Владимир",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300000100000,
    "fias_id": "f66a00e6-179e-4de9-8ecb-78b0277c9f10",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 17401000000,
    "oktmo": 17701000001,
    "tax_office": 3300,
    "timezone": "UTC+3",
    "geo_lat": 56.1280804,
    "geo_lon": 40.4084376,
    "population": 348256,
    "foundation_year": 990
  },
  {
    "address": "Владимирская обл, г Вязники",
    "postal_code": 601440,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Вязниковский",
    "city_type": "г",
    "city": "Вязники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300300100000,
    "fias_id": "0114e0c6-4060-4473-bc44-779e5a85e9dd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17210501000,
    "oktmo": 17610101001,
    "tax_office": 3332,
    "timezone": "UTC+3",
    "geo_lat": 56.29773,
    "geo_lon": 42.2687398,
    "population": 41252,
    "foundation_year": 1608
  },
  {
    "address": "Владимирская обл, г Гороховец",
    "postal_code": 601480,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Гороховецкий",
    "city_type": "г",
    "city": "Гороховец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300400100000,
    "fias_id": "e3bc9262-6bcf-42f8-a0bd-a8a00def58bc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17215501000,
    "oktmo": 17615101001,
    "tax_office": 3332,
    "timezone": "UTC+3",
    "geo_lat": 56.2021036,
    "geo_lon": 42.6926111,
    "population": 14015,
    "foundation_year": 1158
  },
  {
    "address": "Владимирская обл, г Гусь-Хрустальный",
    "postal_code": 601500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Гусь-Хрустальный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300000300000,
    "fias_id": "aa4aa0d7-f97f-4974-9291-c0a530a1ccb6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17420000000,
    "oktmo": 17720000001,
    "tax_office": 3304,
    "timezone": "UTC+3",
    "geo_lat": 55.6198751,
    "geo_lon": 40.6579929,
    "population": 60773,
    "foundation_year": 1756
  },
  {
    "address": "Владимирская обл, г Камешково",
    "postal_code": 601300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Камешковский",
    "city_type": "г",
    "city": "Камешково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300600100000,
    "fias_id": "13cf312e-735f-4faa-8a7d-9a2622d50438",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17225501000,
    "oktmo": 17625101001,
    "tax_office": 3340,
    "timezone": "UTC+3",
    "geo_lat": 56.3490152,
    "geo_lon": 40.9955183,
    "population": 13113,
    "foundation_year": 1892
  },
  {
    "address": "Владимирская обл, Александровский р-н, г Карабаново",
    "postal_code": 601640,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Александровский",
    "city_type": "г",
    "city": "Карабаново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300200200000,
    "fias_id": "4f178a20-53a7-4032-b62b-7337315e0fc1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17205505000,
    "oktmo": 17605105001,
    "tax_office": 3339,
    "timezone": "UTC+3",
    "geo_lat": 56.3131822,
    "geo_lon": 38.7034257,
    "population": 14868,
    "foundation_year": 1630
  },
  {
    "address": "Владимирская обл, г Киржач",
    "postal_code": 601010,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Киржачский",
    "city_type": "г",
    "city": "Киржач",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300700100000,
    "fias_id": "115db243-1238-412f-a2a1-6d53ef30ba9e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17230501000,
    "oktmo": 17630101001,
    "tax_office": 3316,
    "timezone": "UTC+3",
    "geo_lat": 56.1486863,
    "geo_lon": 38.8635701,
    "population": 30044,
    "foundation_year": 1332
  },
  {
    "address": "Владимирская обл, г Ковров",
    "postal_code": 601900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ковров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300000400000,
    "fias_id": "0b4978e2-e64c-4db1-b84d-93cf34bdb04b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17425000000,
    "oktmo": 17725000001,
    "tax_office": 3332,
    "timezone": "UTC+3",
    "geo_lat": 56.3554349,
    "geo_lon": 41.3170576,
    "population": 145492,
    "foundation_year": 1100
  },
  {
    "address": "Владимирская обл, г Кольчугино",
    "postal_code": 601780,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Кольчугинский",
    "city_type": "г",
    "city": "Кольчугино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300900100000,
    "fias_id": "8f9faad4-ff93-471d-b0c0-c8e5c0162dee",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17240501000,
    "oktmo": 17640101001,
    "tax_office": 3326,
    "timezone": "UTC+3",
    "geo_lat": 56.3327254,
    "geo_lon": 39.391336,
    "population": 45804,
    "foundation_year": 1871
  },
  {
    "address": "Владимирская обл, Петушинский р-н, г Костерево",
    "postal_code": 601110,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Петушинский",
    "city_type": "г",
    "city": "Костерево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301200200000,
    "fias_id": "50b6831d-52f4-4905-93d7-8e42812cd947",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17246510000,
    "oktmo": 17646110001,
    "tax_office": 3316,
    "timezone": "UTC+3",
    "geo_lat": 55.9337222,
    "geo_lon": 39.6247398,
    "population": 9136,
    "foundation_year": 1890
  },
  {
    "address": "Владимирская обл, Гусь-Хрустальный р-н, г Курлово",
    "postal_code": 601570,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Гусь-Хрустальный",
    "city_type": "г",
    "city": "Курлово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300500200000,
    "fias_id": "4f68f8c7-bf54-48a6-8165-0313d75a83c2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17220515000,
    "oktmo": 17620115001,
    "tax_office": 3304,
    "timezone": "UTC+3",
    "geo_lat": 55.452698,
    "geo_lon": 40.6124108,
    "population": 6791,
    "foundation_year": 1811
  },
  {
    "address": "Владимирская обл, Собинский р-н, г Лакинск",
    "postal_code": 601240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Собинский",
    "city_type": "г",
    "city": "Лакинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301400200000,
    "fias_id": "67eb946d-db25-4ad2-9852-6436ed35b39e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17250503000,
    "oktmo": 17650110001,
    "tax_office": 3327,
    "timezone": "UTC+3",
    "geo_lat": 56.0180587,
    "geo_lon": 39.956551,
    "population": 15707,
    "foundation_year": 1400
  },
  {
    "address": "Владимирская обл, г Меленки",
    "postal_code": 602101,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Меленковский",
    "city_type": "г",
    "city": "Меленки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301000100000,
    "fias_id": "710f2d29-5c22-4d4d-be27-04713381c676",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17242501000,
    "oktmo": 17642101001,
    "tax_office": 3334,
    "timezone": "UTC+3",
    "geo_lat": 55.3387515,
    "geo_lon": 41.6340046,
    "population": 15208,
    "foundation_year": 1700
  },
  {
    "address": "Владимирская обл, г Муром",
    "postal_code": 602205,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Муром",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300000500000,
    "fias_id": "0d7d5d87-f0a6-428f-b655-d3be106c64a2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17435000000,
    "oktmo": 17735000001,
    "tax_office": 3334,
    "timezone": "UTC+3",
    "geo_lat": 55.5630311,
    "geo_lon": 42.0231362,
    "population": 116078,
    "foundation_year": 862
  },
  {
    "address": "Владимирская обл, г Петушки",
    "postal_code": 601140,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Петушинский",
    "city_type": "г",
    "city": "Петушки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301200100000,
    "fias_id": "b7ba6bfc-4b0c-4b05-88bd-267d50b36850",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17246501000,
    "oktmo": 17646101001,
    "tax_office": 3316,
    "timezone": "UTC+3",
    "geo_lat": 55.9298134,
    "geo_lon": 39.4508075,
    "population": 15167,
    "foundation_year": 1861
  },
  {
    "address": "Владимирская обл, Петушинский р-н, г Покров",
    "postal_code": 601120,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Петушинский",
    "city_type": "г",
    "city": "Покров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301200300000,
    "fias_id": "f1c2252b-c3da-43f4-86ea-5f05812e99d5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17246520000,
    "oktmo": 17646120001,
    "tax_office": 3316,
    "timezone": "UTC+3",
    "geo_lat": 55.9166398,
    "geo_lon": 39.1734526,
    "population": 17762,
    "foundation_year": 1506
  },
  {
    "address": "Владимирская обл, г Радужный",
    "postal_code": 600910,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Радужный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300000200000,
    "fias_id": "c0ac6992-e48e-4648-b176-f56e7bcda57b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17537000000,
    "oktmo": 17737000001,
    "tax_office": 3340,
    "timezone": "UTC+3",
    "geo_lat": 55.9960277,
    "geo_lon": 40.3321855,
    "population": 18212,
    "foundation_year": 1971
  },
  {
    "address": "Владимирская обл, г Собинка",
    "postal_code": 601201,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Собинский",
    "city_type": "г",
    "city": "Собинка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301400100000,
    "fias_id": "d33946c4-bda9-43a5-b5c4-1157ae28149a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17250501000,
    "oktmo": 17650101001,
    "tax_office": 3327,
    "timezone": "UTC+3",
    "geo_lat": 55.9939169,
    "geo_lon": 40.0179653,
    "population": 19482,
    "foundation_year": 1858
  },
  {
    "address": "Владимирская обл, Александровский р-н, г Струнино",
    "postal_code": 601670,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Александровский",
    "city_type": "г",
    "city": "Струнино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3300200300000,
    "fias_id": "9f490940-079f-4bf9-9931-48952c1bea4f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 17205508000,
    "oktmo": 17605108001,
    "tax_office": 3339,
    "timezone": "UTC+3",
    "geo_lat": 56.3749967,
    "geo_lon": 38.5839667,
    "population": 14372,
    "foundation_year": 1492
  },
  {
    "address": "Владимирская обл, г Судогда",
    "postal_code": 601351,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Судогодский",
    "city_type": "г",
    "city": "Судогда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301500100000,
    "fias_id": "469a6b26-cb42-4db7-8fe7-758b946956c4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17252501000,
    "oktmo": 17652101001,
    "tax_office": 3304,
    "timezone": "UTC+3",
    "geo_lat": 55.9498056,
    "geo_lon": 40.8562939,
    "population": 11848,
    "foundation_year": 1552
  },
  {
    "address": "Владимирская обл, г Суздаль",
    "postal_code": 601291,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Суздальский",
    "city_type": "г",
    "city": "Суздаль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301600100000,
    "fias_id": "f347f78e-f991-4b0b-bfbd-600ac927b009",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17254501000,
    "oktmo": 17654101001,
    "tax_office": 3340,
    "timezone": "UTC+3",
    "geo_lat": 56.4274441,
    "geo_lon": 40.4525692,
    "population": 10535,
    "foundation_year": 999
  },
  {
    "address": "Владимирская обл, г Юрьев-Польский",
    "postal_code": 601800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Владимирская",
    "area_type": "р-н",
    "area": "Юрьев-Польский",
    "city_type": "г",
    "city": "Юрьев-Польский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3301700100000,
    "fias_id": "4859c31b-fba4-460a-9543-befc791da8a9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 17256501000,
    "oktmo": 17656101001,
    "tax_office": 3326,
    "timezone": "UTC+3",
    "geo_lat": 56.4937757,
    "geo_lon": 39.6680539,
    "population": 19588,
    "foundation_year": 1152
  },
  {
    "address": "г Волгоград",
    "postal_code": 400000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волгоград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000100000,
    "fias_id": "a52b7389-0cfe-46fb-ae15-298652a64cf8",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 18401000000,
    "oktmo": 18701000001,
    "tax_office": 3400,
    "timezone": "UTC+3",
    "geo_lat": 48.7070042,
    "geo_lon": 44.5170339,
    "population": 1021244,
    "foundation_year": 1589
  },
  {
    "address": "Волгоградская обл, г Волжский",
    "postal_code": 404100,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волжский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000200000,
    "fias_id": "bc5ed788-84c8-493e-9598-7a15a9f1e4c1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18410000000,
    "oktmo": 18710000001,
    "tax_office": 3435,
    "timezone": "UTC+3",
    "geo_lat": 48.7978209,
    "geo_lon": 44.7462538,
    "population": 314436,
    "foundation_year": 1951
  },
  {
    "address": "Волгоградская обл, г Дубовка",
    "postal_code": 404002,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Дубовский",
    "city_type": "г",
    "city": "Дубовка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400600100000,
    "fias_id": "66a2c07f-45b1-47d2-ab51-fa16288d07a6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18208501000,
    "oktmo": 18608101001,
    "tax_office": 3455,
    "timezone": "UTC+3",
    "geo_lat": 49.0554742,
    "geo_lon": 44.8270085,
    "population": 14345,
    "foundation_year": 1732
  },
  {
    "address": "Волгоградская обл, г Жирновск",
    "postal_code": 403790,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Жирновский",
    "city_type": "г",
    "city": "Жирновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400800100000,
    "fias_id": "1f9bda6f-45a5-4bf5-b1d8-434d763ea834",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18212501000,
    "oktmo": 18612101001,
    "tax_office": 3453,
    "timezone": "UTC+3",
    "geo_lat": 50.9768814,
    "geo_lon": 44.7858202,
    "population": 16890,
    "foundation_year": 1700
  },
  {
    "address": "Волгоградская обл, г Калач-на-Дону",
    "postal_code": 404500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Калачевский",
    "city_type": "г",
    "city": "Калач-на-Дону",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3401000100000,
    "fias_id": "acc6220a-f624-4880-84d8-670319b0e25d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18216501000,
    "oktmo": 18616101001,
    "tax_office": 3455,
    "timezone": "UTC+3",
    "geo_lat": 48.6889024,
    "geo_lon": 43.5306303,
    "population": 26892,
    "foundation_year": 1708
  },
  {
    "address": "Волгоградская обл, г Камышин",
    "postal_code": 403870,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Камышин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000300000,
    "fias_id": "0f9fb687-8d03-41a2-968f-033a2ba44f2f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18415000000,
    "oktmo": 18715000001,
    "tax_office": 3453,
    "timezone": "UTC+3",
    "geo_lat": 50.0651529,
    "geo_lon": 45.3844202,
    "population": 119924,
    "foundation_year": 1668
  },
  {
    "address": "Волгоградская обл, г Котельниково",
    "postal_code": 404350,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Котельниковский",
    "city_type": "г",
    "city": "Котельниково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3401400100000,
    "fias_id": "ec52e094-acd0-49ab-bc84-9ce0aa80c7c0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18224501000,
    "oktmo": 18624101001,
    "tax_office": 3458,
    "timezone": "UTC+3",
    "geo_lat": 47.6310259,
    "geo_lon": 43.1330872,
    "population": 20441,
    "foundation_year": 1897
  },
  {
    "address": "Волгоградская обл, г Котово",
    "postal_code": 403801,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Котовский",
    "city_type": "г",
    "city": "Котово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3401500100000,
    "fias_id": "6a9dded3-afcf-46ba-9881-5048c3bec5a3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18226501000,
    "oktmo": 18626101001,
    "tax_office": 3453,
    "timezone": "UTC+3",
    "geo_lat": 50.3205766,
    "geo_lon": 44.8030699,
    "population": 24104,
    "foundation_year": 1710
  },
  {
    "address": "Волгоградская обл, Среднеахтубинский р-н, г Краснослободск",
    "postal_code": 404160,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Среднеахтубинский",
    "city_type": "г",
    "city": "Краснослободск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3402800200000,
    "fias_id": "68e32d89-51bb-4030-b697-ceca53b9e740",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18251507000,
    "oktmo": 18651107001,
    "tax_office": 3454,
    "timezone": "UTC+3",
    "geo_lat": 48.7068721,
    "geo_lon": 44.5630857,
    "population": 16007,
    "foundation_year": 1870
  },
  {
    "address": "Волгоградская обл, г Ленинск",
    "postal_code": 404620,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Ленинский",
    "city_type": "г",
    "city": "Ленинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3401700100000,
    "fias_id": "bee1ee84-fdcf-45b2-8c87-7b015efc4332",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18230501000,
    "oktmo": 18630101001,
    "tax_office": 3454,
    "timezone": "UTC+3",
    "geo_lat": 48.6936061,
    "geo_lon": 45.1992692,
    "population": 15527,
    "foundation_year": 1802
  },
  {
    "address": "Волгоградская обл, г Михайловка",
    "postal_code": 403340,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Михайловка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000400000,
    "fias_id": "f02dbf6d-57ad-49bc-8011-603bf7126bb5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18420000000,
    "oktmo": 18720000001,
    "tax_office": 3456,
    "timezone": "UTC+3",
    "geo_lat": 50.0708719,
    "geo_lon": 43.2401512,
    "population": 59153,
    "foundation_year": 1762
  },
  {
    "address": "Волгоградская обл, г Николаевск",
    "postal_code": 404030,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Николаевский",
    "city_type": "г",
    "city": "Николаевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3402000100000,
    "fias_id": "8680a7a3-5c93-4c0d-ae64-d828306b2310",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18236501000,
    "oktmo": 18636101001,
    "tax_office": 3454,
    "timezone": "UTC+3",
    "geo_lat": 50.0165306,
    "geo_lon": 45.4731658,
    "population": 15081,
    "foundation_year": 1747
  },
  {
    "address": "Волгоградская обл, г Новоаннинский",
    "postal_code": 403950,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Новоаннинский",
    "city_type": "г",
    "city": "Новоаннинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3402100100000,
    "fias_id": "254f7c3a-fc21-4405-8779-eee0a7a5c63f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18238501000,
    "oktmo": 18638101001,
    "tax_office": 3457,
    "timezone": "UTC+3",
    "geo_lat": 50.5296067,
    "geo_lon": 42.6666439,
    "population": 17911,
    "foundation_year": 1956
  },
  {
    "address": "Волгоградская обл, г Палласовка",
    "postal_code": 404260,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Палласовский",
    "city_type": "г",
    "city": "Палласовка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3402400100000,
    "fias_id": "e6891ad3-e99a-48fa-93e5-805da20e849c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18245501000,
    "oktmo": 18645101,
    "tax_office": 3454,
    "timezone": "UTC+3",
    "geo_lat": 50.0500944,
    "geo_lon": 46.8804277,
    "population": 15984,
    "foundation_year": 1907
  },
  {
    "address": "Волгоградская обл, Камышинский р-н, г Петров Вал",
    "postal_code": 403840,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Камышинский",
    "city_type": "г",
    "city": "Петров Вал",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3401100200000,
    "fias_id": "02324444-36d6-4af8-a9af-15b77576adea",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18218503000,
    "oktmo": 18618103001,
    "tax_office": 3453,
    "timezone": "UTC+3",
    "geo_lat": 50.1380557,
    "geo_lon": 45.20914,
    "population": 13264,
    "foundation_year": 1942
  },
  {
    "address": "Волгоградская обл, г Серафимович",
    "postal_code": 403441,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Серафимовичский",
    "city_type": "г",
    "city": "Серафимович",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3402700100000,
    "fias_id": "fb921dd7-0b8e-42a6-a5bb-3d1387f090d0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18250501000,
    "oktmo": 18650101001,
    "tax_office": 3456,
    "timezone": "UTC+3",
    "geo_lat": 49.5663183,
    "geo_lon": 42.7360402,
    "population": 9368,
    "foundation_year": 1589
  },
  {
    "address": "Волгоградская обл, г Суровикино",
    "postal_code": 404410,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "р-н",
    "area": "Суровикинский",
    "city_type": "г",
    "city": "Суровикино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3403000100000,
    "fias_id": "b5bab4ba-e94f-4178-b6f7-67e01c89bec2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 18253501000,
    "oktmo": 18653101001,
    "tax_office": 3458,
    "timezone": "UTC+3",
    "geo_lat": 48.618917,
    "geo_lon": 42.8541163,
    "population": 20527,
    "foundation_year": 1744
  },
  {
    "address": "Волгоградская обл, г Урюпинск",
    "postal_code": 403110,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Урюпинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000500000,
    "fias_id": "2e5879fc-35c2-4354-816b-f1fce74ece87",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18425000000,
    "oktmo": 18725000001,
    "tax_office": 3457,
    "timezone": "UTC+3",
    "geo_lat": 50.7903789,
    "geo_lon": 42.0288513,
    "population": 41594,
    "foundation_year": 1618
  },
  {
    "address": "Волгоградская обл, г Фролово",
    "postal_code": 403530,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Волгоградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Фролово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3400000600000,
    "fias_id": "57e3991f-e685-4ba2-9cef-9988e6fa5862",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 18428000000,
    "oktmo": 18728000001,
    "tax_office": 3456,
    "timezone": "UTC+3",
    "geo_lat": 49.7649148,
    "geo_lon": 43.6648641,
    "population": 39489,
    "foundation_year": 1859
  },
  {
    "address": "Вологодская обл, г Бабаево",
    "postal_code": 162480,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Бабаевский",
    "city_type": "г",
    "city": "Бабаево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500200100000,
    "fias_id": "e61e9415-3cdc-4e44-9cdf-a98662bab04f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19205501000,
    "oktmo": 19605101001,
    "tax_office": 3532,
    "timezone": "UTC+3",
    "geo_lat": 59.3892583,
    "geo_lon": 35.9377058,
    "population": 12074,
    "foundation_year": 1400
  },
  {
    "address": "Вологодская обл, г Белозерск",
    "postal_code": 161200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Белозерский",
    "city_type": "г",
    "city": "Белозерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500400100000,
    "fias_id": "9444e5a5-b164-42b8-b4ff-e3b4c157fc54",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19210501000,
    "oktmo": 19610101001,
    "tax_office": 3533,
    "timezone": "UTC+3",
    "geo_lat": 60.0308381,
    "geo_lon": 37.7890586,
    "population": 9614,
    "foundation_year": 862
  },
  {
    "address": "Вологодская обл, г Великий Устюг",
    "postal_code": 162390,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Великоустюгский",
    "city_type": "г",
    "city": "Великий Устюг",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500600100000,
    "fias_id": "9db452cb-452a-4373-9610-a265b3b55653",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19410000000,
    "oktmo": 19614101001,
    "tax_office": 3538,
    "timezone": "UTC+3",
    "geo_lat": 60.7603913,
    "geo_lon": 46.3054414,
    "population": 31664,
    "foundation_year": 1147
  },
  {
    "address": "г Вологда",
    "postal_code": 160000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Вологда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500000100000,
    "fias_id": "023484a5-f98d-4849-82e1-b7e0444b54ef",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 19401000000,
    "oktmo": 19701000001,
    "tax_office": 3525,
    "timezone": "UTC+3",
    "geo_lat": 59.2483905,
    "geo_lon": 39.8355662,
    "population": 301642,
    "foundation_year": 1147
  },
  {
    "address": "Вологодская обл, г Вытегра",
    "postal_code": 162900,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Вытегорский",
    "city_type": "г",
    "city": "Вытегра",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500900100000,
    "fias_id": "f66fbc22-445e-42ab-8f53-3de507d877c9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19222501000,
    "oktmo": 19622101001,
    "tax_office": 3533,
    "timezone": "UTC+3",
    "geo_lat": 61.0063465,
    "geo_lon": 36.4495137,
    "population": 10490,
    "foundation_year": 1710
  },
  {
    "address": "Вологодская обл, г Грязовец",
    "postal_code": 162000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Грязовецкий",
    "city_type": "г",
    "city": "Грязовец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3501000100000,
    "fias_id": "aeee36bc-969a-4cc6-9fb3-5e237e776068",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19224501000,
    "oktmo": 19624101001,
    "tax_office": 3529,
    "timezone": "UTC+3",
    "geo_lat": 58.8757553,
    "geo_lon": 40.2485362,
    "population": 15528,
    "foundation_year": 1538
  },
  {
    "address": "Вологодская обл, Сокольский р-н, г Кадников",
    "postal_code": 162107,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Сокольский",
    "city_type": "г",
    "city": "Кадников",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3501700200000,
    "fias_id": "eb52b8bf-131d-4250-9a09-c0d41a7d42ee",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 19238504000,
    "oktmo": 19638104001,
    "tax_office": 3537,
    "timezone": "UTC+3",
    "geo_lat": 59.5037764,
    "geo_lon": 40.3441148,
    "population": 4797,
    "foundation_year": 1492
  },
  {
    "address": "Вологодская обл, г Кириллов",
    "postal_code": 161100,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Кирилловский",
    "city_type": "г",
    "city": "Кириллов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3501200100000,
    "fias_id": "bfe722ba-8bfd-4dbd-acc5-73da667a0f95",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19228501000,
    "oktmo": 19628101001,
    "tax_office": 3533,
    "timezone": "UTC+3",
    "geo_lat": 59.8591523,
    "geo_lon": 38.3748782,
    "population": 7735,
    "foundation_year": 1397
  },
  {
    "address": "Вологодская обл, Великоустюгский р-н, г Красавино",
    "postal_code": 162341,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Великоустюгский",
    "city_type": "г",
    "city": "Красавино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500600200000,
    "fias_id": "78a546b6-196d-407f-a082-e7e85ff4acad",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 19410501000,
    "oktmo": 19614105001,
    "tax_office": 3538,
    "timezone": "UTC+3",
    "geo_lat": 60.9612823,
    "geo_lon": 46.4814116,
    "population": 7003,
    "foundation_year": 1848
  },
  {
    "address": "Вологодская обл, г Никольск",
    "postal_code": 161440,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Никольский",
    "city_type": "г",
    "city": "Никольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3501500100000,
    "fias_id": "9b602724-8b73-4979-ae01-3ba8a22c93f2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19234501000,
    "oktmo": 19634101001,
    "tax_office": 3538,
    "timezone": "UTC+3",
    "geo_lat": 59.5351837,
    "geo_lon": 45.4576137,
    "population": 8515,
    "foundation_year": 1300
  },
  {
    "address": "Вологодская обл, г Сокол",
    "postal_code": 162129,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Сокольский",
    "city_type": "г",
    "city": "Сокол",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3501700100000,
    "fias_id": "500b9e8f-79cc-4c35-b497-110f550d6615",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19420000000,
    "oktmo": 19638101001,
    "tax_office": 3537,
    "timezone": "UTC+3",
    "geo_lat": 59.4758605,
    "geo_lon": 40.1114187,
    "population": 38454,
    "foundation_year": 1615
  },
  {
    "address": "Вологодская обл, г Тотьма",
    "postal_code": 161300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Тотемский",
    "city_type": "г",
    "city": "Тотьма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3502000100000,
    "fias_id": "3a0d6df8-c23f-4435-83bb-9f97dd2e3343",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19246501000,
    "oktmo": 19646101001,
    "tax_office": 3535,
    "timezone": "UTC+3",
    "geo_lat": 59.9734998,
    "geo_lon": 42.7589506,
    "population": 9784,
    "foundation_year": 1137
  },
  {
    "address": "Вологодская обл, г Устюжна",
    "postal_code": 162840,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Устюженский",
    "city_type": "г",
    "city": "Устюжна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3502200100000,
    "fias_id": "3c2ceeea-04a9-4171-b4dd-341620290258",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19250501000,
    "oktmo": 19650101001,
    "tax_office": 3532,
    "timezone": "UTC+3",
    "geo_lat": 58.8383117,
    "geo_lon": 36.4425478,
    "population": 9478,
    "foundation_year": 1252
  },
  {
    "address": "Вологодская обл, г Харовск",
    "postal_code": 162250,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "р-н",
    "area": "Харовский",
    "city_type": "г",
    "city": "Харовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3502300100000,
    "fias_id": "e5e0513c-fc84-4373-b3ee-05f8382f8110",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 19252501000,
    "oktmo": 19652101001,
    "tax_office": 3535,
    "timezone": "UTC+3",
    "geo_lat": 59.9479423,
    "geo_lon": 40.2000298,
    "population": 10078,
    "foundation_year": 1903
  },
  {
    "address": "Вологодская обл, г Череповец",
    "postal_code": 162600,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Вологодская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Череповец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3500000200000,
    "fias_id": "b4c30848-5181-44b4-88fa-456e1c4aeb0f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 19430000000,
    "oktmo": 19730000001,
    "tax_office": 3528,
    "timezone": "UTC+3",
    "geo_lat": 59.1269212,
    "geo_lon": 37.9090497,
    "population": 312311,
    "foundation_year": 1777
  },
  {
    "address": "Воронежская обл, г Бобров",
    "postal_code": 397700,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Бобровский",
    "city_type": "г",
    "city": "Бобров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600300100000,
    "fias_id": "0a50bda2-e801-4e11-bcc0-8c6469d46a5d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20204501000,
    "oktmo": 20604101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 51.0901649,
    "geo_lon": 40.0318256,
    "population": 19738,
    "foundation_year": 1698
  },
  {
    "address": "Воронежская обл, г Богучар",
    "postal_code": 396790,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Богучарский",
    "city_type": "г",
    "city": "Богучар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600400100000,
    "fias_id": "33b88f52-819d-4ba5-aa70-3fe4ccfd33d6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20205501000,
    "oktmo": 20605101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 49.9352454,
    "geo_lon": 40.5590801,
    "population": 11811,
    "foundation_year": 1704
  },
  {
    "address": "Воронежская обл, г Борисоглебск",
    "postal_code": 397160,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Борисоглебский",
    "city_type": "г",
    "city": "Борисоглебск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600500100000,
    "fias_id": "e5c49f70-5235-49ef-a16b-816114b36566",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20410000000,
    "oktmo": 20710000001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 51.3655754,
    "geo_lon": 42.1008334,
    "population": 65585,
    "foundation_year": 1698
  },
  {
    "address": "Воронежская обл, г Бутурлиновка",
    "postal_code": 397500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Бутурлиновский",
    "city_type": "г",
    "city": "Бутурлиновка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600600100000,
    "fias_id": "b3c350d7-8c81-4016-b198-f197536e8321",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20208501000,
    "oktmo": 20608101001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 50.8311818,
    "geo_lon": 40.5976923,
    "population": 27208,
    "foundation_year": 1740
  },
  {
    "address": "г Воронеж",
    "postal_code": 394000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Воронеж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600000100000,
    "fias_id": "5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 20401000000,
    "oktmo": 20701000001,
    "tax_office": 3600,
    "timezone": "UTC+3",
    "geo_lat": 51.6593332,
    "geo_lon": 39.1969229,
    "population": 889680,
    "foundation_year": 1586
  },
  {
    "address": "Воронежская обл, г Калач",
    "postal_code": 397600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Калачеевский",
    "city_type": "г",
    "city": "Калач",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3601100100000,
    "fias_id": "57a863b5-02a7-482a-8320-a3d165c4bfd0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20215501000,
    "oktmo": 20615101001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 50.4242134,
    "geo_lon": 41.0162014,
    "population": 20046,
    "foundation_year": 1716
  },
  {
    "address": "Воронежская обл, г Лиски",
    "postal_code": 397904,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Лискинский",
    "city_type": "г",
    "city": "Лиски",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3601500100000,
    "fias_id": "e0a82358-88c2-44ab-b402-c450e1aa52a8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20221501000,
    "oktmo": 20621101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 50.9945626,
    "geo_lon": 39.5184909,
    "population": 55864,
    "foundation_year": 1500
  },
  {
    "address": "Воронежская обл, г Нововоронеж",
    "postal_code": 396070,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нововоронеж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3600000300000,
    "fias_id": "c7cdacb9-6a1e-4402-8bc6-6ec36a42d05c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 20427000000,
    "oktmo": 20727000001,
    "tax_office": 3663,
    "timezone": "UTC+3",
    "geo_lat": 51.3091524,
    "geo_lon": 39.2162843,
    "population": 32635,
    "foundation_year": 1957
  },
  {
    "address": "Воронежская обл, г Новохоперск",
    "postal_code": 397400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Новохоперский",
    "city_type": "г",
    "city": "Новохоперск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3601800100000,
    "fias_id": "43fe280f-6fa3-4a06-8daf-94586e2bea44",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20227501000,
    "oktmo": 20627101001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 51.0952211,
    "geo_lon": 41.6173404,
    "population": 6849,
    "foundation_year": 1710
  },
  {
    "address": "Воронежская обл, г Острогожск",
    "postal_code": 397837,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Острогожский",
    "city_type": "г",
    "city": "Острогожск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3602000100000,
    "fias_id": "8434550d-dd9b-4bc7-8e5b-d43a991753e2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20231501000,
    "oktmo": 20631101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 50.8677905,
    "geo_lon": 39.0407746,
    "population": 33842,
    "foundation_year": 1652
  },
  {
    "address": "Воронежская обл, г Павловск",
    "postal_code": 396420,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Павловский",
    "city_type": "г",
    "city": "Павловск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3602100100000,
    "fias_id": "f4a89d85-b770-4d58-b205-31165a9958b8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20233501000,
    "oktmo": 20633101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 50.453455,
    "geo_lon": 40.136874,
    "population": 25126,
    "foundation_year": 1709
  },
  {
    "address": "Воронежская обл, г Поворино",
    "postal_code": 397350,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Поворинский",
    "city_type": "г",
    "city": "Поворино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3602400100000,
    "fias_id": "9cb55edf-0fb2-46af-8f00-02b15c2f1644",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20239501000,
    "oktmo": 20639101001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 51.1954419,
    "geo_lon": 42.2472726,
    "population": 17692,
    "foundation_year": 1870
  },
  {
    "address": "Воронежская обл, г Россошь",
    "postal_code": 396650,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Россошанский",
    "city_type": "г",
    "city": "Россошь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3602800100000,
    "fias_id": "1ed74fcf-2dd0-460a-99b9-029ca382517d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20247501000,
    "oktmo": 20647101001,
    "tax_office": 3652,
    "timezone": "UTC+3",
    "geo_lat": 50.1701949,
    "geo_lon": 39.6226965,
    "population": 62865,
    "foundation_year": 1600
  },
  {
    "address": "Воронежская обл, г Семилуки",
    "postal_code": 396900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Семилукский",
    "city_type": "г",
    "city": "Семилуки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3602900100000,
    "fias_id": "15d507b5-f67e-4ee6-9f25-683562699fac",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20249501000,
    "oktmo": 20649101001,
    "tax_office": 3666,
    "timezone": "UTC+3",
    "geo_lat": 51.6951644,
    "geo_lon": 39.0190454,
    "population": 26025,
    "foundation_year": 1615
  },
  {
    "address": "Воронежская обл, г Эртиль",
    "postal_code": 397030,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Воронежская",
    "area_type": "р-н",
    "area": "Эртильский",
    "city_type": "г",
    "city": "Эртиль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3603300100000,
    "fias_id": "fdbadb36-e4f6-4843-8f1f-80bd4fb4381e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 20258501000,
    "oktmo": 20658101001,
    "tax_office": 3604,
    "timezone": "UTC+3",
    "geo_lat": 51.830932,
    "geo_lon": 40.8074182,
    "population": 11387,
    "foundation_year": 1897
  },
  {
    "address": "Респ Дагестан, г Буйнакск",
    "postal_code": 368220,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Буйнакск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500001000000",
    "fias_id": "fab58303-fb09-422e-aeb7-6fdb5dfe7941",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82405000000,
    "oktmo": 82705000001,
    "tax_office": "0507",
    "timezone": "UTC+3",
    "geo_lat": 42.8214424,
    "geo_lon": 47.1165263,
    "population": 65735,
    "foundation_year": 1834
  },
  {
    "address": "Респ Дагестан, г Дагестанские Огни",
    "postal_code": 368670,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дагестанские Огни",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000200000",
    "fias_id": "8b4a997b-1e70-402a-a56a-e93c81d0ff20",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82408000000,
    "oktmo": 82708000001,
    "tax_office": "0550",
    "timezone": "UTC+3",
    "geo_lat": 42.1152296,
    "geo_lon": 48.1939354,
    "population": 30671,
    "foundation_year": 1914
  },
  {
    "address": "Респ Дагестан, г Дербент",
    "postal_code": 368000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дербент",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000600000",
    "fias_id": "1bde5cf4-7943-4b17-9718-2c1d96742be5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82410000000,
    "oktmo": 82710000001,
    "tax_office": "0542",
    "timezone": "UTC+3",
    "geo_lat": 42.058966,
    "geo_lon": 48.2907452,
    "population": 119961,
    "foundation_year": 438
  },
  {
    "address": "Респ Дагестан, г Избербаш",
    "postal_code": 368500,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Избербаш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000300000",
    "fias_id": "bf14786f-6c0e-4369-8907-57f9d3fd93ac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82415000000,
    "oktmo": 82715000001,
    "tax_office": "0548",
    "timezone": "UTC+3",
    "geo_lat": 42.5650962,
    "geo_lon": 47.8710051,
    "population": 56301,
    "foundation_year": 1932
  },
  {
    "address": "Респ Дагестан, г Каспийск",
    "postal_code": 368300,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Каспийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000400000",
    "fias_id": "a8695cce-c623-4451-bb47-2d69ab22092a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82420000000,
    "oktmo": 82720000001,
    "tax_office": "0554",
    "timezone": "UTC+3",
    "geo_lat": 42.8916007,
    "geo_lon": 47.6367066,
    "population": 103914,
    "foundation_year": 1932
  },
  {
    "address": "Респ Дагестан, г Кизилюрт",
    "postal_code": 368120,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кизилюрт",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000700000",
    "fias_id": "efc8a6e2-ffab-4d69-a35b-019bfe89745e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82425000000,
    "oktmo": 82725000001,
    "tax_office": "0546",
    "timezone": "UTC+3",
    "geo_lat": 43.203825,
    "geo_lon": 46.8729636,
    "population": 36187,
    "foundation_year": 1963
  },
  {
    "address": "Респ Дагестан, г Кизляр",
    "postal_code": 368830,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кизляр",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000800000",
    "fias_id": "e18e67f7-e62a-4abe-853c-ffb8e5ab57e1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82430000000,
    "oktmo": 82730000001,
    "tax_office": "0547",
    "timezone": "UTC+3",
    "geo_lat": 43.8484083,
    "geo_lon": 46.7233699,
    "population": 49169,
    "foundation_year": 1735
  },
  {
    "address": "г Махачкала",
    "postal_code": 367000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Махачкала",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000100000",
    "fias_id": "727cdf1e-1b70-4e07-8995-9bf7ca9abefb",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 82401000000,
    "oktmo": 82701000001,
    "tax_office": "0500",
    "timezone": "UTC+3",
    "geo_lat": 42.9849159,
    "geo_lon": 47.5047181,
    "population": 577990,
    "foundation_year": 1844
  },
  {
    "address": "Респ Дагестан, г Хасавюрт",
    "postal_code": 368000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Хасавюрт",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000900000",
    "fias_id": "b10ca6a7-e9c0-4382-b939-4787aaf43a58",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82435000000,
    "oktmo": 82735000001,
    "tax_office": "0544",
    "timezone": "UTC+3",
    "geo_lat": 43.2504665,
    "geo_lon": 46.5851292,
    "population": 133929,
    "foundation_year": 1846
  },
  {
    "address": "Респ Дагестан, г Южно-Сухокумск",
    "postal_code": 368890,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Дагестан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Южно-Сухокумск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0500000500000",
    "fias_id": "733221a2-f789-4dda-a3f4-e41c88b6cfbd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 82438000000,
    "oktmo": 82738000001,
    "tax_office": "0547",
    "timezone": "UTC+3",
    "geo_lat": 44.6602467,
    "geo_lon": 45.6499523,
    "population": 10048,
    "foundation_year": 1958
  },
  {
    "address": "г Биробиджан",
    "postal_code": 679002,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Аобл",
    "region": "Еврейская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Биробиджан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7900000100000,
    "fias_id": "5d133391-46ee-496b-83a6-efeeaa903643",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 99401000000,
    "oktmo": 99701000001,
    "tax_office": 7900,
    "timezone": "UTC+10",
    "geo_lat": 48.7946446,
    "geo_lon": 132.9217207,
    "population": 75419,
    "foundation_year": 1915
  },
  {
    "address": "Еврейская Аобл, г Облучье",
    "postal_code": 679100,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Аобл",
    "region": "Еврейская",
    "area_type": "р-н",
    "area": "Облученский",
    "city_type": "г",
    "city": "Облучье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7900300100000,
    "fias_id": "b60c1e9f-9355-47ef-b5a3-82906d074bd4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 99220501000,
    "oktmo": 99620101001,
    "tax_office": 7900,
    "timezone": "UTC+10",
    "geo_lat": 49.0189345,
    "geo_lon": 131.0540102,
    "population": 9379,
    "foundation_year": 1911
  },
  {
    "address": "Забайкальский край, г Балей",
    "postal_code": 673450,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Балейский",
    "city_type": "г",
    "city": "Балей",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7500400100000,
    "fias_id": "29a51a64-5ca3-4cc6-87ff-9cdfcc17642e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76206501000,
    "oktmo": 76606101001,
    "tax_office": 7527,
    "timezone": "UTC+9",
    "geo_lat": 51.5823759,
    "geo_lon": 116.6379549,
    "population": 12536,
    "foundation_year": 1736
  },
  {
    "address": "Забайкальский край, г Борзя",
    "postal_code": 674600,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Борзинский",
    "city_type": "г",
    "city": "Борзя",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7500500100000,
    "fias_id": "d4b135b9-4504-4bae-8585-da058d350f58",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76209501000,
    "oktmo": 76609101001,
    "tax_office": 7505,
    "timezone": "UTC+9",
    "geo_lat": 50.3876058,
    "geo_lon": 116.5234779,
    "population": 31376,
    "foundation_year": 1899
  },
  {
    "address": "Забайкальский край, г Краснокаменск",
    "postal_code": 674670,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Краснокаменский",
    "city_type": "г",
    "city": "Краснокаменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7501100100000,
    "fias_id": "3c9d5e9e-2922-4e9e-9e49-dbe6faf01fb2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76221501000,
    "oktmo": 76621101001,
    "tax_office": 7530,
    "timezone": "UTC+9",
    "geo_lat": 50.0929703,
    "geo_lon": 118.0323936,
    "population": 55668,
    "foundation_year": 1967
  },
  {
    "address": "Забайкальский край, г Могоча",
    "postal_code": 673730,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Могочинский",
    "city_type": "г",
    "city": "Могоча",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7501400100000,
    "fias_id": "29eb6b99-27ad-493c-9fd1-a83fe8acdf24",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76226501000,
    "oktmo": 76626101001,
    "tax_office": 7527,
    "timezone": "UTC+9",
    "geo_lat": 53.7361398,
    "geo_lon": 119.7660867,
    "population": 13228,
    "foundation_year": 1910
  },
  {
    "address": "Забайкальский край, г Нерчинск",
    "postal_code": 673400,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Нерчинский",
    "city_type": "г",
    "city": "Нерчинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7501500100000,
    "fias_id": "ba325548-ac92-4a21-904b-bb535e5fe249",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76228501000,
    "oktmo": 76628101001,
    "tax_office": 7513,
    "timezone": "UTC+9",
    "geo_lat": 51.9594977,
    "geo_lon": 116.5852383,
    "population": 14976,
    "foundation_year": 1653
  },
  {
    "address": "Забайкальский край, г Петровск-Забайкальский",
    "postal_code": 673000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Петровск-Забайкальский",
    "city_type": "г",
    "city": "Петровск-Забайкальский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7501900100000,
    "fias_id": "b26bb2c1-f79b-4c97-9d2d-e73790cfe622",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76236501000,
    "oktmo": 76715000001,
    "tax_office": 7538,
    "timezone": "UTC+9",
    "geo_lat": 51.2748592,
    "geo_lon": 108.846681,
    "population": 18555,
    "foundation_year": 1789
  },
  {
    "address": "Забайкальский край, г Сретенск",
    "postal_code": 673500,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Сретенский",
    "city_type": "г",
    "city": "Сретенск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7502100100000,
    "fias_id": "f9dd7993-4953-4980-bbcc-2666b94b2a06",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76240501000,
    "oktmo": 76640101001,
    "tax_office": 7513,
    "timezone": "UTC+9",
    "geo_lat": 52.2461454,
    "geo_lon": 117.7117842,
    "population": 6850,
    "foundation_year": 1689
  },
  {
    "address": "Забайкальский край, г Хилок",
    "postal_code": 673200,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Хилокский",
    "city_type": "г",
    "city": "Хилок",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7502500100000,
    "fias_id": "a4b18a69-c5df-4022-ba9b-a29f99acfa42",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76247501000,
    "oktmo": 76647101001,
    "tax_office": 7538,
    "timezone": "UTC+9",
    "geo_lat": 51.3634856,
    "geo_lon": 110.4590898,
    "population": 11530,
    "foundation_year": 1895
  },
  {
    "address": "г Чита",
    "postal_code": 672000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чита",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7500000100000,
    "fias_id": "2d9abaa6-85a6-4f1f-a1bd-14b76ec17d9c",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 76401000000,
    "oktmo": 76701000001,
    "tax_office": 7536,
    "timezone": "UTC+9",
    "geo_lat": 52.0340142,
    "geo_lon": 113.4994,
    "population": 323964,
    "foundation_year": 1653
  },
  {
    "address": "Забайкальский край, г Шилка",
    "postal_code": 673303,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Забайкальский",
    "area_type": "р-н",
    "area": "Шилкинский",
    "city_type": "г",
    "city": "Шилка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7502800100000,
    "fias_id": "69efa85f-c9d7-44bb-bcda-95205cd1a647",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 76254501000,
    "oktmo": 76654101001,
    "tax_office": 7527,
    "timezone": "UTC+9",
    "geo_lat": 51.8497035,
    "geo_lon": 116.0334461,
    "population": 13947,
    "foundation_year": 1897
  },
  {
    "address": "Ивановская обл, г Вичуга",
    "postal_code": 155330,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Вичугский",
    "city_type": "г",
    "city": "Вичуга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700300100000,
    "fias_id": "3d518b4e-86b2-48d5-bcf3-48f107165918",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24403000000,
    "oktmo": 24703000001,
    "tax_office": 3703,
    "timezone": "UTC+3",
    "geo_lat": 57.2044698,
    "geo_lon": 41.9132201,
    "population": 37609,
    "foundation_year": 1925
  },
  {
    "address": "Ивановская обл, г Гаврилов Посад",
    "postal_code": 155000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Гаврилово-Посадский",
    "city_type": "г",
    "city": "Гаврилов Посад",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700400100000,
    "fias_id": "3f81927e-bc4b-45c7-a962-b80c0e216115",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24203501000,
    "oktmo": 24603101001,
    "tax_office": 3704,
    "timezone": "UTC+3",
    "geo_lat": 56.5586946,
    "geo_lon": 40.1228906,
    "population": 6434,
    "foundation_year": 1434
  },
  {
    "address": "Ивановская обл, г Заволжск",
    "postal_code": 155410,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Заволжский",
    "city_type": "г",
    "city": "Заволжск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700500100000,
    "fias_id": "3bf1c329-e556-4d56-82d0-3b1b42b298da",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24205501000,
    "oktmo": 24605101001,
    "tax_office": 3703,
    "timezone": "UTC+3",
    "geo_lat": 57.4918141,
    "geo_lon": 42.1375625,
    "population": 12045,
    "foundation_year": 1800
  },
  {
    "address": "г Иваново",
    "postal_code": 153000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Иваново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700000100000,
    "fias_id": "40c6863e-2a5f-4033-a377-3416533948bd",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 24401000000,
    "oktmo": 24701000001,
    "tax_office": 3702,
    "timezone": "UTC+3",
    "geo_lat": 56.9993792,
    "geo_lon": 40.9728272,
    "population": 409277,
    "foundation_year": 1561
  },
  {
    "address": "Ивановская обл, г Кинешма",
    "postal_code": 155800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кинешма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700000200000,
    "fias_id": "df284e77-a45c-411d-8901-94c91f4a92e5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 24405000000,
    "oktmo": 24705000001,
    "tax_office": 3703,
    "timezone": "UTC+3",
    "geo_lat": 57.4425463,
    "geo_lon": 42.168914,
    "population": 88113,
    "foundation_year": 1429
  },
  {
    "address": "Ивановская обл, г Комсомольск",
    "postal_code": 155150,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Комсомольский",
    "city_type": "г",
    "city": "Комсомольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700800100000,
    "fias_id": "d812b7bf-68de-4e11-95d6-39f7c8ec111e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24213501000,
    "oktmo": 24613101001,
    "tax_office": 3704,
    "timezone": "UTC+3",
    "geo_lat": 57.0273052,
    "geo_lon": 40.3776851,
    "population": 8693,
    "foundation_year": 1931
  },
  {
    "address": "Ивановская обл, г Кохма",
    "postal_code": 153510,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Ивановский",
    "city_type": "г",
    "city": "Кохма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700100200000,
    "fias_id": "022e301b-514f-477f-9d1e-f60430c40429",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24207504000,
    "oktmo": 24706000001,
    "tax_office": 3711,
    "timezone": "UTC+3",
    "geo_lat": 56.9324606,
    "geo_lon": 41.0931657,
    "population": 29408,
    "foundation_year": 1619
  },
  {
    "address": "Ивановская обл, Кинешемский р-н, г Наволоки",
    "postal_code": 155830,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Кинешемский",
    "city_type": "г",
    "city": "Наволоки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3700700200000,
    "fias_id": "36d31ef0-a525-4be4-a6e9-28ced781dfae",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 24211504000,
    "oktmo": 24611104001,
    "tax_office": 3703,
    "timezone": "UTC+3",
    "geo_lat": 57.4679066,
    "geo_lon": 41.9608002,
    "population": 10207,
    "foundation_year": 1775
  },
  {
    "address": "Ивановская обл, Приволжский р-н, г Плес",
    "postal_code": 155555,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Приволжский",
    "city_type": "г",
    "city": "Плес",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701300200000,
    "fias_id": "8bb29a7a-de9c-4c21-ad15-3af39f0a41fa",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 24220504000,
    "oktmo": 24620104001,
    "tax_office": 3705,
    "timezone": "UTC+3",
    "geo_lat": 57.4606031,
    "geo_lon": 41.5122672,
    "population": 2341,
    "foundation_year": 1410
  },
  {
    "address": "Ивановская обл, г Приволжск",
    "postal_code": 155550,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Приволжский",
    "city_type": "г",
    "city": "Приволжск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701300100000,
    "fias_id": "43706edd-5cf4-4400-8e60-03ea3660c25f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24220506000,
    "oktmo": 24620106001,
    "tax_office": 3705,
    "timezone": "UTC+3",
    "geo_lat": 57.3805743,
    "geo_lon": 41.2808565,
    "population": 16749,
    "foundation_year": 1484
  },
  {
    "address": "Ивановская обл, г Пучеж",
    "postal_code": 155360,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Пучежский",
    "city_type": "г",
    "city": "Пучеж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701400100000,
    "fias_id": "a85feb12-89e9-441d-956a-8510493cdc8d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24221501000,
    "oktmo": 24621101001,
    "tax_office": 3706,
    "timezone": "UTC+3",
    "geo_lat": 56.9820688,
    "geo_lon": 43.1683321,
    "population": 8583,
    "foundation_year": 1594
  },
  {
    "address": "Ивановская обл, г Родники",
    "postal_code": 155250,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Родниковский",
    "city_type": "г",
    "city": "Родники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701500100000,
    "fias_id": "90328f6e-d961-48ba-ab03-366d93df3c07",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24223501000,
    "oktmo": 24623101001,
    "tax_office": 3703,
    "timezone": "UTC+3",
    "geo_lat": 57.1025975,
    "geo_lon": 41.7298834,
    "population": 26318,
    "foundation_year": 1606
  },
  {
    "address": "Ивановская обл, г Тейково",
    "postal_code": 155040,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Тейковский",
    "city_type": "г",
    "city": "Тейково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701700100000,
    "fias_id": "09a16212-dcce-4f9a-be1a-515911beaba3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24407000000,
    "oktmo": 24707000001,
    "tax_office": 3704,
    "timezone": "UTC+3",
    "geo_lat": 56.8542719,
    "geo_lon": 40.5353874,
    "population": 34993,
    "foundation_year": 1600
  },
  {
    "address": "Ивановская обл, г Фурманов",
    "postal_code": 155520,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Фурмановский",
    "city_type": "г",
    "city": "Фурманов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701800100000,
    "fias_id": "9d56ad01-abe9-4f14-89e5-9af4544b94b2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24231000000,
    "oktmo": 24631101001,
    "tax_office": 3705,
    "timezone": "UTC+3",
    "geo_lat": 57.2539276,
    "geo_lon": 41.1054432,
    "population": 36149,
    "foundation_year": 1918
  },
  {
    "address": "Ивановская обл, г Шуя",
    "postal_code": 155900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Шуйский",
    "city_type": "г",
    "city": "Шуя",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3701900100000,
    "fias_id": "a6c7e92c-79cf-4b47-beb6-40192295c559",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24411000000,
    "oktmo": 24711000001,
    "tax_office": 3706,
    "timezone": "UTC+3",
    "geo_lat": 56.8560234,
    "geo_lon": 41.3800939,
    "population": 58528,
    "foundation_year": 1394
  },
  {
    "address": "Ивановская обл, г Южа",
    "postal_code": 155630,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Южский",
    "city_type": "г",
    "city": "Южа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3702000100000,
    "fias_id": "16c1333c-8766-4135-a29a-e08f959e60bc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24235501000,
    "oktmo": 24635101001,
    "tax_office": 3706,
    "timezone": "UTC+3",
    "geo_lat": 56.5926877,
    "geo_lon": 42.0458099,
    "population": 14170,
    "foundation_year": 1628
  },
  {
    "address": "Ивановская обл, г Юрьевец",
    "postal_code": 155450,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ивановская",
    "area_type": "р-н",
    "area": "Юрьевецкий",
    "city_type": "г",
    "city": "Юрьевец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3702100100000,
    "fias_id": "be9d618e-8dc4-498f-aed6-03ce38f82683",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 24237501000,
    "oktmo": 24637101001,
    "tax_office": 3706,
    "timezone": "UTC+3",
    "geo_lat": 57.3177781,
    "geo_lon": 43.1110401,
    "population": 10205,
    "foundation_year": 1225
  },
  {
    "address": "Респ Ингушетия, г Карабулак",
    "postal_code": 386230,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Ингушетия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Карабулак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0600000200000",
    "fias_id": "2c33c72e-642b-4a7e-afe5-982f5adf6b17",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 26408000000,
    "oktmo": 26710000001,
    "tax_office": "0600",
    "timezone": "UTC+3",
    "geo_lat": 43.3055248,
    "geo_lon": 44.9094582,
    "population": 31081,
    "foundation_year": 1859
  },
  {
    "address": "г Магас",
    "postal_code": 386001,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Ингушетия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Магас",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0600000100000",
    "fias_id": "c801edb4-aba2-4e1d-9ab2-69fcbf0aeb9c",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 26401000000,
    "oktmo": 26701000001,
    "tax_office": "0600",
    "timezone": "UTC+3",
    "geo_lat": 43.1688611,
    "geo_lon": 44.8131207,
    "population": 2505,
    "foundation_year": 1995
  },
  {
    "address": "Респ Ингушетия, г Малгобек",
    "postal_code": 386300,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Ингушетия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Малгобек",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0600000400000",
    "fias_id": "5c318987-0763-4822-9c57-0d55e40fb9d7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 26405000000,
    "oktmo": 26715000001,
    "tax_office": "0600",
    "timezone": "UTC+3",
    "geo_lat": 43.5096646,
    "geo_lon": 44.5901963,
    "population": 31076,
    "foundation_year": 1934
  },
  {
    "address": "Респ Ингушетия, г Назрань",
    "postal_code": 386100,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Ингушетия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Назрань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0600000300000",
    "fias_id": "815566a1-04ea-44a9-b34b-25b70ac9aa9a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 26406000000,
    "oktmo": 26706000001,
    "tax_office": "0600",
    "timezone": "UTC+3",
    "geo_lat": 43.2257841,
    "geo_lon": 44.7645779,
    "population": 93357,
    "foundation_year": 1781
  },
  {
    "address": "Респ Ингушетия, г Сунжа",
    "postal_code": 386200,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Ингушетия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сунжа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0600000500000",
    "fias_id": "0effcc07-1b12-4570-9951-23523c06c858",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 26230835001,
    "oktmo": 26720000001,
    "tax_office": "0600",
    "timezone": "UTC+3",
    "geo_lat": 43.3204196,
    "geo_lon": 45.0476331,
    "population": 64493,
    "foundation_year": 1845
  },
  {
    "address": "Иркутская обл, Нижнеудинский р-н, г Алзамай",
    "postal_code": 665160,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Нижнеудинский",
    "city_type": "г",
    "city": "Алзамай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801600200000,
    "fias_id": "a7812582-bd5d-4d13-b284-d2b052265e9d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25228508000,
    "oktmo": 25628105001,
    "tax_office": 3816,
    "timezone": "UTC+8",
    "geo_lat": 55.5551233,
    "geo_lon": 98.6643699,
    "population": 6751,
    "foundation_year": 1899
  },
  {
    "address": "Иркутская обл, г Ангарск",
    "postal_code": 665800,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ангарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000400000,
    "fias_id": "82b6b7c8-82a4-44b2-8bc7-691373706b89",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25203501000,
    "oktmo": 25703000001,
    "tax_office": 3801,
    "timezone": "UTC+8",
    "geo_lat": 52.544879,
    "geo_lon": 103.888543,
    "population": 233765,
    "foundation_year": 1948
  },
  {
    "address": "Иркутская обл, Слюдянский р-н, г Байкальск",
    "postal_code": 665930,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Слюдянский",
    "city_type": "г",
    "city": "Байкальск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801800200000,
    "fias_id": "cd4477e8-3335-442a-a0a4-9429a52f5c52",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25234508000,
    "oktmo": 25634108001,
    "tax_office": 3810,
    "timezone": "UTC+8",
    "geo_lat": 51.5230393,
    "geo_lon": 104.1487532,
    "population": 13589,
    "foundation_year": 1961
  },
  {
    "address": "Иркутская обл, Тайшетский р-н, г Бирюсинск",
    "postal_code": 665050,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Тайшетский",
    "city_type": "г",
    "city": "Бирюсинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801900200000,
    "fias_id": "5b646190-2037-409f-bf0f-6fb8194a0581",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25236508000,
    "oktmo": 25636105001,
    "tax_office": 3816,
    "timezone": "UTC+8",
    "geo_lat": 55.9609167,
    "geo_lon": 97.8205348,
    "population": 8981,
    "foundation_year": 1967
  },
  {
    "address": "Иркутская обл, г Бодайбо",
    "postal_code": 666901,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бодайбо",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000600000,
    "fias_id": "8ace6ae4-7a35-494a-bfbd-9a743a929c39",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25202501000,
    "oktmo": 25602101001,
    "tax_office": 3808,
    "timezone": "UTC+8",
    "geo_lat": 57.8468636,
    "geo_lon": 114.1866287,
    "population": 15331,
    "foundation_year": 1864
  },
  {
    "address": "Иркутская обл, г Братск",
    "postal_code": 665700,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Братск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000500000,
    "fias_id": "bdf6b629-b33e-4cfa-b4b2-7f693e1d821c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25414000000,
    "oktmo": 25714000001,
    "tax_office": 3800,
    "timezone": "UTC+8",
    "geo_lat": 56.1513108,
    "geo_lon": 101.6340035,
    "population": 246348,
    "foundation_year": 1631
  },
  {
    "address": "Иркутская обл, Братский р-н, г Вихоревка",
    "postal_code": 665770,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Братский",
    "city_type": "г",
    "city": "Вихоревка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800500200000,
    "fias_id": "04bc8726-0523-4aeb-8450-fe07ff425372",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25204503000,
    "oktmo": 25604103001,
    "tax_office": 3805,
    "timezone": "UTC+8",
    "geo_lat": 56.1208145,
    "geo_lon": 101.1702926,
    "population": 22528,
    "foundation_year": 1957
  },
  {
    "address": "Иркутская обл, г Железногорск-Илимский",
    "postal_code": 665650,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Нижнеилимский",
    "city_type": "г",
    "city": "Железногорск-Илимский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801500100000,
    "fias_id": "31ba5d12-ae5b-4cc0-8892-2a504423ee61",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 25226501000,
    "oktmo": 25626101001,
    "tax_office": 3805,
    "timezone": "UTC+8",
    "geo_lat": 56.5847888,
    "geo_lon": 104.114275,
    "population": 26134,
    "foundation_year": 1948
  },
  {
    "address": "Иркутская обл, г Зима",
    "postal_code": 665382,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Зима",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000700000,
    "fias_id": "7c3452ac-3953-4b78-a80c-64ec839620a6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25420000000,
    "oktmo": 25720000001,
    "tax_office": 3814,
    "timezone": "UTC+8",
    "geo_lat": 53.920693,
    "geo_lon": 102.0491772,
    "population": 32522,
    "foundation_year": 1743
  },
  {
    "address": "г Иркутск",
    "postal_code": 664000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Иркутск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000300000,
    "fias_id": "8eeed222-72e7-47c3-ab3a-9a553c31cf72",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 25401000000,
    "oktmo": 25701000001,
    "tax_office": 3800,
    "timezone": "UTC+8",
    "geo_lat": 52.2864036,
    "geo_lon": 104.2807466,
    "population": 587225,
    "foundation_year": 1661
  },
  {
    "address": "Иркутская обл, г Киренск",
    "postal_code": 666700,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Киренский",
    "city_type": "г",
    "city": "Киренск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801200100000,
    "fias_id": "1ea67233-631c-49bf-8d7c-1a17fc785e97",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 25220501000,
    "oktmo": 25620101001,
    "tax_office": 3818,
    "timezone": "UTC+8",
    "geo_lat": 57.7756595,
    "geo_lon": 108.1109412,
    "population": 12652,
    "foundation_year": 1630
  },
  {
    "address": "Иркутская обл, г Нижнеудинск",
    "postal_code": 665100,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижнеудинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000800000,
    "fias_id": "5d596a21-da89-4820-91ef-6ce3940a0c9f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25228501000,
    "oktmo": 25628101001,
    "tax_office": 3816,
    "timezone": "UTC+8",
    "geo_lat": 54.8968931,
    "geo_lon": 99.0314056,
    "population": 37056,
    "foundation_year": 1648
  },
  {
    "address": "Иркутская обл, г Саянск",
    "postal_code": 666300,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000200000,
    "fias_id": "c21b5e04-ce0f-4f98-b0ac-0ceabc531212",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25426000000,
    "oktmo": 25726000001,
    "tax_office": 3814,
    "timezone": "UTC+8",
    "geo_lat": 54.1107238,
    "geo_lon": 102.18015,
    "population": 40786,
    "foundation_year": 1970
  },
  {
    "address": "Иркутская обл, г Свирск",
    "postal_code": 665420,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Свирск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001600000,
    "fias_id": "17e498bd-5f9e-4221-8998-5fa24a35ed2e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25427000000,
    "oktmo": 25746000001,
    "tax_office": 3851,
    "timezone": "UTC+8",
    "geo_lat": 53.0842668,
    "geo_lon": 103.3364093,
    "population": 13649,
    "foundation_year": 1800
  },
  {
    "address": "Иркутская обл, г Слюдянка",
    "postal_code": 665902,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "р-н",
    "area": "Слюдянский",
    "city_type": "г",
    "city": "Слюдянка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3801800100000,
    "fias_id": "31802bef-b635-49b2-866d-1d1006e3081e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 25234501000,
    "oktmo": 25634101001,
    "tax_office": 3810,
    "timezone": "UTC+8",
    "geo_lat": 51.6563983,
    "geo_lon": 103.7187545,
    "population": 18542,
    "foundation_year": 1899
  },
  {
    "address": "Иркутская обл, г Тайшет",
    "postal_code": 665000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тайшет",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800000900000,
    "fias_id": "945ac8e1-b529-4cec-afda-f92c6a6b0f70",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25236501000,
    "oktmo": 25636101001,
    "tax_office": 3816,
    "timezone": "UTC+8",
    "geo_lat": 55.9405334,
    "geo_lon": 98.0030145,
    "population": 35481,
    "foundation_year": 1897
  },
  {
    "address": "Иркутская обл, г Тулун",
    "postal_code": 665250,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тулун",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001000000,
    "fias_id": "869c2c69-e8cd-43ae-9596-e0be50c0fcfe",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25432000000,
    "oktmo": 25732000001,
    "tax_office": 3816,
    "timezone": "UTC+8",
    "geo_lat": 54.557162,
    "geo_lon": 100.5780603,
    "population": 44603,
    "foundation_year": 1735
  },
  {
    "address": "Иркутская обл, г Усолье-Сибирское",
    "postal_code": 665450,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Усолье-Сибирское",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001100000,
    "fias_id": "ac680034-f7da-4bd8-8374-a5c97aa09516",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25436000000,
    "oktmo": 25736000001,
    "tax_office": 3851,
    "timezone": "UTC+8",
    "geo_lat": 52.7565808,
    "geo_lon": 103.6388109,
    "population": 83364,
    "foundation_year": 1669
  },
  {
    "address": "Иркутская обл, г Усть-Илимск",
    "postal_code": 666671,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Усть-Илимск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001200000,
    "fias_id": "78d11295-77b5-4ac4-b128-582e2989c0a5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25438000000,
    "oktmo": 25738000001,
    "tax_office": 3817,
    "timezone": "UTC+8",
    "geo_lat": 57.9430504,
    "geo_lon": 102.7415734,
    "population": 86591,
    "foundation_year": 1966
  },
  {
    "address": "Иркутская обл, г Усть-Кут",
    "postal_code": 666780,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Усть-Кут",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001300000,
    "fias_id": "cfb0a55a-3314-42bf-b3d8-5ed5368abdd6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25244501000,
    "oktmo": 25644101001,
    "tax_office": 3818,
    "timezone": "UTC+8",
    "geo_lat": 56.7928178,
    "geo_lon": 105.7757343,
    "population": 45061,
    "foundation_year": 1631
  },
  {
    "address": "Иркутская обл, г Черемхово",
    "postal_code": 665400,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Черемхово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001400000,
    "fias_id": "f05b68c4-f1c7-4c3d-b557-f1f44a57b85f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25445000000,
    "oktmo": 25745000001,
    "tax_office": 3851,
    "timezone": "UTC+8",
    "geo_lat": 53.1369095,
    "geo_lon": 103.0901268,
    "population": 52650,
    "foundation_year": 1772
  },
  {
    "address": "Иркутская обл, г Шелехов",
    "postal_code": 666031,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Иркутская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шелехов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3800001500000,
    "fias_id": "c727ac3a-e05c-4b81-b8e1-9b1003c28595",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 25255501000,
    "oktmo": 25655101001,
    "tax_office": 3810,
    "timezone": "UTC+8",
    "geo_lat": 52.2102538,
    "geo_lon": 104.0973294,
    "population": 47960,
    "foundation_year": 1953
  },
  {
    "address": "Респ Кабардино-Балкарская, г Баксан",
    "postal_code": 361500,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Баксан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700000300000",
    "fias_id": "67851dfd-e142-451a-b6b1-ea5e9bc7f0c1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83403000000,
    "oktmo": 83703000001,
    "tax_office": "0718",
    "timezone": "UTC+3",
    "geo_lat": 43.6819137,
    "geo_lon": 43.5345036,
    "population": 36857,
    "foundation_year": 1891
  },
  {
    "address": "Респ Кабардино-Балкарская, г Майский",
    "postal_code": 361110,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "р-н",
    "area": "Майский",
    "city_type": "г",
    "city": "Майский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700400100000",
    "fias_id": "e9f48c52-6cc4-47d9-ba6d-e7b12e8e0871",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83220501000,
    "oktmo": 83620101001,
    "tax_office": "0716",
    "timezone": "UTC+3",
    "geo_lat": 43.6281516,
    "geo_lon": 44.0517314,
    "population": 26755,
    "foundation_year": 1800
  },
  {
    "address": "г Нальчик",
    "postal_code": 360000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нальчик",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700000100000",
    "fias_id": "913a82e3-b671-43d5-97b4-8a08b8ee2d28",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 83401000000,
    "oktmo": 83701000001,
    "tax_office": "0700",
    "timezone": "UTC+3",
    "geo_lat": 43.4845464,
    "geo_lon": 43.60713,
    "population": 240095,
    "foundation_year": 1724
  },
  {
    "address": "Респ Кабардино-Балкарская, г Нарткала",
    "postal_code": 361330,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "р-н",
    "area": "Урванский",
    "city_type": "г",
    "city": "Нарткала",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700700100000",
    "fias_id": "cb0f531d-4831-41c0-a859-3ad1f2e0f70b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83240501000,
    "oktmo": 83640101001,
    "tax_office": "0724",
    "timezone": "UTC+3",
    "geo_lat": 43.5578075,
    "geo_lon": 43.8575925,
    "population": 31679,
    "foundation_year": 1913
  },
  {
    "address": "Респ Кабардино-Балкарская, г Прохладный",
    "postal_code": 361040,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Прохладный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700000200000",
    "fias_id": "22973a33-7a1c-449d-9535-def88bd91ed3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 83410000000,
    "oktmo": 83710000001,
    "tax_office": "0716",
    "timezone": "UTC+3",
    "geo_lat": 43.7589602,
    "geo_lon": 44.0102409,
    "population": 59595,
    "foundation_year": 1765
  },
  {
    "address": "Респ Кабардино-Балкарская, г Терек",
    "postal_code": 361200,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "р-н",
    "area": "Терский",
    "city_type": "г",
    "city": "Терек",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700600100000",
    "fias_id": "9886a048-8715-4526-80b1-2f0fd8dd7bdf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83235501000,
    "oktmo": 83635101001,
    "tax_office": "0724",
    "timezone": "UTC+3",
    "geo_lat": 43.4839358,
    "geo_lon": 44.1402161,
    "population": 19170,
    "foundation_year": 1876
  },
  {
    "address": "Респ Кабардино-Балкарская, г Тырныауз",
    "postal_code": 361621,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "р-н",
    "area": "Эльбрусский",
    "city_type": "г",
    "city": "Тырныауз",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0701000100000",
    "fias_id": "e0ee885d-786e-457e-9568-2def04ee83b0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83248501000,
    "oktmo": 83648101001,
    "tax_office": "0720",
    "timezone": "UTC+3",
    "geo_lat": 43.3981585,
    "geo_lon": 42.9213582,
    "population": 21000,
    "foundation_year": 1934
  },
  {
    "address": "Респ Кабардино-Балкарская, г Чегем",
    "postal_code": 361400,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Кабардино-Балкарская",
    "area_type": "р-н",
    "area": "Чегемский",
    "city_type": "г",
    "city": "Чегем",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0700800100000",
    "fias_id": "17bfd906-04ef-45f2-abbb-fcf801d0ba84",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 83245501000,
    "oktmo": 83645101001,
    "tax_office": "0724",
    "timezone": "UTC+3",
    "geo_lat": 43.5671525,
    "geo_lon": 43.5865792,
    "population": 17988,
    "foundation_year": 1822
  },
  {
    "address": "Калининградская обл, г Багратионовск",
    "postal_code": 238420,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Багратионовский",
    "city_type": "г",
    "city": "Багратионовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900200100000,
    "fias_id": "427dc561-3277-43bd-a310-7c1953a3c8bd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27203501000,
    "oktmo": 27703000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.3866518,
    "geo_lon": 20.6418091,
    "population": 6399,
    "foundation_year": 1336
  },
  {
    "address": "Калининградская обл, г Балтийск",
    "postal_code": 238520,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Балтийский",
    "city_type": "г",
    "city": "Балтийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901500100000,
    "fias_id": "97323948-09db-411c-8e9a-43ad2ec25687",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27405000000,
    "oktmo": 27705000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.6513372,
    "geo_lon": 19.9140572,
    "population": 32670,
    "foundation_year": 1626
  },
  {
    "address": "Калининградская обл, г Гвардейск",
    "postal_code": 238210,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Гвардейский",
    "city_type": "г",
    "city": "Гвардейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900300100000,
    "fias_id": "8f9e9cc0-711f-470a-9fff-2a65c00c8439",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27206501000,
    "oktmo": 27706000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.6588378,
    "geo_lon": 21.0501388,
    "population": 13888,
    "foundation_year": 1255
  },
  {
    "address": "Калининградская обл, г Гурьевск",
    "postal_code": 238300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Гурьевский",
    "city_type": "г",
    "city": "Гурьевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900400100000,
    "fias_id": "27bc7f09-4593-4972-950b-231f2a49d44b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27209501000,
    "oktmo": 27707000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.770638,
    "geo_lon": 20.6039767,
    "population": 12433,
    "foundation_year": 1262
  },
  {
    "address": "Калининградская обл, г Гусев",
    "postal_code": 238050,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Гусевский",
    "city_type": "г",
    "city": "Гусев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900500100000,
    "fias_id": "66cb21da-0212-4d22-a2a3-f1badcd3a9d4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27212501000,
    "oktmo": 27709000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 54.5915455,
    "geo_lon": 22.1942445,
    "population": 28260,
    "foundation_year": 1500
  },
  {
    "address": "Калининградская обл, г Зеленоградск",
    "postal_code": 238326,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Зеленоградский",
    "city_type": "г",
    "city": "Зеленоградск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900600100000,
    "fias_id": "448d8a42-8ebf-4ca5-a9f2-98efb532630f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27215501000,
    "oktmo": 27710000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.9600185,
    "geo_lon": 20.4753652,
    "population": 13015,
    "foundation_year": 1252
  },
  {
    "address": "г Калининград",
    "postal_code": 236001,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Калининград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000100000,
    "fias_id": "df679694-d505-4dd3-b514-4ba48c8a97d8",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 27401000000,
    "oktmo": 27701000001,
    "tax_office": 3905,
    "timezone": "UTC+2",
    "geo_lat": 54.7074702,
    "geo_lon": 20.5073241,
    "population": 431491,
    "foundation_year": 1255
  },
  {
    "address": "Калининградская обл, г Краснознаменск",
    "postal_code": 238730,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Краснознаменский",
    "city_type": "г",
    "city": "Краснознаменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900700100000,
    "fias_id": "40c2a58b-8f84-4b30-8a22-038efe4f059a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27218501000,
    "oktmo": 27713000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 54.9453423,
    "geo_lon": 22.4928745,
    "population": 3522,
    "foundation_year": 1576
  },
  {
    "address": "Калининградская обл, г Ладушкин",
    "postal_code": 238460,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ладушкин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000800000,
    "fias_id": "04950f38-5c4c-4989-8b7a-6224795a2b59",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27203505000,
    "oktmo": 27711000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.5692156,
    "geo_lon": 20.1690153,
    "population": 3788,
    "foundation_year": 1314
  },
  {
    "address": "Калининградская обл, г Мамоново",
    "postal_code": 238450,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мамоново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000900000,
    "fias_id": "2942ef2d-01b0-4137-8f46-5228e9eedd7f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27203510000,
    "oktmo": 27712000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.4646036,
    "geo_lon": 19.9453482,
    "population": 7757,
    "foundation_year": 1301
  },
  {
    "address": "Калининградская обл, г Неман",
    "postal_code": 238710,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Неманский",
    "city_type": "г",
    "city": "Неман",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900800100000,
    "fias_id": "180fcdbd-95b4-41f5-90f5-cbe9b9c83da7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27221501000,
    "oktmo": 27714000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 55.0316524,
    "geo_lon": 22.0324064,
    "population": 11794,
    "foundation_year": 1277
  },
  {
    "address": "Калининградская обл, г Нестеров",
    "postal_code": 238010,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Нестеровский",
    "city_type": "г",
    "city": "Нестеров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900900100000,
    "fias_id": "4fb71afc-f221-454e-9edf-d6fd9ee07418",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27224501000,
    "oktmo": 27715000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 54.6313814,
    "geo_lon": 22.5713736,
    "population": 4584,
    "foundation_year": 1539
  },
  {
    "address": "Калининградская обл, г Озерск",
    "postal_code": 238120,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Озерский",
    "city_type": "г",
    "city": "Озерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901000100000,
    "fias_id": "19620317-39e8-4a09-af7e-67b136ef61fe",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27227501000,
    "oktmo": 27716000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 54.4084705,
    "geo_lon": 22.0134438,
    "population": 4740,
    "foundation_year": 1539
  },
  {
    "address": "Калининградская обл, г Пионерский",
    "postal_code": 238590,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пионерский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000300000,
    "fias_id": "9fee1c1b-9d14-42ea-8ff9-2e903501d43d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27417000000,
    "oktmo": 27717000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.9516574,
    "geo_lon": 20.2277424,
    "population": 11017,
    "foundation_year": 1254
  },
  {
    "address": "Калининградская обл, г Полесск",
    "postal_code": 238630,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Полесский",
    "city_type": "г",
    "city": "Полесск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901100100000,
    "fias_id": "27fd5628-fbd7-4641-abed-b213af6029b8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27230501000,
    "oktmo": 27718000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.8625126,
    "geo_lon": 21.0998067,
    "population": 7580,
    "foundation_year": 1258
  },
  {
    "address": "Калининградская обл, г Правдинск",
    "postal_code": 238400,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Правдинский",
    "city_type": "г",
    "city": "Правдинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901200100000,
    "fias_id": "2b3f4c49-0e5b-44c8-bf56-a335eb551c23",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27233501000,
    "oktmo": 27719000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.4430986,
    "geo_lon": 21.0085269,
    "population": 4323,
    "foundation_year": 1312
  },
  {
    "address": "Калининградская обл, Балтийский р-н, г Приморск",
    "postal_code": 238510,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Балтийский",
    "city_type": "г",
    "city": "Приморск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901500200000,
    "fias_id": "5f19f5f2-55b1-4796-9610-8a73eeefcaf1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27405555000,
    "oktmo": 27705000006,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.7311437,
    "geo_lon": 19.9981926,
    "population": 1956,
    "foundation_year": 1268
  },
  {
    "address": "Калининградская обл, г Светлогорск",
    "postal_code": 238560,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Светлогорский",
    "city_type": "г",
    "city": "Светлогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901600100000,
    "fias_id": "19436029-357a-435b-8156-b01fba4ea8a5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27420000000,
    "oktmo": 27734000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.9439286,
    "geo_lon": 20.1514295,
    "population": 10775,
    "foundation_year": 1258
  },
  {
    "address": "Калининградская обл, г Светлый",
    "postal_code": 238340,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Светлый",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000600000,
    "fias_id": "6d379259-b8d5-4cca-9412-1ded0849fd1a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27425000000,
    "oktmo": 27725000001,
    "tax_office": 3917,
    "timezone": "UTC+2",
    "geo_lat": 54.6772897,
    "geo_lon": 20.1357595,
    "population": 21380,
    "foundation_year": 1640
  },
  {
    "address": "Калининградская обл, г Славск",
    "postal_code": 238600,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Славский",
    "city_type": "г",
    "city": "Славск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901300100000,
    "fias_id": "f731c104-431c-494c-b4e8-f97ccaaf71b9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27236501000,
    "oktmo": 27727000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 55.0449714,
    "geo_lon": 21.6742367,
    "population": 4614,
    "foundation_year": 1292
  },
  {
    "address": "Калининградская обл, г Советск",
    "postal_code": 238750,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Советск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3900000700000,
    "fias_id": "e401208f-3469-403f-910b-bba057cf70b2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 27430000000,
    "oktmo": 27730000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 55.0809336,
    "geo_lon": 21.8886106,
    "population": 41709,
    "foundation_year": 1288
  },
  {
    "address": "Калининградская обл, г Черняховск",
    "postal_code": 238150,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Калининградская",
    "area_type": "р-н",
    "area": "Черняховский",
    "city_type": "г",
    "city": "Черняховск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 3901400100000,
    "fias_id": "acde8f61-fd56-4cec-a4df-1f033e2a63ee",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 27239501000,
    "oktmo": 27739000001,
    "tax_office": 3914,
    "timezone": "UTC+2",
    "geo_lat": 54.6244751,
    "geo_lon": 21.7969062,
    "population": 40464,
    "foundation_year": 1336
  },
  {
    "address": "Респ Калмыкия, г Городовиковск",
    "postal_code": 359050,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Калмыкия",
    "area_type": "р-н",
    "area": "Городовиковский",
    "city_type": "г",
    "city": "Городовиковск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0800200100000",
    "fias_id": "ae5ed160-39e8-46b7-ac27-d858e7032877",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 85205501000,
    "oktmo": 85605101001,
    "tax_office": "0800",
    "timezone": "UTC+3",
    "geo_lat": 46.0875083,
    "geo_lon": 41.935537,
    "population": 9565,
    "foundation_year": 1872
  },
  {
    "address": "Респ Калмыкия, г Лагань",
    "postal_code": 359220,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Калмыкия",
    "area_type": "р-н",
    "area": "Лаганский",
    "city_type": "г",
    "city": "Лагань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0800600100000",
    "fias_id": "808f71cf-e9f9-4fe8-ab93-2b6326ebbd8e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 85215501000,
    "oktmo": 85615101,
    "tax_office": "0800",
    "timezone": "UTC+3",
    "geo_lat": 45.3930912,
    "geo_lon": 47.3432602,
    "population": 14323,
    "foundation_year": 1870
  },
  {
    "address": "г Элиста",
    "postal_code": 358000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Калмыкия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Элиста",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0800000100000",
    "fias_id": "d5bd18b9-22c1-48e2-9b4a-3b7a4c89a3cb",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 85401000000,
    "oktmo": 85701000001,
    "tax_office": "0800",
    "timezone": "UTC+3",
    "geo_lat": 46.3083344,
    "geo_lon": 44.2702088,
    "population": 103728,
    "foundation_year": 1865
  },
  {
    "address": "Калужская обл, Боровский р-н, г Балабаново",
    "postal_code": 249000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Боровский",
    "city_type": "г",
    "city": "Балабаново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000400200000,
    "fias_id": "ef65fa9c-6516-4175-a00a-6410a4a6f2d6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29206505000,
    "oktmo": 29606105001,
    "tax_office": 4025,
    "timezone": "UTC+3",
    "geo_lat": 55.1773714,
    "geo_lon": 36.6566951,
    "population": 26337,
    "foundation_year": 1584
  },
  {
    "address": "Калужская обл, Жуковский р-н, г Белоусово",
    "postal_code": 249160,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Жуковский",
    "city_type": "г",
    "city": "Белоусово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000900200000,
    "fias_id": "4601e932-0ca3-474f-a8a6-e67cc034b346",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29213507000,
    "oktmo": 29613157001,
    "tax_office": 4011,
    "timezone": "UTC+3",
    "geo_lat": 55.0956803,
    "geo_lon": 36.677629,
    "population": 8432,
    "foundation_year": 1962
  },
  {
    "address": "Калужская обл, г Боровск",
    "postal_code": 249010,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Боровский",
    "city_type": "г",
    "city": "Боровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000400100000,
    "fias_id": "db14bfff-fea7-469e-82f4-ccf4209d6753",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29206501000,
    "oktmo": 29606101001,
    "tax_office": 4025,
    "timezone": "UTC+3",
    "geo_lat": 55.2130096,
    "geo_lon": 36.4926251,
    "population": 12283,
    "foundation_year": 1358
  },
  {
    "address": "Калужская обл, Боровский р-н, г Ермолино",
    "postal_code": 249026,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Боровский",
    "city_type": "г",
    "city": "Ермолино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000400400000,
    "fias_id": "0c66ce29-8464-40ef-a11d-e8e6df7475fd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29206507000,
    "oktmo": 29606157001,
    "tax_office": 4025,
    "timezone": "UTC+3",
    "geo_lat": 55.1971758,
    "geo_lon": 36.5952722,
    "population": 10409,
    "foundation_year": 1601
  },
  {
    "address": "Калужская обл, г Жиздра",
    "postal_code": 249340,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Жиздринский",
    "city_type": "г",
    "city": "Жиздра",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000800100000,
    "fias_id": "2073f6df-cf12-455f-818d-cedea8f783fa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29212501000,
    "oktmo": 29612101,
    "tax_office": 4024,
    "timezone": "UTC+3",
    "geo_lat": 53.7521926,
    "geo_lon": 34.7386592,
    "population": 5585,
    "foundation_year": 1146
  },
  {
    "address": "Калужская обл, г Жуков",
    "postal_code": 249190,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Жуковский",
    "city_type": "г",
    "city": "Жуков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000900100000,
    "fias_id": "53f014ee-1074-4f90-b948-9e5219e24b9b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29213501000,
    "oktmo": 29613101001,
    "tax_office": 4011,
    "timezone": "UTC+3",
    "geo_lat": 55.0301833,
    "geo_lon": 36.7394903,
    "population": 12150,
    "foundation_year": 1600
  },
  {
    "address": "г Калуга",
    "postal_code": 248000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Калуга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000000100000,
    "fias_id": "b502ae45-897e-4b6f-9776-6ff49740b537",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 29401000000,
    "oktmo": 29701000001,
    "tax_office": 4000,
    "timezone": "UTC+3",
    "geo_lat": 54.5059848,
    "geo_lon": 36.2516245,
    "population": 325185,
    "foundation_year": 1371
  },
  {
    "address": "Калужская обл, г Киров",
    "postal_code": 249440,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Киров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001100100000,
    "fias_id": "7c875ac0-5c75-4798-8786-564ccb5bd9f2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29214501000,
    "oktmo": 29614101001,
    "tax_office": 4023,
    "timezone": "UTC+3",
    "geo_lat": 54.0790111,
    "geo_lon": 34.3076201,
    "population": 31888,
    "foundation_year": 1745
  },
  {
    "address": "Калужская обл, г Козельск",
    "postal_code": 249720,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Козельский",
    "city_type": "г",
    "city": "Козельск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001200100000,
    "fias_id": "adc77035-406d-4cb4-9bcc-094c89ac67fc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29216501000,
    "oktmo": 29616101001,
    "tax_office": 4001,
    "timezone": "UTC+3",
    "geo_lat": 54.0347201,
    "geo_lon": 35.780768,
    "population": 18203,
    "foundation_year": 1100
  },
  {
    "address": "Калужская обл, г Кондрово",
    "postal_code": 249830,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Дзержинский",
    "city_type": "г",
    "city": "Кондрово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000600100000,
    "fias_id": "9ea37890-3ec5-4425-8e41-f60d1844335d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29208501000,
    "oktmo": 29608101001,
    "tax_office": 4004,
    "timezone": "UTC+3",
    "geo_lat": 54.7959473,
    "geo_lon": 35.9274842,
    "population": 16672,
    "foundation_year": 1615
  },
  {
    "address": "Калужская обл, Жуковский р-н, г Кременки",
    "postal_code": 249185,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Жуковский",
    "city_type": "г",
    "city": "Кременки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000900400000,
    "fias_id": "73c0bcae-5da5-4796-9084-9b9e5905d501",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29213510000,
    "oktmo": 29613160001,
    "tax_office": 4011,
    "timezone": "UTC+3",
    "geo_lat": 54.8862447,
    "geo_lon": 37.1168701,
    "population": 11617,
    "foundation_year": 1700
  },
  {
    "address": "Калужская обл, г Людиново",
    "postal_code": 249400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Людиновский",
    "city_type": "г",
    "city": "Людиново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001400100000,
    "fias_id": "e6b8a826-768f-4745-889b-e774c1520c5f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29220501000,
    "oktmo": 29620101001,
    "tax_office": 4024,
    "timezone": "UTC+3",
    "geo_lat": 53.8700828,
    "geo_lon": 34.4385915,
    "population": 40550,
    "foundation_year": 1626
  },
  {
    "address": "Калужская обл, г Малоярославец",
    "postal_code": 249091,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Малоярославецкий",
    "city_type": "г",
    "city": "Малоярославец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001500100000,
    "fias_id": "72cb3a9a-0180-4619-ac08-07e762e58e6f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29223501000,
    "oktmo": 29623101001,
    "tax_office": 4011,
    "timezone": "UTC+3",
    "geo_lat": 55.0177123,
    "geo_lon": 36.4633603,
    "population": 30401,
    "foundation_year": 1402
  },
  {
    "address": "Калужская обл, г Медынь",
    "postal_code": 249950,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Медынский",
    "city_type": "г",
    "city": "Медынь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001600100000,
    "fias_id": "df03aec4-0859-4f40-9f51-90b2ed2f83ef",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29225501000,
    "oktmo": 29625101001,
    "tax_office": 4004,
    "timezone": "UTC+3",
    "geo_lat": 54.9689785,
    "geo_lon": 35.8872168,
    "population": 8298,
    "foundation_year": 1386
  },
  {
    "address": "Калужская обл, г Мещовск",
    "postal_code": 249240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Мещовский",
    "city_type": "г",
    "city": "Мещовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001700100000,
    "fias_id": "9b4890ea-75d9-4464-96f1-077c8a9c7e34",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29227501000,
    "oktmo": 29627101001,
    "tax_office": 4001,
    "timezone": "UTC+3",
    "geo_lat": 54.3191471,
    "geo_lon": 35.2816918,
    "population": 4101,
    "foundation_year": 1238
  },
  {
    "address": "Калужская обл, г Мосальск",
    "postal_code": 249930,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Мосальский",
    "city_type": "г",
    "city": "Мосальск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001800100000,
    "fias_id": "4ae6d306-349e-4fb8-9ce9-7c3735d3c4e6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29229501000,
    "oktmo": 29629101001,
    "tax_office": 4023,
    "timezone": "UTC+3",
    "geo_lat": 54.4824939,
    "geo_lon": 34.9872239,
    "population": 4285,
    "foundation_year": 1231
  },
  {
    "address": "Калужская обл, г Обнинск",
    "postal_code": 249030,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Обнинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000000200000,
    "fias_id": "05a647ff-b859-4c93-8c8e-3590110a0085",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29415000000,
    "oktmo": 29715000001,
    "tax_office": 4025,
    "timezone": "UTC+3",
    "geo_lat": 55.0943144,
    "geo_lon": 36.6121639,
    "population": 104798,
    "foundation_year": 1946
  },
  {
    "address": "Калужская обл, Козельский р-н, г Сосенский",
    "postal_code": 249710,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Козельский",
    "city_type": "г",
    "city": "Сосенский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4001200200000,
    "fias_id": "44326142-0196-4cec-abcb-79853fe6cfd4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29216504000,
    "oktmo": 29616104001,
    "tax_office": 4001,
    "timezone": "UTC+3",
    "geo_lat": 54.0566016,
    "geo_lon": 35.9621646,
    "population": 12394,
    "foundation_year": 1952
  },
  {
    "address": "Калужская обл, г Спас-Деменск",
    "postal_code": 249610,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Спас-Деменский",
    "city_type": "г",
    "city": "Спас-Деменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4000500100000,
    "fias_id": "30745bd5-b803-4327-baf9-006f25353acc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29234501000,
    "oktmo": 29634101001,
    "tax_office": 4023,
    "timezone": "UTC+3",
    "geo_lat": 54.409922,
    "geo_lon": 34.0189631,
    "population": 4904,
    "foundation_year": 1446
  },
  {
    "address": "Калужская обл, г Сухиничи",
    "postal_code": 249270,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Сухиничский",
    "city_type": "г",
    "city": "Сухиничи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4002000100000,
    "fias_id": "84778f7b-bf8c-45cc-84e1-dfd136368e6a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29236501000,
    "oktmo": 29636101001,
    "tax_office": 4001,
    "timezone": "UTC+3",
    "geo_lat": 54.097296,
    "geo_lon": 35.3443568,
    "population": 16295,
    "foundation_year": 1700
  },
  {
    "address": "Калужская обл, Тарусский р-н, г Таруса",
    "postal_code": 249100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Тарусский",
    "city_type": "г",
    "city": "Таруса",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4002100100000,
    "fias_id": "31a0a531-a20f-4ccc-a370-551cd9867b0c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 29238501000,
    "oktmo": 29638101001,
    "tax_office": 4029,
    "timezone": "UTC+3",
    "geo_lat": 54.7236477,
    "geo_lon": 37.1671,
    "population": 9656,
    "foundation_year": 1246
  },
  {
    "address": "Калужская обл, г Юхнов",
    "postal_code": 249910,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Калужская",
    "area_type": "р-н",
    "area": "Юхновский",
    "city_type": "г",
    "city": "Юхнов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4002500100000,
    "fias_id": "503b2859-0304-4e8e-b627-8f611ff27d5e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 29250501000,
    "oktmo": 29650101001,
    "tax_office": 4004,
    "timezone": "UTC+3",
    "geo_lat": 54.7446445,
    "geo_lon": 35.2424346,
    "population": 7056,
    "foundation_year": 1410
  },
  {
    "address": "Камчатский край, г Вилючинск",
    "postal_code": 684090,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Камчатский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Вилючинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4100000200000,
    "fias_id": "b75a9701-5cd4-4623-99d5-4fa7463ceb47",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 30535000000,
    "oktmo": 30735000001,
    "tax_office": 4100,
    "timezone": "UTC+12",
    "geo_lat": 52.9302415,
    "geo_lon": 158.4057632,
    "population": 22905,
    "foundation_year": 1968
  },
  {
    "address": "Камчатский край, г Елизово",
    "postal_code": 684000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Камчатский",
    "area_type": "р-н",
    "area": "Елизовский",
    "city_type": "г",
    "city": "Елизово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4100500100000,
    "fias_id": "3da129ee-9b72-45ec-bef9-07b69e1eed4e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 30402000000,
    "oktmo": 30607101001,
    "tax_office": 4100,
    "timezone": "UTC+12",
    "geo_lat": 53.1830375,
    "geo_lon": 158.3883548,
    "population": 39548,
    "foundation_year": 1848
  },
  {
    "address": "г Петропавловск-Камчатский",
    "postal_code": 683000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Камчатский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Петропавловск-Камчатский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4100000100000,
    "fias_id": "0b3f0723-5fe0-4c23-af44-8082166c6d2e",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 30401000000,
    "oktmo": 30701000001,
    "tax_office": 4100,
    "timezone": "UTC+12",
    "geo_lat": 53.036908,
    "geo_lon": 158.6559254,
    "population": 179526,
    "foundation_year": 1740
  },
  {
    "address": "Респ Карачаево-Черкесская, г Карачаевск",
    "postal_code": 369200,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Карачаево-Черкесская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Карачаевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0900000200000",
    "fias_id": "cb390fbd-3149-4ac9-b9fb-6af069d51b71",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 91405000000,
    "oktmo": 91705000001,
    "tax_office": "0916",
    "timezone": "UTC+3",
    "geo_lat": 43.7732525,
    "geo_lon": 41.9143472,
    "population": 23848,
    "foundation_year": 1926
  },
  {
    "address": "Респ Карачаево-Черкесская, г Теберда",
    "postal_code": 369212,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Карачаево-Черкесская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Теберда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0900000300000",
    "fias_id": "2edbb310-a924-44a8-a6d0-c9b825214fcf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 91405503000,
    "oktmo": 91705000006,
    "tax_office": "0916",
    "timezone": "UTC+3",
    "geo_lat": 43.4437731,
    "geo_lon": 41.7415142,
    "population": 9097,
    "foundation_year": 1868
  },
  {
    "address": "Респ Карачаево-Черкесская, г Усть-Джегута",
    "postal_code": 369300,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Карачаево-Черкесская",
    "area_type": "р-н",
    "area": "Усть-Джегутинский",
    "city_type": "г",
    "city": "Усть-Джегута",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0900800100000",
    "fias_id": "5de40749-195c-445b-b65a-fd1c397d1440",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 91235501000,
    "oktmo": 91635101001,
    "tax_office": "0916",
    "timezone": "UTC+3",
    "geo_lat": 44.0838442,
    "geo_lon": 41.9711046,
    "population": 30602,
    "foundation_year": 1868
  },
  {
    "address": "г Черкесск",
    "postal_code": 369000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Карачаево-Черкесская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Черкесск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": "0900000100000",
    "fias_id": "2a4a7c93-f3f8-4042-8cbf-e04ab64f5e08",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 91401000000,
    "oktmo": 91701000001,
    "tax_office": "0917",
    "timezone": "UTC+3",
    "geo_lat": 44.2269425,
    "geo_lon": 42.0466704,
    "population": 121439,
    "foundation_year": 1825
  },
  {
    "address": "Респ Карелия, г Беломорск",
    "postal_code": 186500,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Беломорский",
    "city_type": "г",
    "city": "Беломорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000200100000,
    "fias_id": "b4bc7957-5539-4da9-ac5f-d6ee037c0d52",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86204501000,
    "oktmo": 86604101001,
    "tax_office": 1032,
    "timezone": "UTC+3",
    "geo_lat": 64.5378417,
    "geo_lon": 34.7799462,
    "population": 11217,
    "foundation_year": 1100
  },
  {
    "address": "Респ Карелия, Кемский р-н, г Кемь",
    "postal_code": 186610,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Кемский",
    "city_type": "г",
    "city": "Кемь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000400100000,
    "fias_id": "e4338e50-dae7-4bd0-8843-ca0db77140d3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 86212501000,
    "oktmo": 86612101001,
    "tax_office": 1031,
    "timezone": "UTC+3",
    "geo_lat": 64.9543539,
    "geo_lon": 34.5949263,
    "population": 13061,
    "foundation_year": 1300
  },
  {
    "address": "Респ Карелия, г Кондопога",
    "postal_code": 186220,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Кондопожский",
    "city_type": "г",
    "city": "Кондопога",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000500100000,
    "fias_id": "ca9f6919-1174-4f35-a932-232fa02a4334",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86215501000,
    "oktmo": 86615101001,
    "tax_office": 1039,
    "timezone": "UTC+3",
    "geo_lat": 62.2059817,
    "geo_lon": 34.2682122,
    "population": 32978,
    "foundation_year": 1563
  },
  {
    "address": "Респ Карелия, г Костомукша",
    "postal_code": 186930,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Костомукша",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000000400000,
    "fias_id": "1925075b-8541-4075-97ea-edad0557626e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 86406000000,
    "oktmo": 86706000001,
    "tax_office": 1031,
    "timezone": "UTC+3",
    "geo_lat": 64.5889398,
    "geo_lon": 30.6016832,
    "population": 28433,
    "foundation_year": 1977
  },
  {
    "address": "Респ Карелия, г Лахденпохья",
    "postal_code": 186730,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Лахденпохский",
    "city_type": "г",
    "city": "Лахденпохья",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000600100000,
    "fias_id": "5b02bb69-ea4f-482d-b6c3-5bdfea5244d6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86218501000,
    "oktmo": 86618101001,
    "tax_office": 1035,
    "timezone": "UTC+3",
    "geo_lat": 61.518881,
    "geo_lon": 30.1996116,
    "population": 7818,
    "foundation_year": 1600
  },
  {
    "address": "Респ Карелия, г Медвежьегорск",
    "postal_code": 186350,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Медвежьегорский",
    "city_type": "г",
    "city": "Медвежьегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000800100000,
    "fias_id": "fec511c3-62fc-4093-95bd-89f05b26afa6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86224501000,
    "oktmo": 86624101001,
    "tax_office": 1039,
    "timezone": "UTC+3",
    "geo_lat": 62.9127626,
    "geo_lon": 34.4568489,
    "population": 15536,
    "foundation_year": 1916
  },
  {
    "address": "Респ Карелия, г Олонец",
    "postal_code": 186000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Олонецкий",
    "city_type": "г",
    "city": "Олонец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1001000100000,
    "fias_id": "38568ddc-20b7-409f-aa76-024b3b02c637",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86230501000,
    "oktmo": 86630101001,
    "tax_office": 1035,
    "timezone": "UTC+3",
    "geo_lat": 60.9794025,
    "geo_lon": 32.9725519,
    "population": 9060,
    "foundation_year": 1137
  },
  {
    "address": "г Петрозаводск",
    "postal_code": 185000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Петрозаводск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000000100000,
    "fias_id": "ccc34487-8fd4-4e71-b032-f4e6c82fb354",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 86401000000,
    "oktmo": 86701000001,
    "tax_office": 1001,
    "timezone": "UTC+3",
    "geo_lat": 61.7891264,
    "geo_lon": 34.3596434,
    "population": 263540,
    "foundation_year": 1703
  },
  {
    "address": "Респ Карелия, г Питкяранта",
    "postal_code": 186810,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Питкярантский",
    "city_type": "г",
    "city": "Питкяранта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1001100100000,
    "fias_id": "284264da-5fb5-4301-b36a-1127d751329d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86233501000,
    "oktmo": 86633101001,
    "tax_office": 1035,
    "timezone": "UTC+3",
    "geo_lat": 61.5757191,
    "geo_lon": 31.4640557,
    "population": 11484,
    "foundation_year": 1800
  },
  {
    "address": "Респ Карелия, г Пудож",
    "postal_code": 186150,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Пудожский",
    "city_type": "г",
    "city": "Пудож",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1001300100000,
    "fias_id": "ed96ee68-5ea9-4ebd-950a-2bbbd545b78e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86242501000,
    "oktmo": 86642101001,
    "tax_office": 1039,
    "timezone": "UTC+3",
    "geo_lat": 61.8058821,
    "geo_lon": 36.5329941,
    "population": 9698,
    "foundation_year": 1382
  },
  {
    "address": "Респ Карелия, г Сегежа",
    "postal_code": 186420,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Сегежский",
    "city_type": "г",
    "city": "Сегежа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1001400100000,
    "fias_id": "4eb67866-2460-40c3-b69f-80889385caa3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86245501000,
    "oktmo": 86645101001,
    "tax_office": 1032,
    "timezone": "UTC+3",
    "geo_lat": 63.7437572,
    "geo_lon": 34.3126982,
    "population": 29660,
    "foundation_year": 1914
  },
  {
    "address": "Респ Карелия, г Сортавала",
    "postal_code": 186790,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сортавала",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1000000700000,
    "fias_id": "7fa96873-4865-4f14-99e4-78d118cdca8b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 86410000017,
    "oktmo": 86610101001,
    "tax_office": 1035,
    "timezone": "UTC+3",
    "geo_lat": 61.703367,
    "geo_lon": 30.6916998,
    "population": 19215,
    "foundation_year": 1468
  },
  {
    "address": "Респ Карелия, г Суоярви",
    "postal_code": 186870,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Карелия",
    "area_type": "р-н",
    "area": "Суоярвский",
    "city_type": "г",
    "city": "Суоярви",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1001500100000,
    "fias_id": "8a5c4019-f746-4445-8679-cb6740633a78",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 86250501000,
    "oktmo": 86650101001,
    "tax_office": 1040,
    "timezone": "UTC+3",
    "geo_lat": 62.0787293,
    "geo_lon": 32.3499386,
    "population": 9763,
    "foundation_year": 1500
  },
  {
    "address": "Кемеровская область - Кузбасс, г Анжеро-Судженск",
    "postal_code": 652470,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Анжеро-Судженск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000200000,
    "fias_id": "8a5314a4-903e-475c-a4db-8f03db3b793f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32404000000,
    "oktmo": 32704000001,
    "tax_office": 4246,
    "timezone": "UTC+7",
    "geo_lat": 56.0786281,
    "geo_lon": 86.0201278,
    "population": 76669,
    "foundation_year": 1896
  },
  {
    "address": "Кемеровская область - Кузбасс, г Белово",
    "postal_code": 652600,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Белово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001500000,
    "fias_id": "1346744e-8a64-4712-8939-bb84a1692874",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32407000000,
    "oktmo": 32707000001,
    "tax_office": 4212,
    "timezone": "UTC+7",
    "geo_lat": 54.4220968,
    "geo_lon": 86.3037373,
    "population": 76752,
    "foundation_year": 1726
  },
  {
    "address": "Кемеровская область - Кузбасс, г Березовский",
    "postal_code": 652420,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Березовский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000300000,
    "fias_id": "ef6bac92-b712-42ba-942b-515b36b9a32c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32410000000,
    "oktmo": 32710000001,
    "tax_office": 4205,
    "timezone": "UTC+7",
    "geo_lat": 55.6693513,
    "geo_lon": 86.2744459,
    "population": 47292,
    "foundation_year": 1965
  },
  {
    "address": "Кемеровская область - Кузбасс, г Гурьевск",
    "postal_code": 652780,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "р-н",
    "area": "Гурьевский",
    "city_type": "г",
    "city": "Гурьевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200300100000,
    "fias_id": "9924881d-bc20-4e72-8c2e-e4cf415c93c1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 32413000000,
    "oktmo": 32502000001,
    "tax_office": 4212,
    "timezone": "UTC+7",
    "geo_lat": 54.2859263,
    "geo_lon": 85.9475985,
    "population": 24816,
    "foundation_year": 1816
  },
  {
    "address": "Кемеровская область - Кузбасс, г Калтан",
    "postal_code": 652740,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Калтан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000400000,
    "fias_id": "5000e677-7d3c-4eec-b01d-f3f192abbd41",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32415000000,
    "oktmo": 32715000001,
    "tax_office": 4214,
    "timezone": "UTC+7",
    "geo_lat": 53.5210919,
    "geo_lon": 87.2771636,
    "population": 21893,
    "foundation_year": 1946
  },
  {
    "address": "г Кемерово",
    "postal_code": 650000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кемерово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000900000,
    "fias_id": "94bb19a3-c1fa-410b-8651-ac1bf7c050cd",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 32401000000,
    "oktmo": 32701000001,
    "tax_office": 4205,
    "timezone": "UTC+7",
    "geo_lat": 55.3910651,
    "geo_lon": 86.0467781,
    "population": 532884,
    "foundation_year": 1918
  },
  {
    "address": "Кемеровская область - Кузбасс, г Киселевск",
    "postal_code": 652700,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Киселевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000500000,
    "fias_id": "36122f01-1666-42e0-9c65-937787e8ca45",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32416000000,
    "oktmo": 32716000001,
    "tax_office": 4223,
    "timezone": "UTC+7",
    "geo_lat": 54.0059999,
    "geo_lon": 86.6366116,
    "population": 98382,
    "foundation_year": 1917
  },
  {
    "address": "Кемеровская область - Кузбасс, г Ленинск-Кузнецкий",
    "postal_code": 652500,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ленинск-Кузнецкий",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001000000,
    "fias_id": "cf600ae8-e376-42ae-bf7c-5aa1875dcd28",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32419000000,
    "oktmo": 32719000001,
    "tax_office": 4212,
    "timezone": "UTC+7",
    "geo_lat": 54.6674492,
    "geo_lon": 86.1797324,
    "population": 101666,
    "foundation_year": 1763
  },
  {
    "address": "Кемеровская область - Кузбасс, г Мариинск",
    "postal_code": 652150,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "р-н",
    "area": "Мариинский",
    "city_type": "г",
    "city": "Мариинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200700100000,
    "fias_id": "34a2e393-69d3-4258-8daf-a6290a14b06e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 32422000000,
    "oktmo": 32616101001,
    "tax_office": 4246,
    "timezone": "UTC+7",
    "geo_lat": 56.2127383,
    "geo_lon": 87.7454924,
    "population": 40522,
    "foundation_year": 1698
  },
  {
    "address": "Кемеровская область - Кузбасс, г Междуреченск",
    "postal_code": 652870,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Междуреченск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001600000,
    "fias_id": "c6a7e590-04b4-4a01-85fc-b61b63711bba",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32425000000,
    "oktmo": 32725000001,
    "tax_office": 4214,
    "timezone": "UTC+7",
    "geo_lat": 53.6865289,
    "geo_lon": 88.0702754,
    "population": 101995,
    "foundation_year": 1946
  },
  {
    "address": "Кемеровская область - Кузбасс, г Мыски",
    "postal_code": 652840,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мыски",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000600000,
    "fias_id": "070a8e7b-038f-48c8-9eed-16a6c03ab899",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32428000000,
    "oktmo": 32728000001,
    "tax_office": 4214,
    "timezone": "UTC+7",
    "geo_lat": 53.7125695,
    "geo_lon": 87.8055646,
    "population": 43029,
    "foundation_year": 1826
  },
  {
    "address": "Кемеровская область - Кузбасс, г Новокузнецк",
    "postal_code": 654000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новокузнецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001200000,
    "fias_id": "b28b6f6f-1435-444e-95a6-68c499b0d27a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32431000000,
    "oktmo": 32731000001,
    "tax_office": 4200,
    "timezone": "UTC+7",
    "geo_lat": 53.794315,
    "geo_lon": 87.2142745,
    "population": 547885,
    "foundation_year": 1618
  },
  {
    "address": "Кемеровская область - Кузбасс, г Осинники",
    "postal_code": 652800,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Осинники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000700000,
    "fias_id": "1efff7c4-448a-48c5-b476-c803b4d36842",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32434000000,
    "oktmo": 32734000001,
    "tax_office": 4214,
    "timezone": "UTC+7",
    "geo_lat": 53.5988055,
    "geo_lon": 87.3371272,
    "population": 45997,
    "foundation_year": 1926
  },
  {
    "address": "Кемеровская область - Кузбасс, г Полысаево",
    "postal_code": 652560,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Полысаево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001100000,
    "fias_id": "34ac1b24-f541-4279-abfb-466f80ed1f7b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32435000000,
    "oktmo": 32732000001,
    "tax_office": 4212,
    "timezone": "UTC+7",
    "geo_lat": 54.6055,
    "geo_lon": 86.2809208,
    "population": 27624,
    "foundation_year": 1989
  },
  {
    "address": "Кемеровская область - Кузбасс, г Прокопьевск",
    "postal_code": 652701,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Прокопьевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001300000,
    "fias_id": "2d8b9d01-1c4f-4987-8a0b-1eb5080c1f38",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32437000000,
    "oktmo": 32737000001,
    "tax_office": 4223,
    "timezone": "UTC+7",
    "geo_lat": 53.8604265,
    "geo_lon": 86.7183577,
    "population": 210150,
    "foundation_year": 1650
  },
  {
    "address": "Кемеровская область - Кузбасс, Гурьевский р-н, г Салаир",
    "postal_code": 652770,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "р-н",
    "area": "Гурьевский",
    "city_type": "г",
    "city": "Салаир",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200300200000,
    "fias_id": "e7459805-4536-4037-9113-4b9b53893807",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32413504000,
    "oktmo": 32502000006,
    "tax_office": 4212,
    "timezone": "UTC+7",
    "geo_lat": 54.2351735,
    "geo_lon": 85.8030733,
    "population": 8263,
    "foundation_year": 1626
  },
  {
    "address": "Кемеровская область - Кузбасс, г Тайга",
    "postal_code": 652400,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тайга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200000800000,
    "fias_id": "4da6f430-f531-42f5-9176-1f6cf7ba6367",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32440000000,
    "oktmo": 32740000001,
    "tax_office": 4246,
    "timezone": "UTC+7",
    "geo_lat": 56.0622131,
    "geo_lon": 85.6207182,
    "population": 25330,
    "foundation_year": 1896
  },
  {
    "address": "Кемеровская область - Кузбасс, г Таштагол",
    "postal_code": 652990,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "р-н",
    "area": "Таштагольский",
    "city_type": "г",
    "city": "Таштагол",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4201200100000,
    "fias_id": "6be8ac27-9aa0-4784-ba97-a15f066995d9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 32443000000,
    "oktmo": 32627101001,
    "tax_office": 4214,
    "timezone": "UTC+7",
    "geo_lat": 52.759313,
    "geo_lon": 87.8476546,
    "population": 23114,
    "foundation_year": 1939
  },
  {
    "address": "Кемеровская область - Кузбасс, г Топки",
    "postal_code": 652300,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "р-н",
    "area": "Топкинский",
    "city_type": "г",
    "city": "Топки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4201400100000,
    "fias_id": "5a6d8be0-aa32-4344-9a4d-89fdb4c94ae8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 32446000000,
    "oktmo": 32531000001,
    "tax_office": 4246,
    "timezone": "UTC+7",
    "geo_lat": 55.2764707,
    "geo_lon": 85.6152619,
    "population": 28642,
    "foundation_year": 1914
  },
  {
    "address": "Кемеровская область - Кузбасс, г Юрга",
    "postal_code": 652050,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Кемеровская область - Кузбасс",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Юрга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4200001400000,
    "fias_id": "988289f5-d6d0-40d5-a483-55983e11c033",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 32449000000,
    "oktmo": 32749000001,
    "tax_office": 4246,
    "timezone": "UTC+7",
    "geo_lat": 55.7202694,
    "geo_lon": 84.8886399,
    "population": 81536,
    "foundation_year": 1886
  },
  {
    "address": "Кировская обл, г Белая Холуница",
    "postal_code": 613200,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Белохолуницкий",
    "city_type": "г",
    "city": "Белая Холуница",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300400100000,
    "fias_id": "49e2b1b5-771f-4dfd-9428-e74cd055d113",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33205501000,
    "oktmo": 33605101001,
    "tax_office": 4303,
    "timezone": "UTC+3",
    "geo_lat": 58.8415239,
    "geo_lon": 50.8460699,
    "population": 11232,
    "foundation_year": 1764
  },
  {
    "address": "Кировская обл, г Вятские Поляны",
    "postal_code": 612960,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Вятскополянский",
    "city_type": "г",
    "city": "Вятские Поляны",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300800100000,
    "fias_id": "7ad714c1-1136-4c39-84c1-17e15dbc5aab",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33404000000,
    "oktmo": 33704000001,
    "tax_office": 4307,
    "timezone": "UTC+3",
    "geo_lat": 56.2284817,
    "geo_lon": 51.0614855,
    "population": 35159,
    "foundation_year": 1596
  },
  {
    "address": "Кировская обл, г Зуевка",
    "postal_code": 612410,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Зуевский",
    "city_type": "г",
    "city": "Зуевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4301000100000,
    "fias_id": "fe3dfa87-0363-4fbf-9a2c-dcfb237f6943",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33214501000,
    "oktmo": 33614101001,
    "tax_office": 4312,
    "timezone": "UTC+3",
    "geo_lat": 58.4032152,
    "geo_lon": 51.1331606,
    "population": 11198,
    "foundation_year": 1899
  },
  {
    "address": "г Киров",
    "postal_code": 610000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Киров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300000100000,
    "fias_id": "452a2ddf-88a1-4e35-8d8d-8635493768d4",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 33401000000,
    "oktmo": 33701000001,
    "tax_office": 4345,
    "timezone": "UTC+3",
    "geo_lat": 58.6035264,
    "geo_lon": 49.6679304,
    "population": 473668,
    "foundation_year": 1181
  },
  {
    "address": "Кировская обл, г Кирово-Чепецк",
    "postal_code": 613040,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кирово-Чепецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300000400000,
    "fias_id": "3cdc251a-a649-4063-8c51-00ea2f676a95",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33407000000,
    "oktmo": 33707000001,
    "tax_office": 4312,
    "timezone": "UTC+3",
    "geo_lat": 58.5559424,
    "geo_lon": 50.0316848,
    "population": 80920,
    "foundation_year": 1935
  },
  {
    "address": "Кировская обл, г Кирс",
    "postal_code": 612820,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Верхнекамский",
    "city_type": "г",
    "city": "Кирс",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300600100000,
    "fias_id": "31ed73ca-6927-4243-9618-36de284ab038",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33207501000,
    "oktmo": 33607101001,
    "tax_office": 4303,
    "timezone": "UTC+3",
    "geo_lat": 59.340056,
    "geo_lon": 52.2414437,
    "population": 10420,
    "foundation_year": 1729
  },
  {
    "address": "Кировская обл, г Котельнич",
    "postal_code": 612600,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Котельничский",
    "city_type": "г",
    "city": "Котельнич",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4301400100000,
    "fias_id": "49846fff-046e-4cee-944b-137cb833d67f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33410000000,
    "oktmo": 33710000001,
    "tax_office": 4313,
    "timezone": "UTC+3",
    "geo_lat": 58.3034832,
    "geo_lon": 48.3475597,
    "population": 24979,
    "foundation_year": 1143
  },
  {
    "address": "Кировская обл, г Луза",
    "postal_code": 613980,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Лузский",
    "city_type": "г",
    "city": "Луза",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4301700100000,
    "fias_id": "9e22dc47-e0df-407b-8448-39ca682e3fd6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33222501000,
    "oktmo": 33622101001,
    "tax_office": 4316,
    "timezone": "UTC+3",
    "geo_lat": 60.6290609,
    "geo_lon": 47.2612445,
    "population": 11262,
    "foundation_year": 1899
  },
  {
    "address": "Кировская обл, г Малмыж",
    "postal_code": 612920,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Малмыжский",
    "city_type": "г",
    "city": "Малмыж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4301800100000,
    "fias_id": "2193594d-ddaf-42b6-9dfe-95cda00ba520",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33223501000,
    "oktmo": 33623101001,
    "tax_office": 4307,
    "timezone": "UTC+3",
    "geo_lat": 56.5243844,
    "geo_lon": 50.6782739,
    "population": 8265,
    "foundation_year": 1400
  },
  {
    "address": "Кировская обл, г Мураши",
    "postal_code": 613710,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Мурашинский",
    "city_type": "г",
    "city": "Мураши",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4301900100000,
    "fias_id": "20b2bc28-9404-4c1d-9e50-783338c1ea3c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33224501000,
    "oktmo": 33624101001,
    "tax_office": 4329,
    "timezone": "UTC+3",
    "geo_lat": 59.3955383,
    "geo_lon": 48.9638417,
    "population": 6752,
    "foundation_year": 1895
  },
  {
    "address": "Кировская обл, г Нолинск",
    "postal_code": 613440,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Нолинский",
    "city_type": "г",
    "city": "Нолинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4302200100000,
    "fias_id": "03e15a08-649a-44f9-93fb-0be35ce2fb5f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33227501000,
    "oktmo": 33627101001,
    "tax_office": 4321,
    "timezone": "UTC+3",
    "geo_lat": 57.5597284,
    "geo_lon": 49.93575,
    "population": 9556,
    "foundation_year": 1668
  },
  {
    "address": "Кировская обл, г Омутнинск",
    "postal_code": 612740,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Омутнинский",
    "city_type": "г",
    "city": "Омутнинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4302300100000,
    "fias_id": "9039bbb5-93a8-4172-b97c-af2b9abbb614",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33228501000,
    "oktmo": 33628101001,
    "tax_office": 4322,
    "timezone": "UTC+3",
    "geo_lat": 58.6698913,
    "geo_lon": 52.1894564,
    "population": 23618,
    "foundation_year": 1773
  },
  {
    "address": "Кировская обл, г Орлов",
    "postal_code": 612270,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Орловский",
    "city_type": "г",
    "city": "Орлов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4302600100000,
    "fias_id": "0302ec51-eea9-4343-9248-ce4878ca465e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33245501000,
    "oktmo": 33645101001,
    "tax_office": 4313,
    "timezone": "UTC+3",
    "geo_lat": 58.5389776,
    "geo_lon": 48.8927723,
    "population": 6959,
    "foundation_year": 1459
  },
  {
    "address": "Кировская обл, г Слободской",
    "postal_code": 613150,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Слободской",
    "city_type": "г",
    "city": "Слободской",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4303100100000,
    "fias_id": "8effba3d-4f82-45f6-938a-f1eac4405623",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33413000000,
    "oktmo": 33713000001,
    "tax_office": 4329,
    "timezone": "UTC+3",
    "geo_lat": 58.7311574,
    "geo_lon": 50.1669249,
    "population": 33983,
    "foundation_year": 1505
  },
  {
    "address": "Кировская обл, г Советск",
    "postal_code": 613341,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Советский",
    "city_type": "г",
    "city": "Советск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4303200100000,
    "fias_id": "2fa0734f-2b29-49d5-862f-29f15d88a402",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33236501000,
    "oktmo": 33636101001,
    "tax_office": 4330,
    "timezone": "UTC+3",
    "geo_lat": 57.584196,
    "geo_lon": 48.9590272,
    "population": 16592,
    "foundation_year": 1100
  },
  {
    "address": "Кировская обл, Вятскополянский р-н, г Сосновка",
    "postal_code": 612990,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Вятскополянский",
    "city_type": "г",
    "city": "Сосновка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4300800200000,
    "fias_id": "9e9afbb6-8de5-4765-a69f-2c4dfa498006",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 33210504000,
    "oktmo": 33610104001,
    "tax_office": 4307,
    "timezone": "UTC+3",
    "geo_lat": 56.2532741,
    "geo_lon": 51.2833642,
    "population": 11960,
    "foundation_year": 1699
  },
  {
    "address": "Кировская обл, г Уржум",
    "postal_code": 613530,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Уржумский",
    "city_type": "г",
    "city": "Уржум",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4303600100000,
    "fias_id": "759037c3-6408-4466-afe4-b10adc4edff4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33241501000,
    "oktmo": 33641101001,
    "tax_office": 4334,
    "timezone": "UTC+3",
    "geo_lat": 57.1097477,
    "geo_lon": 50.0058097,
    "population": 10213,
    "foundation_year": 1584
  },
  {
    "address": "Кировская обл, г Яранск",
    "postal_code": 612260,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Кировская",
    "area_type": "р-н",
    "area": "Яранский",
    "city_type": "г",
    "city": "Яранск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4304000100000,
    "fias_id": "af4098b9-6200-450a-999f-6e732cbdbac3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 33250501000,
    "oktmo": 33650101001,
    "tax_office": 4339,
    "timezone": "UTC+3",
    "geo_lat": 57.3040326,
    "geo_lon": 47.8478737,
    "population": 17252,
    "foundation_year": 1584
  },
  {
    "address": "Респ Коми, г Воркута",
    "postal_code": 169900,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Воркута",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000200000,
    "fias_id": "e0bf9ea3-7cbf-4b13-95f1-32c3ee9b3ae5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87410000000,
    "oktmo": 87710000001,
    "tax_office": 1103,
    "timezone": "UTC+3",
    "geo_lat": 67.4974082,
    "geo_lon": 64.061034,
    "population": 70551,
    "foundation_year": 1936
  },
  {
    "address": "Респ Коми, г Вуктыл",
    "postal_code": 169570,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Вуктыл",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000300000,
    "fias_id": "f69a676b-8ede-4a19-80b6-6db85a12b5e1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87412000000,
    "oktmo": 87712000001,
    "tax_office": 1102,
    "timezone": "UTC+3",
    "geo_lat": 63.8615274,
    "geo_lon": 57.3165402,
    "population": 12357,
    "foundation_year": 1968
  },
  {
    "address": "Респ Коми, г Емва",
    "postal_code": 169200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "р-н",
    "area": "Княжпогостский",
    "city_type": "г",
    "city": "Емва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100600100000,
    "fias_id": "77fc9582-dbc7-4696-9ddc-00471e94553f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 87208501000,
    "oktmo": 87608101001,
    "tax_office": 1121,
    "timezone": "UTC+3",
    "geo_lat": 62.5965137,
    "geo_lon": 50.8732125,
    "population": 14574,
    "foundation_year": 1941
  },
  {
    "address": "Респ Коми, г Инта",
    "postal_code": 169840,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Инта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000400000,
    "fias_id": "1b136d3e-3bd6-440c-a3fd-23303143c440",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87415000000,
    "oktmo": 87715000001,
    "tax_office": 1105,
    "timezone": "UTC+3",
    "geo_lat": 66.0367316,
    "geo_lon": 60.1152961,
    "population": 32021,
    "foundation_year": 1940
  },
  {
    "address": "Респ Коми, Усть-Вымский р-н, г Микунь",
    "postal_code": 169060,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "р-н",
    "area": "Усть-Вымский",
    "city_type": "г",
    "city": "Микунь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1101700200000,
    "fias_id": "2144ca72-ea8d-4ad6-90bc-e8443944087a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87244505000,
    "oktmo": 87644105001,
    "tax_office": 1121,
    "timezone": "UTC+3",
    "geo_lat": 62.3579068,
    "geo_lon": 50.0719503,
    "population": 10732,
    "foundation_year": 1937
  },
  {
    "address": "Респ Коми, г Печора",
    "postal_code": 169600,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Печора",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000500000,
    "fias_id": "e0ba898b-b813-4293-b817-510d586afd6e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87420550000,
    "oktmo": 87620101001,
    "tax_office": 1105,
    "timezone": "UTC+3",
    "geo_lat": 65.148584,
    "geo_lon": 57.2239777,
    "population": 43458,
    "foundation_year": 1940
  },
  {
    "address": "Респ Коми, г Сосногорск",
    "postal_code": 169500,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сосногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000600000,
    "fias_id": "9a2569ae-14fb-43bb-b192-a4c29c463c28",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87422550000,
    "oktmo": 87626122001,
    "tax_office": 1102,
    "timezone": "UTC+3",
    "geo_lat": 63.5989841,
    "geo_lon": 53.876293,
    "population": 27809,
    "foundation_year": 1939
  },
  {
    "address": "г Сыктывкар",
    "postal_code": 167000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сыктывкар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000100000,
    "fias_id": "d2944a73-daf4-4a08-9b34-d9b0af7785a1",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 87401000000,
    "oktmo": 87701000001,
    "tax_office": 1100,
    "timezone": "UTC+3",
    "geo_lat": 61.6686617,
    "geo_lon": 50.8358151,
    "population": 235006,
    "foundation_year": 1780
  },
  {
    "address": "Респ Коми, г Усинск",
    "postal_code": 169710,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Усинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000700000,
    "fias_id": "267fd50d-0077-4041-9594-18b1de5f2acb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87423000000,
    "oktmo": 87723000001,
    "tax_office": 1106,
    "timezone": "UTC+3",
    "geo_lat": 65.994147,
    "geo_lon": 57.5569423,
    "population": 41100,
    "foundation_year": 1966
  },
  {
    "address": "Респ Коми, г Ухта",
    "postal_code": 169300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "Респ",
    "region": "Коми",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ухта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1100000800000,
    "fias_id": "067b4cef-e128-4d5a-8305-fecf53e7b7e8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 87425000000,
    "oktmo": 87725000001,
    "tax_office": 1102,
    "timezone": "UTC+3",
    "geo_lat": 63.5565514,
    "geo_lon": 53.7014239,
    "population": 99642,
    "foundation_year": 1929
  },
  {
    "address": "Костромская обл, г Буй",
    "postal_code": 157000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Буйский",
    "city_type": "г",
    "city": "Буй",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400300100000,
    "fias_id": "9180ffd0-7f8d-4fdb-a284-583f27e378eb",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34405000000,
    "oktmo": 34705000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.4733277,
    "geo_lon": 41.5306822,
    "population": 25763,
    "foundation_year": 1536
  },
  {
    "address": "Костромская обл, г Волгореченск",
    "postal_code": 156901,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волгореченск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400000200000,
    "fias_id": "40eb94b1-c51e-455c-92af-7dafaaa92caa",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 34406000000,
    "oktmo": 34706000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 57.4424559,
    "geo_lon": 41.1594201,
    "population": 17108,
    "foundation_year": 1964
  },
  {
    "address": "Костромская обл, г Галич",
    "postal_code": 157200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Галичский",
    "city_type": "г",
    "city": "Галич",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400500100000,
    "fias_id": "f2bc7c4a-cf05-48da-aed1-9abd3c57925b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34408000000,
    "oktmo": 34708000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.3828191,
    "geo_lon": 42.3654013,
    "population": 17346,
    "foundation_year": 1159
  },
  {
    "address": "Костромская обл, г Кологрив",
    "postal_code": 157440,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Кологривский",
    "city_type": "г",
    "city": "Кологрив",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400700100000,
    "fias_id": "2382db41-3085-4e55-a612-d597535ae960",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34212501000,
    "oktmo": 34612101001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.8269323,
    "geo_lon": 44.3184927,
    "population": 3314,
    "foundation_year": 1600
  },
  {
    "address": "г Кострома",
    "postal_code": 156000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кострома",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400000300000,
    "fias_id": "14c73394-b886-40a9-9e5c-547cfd4d6aad",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 34401000000,
    "oktmo": 34701000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 57.768,
    "geo_lon": 40.927,
    "population": 268617,
    "foundation_year": 1152
  },
  {
    "address": "Костромская обл, г Макарьев",
    "postal_code": 157460,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Макарьевский",
    "city_type": "г",
    "city": "Макарьев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4400900100000,
    "fias_id": "dda6ff96-ff7e-4ea0-a9eb-6bfefe3b1ec8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34218501000,
    "oktmo": 34618101001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 57.8807152,
    "geo_lon": 43.8014197,
    "population": 7114,
    "foundation_year": 1439
  },
  {
    "address": "Костромская обл, г Мантурово",
    "postal_code": 157300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Мантуровский",
    "city_type": "г",
    "city": "Мантурово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4401000100000,
    "fias_id": "b045e8f1-b705-4211-862a-101df62b4feb",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34414000000,
    "oktmo": 34714000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.3265441,
    "geo_lon": 44.757494,
    "population": 17479,
    "foundation_year": 1617
  },
  {
    "address": "Костромская обл, г Нерехта",
    "postal_code": 157800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Нерехтский",
    "city_type": "г",
    "city": "Нерехта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4401300100000,
    "fias_id": "d8fcde72-38b9-4032-9670-fa496983e62f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34420000000,
    "oktmo": 34626101001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 57.4543369,
    "geo_lon": 40.5723598,
    "population": 22817,
    "foundation_year": 1214
  },
  {
    "address": "Костромская обл, г Нея",
    "postal_code": 157331,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Нейский",
    "city_type": "г",
    "city": "Нея",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4401200100000,
    "fias_id": "39d5f8b9-551c-42ee-8fbc-d44ceb427b47",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34422000000,
    "oktmo": 34624101,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.2943349,
    "geo_lon": 43.8780948,
    "population": 9827,
    "foundation_year": 1906
  },
  {
    "address": "Костромская обл, г Солигалич",
    "postal_code": 157170,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Солигаличский",
    "city_type": "г",
    "city": "Солигалич",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4402000100000,
    "fias_id": "35df6629-3c19-4285-8a6c-559750ec36ae",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34240501000,
    "oktmo": 34640101001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 59.0784867,
    "geo_lon": 42.2878423,
    "population": 6438,
    "foundation_year": 1335
  },
  {
    "address": "Костромская обл, г Чухлома",
    "postal_code": 157130,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Чухломский",
    "city_type": "г",
    "city": "Чухлома",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4402300100000,
    "fias_id": "fbadac52-08d8-46f2-b2c6-d272b79f36c1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34246501000,
    "oktmo": 34646101001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.753421,
    "geo_lon": 42.6884958,
    "population": 5209,
    "foundation_year": 1381
  },
  {
    "address": "Костромская обл, г Шарья",
    "postal_code": 157500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Костромская",
    "area_type": "р-н",
    "area": "Шарьинский",
    "city_type": "г",
    "city": "Шарья",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4402400100000,
    "fias_id": "c78b9b9e-ace7-46d0-9f6f-c259d7f65e4f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 34430000000,
    "oktmo": 34730000001,
    "tax_office": 4400,
    "timezone": "UTC+3",
    "geo_lat": 58.3760542,
    "geo_lon": 45.4062414,
    "population": 23668,
    "foundation_year": 1906
  },
  {
    "address": "Краснодарский край, г Абинск",
    "postal_code": 353320,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Абинский",
    "city_type": "г",
    "city": "Абинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300200100000,
    "fias_id": "ecd4abbc-79eb-4dd9-b02d-b10760fbc4ff",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03201501000",
    "oktmo": "03601101001",
    "tax_office": 2376,
    "timezone": "UTC+3",
    "geo_lat": 44.8679655,
    "geo_lon": 38.1618157,
    "population": 34926,
    "foundation_year": 1863
  },
  {
    "address": "Краснодарский край, г Анапа",
    "postal_code": 353440,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Анапский",
    "city_type": "г",
    "city": "Анапа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300300100000,
    "fias_id": "eeb7a0a7-3bbf-4f00-8a03-a7eabc257bed",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03403000000",
    "oktmo": "03703000001",
    "tax_office": 2301,
    "timezone": "UTC+3",
    "geo_lat": 44.8950433,
    "geo_lon": 37.3163282,
    "population": 58983,
    "foundation_year": 1781
  },
  {
    "address": "Краснодарский край, г Апшеронск",
    "postal_code": 352690,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Апшеронский",
    "city_type": "г",
    "city": "Апшеронск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300400100000,
    "fias_id": "7b9a7eb9-0d6d-4135-ad1c-3c059db9f386",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03205501000",
    "oktmo": "03605101001",
    "tax_office": 2368,
    "timezone": "UTC+3",
    "geo_lat": 44.4584006,
    "geo_lon": 39.7299824,
    "population": 40229,
    "foundation_year": 1863
  },
  {
    "address": "Краснодарский край, г Армавир",
    "postal_code": 352900,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Армавир",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000200000,
    "fias_id": "fc9c55d0-c66e-455e-8034-b0944b025c38",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03405000000",
    "oktmo": "03705000001",
    "tax_office": 2372,
    "timezone": "UTC+3",
    "geo_lat": 45.0012149,
    "geo_lon": 41.1324168,
    "population": 188897,
    "foundation_year": 1839
  },
  {
    "address": "Краснодарский край, г Белореченск",
    "postal_code": 352630,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Белореченский",
    "city_type": "г",
    "city": "Белореченск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300600100000,
    "fias_id": "ce9dccce-d0a3-49c4-a934-3f8de7fbed2a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03406000000",
    "oktmo": "03608101001",
    "tax_office": 2368,
    "timezone": "UTC+3",
    "geo_lat": 44.7651876,
    "geo_lon": 39.8781494,
    "population": 53891,
    "foundation_year": 1862
  },
  {
    "address": "Краснодарский край, г Геленджик",
    "postal_code": 353460,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Геленджик",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000300000,
    "fias_id": "e157b9c0-f908-48bc-a1d7-39c482a501a5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03408000000",
    "oktmo": "03708000001",
    "tax_office": 2304,
    "timezone": "UTC+3",
    "geo_lat": 44.5630007,
    "geo_lon": 38.0790852,
    "population": 54813,
    "foundation_year": 1831
  },
  {
    "address": "Краснодарский край, г Горячий Ключ",
    "postal_code": 353288,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Горячий Ключ",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000400000,
    "fias_id": "298ebb96-d9c3-4809-b466-386c778234a5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03409000000",
    "oktmo": "03709000001",
    "tax_office": 2368,
    "timezone": "UTC+3",
    "geo_lat": 44.6344864,
    "geo_lon": 39.1354738,
    "population": 30093,
    "foundation_year": 1868
  },
  {
    "address": "Краснодарский край, г Гулькевичи",
    "postal_code": 352190,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Гулькевичский",
    "city_type": "г",
    "city": "Гулькевичи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300900100000,
    "fias_id": "3039644e-5690-43b6-abbc-2d190f6dcecf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03213501000",
    "oktmo": "03613101001",
    "tax_office": 2364,
    "timezone": "UTC+3",
    "geo_lat": 45.3605121,
    "geo_lon": 40.6918389,
    "population": 35225,
    "foundation_year": 1875
  },
  {
    "address": "Краснодарский край, г Ейск",
    "postal_code": 353680,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Ейский",
    "city_type": "г",
    "city": "Ейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2301100100000,
    "fias_id": "d22840fe-89f7-4f1a-8591-c51b87a7aec2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03411000000",
    "oktmo": "03616101001",
    "tax_office": 2361,
    "timezone": "UTC+3",
    "geo_lat": 46.71157,
    "geo_lon": 38.2763895,
    "population": 87771,
    "foundation_year": 1848
  },
  {
    "address": "Краснодарский край, г Кореновск",
    "postal_code": 353180,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Кореновский",
    "city_type": "г",
    "city": "Кореновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2301500100000,
    "fias_id": "76e1af12-0fae-42b0-8771-02191d803ad0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03221501000",
    "oktmo": "03621101001",
    "tax_office": 2373,
    "timezone": "UTC+3",
    "geo_lat": 45.4641703,
    "geo_lon": 39.458949,
    "population": 41179,
    "foundation_year": 1794
  },
  {
    "address": "г Краснодар",
    "postal_code": 350000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Краснодар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000100000,
    "fias_id": "7dfa745e-aa19-4688-b121-b655c11e482f",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "03401000000",
    "oktmo": "03701000001",
    "tax_office": 2300,
    "timezone": "UTC+3",
    "geo_lat": 45.0401604,
    "geo_lon": 38.9759647,
    "population": 744933,
    "foundation_year": 1793
  },
  {
    "address": "Краснодарский край, Кавказский р-н, г Кропоткин",
    "postal_code": 352380,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Кавказский",
    "city_type": "г",
    "city": "Кропоткин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2301200100000,
    "fias_id": "a4f6512e-2ee9-4250-b241-b58504a886b4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03414000000",
    "oktmo": "03618101001",
    "tax_office": 2364,
    "timezone": "UTC+3",
    "geo_lat": 45.4333007,
    "geo_lon": 40.5727951,
    "population": 80743,
    "foundation_year": 1879
  },
  {
    "address": "Краснодарский край, г Крымск",
    "postal_code": 353380,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Крымский",
    "city_type": "г",
    "city": "Крымск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2301800100000,
    "fias_id": "ae6c6804-3c18-4337-bb6e-7479a8cb64f6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03415000000",
    "oktmo": "03625101001",
    "tax_office": 2376,
    "timezone": "UTC+3",
    "geo_lat": 44.9344404,
    "geo_lon": 37.9855795,
    "population": 57370,
    "foundation_year": 1862
  },
  {
    "address": "Краснодарский край, г Курганинск",
    "postal_code": 352430,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Курганинский",
    "city_type": "г",
    "city": "Курганинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2301900100000,
    "fias_id": "2c58f372-aac8-4645-b41a-f39bdffef373",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03227501000",
    "oktmo": "03627101001",
    "tax_office": 2377,
    "timezone": "UTC+3",
    "geo_lat": 44.8877025,
    "geo_lon": 40.5913245,
    "population": 47974,
    "foundation_year": 1855
  },
  {
    "address": "Краснодарский край, г Лабинск",
    "postal_code": 352500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Лабинский",
    "city_type": "г",
    "city": "Лабинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2302100100000,
    "fias_id": "0ec1acd4-d7bb-49f7-87c2-d66edb24f587",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03417000000",
    "oktmo": "03630101001",
    "tax_office": 2377,
    "timezone": "UTC+3",
    "geo_lat": 44.6354363,
    "geo_lon": 40.7245341,
    "population": 62822,
    "foundation_year": 1841
  },
  {
    "address": "Краснодарский край, г Новокубанск",
    "postal_code": 352240,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Новокубанский",
    "city_type": "г",
    "city": "Новокубанск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2302400100000,
    "fias_id": "38dd5f45-0a56-4554-93d8-3edfcdf000dd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03234501000",
    "oktmo": "03634101001",
    "tax_office": 2372,
    "timezone": "UTC+3",
    "geo_lat": 45.1038699,
    "geo_lon": 41.0475175,
    "population": 34847,
    "foundation_year": 1867
  },
  {
    "address": "Краснодарский край, г Новороссийск",
    "postal_code": 353900,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новороссийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000600000,
    "fias_id": "16ac039a-5257-4715-a8c5-d6bd9e617b53",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03420000000",
    "oktmo": "03720000001",
    "tax_office": 2315,
    "timezone": "UTC+3",
    "geo_lat": 44.7235026,
    "geo_lon": 37.7686135,
    "population": 241788,
    "foundation_year": 1838
  },
  {
    "address": "Краснодарский край, г Приморско-Ахтарск",
    "postal_code": 353860,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Приморско-Ахтарский",
    "city_type": "г",
    "city": "Приморско-Ахтарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2302800100000,
    "fias_id": "d9efcf77-c371-434d-a41e-f20e3b4c83c0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03241501000",
    "oktmo": "03641101001",
    "tax_office": 2369,
    "timezone": "UTC+3",
    "geo_lat": 46.0515432,
    "geo_lon": 38.1704875,
    "population": 32253,
    "foundation_year": 1854
  },
  {
    "address": "Краснодарский край, г Славянск-на-Кубани",
    "postal_code": 353560,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Славянский",
    "city_type": "г",
    "city": "Славянск-на-Кубани",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303000100000,
    "fias_id": "06e2b3b8-c02c-4722-887f-168236ae5382",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03423000000",
    "oktmo": "03645101001",
    "tax_office": 2370,
    "timezone": "UTC+3",
    "geo_lat": 45.2603626,
    "geo_lon": 38.1259774,
    "population": 63768,
    "foundation_year": 1865
  },
  {
    "address": "Краснодарский край, г Сочи",
    "postal_code": 354000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сочи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300000700000,
    "fias_id": "79da737a-603b-4c19-9b54-9114c96fb912",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03426000000",
    "oktmo": "03726000001",
    "tax_office": 2300,
    "timezone": "UTC+3",
    "geo_lat": 43.5854551,
    "geo_lon": 39.7231548,
    "population": 343285,
    "foundation_year": 1838
  },
  {
    "address": "Краснодарский край, г Темрюк",
    "postal_code": 353500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Темрюкский",
    "city_type": "г",
    "city": "Темрюк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303300100000,
    "fias_id": "3d6724a2-9641-44e6-bc69-097d93c01cb9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03251501000",
    "oktmo": "03651101001",
    "tax_office": 2352,
    "timezone": "UTC+3",
    "geo_lat": 45.2610949,
    "geo_lon": 37.4454412,
    "population": 38014,
    "foundation_year": 1860
  },
  {
    "address": "Краснодарский край, г Тимашевск",
    "postal_code": 352700,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Тимашевский",
    "city_type": "г",
    "city": "Тимашевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303400100000,
    "fias_id": "f09d518d-5617-476f-8c76-877899375202",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03253501000",
    "oktmo": "03653101001",
    "tax_office": 2369,
    "timezone": "UTC+3",
    "geo_lat": 45.615923,
    "geo_lon": 38.9351837,
    "population": 53921,
    "foundation_year": 1794
  },
  {
    "address": "Краснодарский край, г Тихорецк",
    "postal_code": 352120,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Тихорецкий",
    "city_type": "г",
    "city": "Тихорецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303500100000,
    "fias_id": "1d3511c8-b1dc-49c5-b5fb-3533ed4ce3c4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03429000000",
    "oktmo": "03654101001",
    "tax_office": 2360,
    "timezone": "UTC+3",
    "geo_lat": 45.8546345,
    "geo_lon": 40.1260267,
    "population": 61825,
    "foundation_year": 1895
  },
  {
    "address": "Краснодарский край, г Туапсе",
    "postal_code": 352800,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Туапсинский",
    "city_type": "г",
    "city": "Туапсе",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303600100000,
    "fias_id": "d0e68eaf-5ea0-4e65-81b6-b6f55c4df6f5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03432000000",
    "oktmo": "03655101001",
    "tax_office": 2365,
    "timezone": "UTC+3",
    "geo_lat": 44.1105335,
    "geo_lon": 39.0824904,
    "population": 63233,
    "foundation_year": 1838
  },
  {
    "address": "Краснодарский край, г Усть-Лабинск",
    "postal_code": 352330,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Усть-Лабинский",
    "city_type": "г",
    "city": "Усть-Лабинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2303800100000,
    "fias_id": "f5743b34-7858-46b4-99ca-050a4efa356d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "03257501000",
    "oktmo": "03657101001",
    "tax_office": 2373,
    "timezone": "UTC+3",
    "geo_lat": 45.2227359,
    "geo_lon": 39.6929577,
    "population": 43268,
    "foundation_year": 1794
  },
  {
    "address": "Краснодарский край, Апшеронский р-н, г Хадыженск",
    "postal_code": 352680,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "край",
    "region": "Краснодарский",
    "area_type": "р-н",
    "area": "Апшеронский",
    "city_type": "г",
    "city": "Хадыженск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2300400200000,
    "fias_id": "e27ea530-bc56-43b3-8ff7-24db68ae2760",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "03205509000",
    "oktmo": "03605109001",
    "tax_office": 2368,
    "timezone": "UTC+3",
    "geo_lat": 44.4122963,
    "geo_lon": 39.5320258,
    "population": 21580,
    "foundation_year": 1864
  },
  {
    "address": "Красноярский край, Курагинский р-н, г Артемовск",
    "postal_code": 662951,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Курагинский",
    "city_type": "г",
    "city": "Артемовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2402400200000,
    "fias_id": "d0d7a2fa-5bdc-4944-8047-75e80e4e883c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04230502000",
    "oktmo": "04630102001",
    "tax_office": 2455,
    "timezone": "UTC+7",
    "geo_lat": 54.3473075,
    "geo_lon": 93.4358377,
    "population": 2180,
    "foundation_year": 1700
  },
  {
    "address": "Красноярский край, г Ачинск",
    "postal_code": 662150,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ачинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001200000,
    "fias_id": "c3689d32-dea5-49c8-b69b-b8d2daa1d36b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04403000000",
    "oktmo": "04703000001",
    "tax_office": 2443,
    "timezone": "UTC+7",
    "geo_lat": 56.253907,
    "geo_lon": 90.4794397,
    "population": 109156,
    "foundation_year": 1683
  },
  {
    "address": "Красноярский край, г Боготол",
    "postal_code": 662060,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Боготол",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001300000,
    "fias_id": "cb56cf76-4a34-428d-8244-a802414d5fac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04406000000",
    "oktmo": "04706000001",
    "tax_office": 2443,
    "timezone": "UTC+7",
    "geo_lat": 56.2098468,
    "geo_lon": 89.5299336,
    "population": 21029,
    "foundation_year": 1893
  },
  {
    "address": "Красноярский край, г Бородино",
    "postal_code": 663980,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бородино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000200000,
    "fias_id": "78d7587f-ef26-48dc-81c6-d3ff445c9c8b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04407000000",
    "oktmo": "04707000001",
    "tax_office": 2448,
    "timezone": "UTC+7",
    "geo_lat": 55.9054121,
    "geo_lon": 94.9020967,
    "population": 17423,
    "foundation_year": 1949
  },
  {
    "address": "Красноярский край, г Дивногорск",
    "postal_code": 663081,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дивногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000300000,
    "fias_id": "76cc6918-6b7c-42b4-80da-4635d97d0da1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04409000000",
    "oktmo": "04709000001",
    "tax_office": 2464,
    "timezone": "UTC+7",
    "geo_lat": 55.9576784,
    "geo_lon": 92.3800687,
    "population": 28271,
    "foundation_year": 1956
  },
  {
    "address": "Красноярский край, г Дудинка",
    "postal_code": 647000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Таймырский Долгано-Ненецкий",
    "city_type": "г",
    "city": "Дудинка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2404800100000,
    "fias_id": "80b72eaa-bcf7-4759-b291-09ee5e8b3377",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "04253501000",
    "oktmo": "04653101001",
    "tax_office": 2457,
    "timezone": "UTC+7",
    "geo_lat": 69.4031364,
    "geo_lon": 86.1907218,
    "population": 22207,
    "foundation_year": 1667
  },
  {
    "address": "Красноярский край, г Енисейск",
    "postal_code": 663150,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Енисейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001400000,
    "fias_id": "6eb21d40-5098-49bc-8b08-ac1a2805d22b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04412000000",
    "oktmo": "04712000001",
    "tax_office": 2454,
    "timezone": "UTC+7",
    "geo_lat": 58.4485261,
    "geo_lon": 92.1651083,
    "population": 18769,
    "foundation_year": 1619
  },
  {
    "address": "Красноярский край, г Железногорск",
    "postal_code": 662970,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Железногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000400000,
    "fias_id": "e532b3c3-d300-4585-ada6-40e7900c2abf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04535000000",
    "oktmo": "04735000001",
    "tax_office": 2452,
    "timezone": "UTC+7",
    "geo_lat": 56.2529035,
    "geo_lon": 93.532273,
    "population": 85559,
    "foundation_year": 1950
  },
  {
    "address": "Красноярский край, Рыбинский р-н, г Заозерный",
    "postal_code": 663960,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Рыбинский",
    "city_type": "г",
    "city": "Заозерный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2403301500000,
    "fias_id": "1f8ea2c9-d6f7-4c30-b76d-2aa81640732e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04247501000",
    "oktmo": "04647101001",
    "tax_office": 2448,
    "timezone": "UTC+7",
    "geo_lat": 55.9617752,
    "geo_lon": 94.7091491,
    "population": 10683,
    "foundation_year": 1776
  },
  {
    "address": "Красноярский край, г Зеленогорск",
    "postal_code": 663690,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Зеленогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000500000,
    "fias_id": "38f208d6-6f29-4d30-b826-b42e8beecc67",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04537000000",
    "oktmo": "04737000001",
    "tax_office": 2448,
    "timezone": "UTC+7",
    "geo_lat": 56.1131564,
    "geo_lon": 94.5888103,
    "population": 66018,
    "foundation_year": 1956
  },
  {
    "address": "Красноярский край, Туруханский р-н, г Игарка",
    "postal_code": 663200,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Туруханский",
    "city_type": "г",
    "city": "Игарка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2403801700000,
    "fias_id": "59a84d36-af93-4e61-aac4-999431645666",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04254501000",
    "oktmo": "04654117001",
    "tax_office": 2411,
    "timezone": "UTC+7",
    "geo_lat": 67.4666901,
    "geo_lon": 86.5812794,
    "population": 6183,
    "foundation_year": 1929
  },
  {
    "address": "Красноярский край, г Иланский",
    "postal_code": 663800,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Иланский",
    "city_type": "г",
    "city": "Иланский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2401600100000,
    "fias_id": "8fa29485-6bce-450c-8c5c-26b8ab682bde",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "04218501000",
    "oktmo": "04618101001",
    "tax_office": 2450,
    "timezone": "UTC+7",
    "geo_lat": 56.2374037,
    "geo_lon": 96.067267,
    "population": 16108,
    "foundation_year": 1646
  },
  {
    "address": "Красноярский край, г Канск",
    "postal_code": 663600,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Канск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001600000,
    "fias_id": "75917770-c4cb-44d8-afcc-8a6d395c3bc2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04420000000",
    "oktmo": "04720000001",
    "tax_office": 2450,
    "timezone": "UTC+7",
    "geo_lat": 56.2051282,
    "geo_lon": 95.7051096,
    "population": 94230,
    "foundation_year": 1636
  },
  {
    "address": "Красноярский край, г Кодинск",
    "postal_code": 663491,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Кежемский",
    "city_type": "г",
    "city": "Кодинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2402100100000,
    "fias_id": "a97aba0e-9624-4e6c-8296-4e5fa103ddf0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "04224501000",
    "oktmo": "04624101001",
    "tax_office": 2450,
    "timezone": "UTC+7",
    "geo_lat": 58.6032644,
    "geo_lon": 99.1797962,
    "population": 14835,
    "foundation_year": 1977
  },
  {
    "address": "г Красноярск",
    "postal_code": 660000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноярск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000100000,
    "fias_id": "9b968c73-f4d4-4012-8da8-3dacd4d4c1bd",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "04401000000",
    "oktmo": "04701000001",
    "tax_office": 2400,
    "timezone": "UTC+7",
    "geo_lat": 56.0093879,
    "geo_lon": 92.8524806,
    "population": 973826,
    "foundation_year": 1628
  },
  {
    "address": "Красноярский край, г Лесосибирск",
    "postal_code": 662541,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лесосибирск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000800000,
    "fias_id": "2ddd48d6-e95b-4972-a34e-8586ed507763",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04422000000",
    "oktmo": "04722000001",
    "tax_office": 2454,
    "timezone": "UTC+7",
    "geo_lat": 58.221681,
    "geo_lon": 92.5037872,
    "population": 61146,
    "foundation_year": 1975
  },
  {
    "address": "Красноярский край, г Минусинск",
    "postal_code": 662600,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Минусинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001700000,
    "fias_id": "5cfaf0c0-8f59-4321-9a5b-4b1c51b3779f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04423000000",
    "oktmo": "04723000001",
    "tax_office": 2455,
    "timezone": "UTC+7",
    "geo_lat": 53.7104586,
    "geo_lon": 91.6872907,
    "population": 71171,
    "foundation_year": 1739
  },
  {
    "address": "Красноярский край, г Назарово",
    "postal_code": 662200,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Назарово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001800000,
    "fias_id": "ab0a1754-5ba1-48bb-8960-475c7fbc9feb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04426000000",
    "oktmo": "04726000001",
    "tax_office": 2459,
    "timezone": "UTC+7",
    "geo_lat": 56.0113799,
    "geo_lon": 90.4168775,
    "population": 52829,
    "foundation_year": 1700
  },
  {
    "address": "Красноярский край, г Норильск",
    "postal_code": 663300,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Норильск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400000900000,
    "fias_id": "d301a980-4a45-4066-9a20-4e783856a562",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04429000000",
    "oktmo": "04729000001",
    "tax_office": 2457,
    "timezone": "UTC+7",
    "geo_lat": 69.3489978,
    "geo_lon": 88.2009846,
    "population": 175301,
    "foundation_year": 1935
  },
  {
    "address": "Красноярский край, г Сосновоборск",
    "postal_code": 662500,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сосновоборск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001000000,
    "fias_id": "a139c8ba-831e-4ee5-87a9-1eead6984288",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04433000000",
    "oktmo": "04733000001",
    "tax_office": 2452,
    "timezone": "UTC+7",
    "geo_lat": 56.1202647,
    "geo_lon": 93.3354121,
    "population": 33090,
    "foundation_year": 1971
  },
  {
    "address": "Красноярский край, г Ужур",
    "postal_code": 662251,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Ужурский",
    "city_type": "г",
    "city": "Ужур",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2404000100000,
    "fias_id": "2730ee56-f5f7-472d-9e58-04c0b692bb8d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "04256501000",
    "oktmo": "04656101001",
    "tax_office": 2459,
    "timezone": "UTC+7",
    "geo_lat": 55.3141969,
    "geo_lon": 89.8333918,
    "population": 16079,
    "foundation_year": 1760
  },
  {
    "address": "Красноярский край, г Уяр",
    "postal_code": 663920,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "р-н",
    "area": "Уярский",
    "city_type": "г",
    "city": "Уяр",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2404100100000,
    "fias_id": "aab80404-c16d-43b2-92d4-eb06639f37c2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "04257501000",
    "oktmo": "04657101001",
    "tax_office": 2448,
    "timezone": "UTC+7",
    "geo_lat": 55.8131263,
    "geo_lon": 94.3282601,
    "population": 12666,
    "foundation_year": 1760
  },
  {
    "address": "Красноярский край, г Шарыпово",
    "postal_code": 662310,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "край",
    "region": "Красноярский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шарыпово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2400001900000,
    "fias_id": "e21e9175-86f9-4e24-b129-abdee0f956dc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "04440000000",
    "oktmo": "04740000001",
    "tax_office": 2459,
    "timezone": "UTC+7",
    "geo_lat": 55.5389739,
    "geo_lon": 89.1801044,
    "population": 38570,
    "foundation_year": 1700
  },
  {
    "address": "Респ Крым, г Ялта, г Алупка",
    "postal_code": 298676,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ялта",
    "settlement_type": "г",
    "settlement": "Алупка",
    "kladr_id": 9100000800100,
    "fias_id": "daa6815b-0cf0-44c7-981c-84d72d51f2b1",
    "fias_level": 6,
    "capital_marker": 0,
    "okato": 35419503000,
    "oktmo": 35729000006,
    "tax_office": 9103,
    "timezone": "UTC+3",
    "geo_lat": 44.4164605,
    "geo_lon": 34.0444797,
    "population": 7771,
    "foundation_year": 960
  },
  {
    "address": "Респ Крым, г Алушта",
    "postal_code": 298500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Алушта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100001100000,
    "fias_id": "1fb30227-c758-4b1a-814b-eab9c8e7a582",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35403000000,
    "oktmo": 35703000001,
    "tax_office": 9103,
    "timezone": "UTC+3",
    "geo_lat": 44.6764304,
    "geo_lon": 34.4100624,
    "population": 29078,
    "foundation_year": 500
  },
  {
    "address": "Респ Крым, г Армянск",
    "postal_code": 296012,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Армянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000200000,
    "fias_id": "2634dc63-5ab7-4b52-81de-ab6745a8eb02",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35405000000,
    "oktmo": 35706000001,
    "tax_office": 9106,
    "timezone": "UTC+3",
    "geo_lat": 46.1059307,
    "geo_lon": 33.6910012,
    "population": 21987,
    "foundation_year": 1736
  },
  {
    "address": "Респ Крым, Бахчисарайский р-н, г Бахчисарай",
    "postal_code": 298400,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "р-н",
    "area": "Бахчисарайский",
    "city_type": "г",
    "city": "Бахчисарай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100100100000,
    "fias_id": "bc8c57aa-1f97-4b2a-98ef-fc16bbb0d6cc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35204501000,
    "oktmo": 35604101001,
    "tax_office": 9104,
    "timezone": "UTC+3",
    "geo_lat": 44.7514769,
    "geo_lon": 33.8752176,
    "population": 27448,
    "foundation_year": 1532
  },
  {
    "address": "Респ Крым, Белогорский р-н, г Белогорск",
    "postal_code": 297600,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "р-н",
    "area": "Белогорский",
    "city_type": "г",
    "city": "Белогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100200100000,
    "fias_id": "b8fb5c0b-4de5-4fa4-aa50-e5dc9265116a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35207501000,
    "oktmo": 35607101001,
    "tax_office": 9109,
    "timezone": "UTC+3",
    "geo_lat": 45.057202,
    "geo_lon": 34.5999029,
    "population": 16354,
    "foundation_year": 1200
  },
  {
    "address": "Респ Крым, г Джанкой",
    "postal_code": 296100,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Джанкой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000600000,
    "fias_id": "84a2685c-0d4b-4d2c-829c-e4a1d5f89620",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35407000000,
    "oktmo": 35709000001,
    "tax_office": 9106,
    "timezone": "UTC+3",
    "geo_lat": 45.7092306,
    "geo_lon": 34.3883372,
    "population": 38622,
    "foundation_year": 1784
  },
  {
    "address": "Респ Крым, г Евпатория",
    "postal_code": 297402,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Евпатория",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000900000,
    "fias_id": "e11bab0f-338d-43e0-bfa4-1d3c87759e2a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35409000000,
    "oktmo": 35712000001,
    "tax_office": 9110,
    "timezone": "UTC+3",
    "geo_lat": 45.190629,
    "geo_lon": 33.367634,
    "population": 105719,
    "foundation_year": -497
  },
  {
    "address": "Респ Крым, г Керчь",
    "postal_code": 298300,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Керчь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000100000,
    "fias_id": "b7b1ef3e-bd1c-4080-80ea-f0580b40281b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35412000000,
    "oktmo": 35715000001,
    "tax_office": 9111,
    "timezone": "UTC+3",
    "geo_lat": 45.3562627,
    "geo_lon": 36.4674513,
    "population": 147033,
    "foundation_year": -650
  },
  {
    "address": "Респ Крым, г Красноперекопск",
    "postal_code": 296000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноперекопск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000400000,
    "fias_id": "1df1381d-cef8-4042-a5f8-4c18519d42b0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35413000000,
    "oktmo": 35718000001,
    "tax_office": 9106,
    "timezone": "UTC+3",
    "geo_lat": 45.9537576,
    "geo_lon": 33.7921939,
    "population": 26268,
    "foundation_year": 1932
  },
  {
    "address": "Респ Крым, г Саки",
    "postal_code": 296500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000300000,
    "fias_id": "afb681bd-4264-40ce-a676-cab8b33c7ca0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35414000000,
    "oktmo": 35721000001,
    "tax_office": 9110,
    "timezone": "UTC+3",
    "geo_lat": 45.1341997,
    "geo_lon": 33.6033383,
    "population": 25146,
    "foundation_year": 1952
  },
  {
    "address": "Респ Крым, г Симферополь",
    "postal_code": 295000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Симферополь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000700000,
    "fias_id": "b9001e55-72ed-43bf-b7eb-41b86a14380e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35401000000,
    "oktmo": 35701000001,
    "tax_office": 9102,
    "timezone": "UTC+3",
    "geo_lat": 44.9482948,
    "geo_lon": 34.1001151,
    "population": 332317,
    "foundation_year": 1784
  },
  {
    "address": "Респ Крым, Кировский р-н, г Старый Крым",
    "postal_code": 297345,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Старый Крым",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100400100000,
    "fias_id": "3f9914af-d6b7-47bb-9787-a12a6cf5d1d2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35216504000,
    "oktmo": 35616104001,
    "tax_office": 9108,
    "timezone": "UTC+3",
    "geo_lat": 45.029058,
    "geo_lon": 35.0901474,
    "population": 9277,
    "foundation_year": 1200
  },
  {
    "address": "Респ Крым, г Судак",
    "postal_code": 298000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Судак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000500000,
    "fias_id": "9481ba52-663f-4cac-9ba9-67f168016eab",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35416000000,
    "oktmo": 35723000001,
    "tax_office": 9108,
    "timezone": "UTC+3",
    "geo_lat": 44.8504679,
    "geo_lon": 34.9762034,
    "population": 16492,
    "foundation_year": 212
  },
  {
    "address": "Респ Крым, г Феодосия",
    "postal_code": 298100,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Феодосия",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100001000000,
    "fias_id": "ddc99126-8c9c-49e0-be2b-e05d2dcdf22e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35417000000,
    "oktmo": 35726000001,
    "tax_office": 9108,
    "timezone": "UTC+3",
    "geo_lat": 45.0318393,
    "geo_lon": 35.3824259,
    "population": 69038,
    "foundation_year": -500
  },
  {
    "address": "Респ Крым, Ленинский р-н, г Щелкино",
    "postal_code": 298213,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "р-н",
    "area": "Ленинский",
    "city_type": "г",
    "city": "Щелкино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100700100000,
    "fias_id": "e6010aa6-5f02-4beb-bcd6-d0a31fa6e978",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35227505000,
    "oktmo": 35627105001,
    "tax_office": 9111,
    "timezone": "UTC+3",
    "geo_lat": 45.4288991,
    "geo_lon": 35.825165,
    "population": 10620,
    "foundation_year": 1978
  },
  {
    "address": "Респ Крым, г Ялта",
    "postal_code": 298600,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "Респ",
    "region": "Крым",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ялта",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9100000800000,
    "fias_id": "c64ea472-28c6-4242-968f-0e1a08830f8e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 35419500000,
    "oktmo": 35729000001,
    "tax_office": 9103,
    "timezone": "UTC+3",
    "geo_lat": 44.4953612,
    "geo_lon": 34.166308,
    "population": 76746,
    "foundation_year": 1154
  },
  {
    "address": "Курганская обл, г Далматово",
    "postal_code": 641730,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Далматовский",
    "city_type": "г",
    "city": "Далматово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4500500100000,
    "fias_id": "7a5390a0-3cde-4616-92f4-8f59b9a4ad5c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37208501000,
    "oktmo": 37608101001,
    "tax_office": 4502,
    "timezone": "UTC+5",
    "geo_lat": 56.262114,
    "geo_lon": 62.9387011,
    "population": 13913,
    "foundation_year": 1644
  },
  {
    "address": "Курганская обл, г Катайск",
    "postal_code": 641700,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Катайский",
    "city_type": "г",
    "city": "Катайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4500800100000,
    "fias_id": "a0d524d7-5863-4a73-be5b-c165ad2f5e74",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37212501000,
    "oktmo": 37612101001,
    "tax_office": 4502,
    "timezone": "UTC+5",
    "geo_lat": 56.290809,
    "geo_lon": 62.5800359,
    "population": 14017,
    "foundation_year": 1655
  },
  {
    "address": "г Курган",
    "postal_code": 640002,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Курган",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4500000100000,
    "fias_id": "3bbda77d-ba3f-4457-9d44-c440815cda89",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 37401000000,
    "oktmo": 37701000001,
    "tax_office": 4500,
    "timezone": "UTC+5",
    "geo_lat": 55.4443883,
    "geo_lon": 65.3161963,
    "population": 333640,
    "foundation_year": 1679
  },
  {
    "address": "Курганская обл, г Куртамыш",
    "postal_code": 641430,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Куртамышский",
    "city_type": "г",
    "city": "Куртамыш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4501000100000,
    "fias_id": "167681ea-0c54-48e2-8c4b-c03f71c9c248",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37216501000,
    "oktmo": 37616101001,
    "tax_office": 4524,
    "timezone": "UTC+5",
    "geo_lat": 54.9368539,
    "geo_lon": 64.4203722,
    "population": 17098,
    "foundation_year": 1745
  },
  {
    "address": "Курганская обл, г Макушино",
    "postal_code": 641600,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Макушинский",
    "city_type": "г",
    "city": "Макушино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4501200100000,
    "fias_id": "fe024a51-2da6-4ce6-b727-493a829741ad",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37220501000,
    "oktmo": 37520000001,
    "tax_office": 4510,
    "timezone": "UTC+5",
    "geo_lat": 55.2153947,
    "geo_lon": 67.2451705,
    "population": 8337,
    "foundation_year": 1797
  },
  {
    "address": "Курганская обл, г Петухово",
    "postal_code": 641640,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Петуховский",
    "city_type": "г",
    "city": "Петухово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4501500100000,
    "fias_id": "a476869f-895d-4764-8c88-c406a092f124",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37226501000,
    "oktmo": 37626101001,
    "tax_office": 4510,
    "timezone": "UTC+5",
    "geo_lat": 55.0650077,
    "geo_lon": 67.8873408,
    "population": 11291,
    "foundation_year": 1779
  },
  {
    "address": "Курганская обл, г Шадринск",
    "postal_code": 641870,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шадринск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4500000200000,
    "fias_id": "7870abeb-8b0f-4899-920b-3ab8ff6024c0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 37405000000,
    "oktmo": 37705000001,
    "tax_office": 4502,
    "timezone": "UTC+5",
    "geo_lat": 56.0870344,
    "geo_lon": 63.6297182,
    "population": 77744,
    "foundation_year": 1662
  },
  {
    "address": "Курганская обл, г Шумиха",
    "postal_code": 641100,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Шумихинский",
    "city_type": "г",
    "city": "Шумиха",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4502300100000,
    "fias_id": "79685d02-4ac3-42a0-905d-f123918ca296",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37242501000,
    "oktmo": 37542000001,
    "tax_office": 4524,
    "timezone": "UTC+5",
    "geo_lat": 55.2280246,
    "geo_lon": 63.2901272,
    "population": 17821,
    "foundation_year": 1892
  },
  {
    "address": "Курганская обл, г Щучье",
    "postal_code": 641010,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Курганская",
    "area_type": "р-н",
    "area": "Щучанский",
    "city_type": "г",
    "city": "Щучье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4502400100000,
    "fias_id": "a7b4ee16-32cf-4f35-b1c7-f7ab4e458823",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 37244501000,
    "oktmo": 37644101001,
    "tax_office": 4524,
    "timezone": "UTC+5",
    "geo_lat": 55.2087637,
    "geo_lon": 62.7478548,
    "population": 10971,
    "foundation_year": 1750
  },
  {
    "address": "Курская обл, Дмитриевский р-н, г Дмитриев",
    "postal_code": 307500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "р-н",
    "area": "Дмитриевский",
    "city_type": "г",
    "city": "Дмитриев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600600100000,
    "fias_id": "d3ce7d17-c746-4ea3-a564-34903b79a3fd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 38208501000,
    "oktmo": 38608101001,
    "tax_office": 4633,
    "timezone": "UTC+3",
    "geo_lat": 52.1268464,
    "geo_lon": 35.0739038,
    "population": 7721,
    "foundation_year": 1779
  },
  {
    "address": "Курская обл, г Железногорск",
    "postal_code": 307170,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Железногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600000300000,
    "fias_id": "b73b7c50-112e-4b04-82df-1e0cea13de4b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38405000000,
    "oktmo": 38705000001,
    "tax_office": 4633,
    "timezone": "UTC+3",
    "geo_lat": 52.3380202,
    "geo_lon": 35.3516867,
    "population": 95057,
    "foundation_year": 1957
  },
  {
    "address": "г Курск",
    "postal_code": 305000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Курск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600000100000,
    "fias_id": "d790c72e-479b-4da2-90d7-842b1712a71c",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 38401000000,
    "oktmo": 38701000001,
    "tax_office": 4632,
    "timezone": "UTC+3",
    "geo_lat": 51.7303637,
    "geo_lon": 36.1925603,
    "population": 414595,
    "foundation_year": 1032
  },
  {
    "address": "Курская обл, г Курчатов",
    "postal_code": 307250,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Курчатов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600000200000,
    "fias_id": "a6d4bd96-8c96-4c15-86df-73087f55534d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38408000000,
    "oktmo": 38708000001,
    "tax_office": 4611,
    "timezone": "UTC+3",
    "geo_lat": 51.6604083,
    "geo_lon": 35.6572224,
    "population": 42691,
    "foundation_year": 1968
  },
  {
    "address": "Курская обл, г Льгов",
    "postal_code": 307750,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Льгов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600000400000,
    "fias_id": "6fe805e1-1dde-42cd-821e-173ffe50885d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38410000000,
    "oktmo": 38710000001,
    "tax_office": 4613,
    "timezone": "UTC+3",
    "geo_lat": 51.6597148,
    "geo_lon": 35.2612491,
    "population": 21452,
    "foundation_year": 1152
  },
  {
    "address": "Курская обл, г Обоянь",
    "postal_code": 306230,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "р-н",
    "area": "Обоянский",
    "city_type": "г",
    "city": "Обоянь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4601600100000,
    "fias_id": "60d797fd-62b0-4705-9f5b-aa0a67c5c749",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38226501000,
    "oktmo": 38626101001,
    "tax_office": 4619,
    "timezone": "UTC+3",
    "geo_lat": 51.2119324,
    "geo_lon": 36.2755133,
    "population": 13562,
    "foundation_year": 1639
  },
  {
    "address": "Курская обл, г Рыльск",
    "postal_code": 307370,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "р-н",
    "area": "Рыльский",
    "city_type": "г",
    "city": "Рыльск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4602000100000,
    "fias_id": "fa2b3c62-6e92-4155-a981-d19ea4999342",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38234501000,
    "oktmo": 38634101001,
    "tax_office": 4620,
    "timezone": "UTC+3",
    "geo_lat": 51.5681314,
    "geo_lon": 34.6802597,
    "population": 15667,
    "foundation_year": 1152
  },
  {
    "address": "Курская обл, г Суджа",
    "postal_code": 307800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "р-н",
    "area": "Суджанский",
    "city_type": "г",
    "city": "Суджа",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4602300100000,
    "fias_id": "abd43820-a1d4-4e16-adb4-8532959b6e98",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38240501000,
    "oktmo": 38640101001,
    "tax_office": 4623,
    "timezone": "UTC+3",
    "geo_lat": 51.1918927,
    "geo_lon": 35.2720915,
    "population": 6036,
    "foundation_year": 1500
  },
  {
    "address": "Курская обл, г Фатеж",
    "postal_code": 307100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "р-н",
    "area": "Фатежский",
    "city_type": "г",
    "city": "Фатеж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4602500100000,
    "fias_id": "f5c96821-4438-461c-b5da-2c812cd0bc0a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38244501000,
    "oktmo": 38644101,
    "tax_office": 4611,
    "timezone": "UTC+3",
    "geo_lat": 52.0917728,
    "geo_lon": 35.8538706,
    "population": 5404,
    "foundation_year": 1500
  },
  {
    "address": "Курская обл, г Щигры",
    "postal_code": 306509,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Курская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Щигры",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4600000500000,
    "fias_id": "9382c84c-3d08-4136-a674-9402da0b9e40",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 38415000000,
    "oktmo": 38715000001,
    "tax_office": 4628,
    "timezone": "UTC+3",
    "geo_lat": 51.8786014,
    "geo_lon": 36.8910945,
    "population": 17043,
    "foundation_year": 1700
  },
  {
    "address": "Ленинградская обл, г Бокситогорск",
    "postal_code": 187650,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Бокситогорский",
    "city_type": "г",
    "city": "Бокситогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700200100000,
    "fias_id": "1a12dc30-ca4c-480f-89dd-31452648b493",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41203501000,
    "oktmo": 41603101001,
    "tax_office": 4715,
    "timezone": "UTC+3",
    "geo_lat": 59.4734797,
    "geo_lon": 33.845688,
    "population": 16593,
    "foundation_year": 1929
  },
  {
    "address": "Ленинградская обл, г Волосово",
    "postal_code": 188410,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Волосовский",
    "city_type": "г",
    "city": "Волосово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700300100000,
    "fias_id": "eb8a9d09-6728-43fe-b3d1-21a2c804b40c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41206501000,
    "oktmo": 41606101001,
    "tax_office": 4705,
    "timezone": "UTC+3",
    "geo_lat": 59.4445408,
    "geo_lon": 29.4923355,
    "population": 12162,
    "foundation_year": 1870
  },
  {
    "address": "Ленинградская обл, г Волхов",
    "postal_code": 187401,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Волховский",
    "city_type": "г",
    "city": "Волхов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700400100000,
    "fias_id": "d77089df-c8ed-464d-a26b-69ce42383a0f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41209501000,
    "oktmo": 41609101001,
    "tax_office": 4702,
    "timezone": "UTC+3",
    "geo_lat": 59.9005958,
    "geo_lon": 32.3520756,
    "population": 47344,
    "foundation_year": 1918
  },
  {
    "address": "Ленинградская обл, г Всеволожск",
    "postal_code": 188640,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Всеволожский",
    "city_type": "г",
    "city": "Всеволожск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700500100000,
    "fias_id": "c70a5280-c482-4f6e-801a-cdd6851eab50",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41212501000,
    "oktmo": 41612101001,
    "tax_office": 4703,
    "timezone": "UTC+3",
    "geo_lat": 60.0191278,
    "geo_lon": 30.6456718,
    "population": 59689,
    "foundation_year": 1892
  },
  {
    "address": "Ленинградская обл, г Выборг",
    "postal_code": 188800,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Выборгский",
    "city_type": "г",
    "city": "Выборг",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700600100000,
    "fias_id": "a4a3e3af-ce5a-4eba-9aae-36c350fa7fec",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41215501000,
    "oktmo": 41615101001,
    "tax_office": 4704,
    "timezone": "UTC+3",
    "geo_lat": 60.7130801,
    "geo_lon": 28.7328336,
    "population": 80013,
    "foundation_year": 1293
  },
  {
    "address": "Ленинградская обл, Выборгский р-н, г Высоцк",
    "postal_code": 188909,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Выборгский",
    "city_type": "г",
    "city": "Высоцк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700600200000,
    "fias_id": "f0a4b813-7189-4bd4-8a3a-aaa7fbed4711",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41215504000,
    "oktmo": 41615104001,
    "tax_office": 4704,
    "timezone": "UTC+3",
    "geo_lat": 60.6296236,
    "geo_lon": 28.5709314,
    "population": 1244,
    "foundation_year": 1532
  },
  {
    "address": "Ленинградская обл, г Гатчина",
    "postal_code": 188300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Гатчинский",
    "city_type": "г",
    "city": "Гатчина",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700700100000,
    "fias_id": "d193a2f8-4ea1-406a-ba9a-698ae19caeb7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41218501000,
    "oktmo": 41618101001,
    "tax_office": 4705,
    "timezone": "UTC+3",
    "geo_lat": 59.565237,
    "geo_lon": 30.1282473,
    "population": 92566,
    "foundation_year": 1499
  },
  {
    "address": "Ленинградская обл, Кингисеппский р-н, г Ивангород",
    "postal_code": 188490,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Кингисеппский",
    "city_type": "г",
    "city": "Ивангород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700801100000,
    "fias_id": "752d9929-945c-4464-ae38-20a626108da9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41221505000,
    "oktmo": 41621102001,
    "tax_office": 4707,
    "timezone": "UTC+3",
    "geo_lat": 59.3766119,
    "geo_lon": 28.2231659,
    "population": 9797,
    "foundation_year": 1492
  },
  {
    "address": "Ленинградская обл, Выборгский р-н, г Каменногорск",
    "postal_code": 188950,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Выборгский",
    "city_type": "г",
    "city": "Каменногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700600300000,
    "fias_id": "58399f33-3e18-4921-950a-ac0fa5f9e97c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41215506000,
    "oktmo": 41615106001,
    "tax_office": 4704,
    "timezone": "UTC+3",
    "geo_lat": 60.950855,
    "geo_lon": 29.1308372,
    "population": 6761,
    "foundation_year": 1597
  },
  {
    "address": "Ленинградская обл, г Кингисепп",
    "postal_code": 188455,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Кингисеппский",
    "city_type": "г",
    "city": "Кингисепп",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700800100000,
    "fias_id": "ef14bc6b-dd76-4264-bb40-c0fd8a60985b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41221501000,
    "oktmo": 41621101001,
    "tax_office": 4707,
    "timezone": "UTC+3",
    "geo_lat": 59.3740435,
    "geo_lon": 28.6112444,
    "population": 48667,
    "foundation_year": 1384
  },
  {
    "address": "Ленинградская обл, г Кириши",
    "postal_code": 187110,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Киришский",
    "city_type": "г",
    "city": "Кириши",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700900100000,
    "fias_id": "52da70f1-08b0-4b4c-9d22-d67a62bc2aa5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41224501000,
    "oktmo": 41624101001,
    "tax_office": 4727,
    "timezone": "UTC+3",
    "geo_lat": 59.4742862,
    "geo_lon": 32.0624947,
    "population": 52826,
    "foundation_year": 1693
  },
  {
    "address": "Ленинградская обл, г Кировск",
    "postal_code": 187340,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Кировск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701000100000,
    "fias_id": "a1535e5e-c151-431a-a4c4-954d4545e37a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41225501000,
    "oktmo": 41625101001,
    "tax_office": 4706,
    "timezone": "UTC+3",
    "geo_lat": 59.8754216,
    "geo_lon": 30.981364,
    "population": 25633,
    "foundation_year": 1931
  },
  {
    "address": "Ленинградская обл, Гатчинский р-н, г Коммунар",
    "postal_code": 188320,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Гатчинский",
    "city_type": "г",
    "city": "Коммунар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700700200000,
    "fias_id": "459dca99-04ff-44fb-af9f-7e2100ec28ac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41218505000,
    "oktmo": 41618105001,
    "tax_office": 4705,
    "timezone": "UTC+3",
    "geo_lat": 59.6215133,
    "geo_lon": 30.3934125,
    "population": 20265,
    "foundation_year": 1840
  },
  {
    "address": "Ленинградская обл, Всеволожский р-н, г Кудрово",
    "postal_code": 188691,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Всеволожский",
    "city_type": "г",
    "city": "Кудрово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700511600000,
    "fias_id": "98cd19c4-e172-4fa3-a81d-7caedeec669b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41212503000,
    "oktmo": 41612155005,
    "tax_office": 4703,
    "timezone": "UTC+3",
    "geo_lat": 59.9075226,
    "geo_lon": 30.5121008,
    "population": 13501,
    "foundation_year": 1926
  },
  {
    "address": "Ленинградская обл, г Лодейное Поле",
    "postal_code": 187700,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Лодейнопольский",
    "city_type": "г",
    "city": "Лодейное Поле",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701100100000,
    "fias_id": "5f52edf9-92aa-4e69-9314-e629c45ec1d5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41227501000,
    "oktmo": 41627101001,
    "tax_office": 4711,
    "timezone": "UTC+3",
    "geo_lat": 60.7320936,
    "geo_lon": 33.5521022,
    "population": 21053,
    "foundation_year": 1702
  },
  {
    "address": "Ленинградская обл, г Луга",
    "postal_code": 188230,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Лужский",
    "city_type": "г",
    "city": "Луга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701300100000,
    "fias_id": "d9135f5d-627e-441d-b208-9087ee0367dd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41233501000,
    "oktmo": 41633101001,
    "tax_office": 4710,
    "timezone": "UTC+3",
    "geo_lat": 58.7374031,
    "geo_lon": 29.8465776,
    "population": 36409,
    "foundation_year": 1777
  },
  {
    "address": "Ленинградская обл, Тосненский р-н, г Любань",
    "postal_code": 187050,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Тосненский",
    "city_type": "г",
    "city": "Любань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701800400000,
    "fias_id": "cda96dc0-75b2-411b-b995-16cc63fcfa05",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41248505000,
    "oktmo": 41648105001,
    "tax_office": 4716,
    "timezone": "UTC+3",
    "geo_lat": 59.3493847,
    "geo_lon": 31.2484801,
    "population": 4188,
    "foundation_year": 1499
  },
  {
    "address": "Ленинградская обл, Всеволожский р-н, г Мурино",
    "postal_code": 188662,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Всеволожский",
    "city_type": "г",
    "city": "Мурино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700511700000,
    "fias_id": "d73506c5-015c-4c39-929a-696ea8144e16",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41212504000,
    "oktmo": 41612103001,
    "tax_office": 4703,
    "timezone": "UTC+3",
    "geo_lat": 60.044862,
    "geo_lon": 30.4571456,
    "population": 19775,
    "foundation_year": 1676
  },
  {
    "address": "Ленинградская обл, Тосненский р-н, г Никольское",
    "postal_code": 187026,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Тосненский",
    "city_type": "г",
    "city": "Никольское",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701800300000,
    "fias_id": "43cb88de-fa89-4027-b3ef-84deb2d32ce5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41248508000,
    "oktmo": 41648108001,
    "tax_office": 4716,
    "timezone": "UTC+3",
    "geo_lat": 59.7043309,
    "geo_lon": 30.7874571,
    "population": 19345,
    "foundation_year": 1710
  },
  {
    "address": "Ленинградская обл, Волховский р-н, г Новая Ладога",
    "postal_code": 187450,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Волховский",
    "city_type": "г",
    "city": "Новая Ладога",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700400200000,
    "fias_id": "2145f2cb-3ec2-4c1d-bd52-f84b2858c6a9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41209504000,
    "oktmo": 41609104001,
    "tax_office": 4702,
    "timezone": "UTC+3",
    "geo_lat": 60.1100135,
    "geo_lon": 32.3141203,
    "population": 8890,
    "foundation_year": 1704
  },
  {
    "address": "Ленинградская обл, Кировский р-н, г Отрадное",
    "postal_code": 187330,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Отрадное",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701000200000,
    "fias_id": "c4602384-ff62-4e0f-bf4b-06a5c653702b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41225504000,
    "oktmo": 41625104001,
    "tax_office": 4706,
    "timezone": "UTC+3",
    "geo_lat": 59.7726848,
    "geo_lon": 30.7988557,
    "population": 23874,
    "foundation_year": 1708
  },
  {
    "address": "Ленинградская обл, Бокситогорский р-н, г Пикалево",
    "postal_code": 187600,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Бокситогорский",
    "city_type": "г",
    "city": "Пикалево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700200200000,
    "fias_id": "0a243487-acce-4775-979a-5a08e8c62da6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41203505000,
    "oktmo": 41603102001,
    "tax_office": 4715,
    "timezone": "UTC+3",
    "geo_lat": 59.5132022,
    "geo_lon": 34.1772776,
    "population": 21567,
    "foundation_year": 1620
  },
  {
    "address": "Ленинградская обл, г Подпорожье",
    "postal_code": 187780,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Подпорожский",
    "city_type": "г",
    "city": "Подпорожье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701400100000,
    "fias_id": "6d13b512-6d25-413a-ab29-3d9ade960f3a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41236501000,
    "oktmo": 41636101001,
    "tax_office": 4711,
    "timezone": "UTC+3",
    "geo_lat": 60.9127549,
    "geo_lon": 34.1567664,
    "population": 18729,
    "foundation_year": 1936
  },
  {
    "address": "Ленинградская обл, Выборгский р-н, г Приморск",
    "postal_code": 188910,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Выборгский",
    "city_type": "г",
    "city": "Приморск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700600400000,
    "fias_id": "878aa7cc-76f0-42c8-90f5-3489f43c169c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41215508000,
    "oktmo": 41615108001,
    "tax_office": 4704,
    "timezone": "UTC+3",
    "geo_lat": 60.3660209,
    "geo_lon": 28.6135772,
    "population": 6122,
    "foundation_year": 1268
  },
  {
    "address": "Ленинградская обл, г Приозерск",
    "postal_code": 188760,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Приозерский",
    "city_type": "г",
    "city": "Приозерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701500100000,
    "fias_id": "59a9c284-72fb-49e6-8380-10ca044d0bd7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41239501000,
    "oktmo": 41639101001,
    "tax_office": 4712,
    "timezone": "UTC+3",
    "geo_lat": 61.0330896,
    "geo_lon": 30.1587851,
    "population": 18929,
    "foundation_year": 1100
  },
  {
    "address": "Ленинградская обл, Выборгский р-н, г Светогорск",
    "postal_code": 188990,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Выборгский",
    "city_type": "г",
    "city": "Светогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700600500000,
    "fias_id": "d4789972-b71d-4e70-9af8-f4acfcf80c71",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41215514000,
    "oktmo": 41615114001,
    "tax_office": 4704,
    "timezone": "UTC+3",
    "geo_lat": 61.1111282,
    "geo_lon": 28.8725865,
    "population": 15973,
    "foundation_year": 1887
  },
  {
    "address": "Ленинградская обл, Всеволожский р-н, г Сертолово",
    "postal_code": 188650,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Всеволожский",
    "city_type": "г",
    "city": "Сертолово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700500200000,
    "fias_id": "79b806cd-e36c-48fc-b82c-8cbb5cfd9450",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41212505000,
    "oktmo": 41612102001,
    "tax_office": 4700,
    "timezone": "UTC+3",
    "geo_lat": 60.1446932,
    "geo_lon": 30.2095918,
    "population": 47854,
    "foundation_year": 1936
  },
  {
    "address": "Ленинградская обл, г Сланцы",
    "postal_code": 188560,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Сланцевский",
    "city_type": "г",
    "city": "Сланцы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701600100000,
    "fias_id": "a7a1a8e5-45b2-4542-b718-0976702ccd90",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41242501000,
    "oktmo": 41642101001,
    "tax_office": 4707,
    "timezone": "UTC+3",
    "geo_lat": 59.1178185,
    "geo_lon": 28.0881475,
    "population": 33587,
    "foundation_year": 1934
  },
  {
    "address": "Ленинградская обл, г Сосновый Бор",
    "postal_code": 188540,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сосновый Бор",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700000400000,
    "fias_id": "f4a4b31f-9f0a-4fdb-804c-d67661085eb4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41454000000,
    "oktmo": 41754000001,
    "tax_office": 4726,
    "timezone": "UTC+3",
    "geo_lat": 59.8772884,
    "geo_lon": 29.1291619,
    "population": 65901,
    "foundation_year": 1958
  },
  {
    "address": "Ленинградская обл, Волховский р-н, г Сясьстрой",
    "postal_code": 187420,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Волховский",
    "city_type": "г",
    "city": "Сясьстрой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4700400300000,
    "fias_id": "f59186fa-b4db-4b2d-88b9-e3fde4a90690",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41209508000,
    "oktmo": 41609108001,
    "tax_office": 4702,
    "timezone": "UTC+3",
    "geo_lat": 60.1401739,
    "geo_lon": 32.5601559,
    "population": 13747,
    "foundation_year": 1926
  },
  {
    "address": "Ленинградская обл, г Тихвин",
    "postal_code": 187550,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Тихвинский",
    "city_type": "г",
    "city": "Тихвин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701700100000,
    "fias_id": "86ec26f6-d980-4c40-b4ce-77e1638ec8ad",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41245501000,
    "oktmo": 41645101001,
    "tax_office": 4715,
    "timezone": "UTC+3",
    "geo_lat": 59.6272904,
    "geo_lon": 33.5072731,
    "population": 58843,
    "foundation_year": 1383
  },
  {
    "address": "Ленинградская обл, г Тосно",
    "postal_code": 187000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Тосненский",
    "city_type": "г",
    "city": "Тосно",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701800100000,
    "fias_id": "165119d1-6555-4473-8b90-46ec517b4076",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 41248501000,
    "oktmo": 41648101001,
    "tax_office": 4716,
    "timezone": "UTC+3",
    "geo_lat": 59.5407098,
    "geo_lon": 30.877812,
    "population": 39127,
    "foundation_year": 1500
  },
  {
    "address": "Ленинградская обл, Кировский р-н, г Шлиссельбург",
    "postal_code": 187320,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Ленинградская",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Шлиссельбург",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4701000300000,
    "fias_id": "5a543514-a133-431c-919e-8a61c2261783",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 41225503000,
    "oktmo": 41625102001,
    "tax_office": 4706,
    "timezone": "UTC+3",
    "geo_lat": 59.9443714,
    "geo_lon": 31.0333365,
    "population": 13305,
    "foundation_year": 1323
  },
  {
    "address": "Липецкая обл, г Грязи",
    "postal_code": 399050,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Грязинский",
    "city_type": "г",
    "city": "Грязи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4800300100000,
    "fias_id": "f073b28a-b8f8-4eee-adfd-cfbf769c6771",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42206501000,
    "oktmo": 42606101001,
    "tax_office": 4802,
    "timezone": "UTC+3",
    "geo_lat": 52.4874097,
    "geo_lon": 39.9331142,
    "population": 46798,
    "foundation_year": 1868
  },
  {
    "address": "Липецкая обл, г Данков",
    "postal_code": 399850,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Данковский",
    "city_type": "г",
    "city": "Данков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4800400100000,
    "fias_id": "ad0cefe8-456d-4cac-bee3-c3e910187f4a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42209501000,
    "oktmo": 42609101001,
    "tax_office": 4811,
    "timezone": "UTC+3",
    "geo_lat": 53.2577411,
    "geo_lon": 39.1456184,
    "population": 21056,
    "foundation_year": 1563
  },
  {
    "address": "Липецкая обл, г Елец",
    "postal_code": 399770,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Елец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4800000200000,
    "fias_id": "8261b161-ceac-4fa2-9fe8-593cb2a28210",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 42415000000,
    "oktmo": 42715000001,
    "tax_office": 4828,
    "timezone": "UTC+3",
    "geo_lat": 52.6152411,
    "geo_lon": 38.5289342,
    "population": 108404,
    "foundation_year": 1146
  },
  {
    "address": "Липецкая обл, г Задонск",
    "postal_code": 399200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Задонский",
    "city_type": "г",
    "city": "Задонск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4800900100000,
    "fias_id": "4c035f08-697c-4ad0-9c8e-7b7a8a385974",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42224501000,
    "oktmo": 42624101001,
    "tax_office": 4816,
    "timezone": "UTC+3",
    "geo_lat": 52.4004179,
    "geo_lon": 38.9205032,
    "population": 9695,
    "foundation_year": 1615
  },
  {
    "address": "Липецкая обл, г Лебедянь",
    "postal_code": 399610,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Лебедянский",
    "city_type": "г",
    "city": "Лебедянь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4801200100000,
    "fias_id": "d8a96228-5392-4c32-98cb-6a7ac20db942",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42233501000,
    "oktmo": 42633101001,
    "tax_office": 4811,
    "timezone": "UTC+3",
    "geo_lat": 53.0156117,
    "geo_lon": 39.143536,
    "population": 20991,
    "foundation_year": 1605
  },
  {
    "address": "г Липецк",
    "postal_code": 398000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Липецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4800000100000,
    "fias_id": "eacb5f15-1a2e-432e-904a-ca56bd635f1b",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 42401000000,
    "oktmo": 42701000001,
    "tax_office": 4800,
    "timezone": "UTC+3",
    "geo_lat": 52.610249,
    "geo_lon": 39.5947883,
    "population": 508124,
    "foundation_year": 1703
  },
  {
    "address": "Липецкая обл, г Усмань",
    "postal_code": 399341,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Усманский",
    "city_type": "г",
    "city": "Усмань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4801600100000,
    "fias_id": "1b3ce1ae-6edd-464b-ab82-79237510cd5c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42248501000,
    "oktmo": 42648101001,
    "tax_office": 4816,
    "timezone": "UTC+3",
    "geo_lat": 52.0442648,
    "geo_lon": 39.726401,
    "population": 18752,
    "foundation_year": 1645
  },
  {
    "address": "Липецкая обл, г Чаплыгин",
    "postal_code": 399900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Липецкая",
    "area_type": "р-н",
    "area": "Чаплыгинский",
    "city_type": "г",
    "city": "Чаплыгин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4801800100000,
    "fias_id": "4aed94c2-977d-41cc-bc72-312026ec4117",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 42256501000,
    "oktmo": 42656101001,
    "tax_office": 4813,
    "timezone": "UTC+3",
    "geo_lat": 53.24048,
    "geo_lon": 39.9670973,
    "population": 12656,
    "foundation_year": 1638
  },
  {
    "address": "г Магадан",
    "postal_code": 685000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Магаданская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Магадан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4900000100000,
    "fias_id": "cb8ae35a-93df-4133-b377-50f3698c8b5e",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 44401000000,
    "oktmo": 44701000001,
    "tax_office": 4900,
    "timezone": "UTC+11",
    "geo_lat": 59.5681332,
    "geo_lon": 150.8084956,
    "population": 95925,
    "foundation_year": 1929
  },
  {
    "address": "Магаданская обл, г Сусуман",
    "postal_code": 686314,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Магаданская",
    "area_type": "р-н",
    "area": "Сусуманский",
    "city_type": "г",
    "city": "Сусуман",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 4900600100000,
    "fias_id": "b617d714-cbce-4abe-bf0d-668be5e3d670",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 44213501000,
    "oktmo": 44713000001,
    "tax_office": 4900,
    "timezone": "UTC+11",
    "geo_lat": 62.7805882,
    "geo_lon": 148.1540281,
    "population": 5865,
    "foundation_year": 1938
  },
  {
    "address": "Респ Марий Эл, г Волжск",
    "postal_code": 425000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Марий Эл",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волжск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1200000200000,
    "fias_id": "f183b079-b65f-4781-9b79-310ba29b724d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 88405000000,
    "oktmo": 88705000,
    "tax_office": 1200,
    "timezone": "UTC+3",
    "geo_lat": 55.8623156,
    "geo_lon": 48.3715083,
    "population": 55671,
    "foundation_year": 1500
  },
  {
    "address": "Респ Марий Эл, г Звенигово",
    "postal_code": 425060,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Марий Эл",
    "area_type": "р-н",
    "area": "Звениговский",
    "city_type": "г",
    "city": "Звенигово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1200400100000,
    "fias_id": "a9999751-53ff-4b43-b15f-6ad8034705ba",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 88212501000,
    "oktmo": 88612101001,
    "tax_office": 1200,
    "timezone": "UTC+3",
    "geo_lat": 55.9738571,
    "geo_lon": 48.0170245,
    "population": 11945,
    "foundation_year": 1860
  },
  {
    "address": "г Йошкар-Ола",
    "postal_code": 424000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Марий Эл",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Йошкар-Ола",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1200000100000,
    "fias_id": "0648e41c-a09b-4eac-91cd-8cf61b9ccb7b",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 88401000000,
    "oktmo": 88701000001,
    "tax_office": 1200,
    "timezone": "UTC+3",
    "geo_lat": 56.6343662,
    "geo_lon": 47.8999706,
    "population": 248688,
    "foundation_year": 1584
  },
  {
    "address": "Респ Марий Эл, г Козьмодемьянск",
    "postal_code": 425350,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Марий Эл",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Козьмодемьянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1200000300000,
    "fias_id": "70f9ed24-8b25-4688-857f-a337710d4825",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 88415000000,
    "oktmo": 88715000001,
    "tax_office": 1200,
    "timezone": "UTC+3",
    "geo_lat": 56.3334036,
    "geo_lon": 46.546675,
    "population": 21262,
    "foundation_year": 1583
  },
  {
    "address": "Респ Мордовия, г Ардатов",
    "postal_code": 431850,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "р-н",
    "area": "Ардатовский",
    "city_type": "г",
    "city": "Ардатов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1300200100000,
    "fias_id": "edab359e-50ac-422a-9117-78ce44b6f758",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89203501000,
    "oktmo": 89603101001,
    "tax_office": 1322,
    "timezone": "UTC+3",
    "geo_lat": 54.8465544,
    "geo_lon": 46.2411232,
    "population": 9400,
    "foundation_year": 1671
  },
  {
    "address": "Респ Мордовия, г Инсар",
    "postal_code": 431430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "р-н",
    "area": "Инсарский",
    "city_type": "г",
    "city": "Инсар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1301000100000,
    "fias_id": "e5473064-cb2e-4da5-aa2b-42599c031705",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89224501000,
    "oktmo": 89624101001,
    "tax_office": 1323,
    "timezone": "UTC+3",
    "geo_lat": 53.8770022,
    "geo_lon": 44.3696566,
    "population": 8687,
    "foundation_year": 1648
  },
  {
    "address": "Респ Мордовия, г Ковылкино",
    "postal_code": 431350,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ковылкино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1300000200000,
    "fias_id": "53445e0f-9d61-4359-9f4f-4983ff7aec34",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89410000000,
    "oktmo": 89629101001,
    "tax_office": 1323,
    "timezone": "UTC+3",
    "geo_lat": 54.0391072,
    "geo_lon": 43.9191539,
    "population": 21307,
    "foundation_year": 1892
  },
  {
    "address": "Респ Мордовия, г Краснослободск",
    "postal_code": 431260,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "р-н",
    "area": "Краснослободский",
    "city_type": "г",
    "city": "Краснослободск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1301500100000,
    "fias_id": "870f93e7-2c41-45eb-baeb-b78cbcd79288",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89234501000,
    "oktmo": 89634101001,
    "tax_office": 1314,
    "timezone": "UTC+3",
    "geo_lat": 54.4248207,
    "geo_lon": 43.7845011,
    "population": 10151,
    "foundation_year": 1571
  },
  {
    "address": "Респ Мордовия, г Рузаевка",
    "postal_code": 431440,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Рузаевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1300000300000,
    "fias_id": "a35889dd-6958-4d3e-8a65-3f628b9179ed",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89420000000,
    "oktmo": 89643101001,
    "tax_office": 1324,
    "timezone": "UTC+3",
    "geo_lat": 54.0581967,
    "geo_lon": 44.9490466,
    "population": 47529,
    "foundation_year": 1631
  },
  {
    "address": "г Саранск",
    "postal_code": 430000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саранск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1300000100000,
    "fias_id": "1ccfdc3c-be0f-4e42-ab4d-98f90de972d9",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 89401000000,
    "oktmo": 89701000001,
    "tax_office": 1300,
    "timezone": "UTC+3",
    "geo_lat": 54.1809332,
    "geo_lon": 45.1862632,
    "population": 318841,
    "foundation_year": 1641
  },
  {
    "address": "Респ Мордовия, г Темников",
    "postal_code": 431220,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Мордовия",
    "area_type": "р-н",
    "area": "Темниковский",
    "city_type": "г",
    "city": "Темников",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1302000100000,
    "fias_id": "7a57dd0c-5b64-480e-8369-3e22261e2bf0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 89249501000,
    "oktmo": 89649101001,
    "tax_office": 1314,
    "timezone": "UTC+3",
    "geo_lat": 54.6310583,
    "geo_lon": 43.2161099,
    "population": 7247,
    "foundation_year": 1100
  },
  {
    "address": "г Москва",
    "postal_code": 101000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "г",
    "region": "Москва",
    "area_type": "",
    "area": "",
    "city_type": "",
    "city": "Москва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7700000000000,
    "fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
    "fias_level": 1,
    "capital_marker": 0,
    "okato": 45000000000,
    "oktmo": 45000000,
    "tax_office": 7700,
    "timezone": "UTC+3",
    "geo_lat": 55.7540471,
    "geo_lon": 37.620405,
    "population": 11514330,
    "foundation_year": 1147
  },
  {
    "address": "Московская обл, г Наро-Фоминск, г Апрелевка",
    "postal_code": 143360,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Наро-Фоминск",
    "city_type": "г",
    "city": "Апрелевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004800300000,
    "fias_id": "17b4ba8a-4300-44e4-bbf7-5f0a8118ddf5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46450506000,
    "oktmo": 46750000006,
    "tax_office": 5030,
    "timezone": "UTC+3",
    "geo_lat": 55.5276918,
    "geo_lon": 37.065143,
    "population": 18467,
    "foundation_year": 1899
  },
  {
    "address": "Московская обл, г Балашиха",
    "postal_code": 143900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Балашиха",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003600000,
    "fias_id": "27c5bc66-61bf-4a17-b0cd-ca0eb64192d6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46404000000,
    "oktmo": 46704000001,
    "tax_office": 5001,
    "timezone": "UTC+3",
    "geo_lat": 55.796389,
    "geo_lon": 37.938283,
    "population": 215353,
    "foundation_year": 1830
  },
  {
    "address": "Московская обл, г Воскресенск, г Белоозёрский",
    "postal_code": 140250,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Воскресенск",
    "city_type": "г",
    "city": "Белоозёрский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004400200000,
    "fias_id": "76a108ed-a04d-41d0-a556-39865e793886",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46410506000,
    "oktmo": 46710000006,
    "tax_office": 5005,
    "timezone": "UTC+3",
    "geo_lat": 55.4595766,
    "geo_lon": 38.4389742,
    "population": 17842,
    "foundation_year": 1961
  },
  {
    "address": "Московская обл, г Бронницы",
    "postal_code": 140170,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бронницы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000200000,
    "fias_id": "582fae29-5955-4dba-9f77-ebf9a8e60827",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46405000000,
    "oktmo": 46705000001,
    "tax_office": 5040,
    "timezone": "UTC+3",
    "geo_lat": 55.4255379,
    "geo_lon": 38.264145,
    "population": 21102,
    "foundation_year": 1453
  },
  {
    "address": "Московская обл, г Наро-Фоминск, г Верея",
    "postal_code": 143330,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Наро-Фоминск",
    "city_type": "г",
    "city": "Верея",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004800200000,
    "fias_id": "abda86de-97b7-4098-9d09-e1835c6e8797",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46450511000,
    "oktmo": 46750000011,
    "tax_office": 5030,
    "timezone": "UTC+3",
    "geo_lat": 55.343369,
    "geo_lon": 36.185694,
    "population": 5368,
    "foundation_year": 1371
  },
  {
    "address": "Московская обл, г Видное",
    "postal_code": 142700,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Видное",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002500000,
    "fias_id": "9a72b510-9413-41d8-b076-576fbfd78404",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46407000000,
    "oktmo": 46707000001,
    "tax_office": 5003,
    "timezone": "UTC+3",
    "geo_lat": 55.551725,
    "geo_lon": 37.7061984,
    "population": 56798,
    "foundation_year": 1949
  },
  {
    "address": "Московская обл, г Волоколамск",
    "postal_code": 143600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волоколамск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000006600000,
    "fias_id": "ca8fb626-709c-4851-a8d6-cbfd592c3bf8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46408000000,
    "oktmo": 46708000001,
    "tax_office": 5004,
    "timezone": "UTC+3",
    "geo_lat": 56.0356499,
    "geo_lon": 35.9585112,
    "population": 23386,
    "foundation_year": 1135
  },
  {
    "address": "Московская обл, г Воскресенск",
    "postal_code": 140200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Воскресенск",
    "city_type": "",
    "city": "Воскресенск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004400000000,
    "fias_id": "555e9f1b-627a-4444-b19e-27fb9d67a093",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46410000000,
    "oktmo": 46710000001,
    "tax_office": 5005,
    "timezone": "UTC+3",
    "geo_lat": 55.3071519,
    "geo_lon": 38.7027953,
    "population": 91301,
    "foundation_year": 1862
  },
  {
    "address": "Московская обл, г Клин, г Высоковск",
    "postal_code": 141650,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Клин",
    "city_type": "г",
    "city": "Высоковск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004700200000,
    "fias_id": "a8ad4e79-a4c9-4f2e-ade5-a7fa3ab0da03",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46437506000,
    "oktmo": 46737000006,
    "tax_office": 5020,
    "timezone": "UTC+3",
    "geo_lat": 56.3359513,
    "geo_lon": 36.5251837,
    "population": 10642,
    "foundation_year": 1864
  },
  {
    "address": "Московская обл, г Одинцово, г Голицыно",
    "postal_code": 143040,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Одинцово",
    "city_type": "г",
    "city": "Голицыно",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004200200000,
    "fias_id": "963efb34-c804-43b4-9298-8e145d188ed0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46455506000,
    "oktmo": 46755000006,
    "tax_office": 5032,
    "timezone": "UTC+3",
    "geo_lat": 55.6190582,
    "geo_lon": 36.9856793,
    "population": 17447,
    "foundation_year": 1872
  },
  {
    "address": "Московская обл, г Истра, г Дедовск",
    "postal_code": 143530,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Истра",
    "city_type": "г",
    "city": "Дедовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004600200000,
    "fias_id": "05b3833e-37b3-470e-bf21-78adf1eec36f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46433506000,
    "oktmo": 46733000006,
    "tax_office": 5017,
    "timezone": "UTC+3",
    "geo_lat": 55.8703276,
    "geo_lon": 37.1245043,
    "population": 29280,
    "foundation_year": 1911
  },
  {
    "address": "Московская обл, г Дзержинский",
    "postal_code": 140090,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дзержинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002300000,
    "fias_id": "6fa8b59c-cda7-4c98-8b41-e0bcb2867e16",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46411000000,
    "oktmo": 46711000001,
    "tax_office": 5027,
    "timezone": "UTC+3",
    "geo_lat": 55.6240869,
    "geo_lon": 37.8440276,
    "population": 47125,
    "foundation_year": 1380
  },
  {
    "address": "Московская обл, г Дмитров",
    "postal_code": 141800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Дмитров",
    "city_type": "",
    "city": "Дмитров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004500000000,
    "fias_id": "0d8a246f-6446-403e-91c6-3aec9cdbfc32",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46415000000,
    "oktmo": 46715000001,
    "tax_office": 5007,
    "timezone": "UTC+3",
    "geo_lat": 56.3477457,
    "geo_lon": 37.526672,
    "population": 61454,
    "foundation_year": 1154
  },
  {
    "address": "Московская обл, г Долгопрудный",
    "postal_code": 141700,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Долгопрудный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002900000,
    "fias_id": "dcf522ad-e032-4db9-9dbb-e30d2cddfadb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46416000000,
    "oktmo": 46716000001,
    "tax_office": 5047,
    "timezone": "UTC+3",
    "geo_lat": 55.9385999,
    "geo_lon": 37.5101021,
    "population": 90976,
    "foundation_year": 1931
  },
  {
    "address": "Московская обл, г Домодедово",
    "postal_code": 142000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Домодедово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000100000,
    "fias_id": "d177d438-ea69-4169-854c-d4512d7c8ebe",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46409000000,
    "oktmo": 46709000001,
    "tax_office": 5009,
    "timezone": "UTC+3",
    "geo_lat": 55.4363283,
    "geo_lon": 37.7664984,
    "population": 96123,
    "foundation_year": 1900
  },
  {
    "address": "Московская обл, г Орехово-Зуево, г Дрезна",
    "postal_code": 142660,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Орехово-Зуево",
    "city_type": "г",
    "city": "Дрезна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000100100000,
    "fias_id": "70643e37-137c-4e66-a06e-22feb7784740",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46457506000,
    "oktmo": 46757000006,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.7443143,
    "geo_lon": 38.8498479,
    "population": 11815,
    "foundation_year": 1897
  },
  {
    "address": "Московская обл, г Дубна",
    "postal_code": 141980,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дубна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000300000,
    "fias_id": "c86ad06c-c721-4a7e-aa06-f622eed83b6f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46418000000,
    "oktmo": 46718000001,
    "tax_office": 5010,
    "timezone": "UTC+3",
    "geo_lat": 56.741786,
    "geo_lon": 37.1757223,
    "population": 70569,
    "foundation_year": 1956
  },
  {
    "address": "Московская обл, г Егорьевск",
    "postal_code": 140300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Егорьевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003900000,
    "fias_id": "17a3426e-add6-4b2e-b4b1-6f36da02b6ab",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46422000000,
    "oktmo": 46722000001,
    "tax_office": 5011,
    "timezone": "UTC+3",
    "geo_lat": 55.3830113,
    "geo_lon": 39.0358317,
    "population": 70133,
    "foundation_year": 1462
  },
  {
    "address": "Московская обл, г Жуковский",
    "postal_code": 140180,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Жуковский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000500000,
    "fias_id": "fd91f393-4820-437f-a50c-907e9856c374",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46425000000,
    "oktmo": 46725000001,
    "tax_office": 5040,
    "timezone": "UTC+3",
    "geo_lat": 55.599803,
    "geo_lon": 38.1224298,
    "population": 102729,
    "foundation_year": 1947
  },
  {
    "address": "Московская обл, г Зарайск",
    "postal_code": 140600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Зарайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004600000,
    "fias_id": "98e62df9-591a-45af-876e-471060d057aa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46429000000,
    "oktmo": 46729000001,
    "tax_office": 5072,
    "timezone": "UTC+3",
    "geo_lat": 54.762456,
    "geo_lon": 38.8850978,
    "population": 24648,
    "foundation_year": 1146
  },
  {
    "address": "Московская обл, г Одинцово, г Звенигород",
    "postal_code": 143180,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Одинцово",
    "city_type": "г",
    "city": "Звенигород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004200400000,
    "fias_id": "4bae8c68-e107-4352-9d4b-f937f90469ac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46455511000,
    "oktmo": 46755000011,
    "tax_office": 5032,
    "timezone": "UTC+3",
    "geo_lat": 55.7297089,
    "geo_lon": 36.8554029,
    "population": 16395,
    "foundation_year": 1152
  },
  {
    "address": "Московская обл, г Ивантеевка",
    "postal_code": 141280,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ивантеевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000700000,
    "fias_id": "e12cf909-779a-4352-af51-7a2a07668f60",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46432000000,
    "oktmo": 46732000001,
    "tax_office": 5038,
    "timezone": "UTC+3",
    "geo_lat": 55.9741665,
    "geo_lon": 37.9207539,
    "population": 58594,
    "foundation_year": 1564
  },
  {
    "address": "Московская обл, г Истра",
    "postal_code": 143500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Истра",
    "city_type": "",
    "city": "Истра",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004600000000,
    "fias_id": "f05b2b62-f54c-4b44-aff2-6bc3e55a640c",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46433000000,
    "oktmo": 46733000001,
    "tax_office": 5017,
    "timezone": "UTC+3",
    "geo_lat": 55.9062267,
    "geo_lon": 36.8601454,
    "population": 35106,
    "foundation_year": 1589
  },
  {
    "address": "Московская обл, г Кашира",
    "postal_code": 142900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кашира",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003800000,
    "fias_id": "45b3f9ac-43cd-4bd0-90af-74cf64ea67f7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46435000000,
    "oktmo": 46735000001,
    "tax_office": 5045,
    "timezone": "UTC+3",
    "geo_lat": 54.853337,
    "geo_lon": 38.1904392,
    "population": 41880,
    "foundation_year": 1356
  },
  {
    "address": "Московская обл, г Клин",
    "postal_code": 141601,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Клин",
    "city_type": "",
    "city": "Клин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004700000000,
    "fias_id": "2365151a-7a78-4af5-a8fb-a4faff856df5",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46437000000,
    "oktmo": 46737000001,
    "tax_office": 5020,
    "timezone": "UTC+3",
    "geo_lat": 56.3425605,
    "geo_lon": 36.7240032,
    "population": 80584,
    "foundation_year": 1317
  },
  {
    "address": "Московская обл, г Коломна",
    "postal_code": 140400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Коломна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002700000,
    "fias_id": "b367fb03-29f9-4dac-8d85-01595cfb6ad9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46438000000,
    "oktmo": 46738000001,
    "tax_office": 5022,
    "timezone": "UTC+3",
    "geo_lat": 55.102814,
    "geo_lon": 38.7531002,
    "population": 144642,
    "foundation_year": 1177
  },
  {
    "address": "Московская обл, г Королёв",
    "postal_code": 141060,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Королёв",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000000900000,
    "fias_id": "819d6910-b4d1-474f-ad10-c1fa944dfca4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46434000000,
    "oktmo": 46734000001,
    "tax_office": 5018,
    "timezone": "UTC+3",
    "geo_lat": 55.9161773,
    "geo_lon": 37.8545415,
    "population": 183452,
    "foundation_year": 1938
  },
  {
    "address": "Московская обл, г Котельники",
    "postal_code": 140053,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Котельники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003200000,
    "fias_id": "67488a01-ccb7-4638-bcad-117db0fbe9a3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46444000000,
    "oktmo": 46739000001,
    "tax_office": 5027,
    "timezone": "UTC+3",
    "geo_lat": 55.6597925,
    "geo_lon": 37.8631156,
    "population": 32347,
    "foundation_year": 1600
  },
  {
    "address": "Московская обл, г Красноармейск",
    "postal_code": 141290,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноармейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001000000,
    "fias_id": "bc5d23ce-e886-4dc7-976c-6b8be6d501d2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46443000000,
    "oktmo": 46743000001,
    "tax_office": 5038,
    "timezone": "UTC+3",
    "geo_lat": 56.105426,
    "geo_lon": 38.140838,
    "population": 26294,
    "foundation_year": 1928
  },
  {
    "address": "Московская обл, г Красногорск",
    "postal_code": 143401,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004900000,
    "fias_id": "63fcf18a-365e-451f-baee-8d09ac50b773",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46439000000,
    "oktmo": 46744000001,
    "tax_office": 5024,
    "timezone": "UTC+3",
    "geo_lat": 55.8317203,
    "geo_lon": 37.3295266,
    "population": 116738,
    "foundation_year": 1932
  },
  {
    "address": "Московская обл, г Сергиев Посад, г Краснозаводск",
    "postal_code": 141321,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Сергиев Посад",
    "city_type": "г",
    "city": "Краснозаводск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004100300000,
    "fias_id": "6bec0be0-e5a7-4bf1-907a-9a1dadedc867",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46428506000,
    "oktmo": 46728000006,
    "tax_office": 5042,
    "timezone": "UTC+3",
    "geo_lat": 56.4409979,
    "geo_lon": 38.2320307,
    "population": 13432,
    "foundation_year": 1916
  },
  {
    "address": "Московская обл, г Краснознаменск",
    "postal_code": 143090,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Краснознаменск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001100000,
    "fias_id": "6e8a99b2-e6bd-4169-acb3-f7e6cd53be3b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46505000000,
    "oktmo": 46706000001,
    "tax_office": 5032,
    "timezone": "UTC+3",
    "geo_lat": 55.5978959,
    "geo_lon": 37.0393709,
    "population": 36057,
    "foundation_year": 1952
  },
  {
    "address": "Московская обл, г Одинцово, г Кубинка",
    "postal_code": 143070,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Одинцово",
    "city_type": "г",
    "city": "Кубинка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004200300000,
    "fias_id": "50341359-842b-4ba1-b7f2-398a7b72f829",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46455516000,
    "oktmo": 46755000016,
    "tax_office": 5032,
    "timezone": "UTC+3",
    "geo_lat": 55.5754955,
    "geo_lon": 36.6951995,
    "population": 22918,
    "foundation_year": 1400
  },
  {
    "address": "Московская обл, г Орехово-Зуево, г Куровское",
    "postal_code": 142620,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Орехово-Зуево",
    "city_type": "г",
    "city": "Куровское",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000100200000,
    "fias_id": "9eb64ec8-9b97-465c-8c4d-ae667862dd62",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46457511000,
    "oktmo": 46757000011,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.5792277,
    "geo_lon": 38.9207723,
    "population": 21821,
    "foundation_year": 1646
  },
  {
    "address": "Московская обл, г Орехово-Зуево, г Ликино-Дулёво",
    "postal_code": 142663,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Орехово-Зуево",
    "city_type": "г",
    "city": "Ликино-Дулёво",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000100300000,
    "fias_id": "4ae832d1-e532-45a2-b8a7-8f29aa0149e9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46457516000,
    "oktmo": 46757000016,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.7078257,
    "geo_lon": 38.9578093,
    "population": 31331,
    "foundation_year": 1930
  },
  {
    "address": "Московская обл, г Лобня",
    "postal_code": 141055,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лобня",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001200000,
    "fias_id": "67c493d4-d141-407e-868b-dafc289503f3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46440000000,
    "oktmo": 46740000001,
    "tax_office": 5047,
    "timezone": "UTC+3",
    "geo_lat": 56.0328881,
    "geo_lon": 37.4614035,
    "population": 74350,
    "foundation_year": 1902
  },
  {
    "address": "Московская обл, г Лосино-Петровский",
    "postal_code": 141150,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лосино-Петровский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003100000,
    "fias_id": "96c869d9-51a8-4db6-8011-9fc459c9a78c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46442000000,
    "oktmo": 46742000001,
    "tax_office": 5050,
    "timezone": "UTC+3",
    "geo_lat": 55.8713214,
    "geo_lon": 38.200599,
    "population": 22550,
    "foundation_year": 1708
  },
  {
    "address": "Московская обл, г Луховицы",
    "postal_code": 140500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Луховицы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004800000,
    "fias_id": "7c6d9ebf-8b6a-4435-a174-eb8018f7c598",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46447000000,
    "oktmo": 46747000001,
    "tax_office": 5072,
    "timezone": "UTC+3",
    "geo_lat": 54.9652077,
    "geo_lon": 39.0260266,
    "population": 29849,
    "foundation_year": 1594
  },
  {
    "address": "Московская обл, г Лыткарино",
    "postal_code": 140080,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лыткарино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001300000,
    "fias_id": "bba16a72-09e1-4f3f-93af-df5ecab6714b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46441000000,
    "oktmo": 46741000001,
    "tax_office": 5027,
    "timezone": "UTC+3",
    "geo_lat": 55.5778163,
    "geo_lon": 37.9033507,
    "population": 55147,
    "foundation_year": 1429
  },
  {
    "address": "Московская обл, г Люберцы",
    "postal_code": 140000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Люберцы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000005000000,
    "fias_id": "427b4e19-f221-4abc-8904-81f7409c1ce3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46448000000,
    "oktmo": 46748000001,
    "tax_office": 5027,
    "timezone": "UTC+3",
    "geo_lat": 55.676499,
    "geo_lon": 37.898125,
    "population": 171978,
    "foundation_year": 1623
  },
  {
    "address": "Московская обл, г Можайск",
    "postal_code": 143200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Можайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000005600000,
    "fias_id": "4f86a11c-4258-4859-a227-a79c9ef400ff",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46445000000,
    "oktmo": 46745000001,
    "tax_office": 5075,
    "timezone": "UTC+3",
    "geo_lat": 55.5069259,
    "geo_lon": 36.024043,
    "population": 31388,
    "foundation_year": 1231
  },
  {
    "address": "Московская обл, г Мытищи",
    "postal_code": 141000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мытищи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004400000,
    "fias_id": "5f290be7-14ff-4ccd-8bc8-2871a9ca9d5f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46446000000,
    "oktmo": 46746000001,
    "tax_office": 5029,
    "timezone": "UTC+3",
    "geo_lat": 55.9105782,
    "geo_lon": 37.7363579,
    "population": 173341,
    "foundation_year": 1460
  },
  {
    "address": "Московская обл, г Наро-Фоминск",
    "postal_code": 143300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Наро-Фоминск",
    "city_type": "",
    "city": "Наро-Фоминск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004800000000,
    "fias_id": "08c78435-6ed8-4d7f-a7ed-3f73e2fa6359",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46450000000,
    "oktmo": 46750000001,
    "tax_office": 5030,
    "timezone": "UTC+3",
    "geo_lat": 55.386185,
    "geo_lon": 36.734484,
    "population": 64640,
    "foundation_year": 1840
  },
  {
    "address": "Московская обл, г Ногинск",
    "postal_code": 142400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Ногинск",
    "city_type": "",
    "city": "Ногинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004300000000,
    "fias_id": "40ae04e3-9a69-44ea-8bd7-6c6f08e1a4ca",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46451000000,
    "oktmo": 46751000001,
    "tax_office": 5031,
    "timezone": "UTC+3",
    "geo_lat": 55.8686133,
    "geo_lon": 38.4622104,
    "population": 99762,
    "foundation_year": 1389
  },
  {
    "address": "Московская обл, г Одинцово",
    "postal_code": 143000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Одинцово",
    "city_type": "",
    "city": "Одинцово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004200000000,
    "fias_id": "6ae8ad9e-aa6c-4849-92d8-8e637059c9b0",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46455000000,
    "oktmo": 46755000001,
    "tax_office": 5032,
    "timezone": "UTC+3",
    "geo_lat": 55.6789455,
    "geo_lon": 37.263686,
    "population": 139021,
    "foundation_year": 1470
  },
  {
    "address": "Московская обл, г Озёры",
    "postal_code": 140560,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Озёры",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004000000,
    "fias_id": "e778b800-b432-4150-82fd-d415911fb700",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46456000000,
    "oktmo": 46756000001,
    "tax_office": 5022,
    "timezone": "UTC+3",
    "geo_lat": 54.8541006,
    "geo_lon": 38.5599196,
    "population": 25788,
    "foundation_year": 1834
  },
  {
    "address": "Московская обл, г Орехово-Зуево",
    "postal_code": 142600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Орехово-Зуево",
    "city_type": "",
    "city": "Орехово-Зуево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000100000000,
    "fias_id": "0f2f2d09-8e7a-4356-bd4d-0b055d802e7b",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46457000000,
    "oktmo": 46757000001,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.8151421,
    "geo_lon": 38.9869822,
    "population": 120620,
    "foundation_year": 1917
  },
  {
    "address": "Московская обл, г Павловский Посад",
    "postal_code": 142500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Павловский Посад",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004700000,
    "fias_id": "bb464d94-30bf-4cf8-a320-905733fa67e2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46459000000,
    "oktmo": 46759000001,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.7807244,
    "geo_lon": 38.6596983,
    "population": 63771,
    "foundation_year": 1328
  },
  {
    "address": "Московская обл, г Сергиев Посад, г Пересвет",
    "postal_code": 141320,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Сергиев Посад",
    "city_type": "г",
    "city": "Пересвет",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004100200000,
    "fias_id": "ea80b93f-7a37-455e-a42c-33f13ab8bc06",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46428511000,
    "oktmo": 46728000011,
    "tax_office": 5042,
    "timezone": "UTC+3",
    "geo_lat": 56.4158326,
    "geo_lon": 38.1733534,
    "population": 14142,
    "foundation_year": 1948
  },
  {
    "address": "Московская обл, г Подольск",
    "postal_code": 142100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Подольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002400000,
    "fias_id": "b22cf685-e391-42c8-aab5-a9aadb9193d4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46460000000,
    "oktmo": 46760000001,
    "tax_office": 5074,
    "timezone": "UTC+3",
    "geo_lat": 55.4389322,
    "geo_lon": 37.5703482,
    "population": 187956,
    "foundation_year": 1559
  },
  {
    "address": "Московская обл, г Протвино",
    "postal_code": 142280,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Протвино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001400000,
    "fias_id": "9990bc45-c7c1-407e-bdd2-5526e4f742d2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46467000000,
    "oktmo": 46767000001,
    "tax_office": 5043,
    "timezone": "UTC+3",
    "geo_lat": 54.8705984,
    "geo_lon": 37.2182749,
    "population": 37308,
    "foundation_year": 1960
  },
  {
    "address": "Московская обл, г Пушкино",
    "postal_code": 141200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пушкино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000006700000,
    "fias_id": "7f22cfa4-34fa-4fa0-bcb0-620cd8402cfa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46458000000,
    "oktmo": 46758000001,
    "tax_office": 5038,
    "timezone": "UTC+3",
    "geo_lat": 56.0103638,
    "geo_lon": 37.8471403,
    "population": 102840,
    "foundation_year": 1499
  },
  {
    "address": "Московская обл, г Пущино",
    "postal_code": 142290,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пущино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001500000,
    "fias_id": "49579edb-77cd-4967-9ef2-5530c4076067",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46462000000,
    "oktmo": 46762000001,
    "tax_office": 5043,
    "timezone": "UTC+3",
    "geo_lat": 54.8324412,
    "geo_lon": 37.6210346,
    "population": 20263,
    "foundation_year": 1956
  },
  {
    "address": "Московская обл, г Раменское",
    "postal_code": 140100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Раменское",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000006300000,
    "fias_id": "a54506e0-5641-4d8c-b543-7bcce2c9887f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46468000000,
    "oktmo": 46768000001,
    "tax_office": 5040,
    "timezone": "UTC+3",
    "geo_lat": 55.5495132,
    "geo_lon": 38.2728914,
    "population": 96355,
    "foundation_year": 1328
  },
  {
    "address": "Московская обл, г Реутов",
    "postal_code": 143960,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Реутов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001600000,
    "fias_id": "e0504cf6-4d81-401c-b18b-434457aaaa21",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46464000000,
    "oktmo": 46764000001,
    "tax_office": 5012,
    "timezone": "UTC+3",
    "geo_lat": 55.7582621,
    "geo_lon": 37.8618553,
    "population": 87195,
    "foundation_year": 1492
  },
  {
    "address": "Московская обл, г Шатура, г Рошаль",
    "postal_code": 140730,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Шатура",
    "city_type": "г",
    "city": "Рошаль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004900200000,
    "fias_id": "377ca4f0-3268-42b4-bf68-af98c3682503",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46486506000,
    "oktmo": 46786000006,
    "tax_office": 5049,
    "timezone": "UTC+3",
    "geo_lat": 55.6632776,
    "geo_lon": 39.8656147,
    "population": 21265,
    "foundation_year": 1916
  },
  {
    "address": "Московская обл, г Руза",
    "postal_code": 143100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Руза",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000004500000,
    "fias_id": "9e6c2327-d7ee-49b1-b36c-0bfc0eb5f145",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46466000000,
    "oktmo": 46766000001,
    "tax_office": 5075,
    "timezone": "UTC+3",
    "geo_lat": 55.7014744,
    "geo_lon": 36.1959206,
    "population": 13495,
    "foundation_year": 1328
  },
  {
    "address": "Московская обл, г Сергиев Посад",
    "postal_code": 141300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Сергиев Посад",
    "city_type": "",
    "city": "Сергиев Посад",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004100000000,
    "fias_id": "7baaac39-aa31-4712-893d-51ef80124a86",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46428000000,
    "oktmo": 46728000001,
    "tax_office": 5042,
    "timezone": "UTC+3",
    "geo_lat": 56.3062548,
    "geo_lon": 38.1502661,
    "population": 110878,
    "foundation_year": 1337
  },
  {
    "address": "Московская обл, г Серпухов",
    "postal_code": 142200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Серпухов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002800000,
    "fias_id": "efc02a6a-273b-4a49-aef9-a5606ef8591c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46470000000,
    "oktmo": 46770000001,
    "tax_office": 5043,
    "timezone": "UTC+3",
    "geo_lat": 54.9226466,
    "geo_lon": 37.4033859,
    "population": 126496,
    "foundation_year": 1339
  },
  {
    "address": "Московская обл, г Солнечногорск",
    "postal_code": 141503,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Солнечногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000006500000,
    "fias_id": "d4dadcfd-355d-4fe9-963d-48ad122a7778",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46471000000,
    "oktmo": 46771000001,
    "tax_office": 5044,
    "timezone": "UTC+3",
    "geo_lat": 56.185102,
    "geo_lon": 36.977631,
    "population": 52996,
    "foundation_year": 1700
  },
  {
    "address": "Московская обл, г Ногинск, г Старая Купавна",
    "postal_code": 142450,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Ногинск",
    "city_type": "г",
    "city": "Старая Купавна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004300300000,
    "fias_id": "d49d936b-188f-4186-b2db-47e4b36682d6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46451506000,
    "oktmo": 46751000006,
    "tax_office": 5031,
    "timezone": "UTC+3",
    "geo_lat": 55.810648,
    "geo_lon": 38.175624,
    "population": 21859,
    "foundation_year": 1300
  },
  {
    "address": "Московская обл, г Ступино",
    "postal_code": 142800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ступино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000005400000,
    "fias_id": "f516f0a9-3882-42fb-ae68-87a433e83e06",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46476000000,
    "oktmo": 46776000001,
    "tax_office": 5045,
    "timezone": "UTC+3",
    "geo_lat": 54.886274,
    "geo_lon": 38.078228,
    "population": 66942,
    "foundation_year": 1938
  },
  {
    "address": "Московская обл, г Талдом",
    "postal_code": 141900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Талдом",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000005900000,
    "fias_id": "5f2757f9-6cb1-41a6-9610-ee4f73a2f803",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46478000000,
    "oktmo": 46778000001,
    "tax_office": 5010,
    "timezone": "UTC+3",
    "geo_lat": 56.7308564,
    "geo_lon": 37.5276003,
    "population": 13819,
    "foundation_year": 1677
  },
  {
    "address": "Московская обл, г Фрязино",
    "postal_code": 141190,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Фрязино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000001900000,
    "fias_id": "13696e0e-4c75-49aa-9c83-0a6faf5074ee",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46480000000,
    "oktmo": 46780000001,
    "tax_office": 5050,
    "timezone": "UTC+3",
    "geo_lat": 55.9590588,
    "geo_lon": 38.0410235,
    "population": 55449,
    "foundation_year": 1584
  },
  {
    "address": "Московская обл, г Химки",
    "postal_code": 141402,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Химки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003000000,
    "fias_id": "d76255c8-3173-4db5-a39b-badd3ebdf851",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46483000000,
    "oktmo": 46783000001,
    "tax_office": 5047,
    "timezone": "UTC+3",
    "geo_lat": 55.888657,
    "geo_lon": 37.4303702,
    "population": 207125,
    "foundation_year": 1850
  },
  {
    "address": "Московская обл, г Сергиев Посад, г Хотьково",
    "postal_code": 141370,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Сергиев Посад",
    "city_type": "г",
    "city": "Хотьково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004100100000,
    "fias_id": "6cdcecfe-4d41-426c-bac4-034348f7068b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46428516000,
    "oktmo": 46728000016,
    "tax_office": 5042,
    "timezone": "UTC+3",
    "geo_lat": 56.2516982,
    "geo_lon": 37.9396017,
    "population": 21612,
    "foundation_year": 1308
  },
  {
    "address": "Московская обл, г Черноголовка",
    "postal_code": 142432,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Черноголовка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003500000,
    "fias_id": "70c5b1c8-43ec-471f-b3df-fd6f60557332",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46485000000,
    "oktmo": 46781000001,
    "tax_office": 5031,
    "timezone": "UTC+3",
    "geo_lat": 56.010005,
    "geo_lon": 38.379245,
    "population": 20986,
    "foundation_year": 1710
  },
  {
    "address": "Московская обл, г Чехов",
    "postal_code": 142300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чехов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000005300000,
    "fias_id": "7eeb876e-7b67-4ed7-91ec-0588712db96c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46484000000,
    "oktmo": 46784000001,
    "tax_office": 5043,
    "timezone": "UTC+3",
    "geo_lat": 55.1508011,
    "geo_lon": 37.4533252,
    "population": 60677,
    "foundation_year": 1175
  },
  {
    "address": "Московская обл, г Шатура",
    "postal_code": 140700,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Шатура",
    "city_type": "",
    "city": "Шатура",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004900000000,
    "fias_id": "cd6486b7-fd7a-4da8-b7f3-420d6e16b07b",
    "fias_level": 3,
    "capital_marker": 0,
    "okato": 46486000000,
    "oktmo": 46786000001,
    "tax_office": 5049,
    "timezone": "UTC+3",
    "geo_lat": 55.5777427,
    "geo_lon": 39.5445712,
    "population": 32836,
    "foundation_year": 1423
  },
  {
    "address": "Московская обл, г Щёлково",
    "postal_code": 141100,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Щёлково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000006200000,
    "fias_id": "c38a33c1-8f16-4454-bbf4-656d6b8b1739",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 46488000000,
    "oktmo": 46788000001,
    "tax_office": 5050,
    "timezone": "UTC+3",
    "geo_lat": 55.9233801,
    "geo_lon": 37.9783707,
    "population": 110380,
    "foundation_year": 1500
  },
  {
    "address": "Московская обл, г Электрогорск",
    "postal_code": 142530,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Электрогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000003300000,
    "fias_id": "091e36e6-fab6-477b-8b07-9151cbeb9cd5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46491000000,
    "oktmo": 46791000001,
    "tax_office": 5034,
    "timezone": "UTC+3",
    "geo_lat": 55.8780241,
    "geo_lon": 38.7806752,
    "population": 22120,
    "foundation_year": 1912
  },
  {
    "address": "Московская обл, г Электросталь",
    "postal_code": 144000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Электросталь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5000002100000,
    "fias_id": "e0b171b6-bf2e-4d68-ab05-4d42ab2b375a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46490000000,
    "oktmo": 46790000001,
    "tax_office": 5031,
    "timezone": "UTC+3",
    "geo_lat": 55.7847291,
    "geo_lon": 38.4447045,
    "population": 155324,
    "foundation_year": 1916
  },
  {
    "address": "Московская обл, г Ногинск, г Электроугли",
    "postal_code": 142455,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Ногинск",
    "city_type": "г",
    "city": "Электроугли",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004300200000,
    "fias_id": "27fda454-386e-4618-8c08-4e639a0a8bc1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46451511000,
    "oktmo": 46751000011,
    "tax_office": 5031,
    "timezone": "UTC+3",
    "geo_lat": 55.7170877,
    "geo_lon": 38.2192563,
    "population": 20120,
    "foundation_year": 1899
  },
  {
    "address": "Московская обл, г Дмитров, г Яхрома",
    "postal_code": 141840,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Московская",
    "area_type": "г",
    "area": "Дмитров",
    "city_type": "г",
    "city": "Яхрома",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5004500200000,
    "fias_id": "4e503ad8-0372-4434-888c-f111ed78040c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 46415506000,
    "oktmo": 46715000006,
    "tax_office": 5007,
    "timezone": "UTC+3",
    "geo_lat": 56.2890516,
    "geo_lon": 37.4831799,
    "population": 13248,
    "foundation_year": 1841
  },
  {
    "address": "Мурманская обл, г Апатиты",
    "postal_code": 184200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Апатиты",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000200000,
    "fias_id": "f0851e80-59d9-400b-b6d5-5f5f623bb29d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47405000000,
    "oktmo": 47705000001,
    "tax_office": 5118,
    "timezone": "UTC+3",
    "geo_lat": 67.5677761,
    "geo_lon": 33.4067929,
    "population": 59690,
    "foundation_year": 1926
  },
  {
    "address": "Мурманская обл, г Гаджиево",
    "postal_code": 184670,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Гаджиево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100001200000,
    "fias_id": "b77147ce-b55e-4019-9aca-ed3872b9fbb0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47529000000,
    "oktmo": 47737000005,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 69.2491311,
    "geo_lon": 33.315341,
    "population": 11089,
    "foundation_year": 1957
  },
  {
    "address": "Мурманская обл, г Заозерск",
    "postal_code": 184310,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Заозерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000300000,
    "fias_id": "81a88076-04de-46c9-940a-3cb300e70e5b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47533000000,
    "oktmo": 47733000,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 69.4003584,
    "geo_lon": 32.4501496,
    "population": 11206,
    "foundation_year": 1958
  },
  {
    "address": "Мурманская обл, Печенгский р-н, г Заполярный",
    "postal_code": 184430,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "р-н",
    "area": "Печенгский",
    "city_type": "г",
    "city": "Заполярный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100500200000,
    "fias_id": "304cb068-e1d1-4947-944f-07c38cd8db6e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47215503000,
    "oktmo": 47515000002,
    "tax_office": 5105,
    "timezone": "UTC+3",
    "geo_lat": 69.4132852,
    "geo_lon": 30.7984312,
    "population": 15835,
    "foundation_year": 1956
  },
  {
    "address": "Мурманская обл, г Кандалакша",
    "postal_code": 184042,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "р-н",
    "area": "Кандалакшский",
    "city_type": "г",
    "city": "Кандалакша",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100100100000,
    "fias_id": "30fa6bcc-608e-40cb-b22a-b202967ff2a6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 47202501000,
    "oktmo": 47608101001,
    "tax_office": 5102,
    "timezone": "UTC+3",
    "geo_lat": 67.1567974,
    "geo_lon": 32.4143218,
    "population": 35659,
    "foundation_year": 1000
  },
  {
    "address": "Мурманская обл, г Кировск",
    "postal_code": 184250,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кировск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000500000,
    "fias_id": "f658a2c0-1f7d-4423-9465-e55d3553bf86",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47412000000,
    "oktmo": 47712000001,
    "tax_office": 5118,
    "timezone": "UTC+3",
    "geo_lat": 67.6150424,
    "geo_lon": 33.663735,
    "population": 28659,
    "foundation_year": 1929
  },
  {
    "address": "Мурманская обл, г Ковдор",
    "postal_code": 184144,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "р-н",
    "area": "Ковдорский",
    "city_type": "г",
    "city": "Ковдор",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100200100000,
    "fias_id": "01cc7377-a3a7-4793-9c77-52e3ef66a6ff",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 47203501000,
    "oktmo": 47703000001,
    "tax_office": 5108,
    "timezone": "UTC+3",
    "geo_lat": 67.5661417,
    "geo_lon": 30.4741941,
    "population": 18836,
    "foundation_year": 1953
  },
  {
    "address": "Мурманская обл, г Кола",
    "postal_code": 184380,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "р-н",
    "area": "Кольский",
    "city_type": "г",
    "city": "Кола",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100300100000,
    "fias_id": "866ace16-21ff-49c4-af8d-b74ef0e80208",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 47205501000,
    "oktmo": 47605101001,
    "tax_office": 5105,
    "timezone": "UTC+3",
    "geo_lat": 68.8786028,
    "geo_lon": 33.0262299,
    "population": 10447,
    "foundation_year": 1517
  },
  {
    "address": "Мурманская обл, г Мончегорск",
    "postal_code": 184505,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мончегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000600000,
    "fias_id": "177e037d-a06d-4f8a-9333-fd534dd34c5d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47415000000,
    "oktmo": 47715000001,
    "tax_office": 5108,
    "timezone": "UTC+3",
    "geo_lat": 67.9386153,
    "geo_lon": 32.9359719,
    "population": 45381,
    "foundation_year": 1937
  },
  {
    "address": "г Мурманск",
    "postal_code": 183000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мурманск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000100000,
    "fias_id": "b7127184-ead6-422b-b7cf-4fc7725b47a5",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 47401000000,
    "oktmo": 47701000001,
    "tax_office": 5100,
    "timezone": "UTC+3",
    "geo_lat": 69.007721,
    "geo_lon": 33.0685865,
    "population": 307664,
    "foundation_year": 1916
  },
  {
    "address": "Мурманская обл, г Оленегорск",
    "postal_code": 184530,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Оленегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000700000,
    "fias_id": "c5fe1817-5163-4e3b-b06a-36d322c6bf57",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47417000000,
    "oktmo": 47717000001,
    "tax_office": 5108,
    "timezone": "UTC+3",
    "geo_lat": 68.1422058,
    "geo_lon": 33.2669407,
    "population": 23079,
    "foundation_year": 1949
  },
  {
    "address": "Мурманская обл, г Островной",
    "postal_code": 184640,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Островной",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000800000,
    "fias_id": "0a358e8a-d3c4-45e5-b9fc-126092afd6f5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47531000000,
    "oktmo": 47731000,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 68.0510344,
    "geo_lon": 39.5077846,
    "population": 2177,
    "foundation_year": 1611
  },
  {
    "address": "Мурманская обл, г Полярные Зори",
    "postal_code": 184230,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Полярные Зори",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100000900000,
    "fias_id": "d70b2917-8cea-4555-a9fe-6d2c72d8d9cc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47419000000,
    "oktmo": 47719000001,
    "tax_office": 5102,
    "timezone": "UTC+3",
    "geo_lat": 67.373084,
    "geo_lon": 32.4975636,
    "population": 15106,
    "foundation_year": 1968
  },
  {
    "address": "Мурманская обл, г Полярный",
    "postal_code": 184650,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Полярный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100001000000,
    "fias_id": "a4ae6202-6ec6-4bc6-abc5-4ecd6ca96d53",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47525000000,
    "oktmo": 47737000005,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 69.1989583,
    "geo_lon": 33.4508591,
    "population": 17304,
    "foundation_year": 1896
  },
  {
    "address": "Мурманская обл, г Североморск",
    "postal_code": 184601,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Североморск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100001100000,
    "fias_id": "53e652bc-dd1b-44e1-9ab2-401b04cf3ceb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47530000000,
    "oktmo": 47730000,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 69.0766801,
    "geo_lon": 33.4177759,
    "population": 50076,
    "foundation_year": 1896
  },
  {
    "address": "Мурманская обл, г Снежногорск",
    "postal_code": 184682,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Мурманская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Снежногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5100001300000,
    "fias_id": "22e4a0a6-68a6-47bc-a08c-11610ec6231a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 47527000000,
    "oktmo": 47737000011,
    "tax_office": 5110,
    "timezone": "UTC+3",
    "geo_lat": 69.1921409,
    "geo_lon": 33.2383502,
    "population": 12698,
    "foundation_year": 1970
  },
  {
    "address": "г Нарьян-Мар",
    "postal_code": 166000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "АО",
    "region": "Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нарьян-Мар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8300000100000,
    "fias_id": "b0122c31-eb1c-40ae-b998-08f9e99a0fa1",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 11111000000,
    "oktmo": 11851000001,
    "tax_office": 2983,
    "timezone": "UTC+3",
    "geo_lat": 67.6379672,
    "geo_lon": 53.0069565,
    "population": 21296,
    "foundation_year": 1929
  },
  {
    "address": "Нижегородская обл, г Арзамас",
    "postal_code": 607220,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Арзамас",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000400000,
    "fias_id": "7e42f157-87d6-4111-a406-17432e814723",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22403000000,
    "oktmo": 22703000001,
    "tax_office": 5243,
    "timezone": "UTC+3",
    "geo_lat": 55.3945814,
    "geo_lon": 43.8408141,
    "population": 106367,
    "foundation_year": 1552
  },
  {
    "address": "Нижегородская обл, г Балахна",
    "postal_code": 606407,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Балахнинский",
    "city_type": "г",
    "city": "Балахна",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200400100000,
    "fias_id": "275737a6-dee6-493b-82bb-ca5c3c97b24e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22205501000,
    "oktmo": 22505000001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 56.495036,
    "geo_lon": 43.5758423,
    "population": 51526,
    "foundation_year": 1399
  },
  {
    "address": "Нижегородская обл, г Богородск",
    "postal_code": 607600,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Богородский",
    "city_type": "г",
    "city": "Богородск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200500100000,
    "fias_id": "17962cbc-48c7-43d4-b174-c35485d114e7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22207501000,
    "oktmo": 22507000001,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 56.1020828,
    "geo_lon": 43.5135442,
    "population": 25497,
    "foundation_year": 1570
  },
  {
    "address": "Нижегородская обл, г Бор",
    "postal_code": 606440,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бор",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000500000,
    "fias_id": "e736e903-6bc3-450b-b8af-7909213dc9ef",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22412000000,
    "oktmo": 22712000001,
    "tax_office": 5257,
    "timezone": "UTC+3",
    "geo_lat": 56.3565458,
    "geo_lon": 44.0646481,
    "population": 78079,
    "foundation_year": 1300
  },
  {
    "address": "Нижегородская обл, г Ветлуга",
    "postal_code": 606860,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Ветлужский",
    "city_type": "г",
    "city": "Ветлуга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5201300100000,
    "fias_id": "aa1e00f2-a813-4f3d-b74a-9f9d73bfb1ff",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22218501000,
    "oktmo": 22618101001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 57.8559204,
    "geo_lon": 45.7761957,
    "population": 8956,
    "foundation_year": 1606
  },
  {
    "address": "Нижегородская обл, г Володарск",
    "postal_code": 606072,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Володарский",
    "city_type": "г",
    "city": "Володарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5201600100000,
    "fias_id": "b92c9e2b-161d-4043-87ef-ed255529f7c6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22231503000,
    "oktmo": 22631103001,
    "tax_office": 5249,
    "timezone": "UTC+3",
    "geo_lat": 56.2169751,
    "geo_lon": 43.1596417,
    "population": 9924,
    "foundation_year": 1932
  },
  {
    "address": "Нижегородская обл, Павловский р-н, г Ворсма",
    "postal_code": 606120,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Павловский",
    "city_type": "г",
    "city": "Ворсма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5203200200000,
    "fias_id": "cd695c84-0b68-4d4c-8068-76e9bba1228a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22242503000,
    "oktmo": 22542000003,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 55.989943,
    "geo_lon": 43.2718859,
    "population": 11622,
    "foundation_year": 1500
  },
  {
    "address": "Нижегородская обл, г Выкса",
    "postal_code": 607060,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Выкса",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000700000,
    "fias_id": "d89942e8-e06b-46bd-a805-7cec8f77417e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22415000000,
    "oktmo": 22715000001,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 55.3207727,
    "geo_lon": 42.1678834,
    "population": 56196,
    "foundation_year": 1757
  },
  {
    "address": "Нижегородская обл, Павловский р-н, г Горбатов",
    "postal_code": 606125,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Павловский",
    "city_type": "г",
    "city": "Горбатов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5203200300000,
    "fias_id": "9e79f812-d4b6-48d6-97c3-aa94c102de3e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22242505000,
    "oktmo": 22542000005,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 56.1307769,
    "geo_lon": 43.0626185,
    "population": 2278,
    "foundation_year": 1500
  },
  {
    "address": "Нижегородская обл, г Городец",
    "postal_code": 606500,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Городецкий",
    "city_type": "г",
    "city": "Городец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5202000100000,
    "fias_id": "a80ea6a8-87f3-4621-89bb-eeb16f56ef94",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22228501000,
    "oktmo": 22628101001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 56.6449218,
    "geo_lon": 43.4723104,
    "population": 30699,
    "foundation_year": 1100
  },
  {
    "address": "Нижегородская обл, г Дзержинск",
    "postal_code": 606000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дзержинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000200000,
    "fias_id": "1d5a97d5-9bdf-44c9-ac42-e201833e7f28",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22421000000,
    "oktmo": 22721000001,
    "tax_office": 5249,
    "timezone": "UTC+3",
    "geo_lat": 56.2376047,
    "geo_lon": 43.4599416,
    "population": 240762,
    "foundation_year": 1606
  },
  {
    "address": "Нижегородская обл, Городецкий р-н, г Заволжье",
    "postal_code": 606520,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Городецкий",
    "city_type": "г",
    "city": "Заволжье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5202000200000,
    "fias_id": "b1a903c2-9bdf-4a10-8e4b-e34d7b8d7dc3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22228503000,
    "oktmo": 22628103001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 56.6404286,
    "geo_lon": 43.3872492,
    "population": 40265,
    "foundation_year": 1950
  },
  {
    "address": "Нижегородская обл, г Княгинино",
    "postal_code": 606340,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Княгининский",
    "city_type": "г",
    "city": "Княгинино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5202300100000,
    "fias_id": "b3350a67-b3c0-47b2-afb3-2b308227a89e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22233501000,
    "oktmo": 22633101001,
    "tax_office": 5250,
    "timezone": "UTC+3",
    "geo_lat": 55.8205915,
    "geo_lon": 45.032337,
    "population": 6708,
    "foundation_year": 1569
  },
  {
    "address": "Нижегородская обл, г Кстово",
    "postal_code": 607650,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Кстовский",
    "city_type": "г",
    "city": "Кстово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5202700100000,
    "fias_id": "8d72434a-977f-418b-80e0-f37ff75d52e4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22237501000,
    "oktmo": 22637101001,
    "tax_office": 5250,
    "timezone": "UTC+3",
    "geo_lat": 56.1432084,
    "geo_lon": 44.1664198,
    "population": 66641,
    "foundation_year": 1400
  },
  {
    "address": "Нижегородская обл, г Кулебаки",
    "postal_code": 607010,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кулебаки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200001000000,
    "fias_id": "a963a64c-29cf-4342-bdc7-d998017aa4cd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22427000000,
    "oktmo": 22727000,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 55.4296181,
    "geo_lon": 42.5125538,
    "population": 35762,
    "foundation_year": 1677
  },
  {
    "address": "Нижегородская обл, г Лукоянов",
    "postal_code": 607800,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Лукояновский",
    "city_type": "г",
    "city": "Лукоянов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5202900100000,
    "fias_id": "edc07c38-090b-4990-9484-af85752c484f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22239501000,
    "oktmo": 22639101001,
    "tax_office": 5243,
    "timezone": "UTC+3",
    "geo_lat": 55.0326225,
    "geo_lon": 44.4933807,
    "population": 14949,
    "foundation_year": 1500
  },
  {
    "address": "Нижегородская обл, г Лысково",
    "postal_code": 606210,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Лысковский",
    "city_type": "г",
    "city": "Лысково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5203000100000,
    "fias_id": "978c4d8c-f724-43b1-aa95-0c1fc49dc6b7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22240501000,
    "oktmo": 22540000001,
    "tax_office": 5250,
    "timezone": "UTC+3",
    "geo_lat": 56.0262359,
    "geo_lon": 45.035771,
    "population": 21882,
    "foundation_year": 1410
  },
  {
    "address": "Нижегородская обл, г Навашино",
    "postal_code": 607100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Навашино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200001100000,
    "fias_id": "87f58ce7-8bad-4b33-9654-907bbd18d36a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22430000000,
    "oktmo": 22730000001,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 55.543811,
    "geo_lon": 42.1887089,
    "population": 16413,
    "foundation_year": 1957
  },
  {
    "address": "г Нижний Новгород",
    "postal_code": 603000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижний Новгород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000100000,
    "fias_id": "555e7d61-d9a7-4ba6-9770-6caa8198c483",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 22401000000,
    "oktmo": 22701000001,
    "tax_office": 5200,
    "timezone": "UTC+3",
    "geo_lat": 56.3240627,
    "geo_lon": 44.0053913,
    "population": 1250615,
    "foundation_year": 1221
  },
  {
    "address": "Нижегородская обл, г Павлово",
    "postal_code": 606100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Павловский",
    "city_type": "г",
    "city": "Павлово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5203200100000,
    "fias_id": "169a49f5-e0ed-44ae-83f8-653e44685e15",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22242501000,
    "oktmo": 22542000001,
    "tax_office": 5252,
    "timezone": "UTC+3",
    "geo_lat": 55.9797564,
    "geo_lon": 43.0995042,
    "population": 60699,
    "foundation_year": 1566
  },
  {
    "address": "Нижегородская обл, г Первомайск",
    "postal_code": 607751,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Первомайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000800000,
    "fias_id": "a72a0367-92a4-4ec3-9aa2-2397039a3d70",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22434000000,
    "oktmo": 22734000001,
    "tax_office": 5243,
    "timezone": "UTC+3",
    "geo_lat": 54.8675792,
    "geo_lon": 43.8013992,
    "population": 14567,
    "foundation_year": 1800
  },
  {
    "address": "Нижегородская обл, г Перевоз",
    "postal_code": 607400,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Перевоз",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200001200000,
    "fias_id": "e3ff7ef2-92b6-4ab7-b2ff-4d58cab73dbc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22435000000,
    "oktmo": 22739000001,
    "tax_office": 5250,
    "timezone": "UTC+3",
    "geo_lat": 55.5967718,
    "geo_lon": 44.5448369,
    "population": 9201,
    "foundation_year": 1500
  },
  {
    "address": "Нижегородская обл, г Саров",
    "postal_code": 607180,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000300000,
    "fias_id": "be0210d1-1b58-49c1-8304-eb5ff5618fad",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22503000000,
    "oktmo": 22704000001,
    "tax_office": 5243,
    "timezone": "UTC+3",
    "geo_lat": 54.9228268,
    "geo_lon": 43.3448089,
    "population": 92073,
    "foundation_year": 1691
  },
  {
    "address": "Нижегородская обл, г Семенов",
    "postal_code": 606605,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Семенов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000600000,
    "fias_id": "dacc69b0-38df-462e-b346-26541cb9c0b8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22437000000,
    "oktmo": 22737000001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 56.7889794,
    "geo_lon": 44.4902885,
    "population": 24472,
    "foundation_year": 1644
  },
  {
    "address": "Нижегородская обл, г Сергач",
    "postal_code": 607510,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Сергачский",
    "city_type": "г",
    "city": "Сергач",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5203800100000,
    "fias_id": "ef4d5a83-c574-48d9-86ec-386f18bec524",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22248501000,
    "oktmo": 22648101001,
    "tax_office": 5250,
    "timezone": "UTC+3",
    "geo_lat": 55.5201515,
    "geo_lon": 45.4813231,
    "population": 21387,
    "foundation_year": 1649
  },
  {
    "address": "Нижегородская обл, г Урень",
    "postal_code": 606803,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Уренский",
    "city_type": "г",
    "city": "Урень",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5204500100000,
    "fias_id": "0e7e2d6e-7217-4624-857a-67b2300c0952",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22254501000,
    "oktmo": 22554000001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 57.4612572,
    "geo_lon": 45.7833293,
    "population": 12311,
    "foundation_year": 1719
  },
  {
    "address": "Нижегородская обл, г Чкаловск",
    "postal_code": 606540,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "р-н",
    "area": "Чкаловский",
    "city_type": "г",
    "city": "Чкаловск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5204600100000,
    "fias_id": "46659847-4fed-4981-bdaf-d260874864ee",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 22442000000,
    "oktmo": 22755000001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 56.7651262,
    "geo_lon": 43.242077,
    "population": 12371,
    "foundation_year": 1100
  },
  {
    "address": "Нижегородская обл, г Шахунья",
    "postal_code": 606910,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Нижегородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шахунья",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5200000900000,
    "fias_id": "a5d97e2a-acea-44c0-92d1-1f107fc472cd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 22458000000,
    "oktmo": 22758000001,
    "tax_office": 5248,
    "timezone": "UTC+3",
    "geo_lat": 57.6764293,
    "geo_lon": 46.6129009,
    "population": 21337,
    "foundation_year": 1921
  },
  {
    "address": "Новгородская обл, г Боровичи",
    "postal_code": 174400,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Боровичский",
    "city_type": "г",
    "city": "Боровичи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5300300100000,
    "fias_id": "ae2f372e-7df2-400c-95f9-4b0522567634",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49408000000,
    "oktmo": 49606101001,
    "tax_office": 5331,
    "timezone": "UTC+3",
    "geo_lat": 58.3840197,
    "geo_lon": 33.9175929,
    "population": 54731,
    "foundation_year": 1400
  },
  {
    "address": "Новгородская обл, г Валдай",
    "postal_code": 175400,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Валдайский",
    "city_type": "г",
    "city": "Валдай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5300400100000,
    "fias_id": "a9075bb6-f2b2-497f-86e1-fe3d9592c075",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49208501000,
    "oktmo": 49608101001,
    "tax_office": 5331,
    "timezone": "UTC+3",
    "geo_lat": 57.9823766,
    "geo_lon": 33.2369436,
    "population": 16099,
    "foundation_year": 1400
  },
  {
    "address": "г Великий Новгород",
    "postal_code": 173000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Великий Новгород",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5300000100000,
    "fias_id": "8d0a05bf-3b8a-43e9-ac26-7ce61d7c4560",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 49401000000,
    "oktmo": 49701000001,
    "tax_office": 5321,
    "timezone": "UTC+3",
    "geo_lat": 58.5213846,
    "geo_lon": 31.2755394,
    "population": 218724,
    "foundation_year": 800
  },
  {
    "address": "Новгородская обл, г Малая Вишера",
    "postal_code": 174260,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Маловишерский",
    "city_type": "г",
    "city": "Малая Вишера",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5300900100000,
    "fias_id": "58fbfb62-6e1e-402d-8c8d-c8ec722f4a5a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49220501000,
    "oktmo": 49620101001,
    "tax_office": 5336,
    "timezone": "UTC+3",
    "geo_lat": 58.8458379,
    "geo_lon": 32.2247401,
    "population": 12461,
    "foundation_year": 1843
  },
  {
    "address": "Новгородская обл, г Окуловка",
    "postal_code": 174350,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Окуловский",
    "city_type": "г",
    "city": "Окуловка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5301200100000,
    "fias_id": "b9e66b8f-b027-4e77-8466-55ba3be0b42b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49228501000,
    "oktmo": 49628101001,
    "tax_office": 5331,
    "timezone": "UTC+3",
    "geo_lat": 58.3910296,
    "geo_lon": 33.2901557,
    "population": 12464,
    "foundation_year": 1851
  },
  {
    "address": "Новгородская обл, г Пестово",
    "postal_code": 174510,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Пестовский",
    "city_type": "г",
    "city": "Пестово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5301400100000,
    "fias_id": "4ad123c8-0130-4432-9d6c-e64946f2d647",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49232501000,
    "oktmo": 49632101001,
    "tax_office": 5331,
    "timezone": "UTC+3",
    "geo_lat": 58.5973723,
    "geo_lon": 35.8143898,
    "population": 15911,
    "foundation_year": 1918
  },
  {
    "address": "Новгородская обл, г Сольцы",
    "postal_code": 175040,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Солецкий",
    "city_type": "г",
    "city": "Сольцы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5301600100000,
    "fias_id": "c2f4a34c-80b4-4aea-a45b-d932909cccc0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49238501000,
    "oktmo": 49538000001,
    "tax_office": 5332,
    "timezone": "UTC+3",
    "geo_lat": 58.1201281,
    "geo_lon": 30.309351,
    "population": 10317,
    "foundation_year": 1390
  },
  {
    "address": "Новгородская обл, г Старая Русса",
    "postal_code": 175200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Старорусский",
    "city_type": "г",
    "city": "Старая Русса",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5301700100000,
    "fias_id": "bc4e8fbc-54dc-4114-9c48-fd7a9b3e2e5c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49413000000,
    "oktmo": 49639101001,
    "tax_office": 5332,
    "timezone": "UTC+3",
    "geo_lat": 57.990737,
    "geo_lon": 31.3554897,
    "population": 32235,
    "foundation_year": 1167
  },
  {
    "address": "Новгородская обл, г Холм",
    "postal_code": 175270,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Холмский",
    "city_type": "г",
    "city": "Холм",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5301900100000,
    "fias_id": "b12ce6eb-00e8-4d54-b309-14569350f346",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49247501000,
    "oktmo": 49647101001,
    "tax_office": 5332,
    "timezone": "UTC+3",
    "geo_lat": 57.145108,
    "geo_lon": 31.1787499,
    "population": 3829,
    "foundation_year": 1144
  },
  {
    "address": "Новгородская обл, г Чудово",
    "postal_code": 174210,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Новгородская",
    "area_type": "р-н",
    "area": "Чудовский",
    "city_type": "г",
    "city": "Чудово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5302000100000,
    "fias_id": "a084418b-c41f-4086-9009-0adb7e9117bf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 49250501000,
    "oktmo": 49650101001,
    "tax_office": 5336,
    "timezone": "UTC+3",
    "geo_lat": 59.1248394,
    "geo_lon": 31.6866241,
    "population": 16148,
    "foundation_year": 1500
  },
  {
    "address": "Новосибирская обл, Барабинский р-н, г Барабинск",
    "postal_code": 632330,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Барабинский",
    "city_type": "г",
    "city": "Барабинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400301200000,
    "fias_id": "0133a54d-617a-4682-8885-e22c85588be8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50405000000,
    "oktmo": 50604101001,
    "tax_office": 5485,
    "timezone": "UTC+7",
    "geo_lat": 55.35146,
    "geo_lon": 78.3464506,
    "population": 30250,
    "foundation_year": 1893
  },
  {
    "address": "Новосибирская обл, г Бердск",
    "postal_code": 633000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бердск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400000200000,
    "fias_id": "fd0191f4-4687-42d9-be64-4ebc7824bd08",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50408000000,
    "oktmo": 50708000001,
    "tax_office": 5483,
    "timezone": "UTC+7",
    "geo_lat": 54.7582156,
    "geo_lon": 83.1070605,
    "population": 98809,
    "foundation_year": 1944
  },
  {
    "address": "Новосибирская обл, г Болотное",
    "postal_code": 633340,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Болотнинский",
    "city_type": "г",
    "city": "Болотное",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400400100000,
    "fias_id": "7e18e6d0-f7e1-4ada-a845-cc7ab5431a1a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50206501000,
    "oktmo": 50606101001,
    "tax_office": 5475,
    "timezone": "UTC+7",
    "geo_lat": 55.6692421,
    "geo_lon": 84.3906889,
    "population": 16969,
    "foundation_year": 1805
  },
  {
    "address": "Новосибирская обл, г Искитим",
    "postal_code": 633200,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Искитим",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400000500000,
    "fias_id": "6965fcac-b7e4-4cab-ab35-a591ed65edf5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50412000000,
    "oktmo": 50712000001,
    "tax_office": 5483,
    "timezone": "UTC+7",
    "geo_lat": 54.6267603,
    "geo_lon": 83.2951244,
    "population": 60072,
    "foundation_year": 1717
  },
  {
    "address": "Новосибирская обл, г Карасук",
    "postal_code": 632860,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Карасукский",
    "city_type": "г",
    "city": "Карасук",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400900100000,
    "fias_id": "23d59dbe-1aed-4564-84da-fe921f9ce294",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50217501000,
    "oktmo": 50617101001,
    "tax_office": 5474,
    "timezone": "UTC+7",
    "geo_lat": 53.7343189,
    "geo_lon": 78.0422967,
    "population": 28929,
    "foundation_year": 1912
  },
  {
    "address": "Новосибирская обл, г Каргат",
    "postal_code": 632400,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Каргатский",
    "city_type": "г",
    "city": "Каргат",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5401000100000,
    "fias_id": "f3a2db25-007e-4830-b460-e763ced3a642",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50219501000,
    "oktmo": 50619101001,
    "tax_office": 5485,
    "timezone": "UTC+7",
    "geo_lat": 55.1945666,
    "geo_lon": 80.2829495,
    "population": 10620,
    "foundation_year": 1700
  },
  {
    "address": "Новосибирская обл, Куйбышевский р-н, г Куйбышев",
    "postal_code": 632380,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Куйбышевский",
    "city_type": "г",
    "city": "Куйбышев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5401501800000,
    "fias_id": "1b70e712-092e-4c45-9984-af3a2c7cfdd7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50415000000,
    "oktmo": 50630101001,
    "tax_office": 5485,
    "timezone": "UTC+7",
    "geo_lat": 55.4685094,
    "geo_lon": 78.3242048,
    "population": 47278,
    "foundation_year": 1722
  },
  {
    "address": "Новосибирская обл, г Купино",
    "postal_code": 632730,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Купинский",
    "city_type": "г",
    "city": "Купино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5401600100000,
    "fias_id": "b1467e32-e362-4e64-b5d7-0d7deba77505",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50232501000,
    "oktmo": 50632101001,
    "tax_office": 5474,
    "timezone": "UTC+7",
    "geo_lat": 54.366055,
    "geo_lon": 77.2973368,
    "population": 15448,
    "foundation_year": 1886
  },
  {
    "address": "г Новосибирск",
    "postal_code": 630000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новосибирск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400000100000,
    "fias_id": "8dea00e3-9aab-4d8e-887c-ef2aaa546456",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 50401000000,
    "oktmo": 50701000001,
    "tax_office": 5400,
    "timezone": "UTC+7",
    "geo_lat": 55.028191,
    "geo_lon": 82.9211489,
    "population": 1498921,
    "foundation_year": 1893
  },
  {
    "address": "Новосибирская обл, г Обь",
    "postal_code": 633100,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Обь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5400000300000,
    "fias_id": "8571e389-8c43-4c36-afae-b532d078c928",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50417000000,
    "oktmo": 50717000001,
    "tax_office": 5475,
    "timezone": "UTC+7",
    "geo_lat": 54.9945576,
    "geo_lon": 82.6937181,
    "population": 26137,
    "foundation_year": 1934
  },
  {
    "address": "Новосибирская обл, Татарский р-н, г Татарск",
    "postal_code": 632120,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Татарский",
    "city_type": "г",
    "city": "Татарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5402302200000,
    "fias_id": "17f3169d-63c5-4c04-9395-298333f7e0f4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 50418000000,
    "oktmo": 50650101001,
    "tax_office": 5474,
    "timezone": "UTC+7",
    "geo_lat": 55.2146167,
    "geo_lon": 75.9739914,
    "population": 26114,
    "foundation_year": 1894
  },
  {
    "address": "Новосибирская обл, г Тогучин",
    "postal_code": 633450,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Тогучинский",
    "city_type": "г",
    "city": "Тогучин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5402400100000,
    "fias_id": "579da04d-579c-4ef7-8f84-aa63088be55b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50252501000,
    "oktmo": 50652101001,
    "tax_office": 5475,
    "timezone": "UTC+7",
    "geo_lat": 55.2251631,
    "geo_lon": 84.4104118,
    "population": 21531,
    "foundation_year": 1867
  },
  {
    "address": "Новосибирская обл, г Черепаново",
    "postal_code": 633520,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Черепановский",
    "city_type": "г",
    "city": "Черепаново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5402800100000,
    "fias_id": "e849dc37-0f3b-4a66-8f0e-780c75927025",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50257501000,
    "oktmo": 50657101001,
    "tax_office": 5483,
    "timezone": "UTC+7",
    "geo_lat": 54.2206476,
    "geo_lon": 83.3724521,
    "population": 19346,
    "foundation_year": 1912
  },
  {
    "address": "Новосибирская обл, г Чулым",
    "postal_code": 632550,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Новосибирская",
    "area_type": "р-н",
    "area": "Чулымский",
    "city_type": "г",
    "city": "Чулым",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5403000100000,
    "fias_id": "bd136162-7404-4915-b456-239426f78b94",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 50259501000,
    "oktmo": 50659101001,
    "tax_office": 5485,
    "timezone": "UTC+7",
    "geo_lat": 55.0906867,
    "geo_lon": 80.9592508,
    "population": 11964,
    "foundation_year": 1762
  },
  {
    "address": "Омская обл, г Исилькуль",
    "postal_code": 646020,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "р-н",
    "area": "Исилькульский",
    "city_type": "г",
    "city": "Исилькуль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5500700100000,
    "fias_id": "097baa31-3a80-4fea-b34b-c613fbd8730e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 52405000000,
    "oktmo": 52615101001,
    "tax_office": 5507,
    "timezone": "UTC+6",
    "geo_lat": 54.9096002,
    "geo_lon": 71.2816284,
    "population": 25905,
    "foundation_year": 1895
  },
  {
    "address": "Омская обл, г Калачинск",
    "postal_code": 646900,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "р-н",
    "area": "Калачинский",
    "city_type": "г",
    "city": "Калачинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5500800100000,
    "fias_id": "fc59f020-5c8e-45e8-baf5-c9069a61e4a9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 52410000000,
    "oktmo": 52618101001,
    "tax_office": 5501,
    "timezone": "UTC+6",
    "geo_lat": 55.0598155,
    "geo_lon": 74.5653644,
    "population": 24000,
    "foundation_year": 1952
  },
  {
    "address": "Омская обл, г Называевск",
    "postal_code": 646100,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "р-н",
    "area": "Называевский",
    "city_type": "г",
    "city": "Называевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5501600100000,
    "fias_id": "db2e2372-fed9-426b-a861-e64e2201f225",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 52413000000,
    "oktmo": 52636101001,
    "tax_office": 5507,
    "timezone": "UTC+6",
    "geo_lat": 55.5689323,
    "geo_lon": 71.3503426,
    "population": 12119,
    "foundation_year": 1910
  },
  {
    "address": "г Омск",
    "postal_code": 644000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Омск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5500000100000,
    "fias_id": "140e31da-27bf-4519-9ea0-6185d681d44e",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 52401000000,
    "oktmo": 52701000001,
    "tax_office": 5500,
    "timezone": "UTC+6",
    "geo_lat": 54.9848566,
    "geo_lon": 73.3674517,
    "population": 1154000,
    "foundation_year": 1716
  },
  {
    "address": "Омская обл, г Тара",
    "postal_code": 646530,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "р-н",
    "area": "Тарский",
    "city_type": "г",
    "city": "Тара",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5502800100000,
    "fias_id": "191443b9-a283-4368-a09d-0a98f16c7dc5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 52415000000,
    "oktmo": 52654101001,
    "tax_office": 5501,
    "timezone": "UTC+6",
    "geo_lat": 56.9160511,
    "geo_lon": 74.3649194,
    "population": 26664,
    "foundation_year": 1594
  },
  {
    "address": "Омская обл, г Тюкалинск",
    "postal_code": 646330,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Омская",
    "area_type": "р-н",
    "area": "Тюкалинский",
    "city_type": "г",
    "city": "Тюкалинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5503000100000,
    "fias_id": "10959db6-8297-49eb-93e9-2bb7641898ce",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 52418000000,
    "oktmo": 52656101001,
    "tax_office": 5507,
    "timezone": "UTC+6",
    "geo_lat": 55.8703415,
    "geo_lon": 72.1954747,
    "population": 12050,
    "foundation_year": 1759
  },
  {
    "address": "Оренбургская обл, г Абдулино",
    "postal_code": 461743,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "р-н",
    "area": "Абдулинский",
    "city_type": "г",
    "city": "Абдулино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5605500100000,
    "fias_id": "5e796fe8-2870-4a24-a14a-5669ca0141e5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 53404000000,
    "oktmo": 53704000001,
    "tax_office": 5602,
    "timezone": "UTC+5",
    "geo_lat": 53.6778906,
    "geo_lon": 53.6472483,
    "population": 20663,
    "foundation_year": 1795
  },
  {
    "address": "Оренбургская обл, г Бугуруслан",
    "postal_code": 461630,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бугуруслан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000500000,
    "fias_id": "680f6208-865b-43b4-ab24-1f42f51d2c33",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53408000000,
    "oktmo": 53708000001,
    "tax_office": 5602,
    "timezone": "UTC+5",
    "geo_lat": 53.6523728,
    "geo_lon": 52.4326853,
    "population": 52249,
    "foundation_year": 1748
  },
  {
    "address": "Оренбургская обл, г Бузулук",
    "postal_code": 461040,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бузулук",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000600000,
    "fias_id": "653b316e-d0ca-414e-9211-03a96643e91b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53412000000,
    "oktmo": 53712000001,
    "tax_office": 5603,
    "timezone": "UTC+5",
    "geo_lat": 52.7881277,
    "geo_lon": 52.2624877,
    "population": 82816,
    "foundation_year": 1736
  },
  {
    "address": "Оренбургская обл, г Гай",
    "postal_code": 462631,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Гай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000700000,
    "fias_id": "c9a3cd9e-a133-4de9-aafb-a6e0b664312c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53413000000,
    "oktmo": 53713000001,
    "tax_office": 5607,
    "timezone": "UTC+5",
    "geo_lat": 51.4649189,
    "geo_lon": 58.4436875,
    "population": 38302,
    "foundation_year": 1959
  },
  {
    "address": "Оренбургская обл, г Кувандык",
    "postal_code": 462240,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кувандык",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000800000,
    "fias_id": "7644221b-0ef9-40c3-8378-cb0e2299747e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53414000000,
    "oktmo": 53714000001,
    "tax_office": 5607,
    "timezone": "UTC+5",
    "geo_lat": 51.4783857,
    "geo_lon": 57.3612636,
    "population": 26176,
    "foundation_year": 1800
  },
  {
    "address": "Оренбургская обл, г Медногорск",
    "postal_code": 462271,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Медногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000200000,
    "fias_id": "86204c33-b552-4ebd-a93a-9dc8b274ee9c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53415000000,
    "oktmo": 53715000001,
    "tax_office": 5607,
    "timezone": "UTC+5",
    "geo_lat": 51.4037617,
    "geo_lon": 57.583163,
    "population": 27253,
    "foundation_year": 1933
  },
  {
    "address": "Оренбургская обл, г Новотроицк",
    "postal_code": 462351,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новотроицк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000300000,
    "fias_id": "5cb431ac-6645-47b2-9716-5fccc9ec1d7f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53420000000,
    "oktmo": 53720000001,
    "tax_office": 5600,
    "timezone": "UTC+5",
    "geo_lat": 51.1964202,
    "geo_lon": 58.3018192,
    "population": 97914,
    "foundation_year": 1920
  },
  {
    "address": "г Оренбург",
    "postal_code": 460000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Оренбург",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000100000,
    "fias_id": "dce97bff-deb2-4fd9-9aec-4f4327bbf89b",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 53401000000,
    "oktmo": 53701000001,
    "tax_office": 5600,
    "timezone": "UTC+5",
    "geo_lat": 51.7875092,
    "geo_lon": 55.1018828,
    "population": 570329,
    "foundation_year": 1743
  },
  {
    "address": "Оренбургская обл, г Орск",
    "postal_code": 462400,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Орск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000400000,
    "fias_id": "71e7cb45-f365-4e6b-b068-a7d35349c3ba",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53423000000,
    "oktmo": 53723000001,
    "tax_office": 5614,
    "timezone": "UTC+5",
    "geo_lat": 51.2294282,
    "geo_lon": 58.4752777,
    "population": 238006,
    "foundation_year": 1735
  },
  {
    "address": "Оренбургская обл, г Соль-Илецк",
    "postal_code": 461504,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "р-н",
    "area": "Соль-Илецкий",
    "city_type": "г",
    "city": "Соль-Илецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5601500100000,
    "fias_id": "d2d93864-f4a5-4ac2-b953-b230cc9d0fa0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 53425000000,
    "oktmo": 53725000001,
    "tax_office": 5600,
    "timezone": "UTC+5",
    "geo_lat": 51.1633736,
    "geo_lon": 54.9896726,
    "population": 26308,
    "foundation_year": 1754
  },
  {
    "address": "Оренбургская обл, г Сорочинск",
    "postal_code": 461900,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сорочинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600000900000,
    "fias_id": "1d9296cc-f534-4e81-9e97-c31d3ef9c25a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 53427000000,
    "oktmo": 53727000001,
    "tax_office": 5600,
    "timezone": "UTC+5",
    "geo_lat": 52.426685,
    "geo_lon": 53.1542745,
    "population": 30136,
    "foundation_year": 1737
  },
  {
    "address": "Оренбургская обл, г Ясный",
    "postal_code": 462780,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Оренбургская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ясный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5600001000000,
    "fias_id": "c30ba5a3-1222-4fde-a8d0-ef2d8494e4c8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 53432000000,
    "oktmo": 53732000001,
    "tax_office": 5635,
    "timezone": "UTC+5",
    "geo_lat": 51.036838,
    "geo_lon": 59.874344,
    "population": 16082,
    "foundation_year": 1961
  },
  {
    "address": "Орловская обл, г Болхов",
    "postal_code": 303140,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "р-н",
    "area": "Болховский",
    "city_type": "г",
    "city": "Болхов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5700200100000,
    "fias_id": "4393f437-1a48-4083-bed9-ad3382fdad16",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 54204501000,
    "oktmo": 54604101001,
    "tax_office": 5744,
    "timezone": "UTC+3",
    "geo_lat": 53.4438496,
    "geo_lon": 36.0076833,
    "population": 11421,
    "foundation_year": 1196
  },
  {
    "address": "Орловская обл, г Дмитровск",
    "postal_code": 303240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "р-н",
    "area": "Дмитровский",
    "city_type": "г",
    "city": "Дмитровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5700500100000,
    "fias_id": "25748825-cbac-4a45-b3d6-4770c3872478",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 54212501000,
    "oktmo": 54612101001,
    "tax_office": 5748,
    "timezone": "UTC+3",
    "geo_lat": 52.5054851,
    "geo_lon": 35.1415009,
    "population": 5956,
    "foundation_year": 1711
  },
  {
    "address": "Орловская обл, г Ливны",
    "postal_code": 303850,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ливны",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5700000200000,
    "fias_id": "aef2c735-2e21-42e5-8638-9667bade6a2b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 54405000000,
    "oktmo": 54705000001,
    "tax_office": 5743,
    "timezone": "UTC+3",
    "geo_lat": 52.4284558,
    "geo_lon": 37.6039506,
    "population": 50430,
    "foundation_year": 1586
  },
  {
    "address": "Орловская обл, г Малоархангельск",
    "postal_code": 303369,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "р-н",
    "area": "Малоархангельский",
    "city_type": "г",
    "city": "Малоархангельск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5701400100000,
    "fias_id": "2afa3f40-891a-4640-a1cf-af9a5a6d1eb7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 54232501000,
    "oktmo": 54632101001,
    "tax_office": 5743,
    "timezone": "UTC+3",
    "geo_lat": 52.4002192,
    "geo_lon": 36.5038579,
    "population": 3872,
    "foundation_year": 1600
  },
  {
    "address": "Орловская обл, г Мценск",
    "postal_code": 303030,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мценск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5700000300000,
    "fias_id": "76f2780b-0f1c-4818-98a4-13da5c8a2c80",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 54410000000,
    "oktmo": 54710000001,
    "tax_office": 5744,
    "timezone": "UTC+3",
    "geo_lat": 53.2788438,
    "geo_lon": 36.5749105,
    "population": 43216,
    "foundation_year": 1146
  },
  {
    "address": "Орловская обл, г Новосиль",
    "postal_code": 303500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "р-н",
    "area": "Новосильский",
    "city_type": "г",
    "city": "Новосиль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5701700100000,
    "fias_id": "5f17239d-7e83-4e7d-afcd-b94e451465c9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 54243501000,
    "oktmo": 54643101001,
    "tax_office": 5745,
    "timezone": "UTC+3",
    "geo_lat": 52.97454,
    "geo_lon": 37.0437146,
    "population": 3799,
    "foundation_year": 1155
  },
  {
    "address": "г Орёл",
    "postal_code": 302000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Орловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Орёл",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5700000100000,
    "fias_id": "2abed4d9-5565-4885-bc96-f4ffccc6cba4",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 54401000000,
    "oktmo": 54701000001,
    "tax_office": 5740,
    "timezone": "UTC+3",
    "geo_lat": 52.9671298,
    "geo_lon": 36.0696427,
    "population": 317854,
    "foundation_year": 1566
  },
  {
    "address": "Пензенская обл, г Белинский",
    "postal_code": 442250,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Белинский",
    "city_type": "г",
    "city": "Белинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800500100000,
    "fias_id": "ea57fb75-444f-4f54-b89e-d66ac91774ba",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56212501000,
    "oktmo": 56612101,
    "tax_office": 5802,
    "timezone": "UTC+3",
    "geo_lat": 52.9640996,
    "geo_lon": 43.4183212,
    "population": 8567,
    "foundation_year": 1708
  },
  {
    "address": "Пензенская обл, г Городище",
    "postal_code": 442310,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Городищенский",
    "city_type": "г",
    "city": "Городище",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800800100000,
    "fias_id": "b9d45c36-c679-40bb-8168-7ab78913a2c7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56218501000,
    "oktmo": 56618101001,
    "tax_office": 5826,
    "timezone": "UTC+3",
    "geo_lat": 53.2726916,
    "geo_lon": 45.7026142,
    "population": 8102,
    "foundation_year": 1681
  },
  {
    "address": "Пензенская обл, г Заречный",
    "postal_code": 442960,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Заречный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800000200000,
    "fias_id": "ff38fc0c-d739-4627-a2f4-58af8f3d9d42",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 56534000000,
    "oktmo": 56734000001,
    "tax_office": 5838,
    "timezone": "UTC+3",
    "geo_lat": 53.1960836,
    "geo_lon": 45.1689907,
    "population": 63579,
    "foundation_year": 1958
  },
  {
    "address": "Пензенская обл, г Каменка",
    "postal_code": 442240,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Каменский",
    "city_type": "г",
    "city": "Каменка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5801100100000,
    "fias_id": "e5313349-e01d-47a2-b329-b1629c243520",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56229501000,
    "oktmo": 56629101001,
    "tax_office": 5802,
    "timezone": "UTC+3",
    "geo_lat": 53.1855463,
    "geo_lon": 44.0469717,
    "population": 39579,
    "foundation_year": 1700
  },
  {
    "address": "Пензенская обл, г Кузнецк",
    "postal_code": 442530,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кузнецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800000300000,
    "fias_id": "b4751488-e03d-416c-9cde-af7465256bfa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56405000000,
    "oktmo": 56705000001,
    "tax_office": 5803,
    "timezone": "UTC+3",
    "geo_lat": 53.1130888,
    "geo_lon": 46.605092,
    "population": 88886,
    "foundation_year": 1699
  },
  {
    "address": "Пензенская обл, г Нижний Ломов",
    "postal_code": 442150,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Нижнеломовский",
    "city_type": "г",
    "city": "Нижний Ломов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5802200100000,
    "fias_id": "f852d3a4-5d39-4a1b-be4a-f20ca9c4e60c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56251501000,
    "oktmo": 56651101001,
    "tax_office": 5827,
    "timezone": "UTC+3",
    "geo_lat": 53.5300905,
    "geo_lon": 43.6730217,
    "population": 22678,
    "foundation_year": 1636
  },
  {
    "address": "Пензенская обл, г Никольск",
    "postal_code": 442680,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Никольский",
    "city_type": "г",
    "city": "Никольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5802300100000,
    "fias_id": "6e26041a-db1c-4f41-93f1-f056ab0b70ef",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56253501000,
    "oktmo": 56653101001,
    "tax_office": 5826,
    "timezone": "UTC+3",
    "geo_lat": 53.7137496,
    "geo_lon": 46.0799857,
    "population": 22471,
    "foundation_year": 1761
  },
  {
    "address": "г Пенза",
    "postal_code": 440000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пенза",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800000100000,
    "fias_id": "ff3292b1-a1d2-47d4-b35b-ac06b50555cc",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 56401000000,
    "oktmo": 56701000001,
    "tax_office": 5800,
    "timezone": "UTC+3",
    "geo_lat": 53.1753314,
    "geo_lon": 45.0348625,
    "population": 519592,
    "foundation_year": 1663
  },
  {
    "address": "Пензенская обл, г Сердобск",
    "postal_code": 442890,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Сердобский",
    "city_type": "г",
    "city": "Сердобск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5802500100000,
    "fias_id": "20c2c219-f4a8-4d07-93c9-72bd6766c0b6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56256501000,
    "oktmo": 56656101001,
    "tax_office": 5805,
    "timezone": "UTC+3",
    "geo_lat": 52.4697595,
    "geo_lon": 44.2122414,
    "population": 35393,
    "foundation_year": 1698
  },
  {
    "address": "Пензенская обл, г Спасск",
    "postal_code": 442600,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Спасский",
    "city_type": "г",
    "city": "Спасск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800300100000,
    "fias_id": "d005a0db-1deb-4728-a800-1e423fa804df",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 56206501000,
    "oktmo": 56606101001,
    "tax_office": 5827,
    "timezone": "UTC+3",
    "geo_lat": 53.9271974,
    "geo_lon": 43.1859073,
    "population": 7442,
    "foundation_year": 1600
  },
  {
    "address": "Пензенская обл, Городищенский р-н, г Сурск",
    "postal_code": 442300,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Пензенская",
    "area_type": "р-н",
    "area": "Городищенский",
    "city_type": "г",
    "city": "Сурск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5800800200000,
    "fias_id": "d8f506c4-a1e4-4524-b997-8651cb6f0af0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 56218508000,
    "oktmo": 56618108001,
    "tax_office": 5826,
    "timezone": "UTC+3",
    "geo_lat": 53.0761357,
    "geo_lon": 45.6910796,
    "population": 7032,
    "foundation_year": 1849
  },
  {
    "address": "Пермский край, г Александровск",
    "postal_code": 618320,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Александровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000300000,
    "fias_id": "4f07ade1-1415-44c8-bed9-e851f1ef558d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57405000000,
    "oktmo": 57502000001,
    "tax_office": 5911,
    "timezone": "UTC+5",
    "geo_lat": 59.1613221,
    "geo_lon": 57.5763459,
    "population": 15022,
    "foundation_year": 1783
  },
  {
    "address": "Пермский край, г Березники",
    "postal_code": 618400,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Березники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000200000,
    "fias_id": "4ffcde97-05e9-4a6e-bd51-3a984b41b7bd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57408000000,
    "oktmo": 57708000001,
    "tax_office": 5911,
    "timezone": "UTC+5",
    "geo_lat": 59.4079923,
    "geo_lon": 56.8039427,
    "population": 156350,
    "foundation_year": 1932
  },
  {
    "address": "Пермский край, г Верещагино",
    "postal_code": 617119,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Верещагинский",
    "city_type": "г",
    "city": "Верещагино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900500100000,
    "fias_id": "9bd0970e-7fbb-4a64-a625-af7670526dba",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57212501000,
    "oktmo": 57712000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 58.0797571,
    "geo_lon": 54.6581309,
    "population": 22760,
    "foundation_year": 1898
  },
  {
    "address": "Пермский край, г Горнозаводск",
    "postal_code": 618820,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Горнозаводский",
    "city_type": "г",
    "city": "Горнозаводск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900600100000,
    "fias_id": "17644b43-80ff-4dea-b823-3b9dbf9e9ef0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57214501000,
    "oktmo": 57714000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 58.3742532,
    "geo_lon": 58.3231716,
    "population": 12334,
    "foundation_year": 1947
  },
  {
    "address": "Пермский край, г Гремячинск",
    "postal_code": 618270,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Гремячинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000400000,
    "fias_id": "edeaf203-1bc3-4fe1-b3ed-15eb89978783",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57412000000,
    "oktmo": 57715000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 58.5626082,
    "geo_lon": 57.8520572,
    "population": 11005,
    "foundation_year": 1941
  },
  {
    "address": "Пермский край, г Губаха",
    "postal_code": 618250,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Губаха",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000500000,
    "fias_id": "1dc365d3-60b1-41ea-a3b3-1c599024cf30",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57415000000,
    "oktmo": 57717000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 58.8371721,
    "geo_lon": 57.554533,
    "population": 27544,
    "foundation_year": 1755
  },
  {
    "address": "Пермский край, г Добрянка",
    "postal_code": 618730,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Добрянка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000600000,
    "fias_id": "230c2f4d-fd9d-46fc-8bbd-b8de26810790",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57416000000,
    "oktmo": 57718000001,
    "tax_office": 5948,
    "timezone": "UTC+5",
    "geo_lat": 58.469685,
    "geo_lon": 56.4130737,
    "population": 35720,
    "foundation_year": 1623
  },
  {
    "address": "Пермский край, г Кизел",
    "postal_code": 618350,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кизел",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000700000,
    "fias_id": "cc8b9eb5-bd4e-4472-8314-f889e8a6679c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57418000000,
    "oktmo": 57719000001,
    "tax_office": 5911,
    "timezone": "UTC+5",
    "geo_lat": 59.0512783,
    "geo_lon": 57.6471028,
    "population": 20277,
    "foundation_year": 1750
  },
  {
    "address": "Пермский край, г Красновишерск",
    "postal_code": 618590,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Красновишерский",
    "city_type": "г",
    "city": "Красновишерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5901300100000,
    "fias_id": "f42f49ba-d949-4245-843a-6ec5203d2fcd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57226501000,
    "oktmo": 57760000001,
    "tax_office": 5919,
    "timezone": "UTC+5",
    "geo_lat": 60.3901321,
    "geo_lon": 57.0535682,
    "population": 17129,
    "foundation_year": 1894
  },
  {
    "address": "Пермский край, г Краснокамск",
    "postal_code": 617060,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Краснокамск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001500000,
    "fias_id": "22ac4485-bdf3-422e-b67f-9b3aba46de5e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57420000000,
    "oktmo": 57720000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 58.0822065,
    "geo_lon": 55.7479936,
    "population": 52632,
    "foundation_year": 1929
  },
  {
    "address": "Пермский край, г Кудымкар",
    "postal_code": 619000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кудымкар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001400000,
    "fias_id": "d36590ad-0286-44a2-876d-7732deee4234",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57421000000,
    "oktmo": 57721000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 59.0167925,
    "geo_lon": 54.6572508,
    "population": 30711,
    "foundation_year": 1579
  },
  {
    "address": "Пермский край, г Кунгур",
    "postal_code": 617470,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кунгур",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000900000,
    "fias_id": "73e5113d-949a-4a9e-8e44-6eae9ef93747",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57422000000,
    "oktmo": 57722000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 57.4283296,
    "geo_lon": 56.9438656,
    "population": 67857,
    "foundation_year": 1663
  },
  {
    "address": "Пермский край, г Лысьва",
    "postal_code": 618900,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лысьва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001000000,
    "fias_id": "5d7c95a5-a4d7-4fb4-9774-93e527636a9e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57427000000,
    "oktmo": 57726000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 58.0995875,
    "geo_lon": 57.8086825,
    "population": 67712,
    "foundation_year": 1785
  },
  {
    "address": "Пермский край, г Нытва",
    "postal_code": 617000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Нытвенский",
    "city_type": "г",
    "city": "Нытва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5901400100000,
    "fias_id": "a49957ba-7860-484f-bf7a-abbbe1d76bab",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57234501000,
    "oktmo": 57727000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 57.9336725,
    "geo_lon": 55.3356084,
    "population": 19041,
    "foundation_year": 1756
  },
  {
    "address": "Пермский край, г Оса",
    "postal_code": 618120,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Осинский",
    "city_type": "г",
    "city": "Оса",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5901600100000,
    "fias_id": "18e7b084-c83f-40bf-9378-d26628528a19",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57240501000,
    "oktmo": 57728000001,
    "tax_office": 5959,
    "timezone": "UTC+5",
    "geo_lat": 57.2889515,
    "geo_lon": 55.4688668,
    "population": 22420,
    "foundation_year": 1591
  },
  {
    "address": "Пермский край, г Оханск",
    "postal_code": 618100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Оханский",
    "city_type": "г",
    "city": "Оханск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5901800100000,
    "fias_id": "48159476-c6db-4258-a0bc-f8ee52e7aef6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57242501000,
    "oktmo": 57729000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 57.7180034,
    "geo_lon": 55.3872469,
    "population": 7597,
    "foundation_year": 1547
  },
  {
    "address": "Пермский край, г Очер",
    "postal_code": 617140,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Очерский",
    "city_type": "г",
    "city": "Очер",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5901900100000,
    "fias_id": "6a9b1a8a-5da1-470c-a03a-ecbb3cee0ee9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57244501000,
    "oktmo": 57762000001,
    "tax_office": 5981,
    "timezone": "UTC+5",
    "geo_lat": 57.8852686,
    "geo_lon": 54.7161091,
    "population": 15003,
    "foundation_year": 1759
  },
  {
    "address": "г Пермь",
    "postal_code": 614000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пермь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900000100000,
    "fias_id": "a309e4ce-2f36-4106-b1ca-53e0f48a6d95",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 57401000000,
    "oktmo": 57701000001,
    "tax_office": 5900,
    "timezone": "UTC+5",
    "geo_lat": 58.0102583,
    "geo_lon": 56.2342034,
    "population": 1000679,
    "foundation_year": 1723
  },
  {
    "address": "Пермский край, г Соликамск",
    "postal_code": 618541,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Соликамск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001100000,
    "fias_id": "8b698775-fe5e-4fc0-9f0d-272855d82d15",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57430000000,
    "oktmo": 57730000001,
    "tax_office": 5919,
    "timezone": "UTC+5",
    "geo_lat": 59.6482998,
    "geo_lon": 56.771009,
    "population": 97239,
    "foundation_year": 1430
  },
  {
    "address": "Пермский край, г Усолье",
    "postal_code": 618460,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Усольский",
    "city_type": "г",
    "city": "Усолье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5902400100000,
    "fias_id": "9acef094-749b-406f-89a2-98c02ebf50c5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57253501000,
    "oktmo": 57708000006,
    "tax_office": 5911,
    "timezone": "UTC+5",
    "geo_lat": 59.4277573,
    "geo_lon": 56.6837872,
    "population": 5694,
    "foundation_year": 1606
  },
  {
    "address": "Пермский край, г Чайковский",
    "postal_code": 617760,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чайковский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001200000,
    "fias_id": "06d7a6d6-8f57-4e5a-b698-2bc44c92bb84",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57435000000,
    "oktmo": 57735000001,
    "tax_office": 5959,
    "timezone": "UTC+5",
    "geo_lat": 56.7781501,
    "geo_lon": 54.1477965,
    "population": 82933,
    "foundation_year": 1955
  },
  {
    "address": "Пермский край, г Чердынь",
    "postal_code": 618601,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Чердынский",
    "city_type": "г",
    "city": "Чердынь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5902700100000,
    "fias_id": "242133ad-1c0f-43f0-9865-7b5f914a778f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57256501000,
    "oktmo": 57756000001,
    "tax_office": 5919,
    "timezone": "UTC+5",
    "geo_lat": 60.4011933,
    "geo_lon": 56.4799933,
    "population": 4920,
    "foundation_year": 1451
  },
  {
    "address": "Пермский край, Ильинский р-н, г Чермоз",
    "postal_code": 617040,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Ильинский",
    "city_type": "г",
    "city": "Чермоз",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900800200000,
    "fias_id": "8f353d4e-1374-4a88-a06b-51c6a3f9acd1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57220504000,
    "oktmo": 57759000006,
    "tax_office": 5907,
    "timezone": "UTC+5",
    "geo_lat": 58.7842834,
    "geo_lon": 56.1507138,
    "population": 4017,
    "foundation_year": 1701
  },
  {
    "address": "Пермский край, г Чернушка",
    "postal_code": 617830,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "р-н",
    "area": "Чернушинский",
    "city_type": "г",
    "city": "Чернушка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5902800100000,
    "fias_id": "c3a35399-c5ea-4f12-bf34-c2cc21e1cc60",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 57257501000,
    "oktmo": 57757000001,
    "tax_office": 5959,
    "timezone": "UTC+5",
    "geo_lat": 56.5160768,
    "geo_lon": 56.0763049,
    "population": 33275,
    "foundation_year": 1854
  },
  {
    "address": "Пермский край, г Чусовой",
    "postal_code": 618200,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "край",
    "region": "Пермский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чусовой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 5900001300000,
    "fias_id": "784b1911-182d-476b-a4ad-0f3fa1ef7777",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 57440000000,
    "oktmo": 57758000001,
    "tax_office": 5918,
    "timezone": "UTC+5",
    "geo_lat": 58.2974596,
    "geo_lon": 57.8193615,
    "population": 46740,
    "foundation_year": 1878
  },
  {
    "address": "Приморский край, г Арсеньев",
    "postal_code": 692330,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Арсеньев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000200000,
    "fias_id": "36e4c7ce-7320-48ee-8320-0587c1e09917",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05403000000",
    "oktmo": "05703000001",
    "tax_office": 2500,
    "timezone": "UTC+10",
    "geo_lat": 44.1622031,
    "geo_lon": 133.2696209,
    "population": 56742,
    "foundation_year": 1902
  },
  {
    "address": "Приморский край, г Артем",
    "postal_code": 692486,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Артем",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000300000,
    "fias_id": "3ce80b09-9b72-4e68-9b2d-becaaee4570e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05405000000",
    "oktmo": "05705000001",
    "tax_office": 2502,
    "timezone": "UTC+10",
    "geo_lat": 43.3501675,
    "geo_lon": 132.1596175,
    "population": 102636,
    "foundation_year": 1924
  },
  {
    "address": "Приморский край, г Большой Камень",
    "postal_code": 692801,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Большой Камень",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000700000,
    "fias_id": "1315186b-9ae8-4f92-b3a5-1db055c15358",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05406000000",
    "oktmo": "05706000001",
    "tax_office": 2536,
    "timezone": "UTC+10",
    "geo_lat": 43.1111742,
    "geo_lon": 132.3480082,
    "population": 39257,
    "foundation_year": 1947
  },
  {
    "address": "г Владивосток",
    "postal_code": 690000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Владивосток",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000100000,
    "fias_id": "7b6de6a5-86d0-4735-b11a-499081111af8",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "05401000000",
    "oktmo": "05701000001",
    "tax_office": 2500,
    "timezone": "UTC+10",
    "geo_lat": 43.1164904,
    "geo_lon": 131.8823937,
    "population": 592069,
    "foundation_year": 1860
  },
  {
    "address": "Приморский край, г Дальнегорск",
    "postal_code": 692441,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дальнегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000800000,
    "fias_id": "b2a863e2-37be-4858-b65e-0babc3872052",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05407000000",
    "oktmo": "05707000001",
    "tax_office": 2508,
    "timezone": "UTC+10",
    "geo_lat": 44.5539457,
    "geo_lon": 135.5662716,
    "population": 37503,
    "foundation_year": 1897
  },
  {
    "address": "Приморский край, г Дальнереченск",
    "postal_code": 692130,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дальнереченск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000900000,
    "fias_id": "e6e6c2b1-20cf-47a7-9a4b-39e8a96cc5c8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05408000000",
    "oktmo": "05708000001",
    "tax_office": 2511,
    "timezone": "UTC+10",
    "geo_lat": 45.9308483,
    "geo_lon": 133.7316907,
    "population": 27601,
    "foundation_year": 1859
  },
  {
    "address": "Приморский край, г Лесозаводск",
    "postal_code": 692031,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лесозаводск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500001200000,
    "fias_id": "71b4cdae-5e53-4406-a418-1e8b5c17dc2e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05411000000",
    "oktmo": "05711000001",
    "tax_office": 2511,
    "timezone": "UTC+10",
    "geo_lat": 45.4780092,
    "geo_lon": 133.4186199,
    "population": 36975,
    "foundation_year": 1932
  },
  {
    "address": "Приморский край, г Находка",
    "postal_code": 692900,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Находка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000400000,
    "fias_id": "225a3506-35aa-4456-8bd7-244bdfbc4eaf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05414000000",
    "oktmo": "05714000001",
    "tax_office": 2508,
    "timezone": "UTC+10",
    "geo_lat": 42.8239372,
    "geo_lon": 132.8927361,
    "population": 159695,
    "foundation_year": 1864
  },
  {
    "address": "Приморский край, г Партизанск",
    "postal_code": 692851,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Партизанск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000500000,
    "fias_id": "b62edf86-4257-4e80-ade6-857529bd1b1e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05417000000",
    "oktmo": "05717000001",
    "tax_office": 2508,
    "timezone": "UTC+10",
    "geo_lat": 43.1280578,
    "geo_lon": 133.1264567,
    "population": 38648,
    "foundation_year": 1896
  },
  {
    "address": "Приморский край, г Спасск-Дальний",
    "postal_code": 692230,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Спасск-Дальний",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500001000000,
    "fias_id": "e55554eb-c571-49d2-bf69-c3c4acf8b10b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05420000000",
    "oktmo": "05720000001",
    "tax_office": 2502,
    "timezone": "UTC+10",
    "geo_lat": 44.5901175,
    "geo_lon": 132.8157288,
    "population": 44166,
    "foundation_year": 1886
  },
  {
    "address": "Приморский край, г Уссурийск",
    "postal_code": 692500,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Уссурийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500001100000,
    "fias_id": "de7335fb-9baa-48eb-927d-0bb299b2e5bc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05423000000",
    "oktmo": "05723000001",
    "tax_office": 2511,
    "timezone": "UTC+10",
    "geo_lat": 43.7971818,
    "geo_lon": 131.9518229,
    "population": 157946,
    "foundation_year": 1866
  },
  {
    "address": "Приморский край, г Фокино",
    "postal_code": 692880,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Приморский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Фокино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2500000600000,
    "fias_id": "fdb16823-586f-43d2-a1b4-b1c7c7f00bcd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "05547000000",
    "oktmo": "05747000001",
    "tax_office": 2536,
    "timezone": "UTC+10",
    "geo_lat": 42.9706317,
    "geo_lon": 132.4110196,
    "population": 23683,
    "foundation_year": 1891
  },
  {
    "address": "Псковская обл, г Великие Луки",
    "postal_code": 182100,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Великие Луки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6000000200000,
    "fias_id": "ca091eae-2c39-4730-b57d-b2fa570f71cb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 58410000000,
    "oktmo": 58710000001,
    "tax_office": 6025,
    "timezone": "UTC+3",
    "geo_lat": 56.332208,
    "geo_lon": 30.5508641,
    "population": 98778,
    "foundation_year": 1166
  },
  {
    "address": "Псковская обл, г Гдов",
    "postal_code": 181600,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Гдовский",
    "city_type": "г",
    "city": "Гдов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6000400100000,
    "fias_id": "cdbe4f8f-1a44-4c99-a3c8-06b501a3ad2e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58208501000,
    "oktmo": 58608101001,
    "tax_office": 6027,
    "timezone": "UTC+3",
    "geo_lat": 58.7432429,
    "geo_lon": 27.8264809,
    "population": 4379,
    "foundation_year": 1300
  },
  {
    "address": "Псковская обл, г Дно",
    "postal_code": 182670,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Дновский",
    "city_type": "г",
    "city": "Дно",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6000600100000,
    "fias_id": "31e4b2e4-ae67-43db-82ea-8728cd5a71c7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58212501000,
    "oktmo": 58612101001,
    "tax_office": 6030,
    "timezone": "UTC+3",
    "geo_lat": 57.826974,
    "geo_lon": 29.9629389,
    "population": 9061,
    "foundation_year": 1897
  },
  {
    "address": "Псковская обл, г Невель",
    "postal_code": 182500,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Невельский",
    "city_type": "г",
    "city": "Невель",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001000100000,
    "fias_id": "6570fdb6-7602-477e-a783-f186456dabfe",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58220501000,
    "oktmo": 58620101001,
    "tax_office": 6009,
    "timezone": "UTC+3",
    "geo_lat": 56.0201973,
    "geo_lon": 29.9239983,
    "population": 16324,
    "foundation_year": 1562
  },
  {
    "address": "Псковская обл, г Новоржев",
    "postal_code": 182440,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Новоржевский",
    "city_type": "г",
    "city": "Новоржев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001100100000,
    "fias_id": "236c48ca-48c9-471f-8891-0b73ea89bc2b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58223501000,
    "oktmo": 58623101001,
    "tax_office": 6032,
    "timezone": "UTC+3",
    "geo_lat": 57.029807,
    "geo_lon": 29.3433083,
    "population": 3695,
    "foundation_year": 1777
  },
  {
    "address": "Псковская обл, г Новосокольники",
    "postal_code": 182200,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Новосокольнический",
    "city_type": "г",
    "city": "Новосокольники",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001200100000,
    "fias_id": "af8fb4a0-5d4e-4e0e-94fc-20021da2f67a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58226501000,
    "oktmo": 58626101001,
    "tax_office": 6025,
    "timezone": "UTC+3",
    "geo_lat": 56.3408431,
    "geo_lon": 30.1527573,
    "population": 8119,
    "foundation_year": 1901
  },
  {
    "address": "Псковская обл, г Опочка",
    "postal_code": 182333,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Опочецкий",
    "city_type": "г",
    "city": "Опочка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001300100000,
    "fias_id": "59c2f176-3b63-4086-bc6c-efa246c64a1e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58229501000,
    "oktmo": 58629101001,
    "tax_office": 6032,
    "timezone": "UTC+3",
    "geo_lat": 56.710725,
    "geo_lon": 28.6717519,
    "population": 11601,
    "foundation_year": 1414
  },
  {
    "address": "Псковская обл, г Остров",
    "postal_code": 181353,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Островский",
    "city_type": "г",
    "city": "Остров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001400100000,
    "fias_id": "67d379ab-16ac-40d0-95c0-f5f1cbeb578d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58233501000,
    "oktmo": 58633101001,
    "tax_office": 6032,
    "timezone": "UTC+3",
    "geo_lat": 57.3451528,
    "geo_lon": 28.3437593,
    "population": 21670,
    "foundation_year": 1341
  },
  {
    "address": "Псковская обл, г Печоры",
    "postal_code": 181502,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Печорский",
    "city_type": "г",
    "city": "Печоры",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001600100000,
    "fias_id": "8cb44bcc-4e46-409a-972a-b99a59fcc7e9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58240501000,
    "oktmo": 58640101001,
    "tax_office": 6032,
    "timezone": "UTC+3",
    "geo_lat": 57.8145817,
    "geo_lon": 27.622259,
    "population": 12308,
    "foundation_year": 1473
  },
  {
    "address": "Псковская обл, г Порхов",
    "postal_code": 182620,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Порховский",
    "city_type": "г",
    "city": "Порхов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001800100000,
    "fias_id": "c02d55c3-2086-400f-81b7-f1f540f31a87",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58247501000,
    "oktmo": 58647101001,
    "tax_office": 6030,
    "timezone": "UTC+3",
    "geo_lat": 57.7765219,
    "geo_lon": 29.5436626,
    "population": 10608,
    "foundation_year": 1239
  },
  {
    "address": "г Псков",
    "postal_code": 180000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Псков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6000000100000,
    "fias_id": "2858811e-448a-482e-9863-e03bf06bb5d4",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 58401000000,
    "oktmo": 58701000001,
    "tax_office": 6027,
    "timezone": "UTC+3",
    "geo_lat": 57.8194415,
    "geo_lon": 28.3317198,
    "population": 203974,
    "foundation_year": 903
  },
  {
    "address": "Псковская обл, г Пустошка",
    "postal_code": 182300,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Пустошкинский",
    "city_type": "г",
    "city": "Пустошка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6001900100000,
    "fias_id": "28a7a368-16ab-4ffc-9b3e-4a4c3e16bf74",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58250501000,
    "oktmo": 58650101001,
    "tax_office": 6009,
    "timezone": "UTC+3",
    "geo_lat": 56.3374813,
    "geo_lon": 29.3668055,
    "population": 4619,
    "foundation_year": 1901
  },
  {
    "address": "Псковская обл, г Пыталово",
    "postal_code": 181410,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Пыталовский",
    "city_type": "г",
    "city": "Пыталово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6002100100000,
    "fias_id": "8cfcdc08-f726-4aff-abfa-b20199b32172",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58253501000,
    "oktmo": 58653101001,
    "tax_office": 6032,
    "timezone": "UTC+3",
    "geo_lat": 57.0637952,
    "geo_lon": 27.9236214,
    "population": 5826,
    "foundation_year": 1782
  },
  {
    "address": "Псковская обл, г Себеж",
    "postal_code": 182250,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "обл",
    "region": "Псковская",
    "area_type": "р-н",
    "area": "Себежский",
    "city_type": "г",
    "city": "Себеж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6002200100000,
    "fias_id": "749fbd77-ddae-408b-8ad8-154a0a22306a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 58254501000,
    "oktmo": 58654101001,
    "tax_office": 6009,
    "timezone": "UTC+3",
    "geo_lat": 56.2908554,
    "geo_lon": 28.4724326,
    "population": 6375,
    "foundation_year": 1414
  },
  {
    "address": "Ростовская обл, г Азов",
    "postal_code": 346780,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Азов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100001300000,
    "fias_id": "a216cad5-7027-40b8-b1a1-d64abefbd5cd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60404000000,
    "oktmo": 60704000001,
    "tax_office": 6188,
    "timezone": "UTC+3",
    "geo_lat": 47.1121589,
    "geo_lon": 39.4232555,
    "population": 82882,
    "foundation_year": 1067
  },
  {
    "address": "Ростовская обл, г Аксай",
    "postal_code": 346720,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Аксайский",
    "city_type": "г",
    "city": "Аксай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100300100000,
    "fias_id": "9bebf626-3ee7-4e1b-9e91-569c9d402152",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60202501000,
    "oktmo": 60602101001,
    "tax_office": 6181,
    "timezone": "UTC+3",
    "geo_lat": 47.2676314,
    "geo_lon": 39.8756872,
    "population": 41984,
    "foundation_year": 1957
  },
  {
    "address": "Ростовская обл, г Батайск",
    "postal_code": 346880,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Батайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000300000,
    "fias_id": "3809afb6-fdfd-4115-9e55-236abf108c81",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60407000000,
    "oktmo": 60707000001,
    "tax_office": 6181,
    "timezone": "UTC+3",
    "geo_lat": 47.1383299,
    "geo_lon": 39.7507179,
    "population": 112400,
    "foundation_year": 1938
  },
  {
    "address": "Ростовская обл, г Белая Калитва",
    "postal_code": 347040,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Белокалитвинский",
    "city_type": "г",
    "city": "Белая Калитва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100500100000,
    "fias_id": "2d38257c-c09e-405d-af01-93fc9cd3f6cd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60206501000,
    "oktmo": 60606101001,
    "tax_office": 6192,
    "timezone": "UTC+3",
    "geo_lat": 48.1769737,
    "geo_lon": 40.8033529,
    "population": 43688,
    "foundation_year": 1703
  },
  {
    "address": "Ростовская обл, г Волгодонск",
    "postal_code": 347360,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волгодонск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000400000,
    "fias_id": "1a453dcd-8885-4999-923b-1bbaa5a1cec4",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60412000000,
    "oktmo": 60712000001,
    "tax_office": 6100,
    "timezone": "UTC+3",
    "geo_lat": 47.5165338,
    "geo_lon": 42.1984951,
    "population": 170621,
    "foundation_year": 1950
  },
  {
    "address": "Ростовская обл, г Гуково",
    "postal_code": 347870,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Гуково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000500000,
    "fias_id": "eef55456-4d89-4620-b361-d620221ad3a7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60415000000,
    "oktmo": 60715000001,
    "tax_office": 6191,
    "timezone": "UTC+3",
    "geo_lat": 48.0448904,
    "geo_lon": 39.9484672,
    "population": 67268,
    "foundation_year": 1878
  },
  {
    "address": "Ростовская обл, г Донецк",
    "postal_code": 346330,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Донецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000600000,
    "fias_id": "8eeb1bf2-9de9-46d5-874f-50344ca9128b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60417000000,
    "oktmo": 60717000001,
    "tax_office": 6191,
    "timezone": "UTC+3",
    "geo_lat": 48.3350706,
    "geo_lon": 39.945891,
    "population": 50085,
    "foundation_year": 1681
  },
  {
    "address": "Ростовская обл, г Зверево",
    "postal_code": 346310,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Зверево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000700000,
    "fias_id": "99f4120c-2af0-4c97-afbc-d17fdf350ba6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60418000000,
    "oktmo": 60718000001,
    "tax_office": 6191,
    "timezone": "UTC+3",
    "geo_lat": 48.0435487,
    "geo_lon": 40.1265822,
    "population": 22416,
    "foundation_year": 1819
  },
  {
    "address": "Ростовская обл, г Зерноград",
    "postal_code": 347740,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Зерноградский",
    "city_type": "г",
    "city": "Зерноград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6101300100000,
    "fias_id": "c07d0c34-eadb-4102-bcef-7dd1a3ba27c3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60218501000,
    "oktmo": 60618101001,
    "tax_office": 6188,
    "timezone": "UTC+3",
    "geo_lat": 46.8495958,
    "geo_lon": 40.312837,
    "population": 26850,
    "foundation_year": 1929
  },
  {
    "address": "Ростовская обл, г Каменск-Шахтинский",
    "postal_code": 347800,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Каменск-Шахтинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000800000,
    "fias_id": "76cb0cf6-49a5-4852-b45d-99e4ce12a7ea",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60419000000,
    "oktmo": 60719000001,
    "tax_office": 6191,
    "timezone": "UTC+3",
    "geo_lat": 48.3205326,
    "geo_lon": 40.2689583,
    "population": 95306,
    "foundation_year": 1671
  },
  {
    "address": "Ростовская обл, г Константиновск",
    "postal_code": 347250,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Константиновский",
    "city_type": "г",
    "city": "Константиновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6101800100000,
    "fias_id": "89de999c-b58a-4d76-94e5-dc6152813b10",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60225501000,
    "oktmo": 60625101001,
    "tax_office": 6174,
    "timezone": "UTC+3",
    "geo_lat": 47.5773717,
    "geo_lon": 41.0967445,
    "population": 17926,
    "foundation_year": 1864
  },
  {
    "address": "Ростовская обл, г Красный Сулин",
    "postal_code": 346350,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Красносулинский",
    "city_type": "г",
    "city": "Красный Сулин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6101900100000,
    "fias_id": "687f3695-dcc4-4591-b8b9-a316f46a459a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60226501000,
    "oktmo": 60626101001,
    "tax_office": 6191,
    "timezone": "UTC+3",
    "geo_lat": 47.8831311,
    "geo_lon": 40.0781298,
    "population": 40866,
    "foundation_year": 1797
  },
  {
    "address": "Ростовская обл, г Миллерово",
    "postal_code": 346130,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Миллеровский",
    "city_type": "г",
    "city": "Миллерово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6102300100000,
    "fias_id": "ac927a82-e1dc-4485-b0e0-59f019b8f295",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60232501000,
    "oktmo": 60632101001,
    "tax_office": 6173,
    "timezone": "UTC+3",
    "geo_lat": 48.9260077,
    "geo_lon": 40.3984087,
    "population": 36493,
    "foundation_year": 1786
  },
  {
    "address": "Ростовская обл, г Морозовск",
    "postal_code": 347210,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Морозовский",
    "city_type": "г",
    "city": "Морозовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6102500100000,
    "fias_id": "933cca52-9ab1-490e-b674-ec3789233e28",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60234501000,
    "oktmo": 60634101001,
    "tax_office": 6192,
    "timezone": "UTC+3",
    "geo_lat": 48.3511807,
    "geo_lon": 41.8309225,
    "population": 27644,
    "foundation_year": 1910
  },
  {
    "address": "Ростовская обл, г Новочеркасск",
    "postal_code": 346400,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новочеркасск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000900000,
    "fias_id": "28bafcb3-92b2-445b-9443-a341be73fdb9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60427000000,
    "oktmo": 60727000001,
    "tax_office": 6183,
    "timezone": "UTC+3",
    "geo_lat": 47.411919,
    "geo_lon": 40.1042098,
    "population": 169039,
    "foundation_year": 1805
  },
  {
    "address": "Ростовская обл, г Новошахтинск",
    "postal_code": 346900,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новошахтинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100001000000,
    "fias_id": "bce1a4f2-7576-4427-8bd8-8d8b4e35ad11",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60430000000,
    "oktmo": 60730000001,
    "tax_office": 6182,
    "timezone": "UTC+3",
    "geo_lat": 47.757773,
    "geo_lon": 39.9363697,
    "population": 111087,
    "foundation_year": 1910
  },
  {
    "address": "Ростовская обл, г Пролетарск",
    "postal_code": 347540,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Пролетарский",
    "city_type": "г",
    "city": "Пролетарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6103200100000,
    "fias_id": "efa48912-9bcc-42ac-959d-289e9a75b046",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60245501000,
    "oktmo": 60645101001,
    "tax_office": 6186,
    "timezone": "UTC+3",
    "geo_lat": 46.7038963,
    "geo_lon": 41.727594,
    "population": 20267,
    "foundation_year": 1600
  },
  {
    "address": "г Ростов-на-Дону",
    "postal_code": 344000,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ростов-на-Дону",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100000100000,
    "fias_id": "c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 60401000000,
    "oktmo": 60701000001,
    "tax_office": 6100,
    "timezone": "UTC+3",
    "geo_lat": 47.2224566,
    "geo_lon": 39.718803,
    "population": 1091544,
    "foundation_year": 1749
  },
  {
    "address": "Ростовская обл, г Сальск",
    "postal_code": 347630,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Сальский",
    "city_type": "г",
    "city": "Сальск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6103500100000,
    "fias_id": "971ba684-f38b-4229-ba72-0d6fe8e179c4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60250501000,
    "oktmo": 60650101001,
    "tax_office": 6186,
    "timezone": "UTC+3",
    "geo_lat": 46.4752095,
    "geo_lon": 41.5410415,
    "population": 61312,
    "foundation_year": 1812
  },
  {
    "address": "Ростовская обл, г Семикаракорск",
    "postal_code": 346630,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Семикаракорский",
    "city_type": "г",
    "city": "Семикаракорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6103600100000,
    "fias_id": "29b72146-2e5d-4841-a1c3-ce2c3f47aa35",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60251501000,
    "oktmo": 60651101001,
    "tax_office": 6183,
    "timezone": "UTC+3",
    "geo_lat": 47.5177337,
    "geo_lon": 40.8114167,
    "population": 23884,
    "foundation_year": 1672
  },
  {
    "address": "Ростовская обл, г Таганрог",
    "postal_code": 347900,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Таганрог",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100001100000,
    "fias_id": "d72c95ed-9fdd-4f27-b94f-898fc3f1177d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60437000000,
    "oktmo": 60737000001,
    "tax_office": 6154,
    "timezone": "UTC+3",
    "geo_lat": 47.2094917,
    "geo_lon": 38.9350989,
    "population": 257692,
    "foundation_year": 1698
  },
  {
    "address": "Ростовская обл, г Цимлянск",
    "postal_code": 347320,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "р-н",
    "area": "Цимлянский",
    "city_type": "г",
    "city": "Цимлянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6104200100000,
    "fias_id": "27095e32-973c-4d07-929a-c46f63076ea8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 60257501000,
    "oktmo": 60657101001,
    "tax_office": 6174,
    "timezone": "UTC+3",
    "geo_lat": 47.6477448,
    "geo_lon": 42.0931505,
    "population": 15029,
    "foundation_year": 1672
  },
  {
    "address": "Ростовская обл, г Шахты",
    "postal_code": 346500,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "обл",
    "region": "Ростовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шахты",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6100001200000,
    "fias_id": "dee2e80e-f2e1-4a68-93b0-b7b89b6f3e74",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 60440000000,
    "oktmo": 60740000001,
    "tax_office": 6182,
    "timezone": "UTC+3",
    "geo_lat": 47.7085287,
    "geo_lon": 40.2159846,
    "population": 240152,
    "foundation_year": 1805
  },
  {
    "address": "Рязанская обл, г Касимов",
    "postal_code": 391300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Касимов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200000400000,
    "fias_id": "5ad034ad-85b5-4106-be01-3d6e8b08cb2c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61405000000,
    "oktmo": 61705000001,
    "tax_office": 6226,
    "timezone": "UTC+3",
    "geo_lat": 54.9373475,
    "geo_lon": 41.3913211,
    "population": 33494,
    "foundation_year": 1152
  },
  {
    "address": "Рязанская обл, г Кораблино",
    "postal_code": 391200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Кораблинский",
    "city_type": "г",
    "city": "Кораблино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200700100000,
    "fias_id": "8ea51b23-4bd6-401a-8e5a-0b3ecaa30081",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61212501000,
    "oktmo": 61612101001,
    "tax_office": 6214,
    "timezone": "UTC+3",
    "geo_lat": 53.9246659,
    "geo_lon": 40.0227745,
    "population": 12657,
    "foundation_year": 1676
  },
  {
    "address": "Рязанская обл, г Михайлов",
    "postal_code": 391710,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Михайловский",
    "city_type": "г",
    "city": "Михайлов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200900100000,
    "fias_id": "8afcc513-bb8b-45a9-ac0d-ce8cb43b4c60",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61217501000,
    "oktmo": 61617101001,
    "tax_office": 6219,
    "timezone": "UTC+3",
    "geo_lat": 54.2297402,
    "geo_lon": 39.0091481,
    "population": 11783,
    "foundation_year": 1137
  },
  {
    "address": "Рязанская обл, Пронский р-н, г Новомичуринск",
    "postal_code": 391160,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Пронский",
    "city_type": "г",
    "city": "Новомичуринск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6201200200000,
    "fias_id": "dc0c31cd-e03e-4fc3-a714-1c9f4b61cc7e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 61225514000,
    "oktmo": 61625114001,
    "tax_office": 6214,
    "timezone": "UTC+3",
    "geo_lat": 54.0376572,
    "geo_lon": 39.74654,
    "population": 19309,
    "foundation_year": 1968
  },
  {
    "address": "Рязанская обл, г Рыбное",
    "postal_code": 391110,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Рыбновский",
    "city_type": "г",
    "city": "Рыбное",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6201400100000,
    "fias_id": "91d71d8c-2f1a-43c8-bc84-d78dc4245a1b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61227501000,
    "oktmo": 61627101001,
    "tax_office": 6200,
    "timezone": "UTC+3",
    "geo_lat": 54.7256164,
    "geo_lon": 39.5134398,
    "population": 18378,
    "foundation_year": 1597
  },
  {
    "address": "Рязанская обл, г Ряжск",
    "postal_code": 391960,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Ряжский",
    "city_type": "г",
    "city": "Ряжск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6201500100000,
    "fias_id": "5fdbcd60-a39f-4e15-94ea-ea2c7faba0ce",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61230501000,
    "oktmo": 61630101,
    "tax_office": 6214,
    "timezone": "UTC+3",
    "geo_lat": 53.7067584,
    "geo_lon": 40.0522274,
    "population": 21676,
    "foundation_year": 1502
  },
  {
    "address": "г Рязань",
    "postal_code": 390000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Рязань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200000100000,
    "fias_id": "86e5bae4-ef58-4031-b34f-5e9ff914cd55",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 61401000000,
    "oktmo": 61701000001,
    "tax_office": 6200,
    "timezone": "UTC+3",
    "geo_lat": 54.6254445,
    "geo_lon": 39.7358609,
    "population": 525062,
    "foundation_year": 1095
  },
  {
    "address": "Рязанская обл, г Сасово",
    "postal_code": 391430,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сасово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200000200000,
    "fias_id": "23c99789-de53-408e-b38d-09c5539cce2f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 61410000000,
    "oktmo": 61710000001,
    "tax_office": 6232,
    "timezone": "UTC+3",
    "geo_lat": 54.3508885,
    "geo_lon": 41.9117422,
    "population": 28117,
    "foundation_year": 1642
  },
  {
    "address": "Рязанская обл, г Скопин",
    "postal_code": 391800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Скопин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200000300000,
    "fias_id": "1ab7c980-83c4-46c6-b6bf-fc0d6b64b56a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61415000000,
    "oktmo": 61715000001,
    "tax_office": 6219,
    "timezone": "UTC+3",
    "geo_lat": 53.823543,
    "geo_lon": 39.5492421,
    "population": 30374,
    "foundation_year": 1676
  },
  {
    "address": "Рязанская обл, г Спас-Клепики",
    "postal_code": 391030,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Клепиковский",
    "city_type": "г",
    "city": "Спас-Клепики",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6200600100000,
    "fias_id": "3d67fdec-69f3-461a-bdbc-7455cb849545",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61210501000,
    "oktmo": 61610101001,
    "tax_office": 6226,
    "timezone": "UTC+3",
    "geo_lat": 55.1291721,
    "geo_lon": 40.1745338,
    "population": 5917,
    "foundation_year": 1500
  },
  {
    "address": "Рязанская обл, г Спасск-Рязанский",
    "postal_code": 391050,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Спасский",
    "city_type": "г",
    "city": "Спасск-Рязанский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6202000100000,
    "fias_id": "94484f92-28a1-4880-a723-0af2f4fce2ca",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61246501000,
    "oktmo": 61646101001,
    "tax_office": 6215,
    "timezone": "UTC+3",
    "geo_lat": 54.4070719,
    "geo_lon": 40.3763426,
    "population": 7745,
    "foundation_year": 1629
  },
  {
    "address": "Рязанская обл, г Шацк",
    "postal_code": 391550,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Рязанская",
    "area_type": "р-н",
    "area": "Шацкий",
    "city_type": "г",
    "city": "Шацк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6202400100000,
    "fias_id": "04b8181c-124d-495d-b8ef-825518186df9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 61256501000,
    "oktmo": 61656101001,
    "tax_office": 6225,
    "timezone": "UTC+3",
    "geo_lat": 54.0287312,
    "geo_lon": 41.7181803,
    "population": 6562,
    "foundation_year": 1553
  },
  {
    "address": "Самарская обл, г Жигулевск",
    "postal_code": 445350,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Жигулевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000200000,
    "fias_id": "2f44f8ee-a505-46bf-b6de-6648a166295e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36404000000,
    "oktmo": 36704000001,
    "tax_office": 6382,
    "timezone": "UTC+4",
    "geo_lat": 53.4011981,
    "geo_lon": 49.4945176,
    "population": 57565,
    "foundation_year": 1949
  },
  {
    "address": "Самарская обл, г Кинель",
    "postal_code": 446430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кинель",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300001000000,
    "fias_id": "8ca7cbe5-a14b-416a-974a-dfa56e7f396a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36408000000,
    "oktmo": 36708000001,
    "tax_office": 6375,
    "timezone": "UTC+4",
    "geo_lat": 53.2210298,
    "geo_lon": 50.6343776,
    "population": 34472,
    "foundation_year": 1837
  },
  {
    "address": "Самарская обл, г Нефтегорск",
    "postal_code": 446600,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "р-н",
    "area": "Нефтегорский",
    "city_type": "г",
    "city": "Нефтегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6301700100000,
    "fias_id": "43406101-21a4-4a07-ac5a-0a686f9e8d1c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 36230501000,
    "oktmo": 36630101001,
    "tax_office": 6375,
    "timezone": "UTC+4",
    "geo_lat": 52.7972914,
    "geo_lon": 51.1637392,
    "population": 18732,
    "foundation_year": 1960
  },
  {
    "address": "Самарская обл, г Новокуйбышевск",
    "postal_code": 446200,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новокуйбышевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000300000,
    "fias_id": "cda74a83-ae08-4ba2-b01c-c6fc373222cb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36413000000,
    "oktmo": 36713000001,
    "tax_office": 6330,
    "timezone": "UTC+4",
    "geo_lat": 53.0994565,
    "geo_lon": 49.9477382,
    "population": 108449,
    "foundation_year": 1946
  },
  {
    "address": "Самарская обл, г Октябрьск",
    "postal_code": 445240,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Октябрьск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000400000,
    "fias_id": "b9a43610-5c01-4ba8-8de7-95facc7e460d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36418000000,
    "oktmo": 36718000001,
    "tax_office": 6325,
    "timezone": "UTC+4",
    "geo_lat": 53.1640488,
    "geo_lon": 48.670762,
    "population": 27244,
    "foundation_year": 1684
  },
  {
    "address": "Самарская обл, г Отрадный",
    "postal_code": 446300,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Отрадный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000500000,
    "fias_id": "05edd5aa-ab64-4afa-b56c-fd5661a14129",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36424000000,
    "oktmo": 36724000001,
    "tax_office": 6372,
    "timezone": "UTC+4",
    "geo_lat": 53.3800848,
    "geo_lon": 51.3438605,
    "population": 47709,
    "foundation_year": 1920
  },
  {
    "address": "Самарская обл, г Похвистнево",
    "postal_code": 446450,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Похвистнево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000900000,
    "fias_id": "ddb9cec8-ee07-4788-b80d-39535aea78f9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36427000000,
    "oktmo": 36727000001,
    "tax_office": 6372,
    "timezone": "UTC+4",
    "geo_lat": 53.6498299,
    "geo_lon": 52.1235156,
    "population": 28181,
    "foundation_year": 1888
  },
  {
    "address": "г Самара",
    "postal_code": 443000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Самара",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000100000,
    "fias_id": "bb035cc3-1dc2-4627-9d25-a1bf2d4b936b",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 36401000000,
    "oktmo": 36701000,
    "tax_office": 6300,
    "timezone": "UTC+4",
    "geo_lat": 53.1950306,
    "geo_lon": 50.1069518,
    "population": 1164900,
    "foundation_year": 1586
  },
  {
    "address": "Самарская обл, г Сызрань",
    "postal_code": 446000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сызрань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000800000,
    "fias_id": "e9e684ce-7d60-4480-ba14-ca6da658188b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36435000000,
    "oktmo": 36735000001,
    "tax_office": 6325,
    "timezone": "UTC+4",
    "geo_lat": 53.1558674,
    "geo_lon": 48.4744629,
    "population": 178773,
    "foundation_year": 1683
  },
  {
    "address": "Самарская обл, г Тольятти",
    "postal_code": 445000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тольятти",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000700000,
    "fias_id": "242e87c1-584d-4360-8c4c-aae2fe90048e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36440000000,
    "oktmo": 36740000001,
    "tax_office": 6300,
    "timezone": "UTC+4",
    "geo_lat": 53.5205348,
    "geo_lon": 49.3894028,
    "population": 719484,
    "foundation_year": 1737
  },
  {
    "address": "Самарская обл, г Чапаевск",
    "postal_code": 446100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Самарская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чапаевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6300000600000,
    "fias_id": "90a11c8e-c39f-4bcd-9df4-f89bed18958c",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 36450000000,
    "oktmo": 36750000001,
    "tax_office": 6330,
    "timezone": "UTC+4",
    "geo_lat": 52.928961,
    "geo_lon": 49.8673269,
    "population": 72689,
    "foundation_year": 1909
  },
  {
    "address": "г Санкт-Петербург",
    "postal_code": 190000,
    "country": "Россия",
    "federal_district": "Северо-Западный",
    "region_type": "г",
    "region": "Санкт-Петербург",
    "area_type": "",
    "area": "",
    "city_type": "",
    "city": "Санкт-Петербург",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7800000000000,
    "fias_id": "c2deb16a-0330-4f05-821f-1d09c93331e6",
    "fias_level": 1,
    "capital_marker": 0,
    "okato": 40000000000,
    "oktmo": 40000000,
    "tax_office": 7800,
    "timezone": "UTC+3",
    "geo_lat": 59.9391313,
    "geo_lon": 30.3159004,
    "population": 4848742,
    "foundation_year": 1703
  },
  {
    "address": "Саратовская обл, г Аркадак",
    "postal_code": 412210,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Аркадакский",
    "city_type": "г",
    "city": "Аркадак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400300100000,
    "fias_id": "9b58edf4-1ee2-43e0-9df9-d93d48a66ad1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63203501000,
    "oktmo": 63603101001,
    "tax_office": 6440,
    "timezone": "UTC+4",
    "geo_lat": 51.938831,
    "geo_lon": 43.4999221,
    "population": 12846,
    "foundation_year": 1721
  },
  {
    "address": "Саратовская обл, г Аткарск",
    "postal_code": 412420,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Аткарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000300000,
    "fias_id": "6f9e3e0b-3872-4abf-a2ba-23ae4b196a73",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63204501000,
    "oktmo": 63604101,
    "tax_office": 6438,
    "timezone": "UTC+4",
    "geo_lat": 51.8736062,
    "geo_lon": 45.0003247,
    "population": 25620,
    "foundation_year": 1700
  },
  {
    "address": "Саратовская обл, г Балаково",
    "postal_code": 413840,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Балаково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000400000,
    "fias_id": "45eea4e7-2977-44bb-9b3d-ef8e9198e5d2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63207501000,
    "oktmo": 63607101001,
    "tax_office": 6439,
    "timezone": "UTC+4",
    "geo_lat": 52.0224231,
    "geo_lon": 47.7827765,
    "population": 199576,
    "foundation_year": 1762
  },
  {
    "address": "Саратовская обл, г Балашов",
    "postal_code": 412300,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Балашов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000500000,
    "fias_id": "574ddbe6-5e16-4e5b-9cf5-5f3be3a8a6c9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63208501000,
    "oktmo": 63608101001,
    "tax_office": 6440,
    "timezone": "UTC+4",
    "geo_lat": 51.5388697,
    "geo_lon": 43.1839757,
    "population": 82222,
    "foundation_year": 1600
  },
  {
    "address": "Саратовская обл, г Вольск",
    "postal_code": 412900,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Вольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000600000,
    "fias_id": "83f29047-8dd9-411d-ac4b-c5811ea815f1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63211501000,
    "oktmo": 63611101001,
    "tax_office": 6441,
    "timezone": "UTC+4",
    "geo_lat": 52.0459865,
    "geo_lon": 47.3873595,
    "population": 66520,
    "foundation_year": 1780
  },
  {
    "address": "Саратовская обл, г Ершов",
    "postal_code": 413500,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Ершовский",
    "city_type": "г",
    "city": "Ершов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6401400100000,
    "fias_id": "2f0ecdcb-c844-4de9-80cc-6d19795ccb3a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63217501000,
    "oktmo": 63617101001,
    "tax_office": 6413,
    "timezone": "UTC+4",
    "geo_lat": 51.3508505,
    "geo_lon": 48.2762226,
    "population": 21447,
    "foundation_year": 1893
  },
  {
    "address": "Саратовская обл, г Калининск",
    "postal_code": 412480,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Калининский",
    "city_type": "г",
    "city": "Калининск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6401600100000,
    "fias_id": "634cee3a-db47-4b0f-9b90-8be1d5965db5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63221501000,
    "oktmo": 63621101001,
    "tax_office": 6438,
    "timezone": "UTC+4",
    "geo_lat": 51.4993591,
    "geo_lon": 44.4710435,
    "population": 16442,
    "foundation_year": 1680
  },
  {
    "address": "Саратовская обл, г Красноармейск",
    "postal_code": 412800,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноармейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000700000,
    "fias_id": "64954753-8e36-4948-9372-af3a08e88d57",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63222501000,
    "oktmo": 63622101001,
    "tax_office": 6432,
    "timezone": "UTC+4",
    "geo_lat": 51.023541,
    "geo_lon": 45.695044,
    "population": 24362,
    "foundation_year": 1765
  },
  {
    "address": "Саратовская обл, г Красный Кут",
    "postal_code": 413230,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Краснокутский",
    "city_type": "г",
    "city": "Красный Кут",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6401800100000,
    "fias_id": "94ff279a-6567-4ac1-8001-42d8fd89605e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63223501000,
    "oktmo": 63623101001,
    "tax_office": 6413,
    "timezone": "UTC+4",
    "geo_lat": 50.9598317,
    "geo_lon": 46.9712016,
    "population": 14420,
    "foundation_year": 1837
  },
  {
    "address": "Саратовская обл, г Маркс",
    "postal_code": 413090,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Маркс",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000800000,
    "fias_id": "c1a0ca30-8b42-4cc8-a16d-2d3392937621",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63226501000,
    "oktmo": 63626101001,
    "tax_office": 6449,
    "timezone": "UTC+4",
    "geo_lat": 51.7133337,
    "geo_lon": 46.7400339,
    "population": 31535,
    "foundation_year": 1918
  },
  {
    "address": "Саратовская обл, г Новоузенск",
    "postal_code": 413360,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Новоузенский",
    "city_type": "г",
    "city": "Новоузенск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6402300100000,
    "fias_id": "2d06120f-ba70-4866-b46e-ec2bad9c4f42",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63230501000,
    "oktmo": 63630101001,
    "tax_office": 6413,
    "timezone": "UTC+4",
    "geo_lat": 50.455199,
    "geo_lon": 48.1413153,
    "population": 17015,
    "foundation_year": 1760
  },
  {
    "address": "Саратовская обл, г Петровск",
    "postal_code": 412540,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "р-н",
    "area": "Петровский",
    "city_type": "г",
    "city": "Петровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6402600700000,
    "fias_id": "b5d97e65-e496-44d0-b025-398a8d43514a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63235501000,
    "oktmo": 63635101001,
    "tax_office": 6444,
    "timezone": "UTC+4",
    "geo_lat": 52.3094237,
    "geo_lon": 45.3851877,
    "population": 31158,
    "foundation_year": 1698
  },
  {
    "address": "Саратовская обл, г Пугачев",
    "postal_code": 413720,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пугачев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400001000000,
    "fias_id": "c1d4d969-0083-4b04-9f4b-e9eaeaefbd6f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 63237501000,
    "oktmo": 63637101001,
    "tax_office": 6445,
    "timezone": "UTC+4",
    "geo_lat": 52.0159921,
    "geo_lon": 48.7972223,
    "population": 41705,
    "foundation_year": 1764
  },
  {
    "address": "Саратовская обл, г Ртищево",
    "postal_code": 412030,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ртищево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400001100000,
    "fias_id": "d08bbea8-4fa3-4597-87ae-0bd79b4a89d2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63241501000,
    "oktmo": 63641101001,
    "tax_office": 6446,
    "timezone": "UTC+4",
    "geo_lat": 52.2616271,
    "geo_lon": 43.7842248,
    "population": 41295,
    "foundation_year": 1666
  },
  {
    "address": "г Саратов",
    "postal_code": 410000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саратов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000100000,
    "fias_id": "bf465fda-7834-47d5-986b-ccdb584a85a6",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 63401000000,
    "oktmo": 63701000001,
    "tax_office": 6400,
    "timezone": "UTC+4",
    "geo_lat": 51.533557,
    "geo_lon": 46.034257,
    "population": 836900,
    "foundation_year": 1590
  },
  {
    "address": "Саратовская обл, г Хвалынск",
    "postal_code": 412780,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Хвалынск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400001200000,
    "fias_id": "ac586a68-2f14-403e-9ce0-46c01832652d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63249501000,
    "oktmo": 63649101001,
    "tax_office": 6441,
    "timezone": "UTC+4",
    "geo_lat": 52.4951572,
    "geo_lon": 48.1045771,
    "population": 13199,
    "foundation_year": 1556
  },
  {
    "address": "Саратовская обл, г Шиханы",
    "postal_code": 412950,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шиханы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400000200000,
    "fias_id": "6c6b065e-9f1c-4afd-ac61-ff8338972818",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63448000000,
    "oktmo": 63746000001,
    "tax_office": 6441,
    "timezone": "UTC+4",
    "geo_lat": 52.1147391,
    "geo_lon": 47.2023118,
    "population": 6067,
    "foundation_year": 1928
  },
  {
    "address": "Саратовская обл, г Энгельс",
    "postal_code": 413100,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Саратовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Энгельс",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6400001300000,
    "fias_id": "c58d0505-54eb-4c34-8216-b14f7cdb0ecb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 63250501000,
    "oktmo": 63650101001,
    "tax_office": 6449,
    "timezone": "UTC+4",
    "geo_lat": 51.4854003,
    "geo_lon": 46.126722,
    "population": 202838,
    "foundation_year": 1747
  },
  {
    "address": "Сахалинская обл, г Александровск-Сахалинский",
    "postal_code": 694420,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Александровск-Сахалинский",
    "city_type": "г",
    "city": "Александровск-Сахалинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501800100000,
    "fias_id": "b56de39f-87a4-4c5c-a2b1-9ccb64039774",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64204501000,
    "oktmo": 64704000001,
    "tax_office": 6517,
    "timezone": "UTC+11",
    "geo_lat": 50.8974378,
    "geo_lon": 142.1578559,
    "population": 10613,
    "foundation_year": 1869
  },
  {
    "address": "Сахалинская обл, г Анива",
    "postal_code": 694030,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Анивский",
    "city_type": "г",
    "city": "Анива",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500200100000,
    "fias_id": "7e2a1112-2976-44b8-94a1-c0af2bad7d38",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64208501000,
    "oktmo": 64708000001,
    "tax_office": 6501,
    "timezone": "UTC+11",
    "geo_lat": 46.713152,
    "geo_lon": 142.5265804,
    "population": 8449,
    "foundation_year": 1886
  },
  {
    "address": "Сахалинская обл, г Долинск",
    "postal_code": 694051,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Долинский",
    "city_type": "г",
    "city": "Долинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500300100000,
    "fias_id": "d1e3874e-1b89-4000-b63e-2a0428adbcd8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64212000000,
    "oktmo": 64712000,
    "tax_office": 6504,
    "timezone": "UTC+11",
    "geo_lat": 47.3255783,
    "geo_lon": 142.7945071,
    "population": 12200,
    "foundation_year": 1884
  },
  {
    "address": "Сахалинская обл, г Корсаков",
    "postal_code": 694020,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Корсаковский",
    "city_type": "г",
    "city": "Корсаков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500400100000,
    "fias_id": "ba09681d-27a6-4784-8369-fa13da3057bf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64216501000,
    "oktmo": 64716000001,
    "tax_office": 6504,
    "timezone": "UTC+11",
    "geo_lat": 46.6324545,
    "geo_lon": 142.799445,
    "population": 33526,
    "foundation_year": 1853
  },
  {
    "address": "Сахалинская обл, г Курильск",
    "postal_code": 694530,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Курильский",
    "city_type": "г",
    "city": "Курильск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500500100000,
    "fias_id": "1e3fbbb8-0c90-4a49-a012-9d5813f4f032",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64220501000,
    "oktmo": 64720000001,
    "tax_office": 6504,
    "timezone": "UTC+11",
    "geo_lat": 45.2270954,
    "geo_lon": 147.8796323,
    "population": 2070,
    "foundation_year": 1700
  },
  {
    "address": "Сахалинская обл, г Макаров",
    "postal_code": 694140,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Макаровский",
    "city_type": "г",
    "city": "Макаров",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500600100000,
    "fias_id": "eeb17cd3-ea24-4d58-bd46-ad6c333d3de7",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64224501000,
    "oktmo": 64724000001,
    "tax_office": 6507,
    "timezone": "UTC+11",
    "geo_lat": 48.6236334,
    "geo_lon": 142.7803205,
    "population": 6788,
    "foundation_year": 1892
  },
  {
    "address": "Сахалинская обл, г Невельск",
    "postal_code": 694740,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Невельский",
    "city_type": "г",
    "city": "Невельск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500700100000,
    "fias_id": "2bdeaf4a-cbcc-497f-b558-3efc68434d76",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64228501000,
    "oktmo": 64728000001,
    "tax_office": 6509,
    "timezone": "UTC+11",
    "geo_lat": 46.6526899,
    "geo_lon": 141.8630725,
    "population": 11682,
    "foundation_year": 1946
  },
  {
    "address": "Сахалинская обл, г Оха",
    "postal_code": 694490,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Охинский",
    "city_type": "г",
    "city": "Оха",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500900100000,
    "fias_id": "273e561f-aa3b-4057-9225-862563c93287",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64236501000,
    "oktmo": 64736000001,
    "tax_office": 6517,
    "timezone": "UTC+11",
    "geo_lat": 53.5867839,
    "geo_lon": 142.9412411,
    "population": 23007,
    "foundation_year": 1908
  },
  {
    "address": "Сахалинская обл, г Поронайск",
    "postal_code": 694240,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Поронайский",
    "city_type": "г",
    "city": "Поронайск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501000100000,
    "fias_id": "10e40296-e5b1-4688-83d5-838eac25ae72",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64240501000,
    "oktmo": 64740000001,
    "tax_office": 6507,
    "timezone": "UTC+11",
    "geo_lat": 49.238866,
    "geo_lon": 143.1008333,
    "population": 16461,
    "foundation_year": 1869
  },
  {
    "address": "Сахалинская обл, г Северо-Курильск",
    "postal_code": 694550,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Северо-Курильский",
    "city_type": "г",
    "city": "Северо-Курильск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501100100000,
    "fias_id": "156ed642-9a53-4830-bec3-88f5e6dedd69",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64243501000,
    "oktmo": 64743000001,
    "tax_office": 6504,
    "timezone": "UTC+11",
    "geo_lat": 50.6730577,
    "geo_lon": 156.1282211,
    "population": 2381,
    "foundation_year": 1875
  },
  {
    "address": "Сахалинская обл, г Томари",
    "postal_code": 694820,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Томаринский",
    "city_type": "г",
    "city": "Томари",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501300100000,
    "fias_id": "06eb791a-25e9-4b8e-aaba-4a845b7394db",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64248501000,
    "oktmo": 64748000001,
    "tax_office": 6509,
    "timezone": "UTC+11",
    "geo_lat": 47.7620108,
    "geo_lon": 142.0615837,
    "population": 4537,
    "foundation_year": 1870
  },
  {
    "address": "Сахалинская обл, г Углегорск",
    "postal_code": 694920,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Углегорский",
    "city_type": "г",
    "city": "Углегорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501500100000,
    "fias_id": "c3da66fb-eb91-47c6-934c-b98ed003c82e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64252501000,
    "oktmo": 64752000001,
    "tax_office": 6509,
    "timezone": "UTC+11",
    "geo_lat": 49.0815774,
    "geo_lon": 142.0692639,
    "population": 11880,
    "foundation_year": 1900
  },
  {
    "address": "Сахалинская обл, г Холмск",
    "postal_code": 694620,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "р-н",
    "area": "Холмский",
    "city_type": "г",
    "city": "Холмск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6501600100000,
    "fias_id": "0d5fd8b6-9e5e-44e1-a341-fff1c3c9e1e0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 64254501000,
    "oktmo": 64754000001,
    "tax_office": 6509,
    "timezone": "UTC+11",
    "geo_lat": 47.0408423,
    "geo_lon": 142.041688,
    "population": 30936,
    "foundation_year": 1870
  },
  {
    "address": "г Южно-Сахалинск",
    "postal_code": 693000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "обл",
    "region": "Сахалинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Южно-Сахалинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6500000100000,
    "fias_id": "44388ad0-06aa-49b0-bbf9-1704629d1d68",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 64401000000,
    "oktmo": 64701000001,
    "tax_office": 6501,
    "timezone": "UTC+11",
    "geo_lat": 46.9591631,
    "geo_lon": 142.737976,
    "population": 181727,
    "foundation_year": 1882
  },
  {
    "address": "Свердловская обл, г Алапаевск",
    "postal_code": 624600,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Алапаевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002400000,
    "fias_id": "a4ab722e-453a-4aed-bb73-728a05e2e27f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65403000000,
    "oktmo": 65728000001,
    "tax_office": 6677,
    "timezone": "UTC+5",
    "geo_lat": 57.8475571,
    "geo_lon": 61.6693817,
    "population": 38198,
    "foundation_year": 1639
  },
  {
    "address": "Свердловская обл, Сысертский р-н, г Арамиль",
    "postal_code": 624000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Сысертский",
    "city_type": "г",
    "city": "Арамиль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6602500200000,
    "fias_id": "c99ffa11-6b7e-4040-9f9e-baa1eeca8a91",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65241503000,
    "oktmo": 65729000001,
    "tax_office": 6685,
    "timezone": "UTC+5",
    "geo_lat": 56.694632,
    "geo_lon": 60.8343125,
    "population": 14227,
    "foundation_year": 1675
  },
  {
    "address": "Свердловская обл, г Артемовский",
    "postal_code": 623780,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Артемовский",
    "city_type": "г",
    "city": "Артемовский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600300100000,
    "fias_id": "fdc6b0ad-4096-43e7-a2e9-16404d2e1f68",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65202501000,
    "oktmo": 65703000001,
    "tax_office": 6677,
    "timezone": "UTC+5",
    "geo_lat": 57.3384177,
    "geo_lon": 61.8947443,
    "population": 32878,
    "foundation_year": 1665
  },
  {
    "address": "Свердловская обл, г Асбест",
    "postal_code": 624260,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Асбест",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000200000,
    "fias_id": "f5fb13f3-c7d2-4075-821b-9cca03f1ae12",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65409000000,
    "oktmo": 65730000001,
    "tax_office": 6683,
    "timezone": "UTC+5",
    "geo_lat": 57.0052277,
    "geo_lon": 61.4581156,
    "population": 70067,
    "foundation_year": 1889
  },
  {
    "address": "Свердловская обл, г Березовский",
    "postal_code": 623700,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Березовский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000300000,
    "fias_id": "adf5df2b-2c2e-45a9-b971-05550353cf43",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65412000000,
    "oktmo": 65731000001,
    "tax_office": 6678,
    "timezone": "UTC+5",
    "geo_lat": 56.9095924,
    "geo_lon": 60.8180907,
    "population": 51583,
    "foundation_year": 1748
  },
  {
    "address": "Свердловская обл, Богдановичский р-н, г Богданович",
    "postal_code": 623530,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Богдановичский",
    "city_type": "г",
    "city": "Богданович",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600800100000,
    "fias_id": "0c5ca223-c100-4d91-b89b-33a59ba2dc69",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65210501000,
    "oktmo": 65707000001,
    "tax_office": 6633,
    "timezone": "UTC+5",
    "geo_lat": 56.7764795,
    "geo_lon": 62.0463679,
    "population": 31752,
    "foundation_year": 1885
  },
  {
    "address": "Свердловская обл, г Верхний Тагил",
    "postal_code": 624160,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Верхний Тагил",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003700000,
    "fias_id": "e76b32c8-a93e-4d3f-bae2-282dc900954a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65453503000,
    "oktmo": 65733000001,
    "tax_office": 6682,
    "timezone": "UTC+5",
    "geo_lat": 57.3763758,
    "geo_lon": 59.9517653,
    "population": 11843,
    "foundation_year": 1716
  },
  {
    "address": "Свердловская обл, г Верхняя Пышма",
    "postal_code": 624090,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Верхняя Пышма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000400000,
    "fias_id": "061cd8e0-b7bd-4a31-944e-a6e3da93631d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65420000000,
    "oktmo": 65732000001,
    "tax_office": 6686,
    "timezone": "UTC+5",
    "geo_lat": 56.9758903,
    "geo_lon": 60.5650383,
    "population": 58707,
    "foundation_year": 1854
  },
  {
    "address": "Свердловская обл, г Верхняя Салда",
    "postal_code": 624760,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Верхняя Салда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600004500000,
    "fias_id": "744462fb-a17d-43be-b71e-e7ecbd687561",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65211501000,
    "oktmo": 65708000001,
    "tax_office": 6623,
    "timezone": "UTC+5",
    "geo_lat": 58.0465803,
    "geo_lon": 60.5560164,
    "population": 46240,
    "foundation_year": 1778
  },
  {
    "address": "Свердловская обл, г Верхняя Тура",
    "postal_code": 624320,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Верхняя Тура",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600004000000,
    "fias_id": "4ffc4d96-d8fb-49c8-9e36-0989968d06e5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65470503000,
    "oktmo": 65734000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.3643685,
    "geo_lon": 59.8265235,
    "population": 9468,
    "foundation_year": 1737
  },
  {
    "address": "Свердловская обл, г Верхотурье",
    "postal_code": 624380,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Верхотурский",
    "city_type": "г",
    "city": "Верхотурье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6601000100000,
    "fias_id": "6a688b13-5231-4d5e-b5eb-ee14da03ac10",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65212501000,
    "oktmo": 65709000001,
    "tax_office": 6680,
    "timezone": "UTC+5",
    "geo_lat": 58.8622073,
    "geo_lon": 60.8103945,
    "population": 8815,
    "foundation_year": 1597
  },
  {
    "address": "Свердловская обл, г Волчанск",
    "postal_code": 624940,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Волчанск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003900000,
    "fias_id": "68edcd30-0b57-4699-a196-343b80d23223",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65445503000,
    "oktmo": 65735000001,
    "tax_office": 6617,
    "timezone": "UTC+5",
    "geo_lat": 59.9351707,
    "geo_lon": 60.0798618,
    "population": 10008,
    "foundation_year": 1956
  },
  {
    "address": "Свердловская обл, г Дегтярск",
    "postal_code": 623270,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Дегтярск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600004100000,
    "fias_id": "079bd9a9-7a84-4e6e-8a69-e73433e51e41",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65484503000,
    "oktmo": 65736000001,
    "tax_office": 6684,
    "timezone": "UTC+5",
    "geo_lat": 56.7048206,
    "geo_lon": 60.079138,
    "population": 15521,
    "foundation_year": 1914
  },
  {
    "address": "г Екатеринбург",
    "postal_code": 620000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Екатеринбург",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000100000,
    "fias_id": "2763c110-cb8b-416a-9dac-ad28a55b4402",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 65401000000,
    "oktmo": 65701000001,
    "tax_office": 6600,
    "timezone": "UTC+5",
    "geo_lat": 56.8385216,
    "geo_lon": 60.6054911,
    "population": 1377738,
    "foundation_year": 1723
  },
  {
    "address": "Свердловская обл, г Заречный",
    "postal_code": 624250,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Заречный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000500000,
    "fias_id": "8018ae42-55a5-4297-a126-b482aee0b634",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65426000000,
    "oktmo": 65737000001,
    "tax_office": 6683,
    "timezone": "UTC+5",
    "geo_lat": 56.8102931,
    "geo_lon": 61.3380029,
    "population": 26803,
    "foundation_year": 1955
  },
  {
    "address": "Свердловская обл, г Ивдель",
    "postal_code": 624590,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ивдель",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000600000,
    "fias_id": "c32d06c4-cca2-42f4-a3b3-b2fee48b9585",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65428000000,
    "oktmo": 65738000001,
    "tax_office": 6617,
    "timezone": "UTC+5",
    "geo_lat": 60.6944496,
    "geo_lon": 60.4245069,
    "population": 17764,
    "foundation_year": 1831
  },
  {
    "address": "Свердловская обл, г Ирбит",
    "postal_code": 623850,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ирбит",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002900000,
    "fias_id": "4a90bd67-7682-4012-a0c5-09865b9d08a0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65432000000,
    "oktmo": 65739000001,
    "tax_office": 6676,
    "timezone": "UTC+5",
    "geo_lat": 57.6838362,
    "geo_lon": 63.057675,
    "population": 38352,
    "foundation_year": 1631
  },
  {
    "address": "Свердловская обл, г Каменск-Уральский",
    "postal_code": 623400,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Каменск-Уральский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002200000,
    "fias_id": "ecc16584-9814-44d7-a0f3-c79601caa734",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65436000000,
    "oktmo": 65740000001,
    "tax_office": 6600,
    "timezone": "UTC+5",
    "geo_lat": 56.414962,
    "geo_lon": 61.9188674,
    "population": 174710,
    "foundation_year": 1682
  },
  {
    "address": "Свердловская обл, г Камышлов",
    "postal_code": 624860,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Камышлов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003000000,
    "fias_id": "ac2abd40-bb9b-41df-abd8-a319afd88f00",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65440000000,
    "oktmo": 65741000001,
    "tax_office": 6633,
    "timezone": "UTC+5",
    "geo_lat": 56.8465034,
    "geo_lon": 62.7119766,
    "population": 26875,
    "foundation_year": 1668
  },
  {
    "address": "Свердловская обл, г Карпинск",
    "postal_code": 624930,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Карпинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000700000,
    "fias_id": "a695f868-8b1d-412a-b011-3e1eb265aad5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65445000000,
    "oktmo": 65742000001,
    "tax_office": 6617,
    "timezone": "UTC+5",
    "geo_lat": 59.7665925,
    "geo_lon": 60.0011703,
    "population": 29118,
    "foundation_year": 1759
  },
  {
    "address": "Свердловская обл, г Качканар",
    "postal_code": 624350,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Качканар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000800000,
    "fias_id": "c6d37528-b353-43eb-81d8-64a4fd1612f5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65448000000,
    "oktmo": 65743000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.7051762,
    "geo_lon": 59.4839155,
    "population": 42563,
    "foundation_year": 1957
  },
  {
    "address": "Свердловская обл, г Кировград",
    "postal_code": 624140,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кировград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600000900000,
    "fias_id": "dd3f07bd-0109-47e0-8a0a-64928436beed",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65453000000,
    "oktmo": 65744000001,
    "tax_office": 6682,
    "timezone": "UTC+5",
    "geo_lat": 57.4299433,
    "geo_lon": 60.0624051,
    "population": 21959,
    "foundation_year": 1661
  },
  {
    "address": "Свердловская обл, г Краснотурьинск",
    "postal_code": 624440,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Краснотурьинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001000000,
    "fias_id": "59b1c816-7f98-4707-b5e2-c6193fff8923",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65456000000,
    "oktmo": 65745000001,
    "tax_office": 6617,
    "timezone": "UTC+5",
    "geo_lat": 59.7636635,
    "geo_lon": 60.1934525,
    "population": 59701,
    "foundation_year": 1758
  },
  {
    "address": "Свердловская обл, г Красноуральск",
    "postal_code": 624330,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001100000,
    "fias_id": "3c6a6e0c-29d7-409b-9d2a-135994cf5e97",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65460000000,
    "oktmo": 65746000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.348651,
    "geo_lon": 60.0408764,
    "population": 24973,
    "foundation_year": 1925
  },
  {
    "address": "Свердловская обл, г Красноуфимск",
    "postal_code": 623300,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красноуфимск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003100000,
    "fias_id": "ddb355ab-7c2a-4f86-b388-6afbd81a7e73",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65468000000,
    "oktmo": 65747000001,
    "tax_office": 6619,
    "timezone": "UTC+5",
    "geo_lat": 56.612387,
    "geo_lon": 57.7636637,
    "population": 39765,
    "foundation_year": 1736
  },
  {
    "address": "Свердловская обл, г Кушва",
    "postal_code": 624300,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кушва",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001200000,
    "fias_id": "c2b7db39-9ef3-4d9d-a109-a3870b583d45",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65470000000,
    "oktmo": 65748000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.2826013,
    "geo_lon": 59.7645766,
    "population": 33027,
    "foundation_year": 1735
  },
  {
    "address": "Свердловская обл, г Лесной",
    "postal_code": 624200,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лесной",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001300000,
    "fias_id": "26c6f82c-8503-4c38-9009-52815c8f4a9b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65542000000,
    "oktmo": 65749000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.6348516,
    "geo_lon": 59.7981565,
    "population": 52464,
    "foundation_year": 1947
  },
  {
    "address": "Свердловская обл, Нижнесергинский р-н, г Михайловск",
    "postal_code": 623080,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Нижнесергинский",
    "city_type": "г",
    "city": "Михайловск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6601700200000,
    "fias_id": "177c10d7-f36f-449e-a94b-5f7f45a0dbc8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65228504000,
    "oktmo": 65628104001,
    "tax_office": 6619,
    "timezone": "UTC+5",
    "geo_lat": 56.4370039,
    "geo_lon": 59.1137316,
    "population": 9215,
    "foundation_year": 1805
  },
  {
    "address": "Свердловская обл, г Невьянск",
    "postal_code": 624191,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Невьянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600004300000,
    "fias_id": "aedb1d40-4877-49c6-8bcb-e2ec6b6cb410",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65227501000,
    "oktmo": 65714000001,
    "tax_office": 6682,
    "timezone": "UTC+5",
    "geo_lat": 57.49131,
    "geo_lon": 60.2183429,
    "population": 25147,
    "foundation_year": 1701
  },
  {
    "address": "Свердловская обл, г Нижние Серги",
    "postal_code": 623090,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Нижнесергинский",
    "city_type": "г",
    "city": "Нижние Серги",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6601700100000,
    "fias_id": "9f066e0f-7799-4eb9-a301-ef12719d7c03",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65228501000,
    "oktmo": 65628101,
    "tax_office": 6619,
    "timezone": "UTC+5",
    "geo_lat": 56.6544959,
    "geo_lon": 59.2953035,
    "population": 11217,
    "foundation_year": 1743
  },
  {
    "address": "Свердловская обл, г Нижний Тагил",
    "postal_code": 622000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижний Тагил",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002300000,
    "fias_id": "cc73d6af-6e2e-4a1f-be8e-682c289b0b57",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65476000000,
    "oktmo": 65751000001,
    "tax_office": 6623,
    "timezone": "UTC+5",
    "geo_lat": 57.910126,
    "geo_lon": 59.9812853,
    "population": 361883,
    "foundation_year": 1722
  },
  {
    "address": "Свердловская обл, г Нижняя Салда",
    "postal_code": 624740,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижняя Салда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002700000,
    "fias_id": "b4326e03-bab5-4188-a8c1-97d69858c514",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65477000000,
    "oktmo": 65750000001,
    "tax_office": 6623,
    "timezone": "UTC+5",
    "geo_lat": 58.0748272,
    "geo_lon": 60.7025418,
    "population": 17969,
    "foundation_year": 1760
  },
  {
    "address": "Свердловская обл, г Нижняя Тура",
    "postal_code": 624220,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижняя Тура",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001400000,
    "fias_id": "5591594f-f2a4-49b0-a3bc-22307f192c4e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65478000000,
    "oktmo": 65715000001,
    "tax_office": 6681,
    "timezone": "UTC+5",
    "geo_lat": 58.6309267,
    "geo_lon": 59.8520344,
    "population": 21596,
    "foundation_year": 1754
  },
  {
    "address": "Свердловская обл, г Новая Ляля",
    "postal_code": 624400,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Новолялинский",
    "city_type": "г",
    "city": "Новая Ляля",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6601800100000,
    "fias_id": "afff2611-ca9b-4e0e-9690-f5d13b7a7535",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65229501000,
    "oktmo": 65716000001,
    "tax_office": 6680,
    "timezone": "UTC+5",
    "geo_lat": 59.0538977,
    "geo_lon": 60.5944825,
    "population": 12400,
    "foundation_year": 1903
  },
  {
    "address": "Свердловская обл, г Новоуральск",
    "postal_code": 624130,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новоуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001500000,
    "fias_id": "22fbcef5-5cab-4ef2-9cb5-fddd1479bc8e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65540000000,
    "oktmo": 65752000001,
    "tax_office": 6682,
    "timezone": "UTC+5",
    "geo_lat": 57.2472567,
    "geo_lon": 60.0956714,
    "population": 91813,
    "foundation_year": 1941
  },
  {
    "address": "Свердловская обл, г Первоуральск",
    "postal_code": 623100,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Первоуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001600000,
    "fias_id": "45bad628-61b7-4445-b62a-fa4c7bc71ccd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65480000000,
    "oktmo": 65753000001,
    "tax_office": 6684,
    "timezone": "UTC+5",
    "geo_lat": 56.9080085,
    "geo_lon": 59.942926,
    "population": 149800,
    "foundation_year": 1732
  },
  {
    "address": "Свердловская обл, г Полевской",
    "postal_code": 623382,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Полевской",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001700000,
    "fias_id": "2a3b4b10-7035-4af5-9bbd-409c42b51eb7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65482000000,
    "oktmo": 65754000001,
    "tax_office": 6679,
    "timezone": "UTC+5",
    "geo_lat": 56.4956952,
    "geo_lon": 60.2365298,
    "population": 64316,
    "foundation_year": 1718
  },
  {
    "address": "Свердловская обл, г Ревда",
    "postal_code": 623280,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ревда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600001800000,
    "fias_id": "b1a4b393-ac99-4447-a11d-c3b454fa3d8e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65484000000,
    "oktmo": 65719000001,
    "tax_office": 6684,
    "timezone": "UTC+5",
    "geo_lat": 56.7986319,
    "geo_lon": 59.9071591,
    "population": 61713,
    "foundation_year": 1734
  },
  {
    "address": "Свердловская обл, г Реж",
    "postal_code": 623750,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Режевской",
    "city_type": "г",
    "city": "Реж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6602100100000,
    "fias_id": "e40dd081-584b-435c-b8ed-d44ab7854729",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65236501000,
    "oktmo": 65720000001,
    "tax_office": 6677,
    "timezone": "UTC+5",
    "geo_lat": 57.3717477,
    "geo_lon": 61.3833842,
    "population": 38709,
    "foundation_year": 1773
  },
  {
    "address": "Свердловская обл, г Североуральск",
    "postal_code": 624480,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Североуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600002100000,
    "fias_id": "862dddf0-0479-4ffb-bc2c-ee83d598e855",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65490000000,
    "oktmo": 65755000001,
    "tax_office": 6617,
    "timezone": "UTC+5",
    "geo_lat": 60.1533109,
    "geo_lon": 59.9525245,
    "population": 29279,
    "foundation_year": 1758
  },
  {
    "address": "Свердловская обл, г Серов",
    "postal_code": 624981,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Серов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003400000,
    "fias_id": "591f3e74-079f-42ff-a155-f01c9dc363ae",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65492000000,
    "oktmo": 65756000001,
    "tax_office": 6680,
    "timezone": "UTC+5",
    "geo_lat": 59.6047724,
    "geo_lon": 60.5753882,
    "population": 99381,
    "foundation_year": 1893
  },
  {
    "address": "Свердловская обл, г Среднеуральск",
    "postal_code": 624070,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Среднеуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600003800000,
    "fias_id": "7c470adb-7d3d-42a1-9190-8f3fd05f710d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65420505000,
    "oktmo": 65757000001,
    "tax_office": 6686,
    "timezone": "UTC+5",
    "geo_lat": 56.9918901,
    "geo_lon": 60.4771018,
    "population": 20357,
    "foundation_year": 1931
  },
  {
    "address": "Свердловская обл, Сухоложский р-н, г Сухой Лог",
    "postal_code": 624800,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Сухоложский",
    "city_type": "г",
    "city": "Сухой Лог",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6602400100000,
    "fias_id": "9e41e6fc-a2a3-4ee4-9f60-045cf22d45d0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65240501000,
    "oktmo": 65758000001,
    "tax_office": 6633,
    "timezone": "UTC+5",
    "geo_lat": 56.9076193,
    "geo_lon": 62.0358093,
    "population": 34836,
    "foundation_year": 1710
  },
  {
    "address": "Свердловская обл, г Сысерть",
    "postal_code": 624021,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Сысертский",
    "city_type": "г",
    "city": "Сысерть",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6602500100000,
    "fias_id": "93fdb310-5e08-43ee-aabe-42e39a950928",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65241501000,
    "oktmo": 65722000001,
    "tax_office": 6685,
    "timezone": "UTC+5",
    "geo_lat": 56.5005715,
    "geo_lon": 60.8190003,
    "population": 20594,
    "foundation_year": 1732
  },
  {
    "address": "Свердловская обл, г Тавда",
    "postal_code": 623950,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тавда",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6600004200000,
    "fias_id": "e3d340e7-15af-436c-a0f0-abfaf2108fca",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 65248501000,
    "oktmo": 65723000001,
    "tax_office": 6676,
    "timezone": "UTC+5",
    "geo_lat": 58.0434672,
    "geo_lon": 65.274217,
    "population": 35421,
    "foundation_year": 1910
  },
  {
    "address": "Свердловская обл, г Талица",
    "postal_code": 623640,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Талицкий",
    "city_type": "г",
    "city": "Талица",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6602800100000,
    "fias_id": "12a6ddb3-4f9e-44bf-9e24-77eb72cae2ed",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65249501000,
    "oktmo": 65724000001,
    "tax_office": 6633,
    "timezone": "UTC+5",
    "geo_lat": 57.0122687,
    "geo_lon": 63.7320757,
    "population": 18339,
    "foundation_year": 1732
  },
  {
    "address": "Свердловская обл, г Туринск",
    "postal_code": 623900,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Свердловская",
    "area_type": "р-н",
    "area": "Туринский",
    "city_type": "г",
    "city": "Туринск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6603000100000,
    "fias_id": "45a7312f-5c8b-46d9-b7ff-46d0efa63366",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 65254501000,
    "oktmo": 65726000001,
    "tax_office": 6676,
    "timezone": "UTC+5",
    "geo_lat": 58.0393524,
    "geo_lon": 63.6981973,
    "population": 17990,
    "foundation_year": 1600
  },
  {
    "address": "г Севастополь",
    "postal_code": 299700,
    "country": "Россия",
    "federal_district": "Южный",
    "region_type": "г",
    "region": "Севастополь",
    "area_type": "",
    "area": "",
    "city_type": "",
    "city": "Севастополь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 9200000000000,
    "fias_id": "6fdecb78-893a-4e3f-a5ba-aa062459463b",
    "fias_level": 1,
    "capital_marker": 0,
    "okato": 67000000000,
    "oktmo": 67000000,
    "tax_office": 9200,
    "timezone": "UTC+3",
    "geo_lat": 44.6167013,
    "geo_lon": 33.525355,
    "population": 393304,
    "foundation_year": 1783
  },
  {
    "address": "Респ Северная Осетия - Алания, г Алагир",
    "postal_code": 363240,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "р-н",
    "area": "Алагирский",
    "city_type": "г",
    "city": "Алагир",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500200100000,
    "fias_id": "e6dcf985-09c1-4ede-ba3d-de714f0a5787",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 90205501000,
    "oktmo": 90605101001,
    "tax_office": 1514,
    "timezone": "UTC+3",
    "geo_lat": 43.0417684,
    "geo_lon": 44.2199715,
    "population": 20949,
    "foundation_year": 1850
  },
  {
    "address": "Респ Северная Осетия - Алания, г Ардон",
    "postal_code": 363331,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "р-н",
    "area": "Ардонский",
    "city_type": "г",
    "city": "Ардон",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500300100000,
    "fias_id": "fc26547e-11ce-464b-b4f2-96b37df6f23f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 90210501000,
    "oktmo": 90610101001,
    "tax_office": 1514,
    "timezone": "UTC+3",
    "geo_lat": 43.1755152,
    "geo_lon": 44.2955775,
    "population": 18774,
    "foundation_year": 1824
  },
  {
    "address": "Респ Северная Осетия - Алания, г Беслан",
    "postal_code": 363020,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "р-н",
    "area": "Правобережный",
    "city_type": "г",
    "city": "Беслан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500800100000,
    "fias_id": "cbf3eb9d-f86c-4dbb-ba9e-f9f6521b096f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 90235501000,
    "oktmo": 90635101001,
    "tax_office": 1511,
    "timezone": "UTC+3",
    "geo_lat": 43.1937529,
    "geo_lon": 44.5338707,
    "population": 36724,
    "foundation_year": 1847
  },
  {
    "address": "г Владикавказ",
    "postal_code": 362000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Владикавказ",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500000100000,
    "fias_id": "20ea2341-4f49-4c5c-a9dc-a54688c8cc61",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 90401000000,
    "oktmo": 90701000001,
    "tax_office": 1513,
    "timezone": "UTC+3",
    "geo_lat": 43.020588,
    "geo_lon": 44.6819182,
    "population": 311635,
    "foundation_year": 1784
  },
  {
    "address": "Респ Северная Осетия - Алания, г Дигора",
    "postal_code": 363410,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "р-н",
    "area": "Дигорский",
    "city_type": "г",
    "city": "Дигора",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500400100000,
    "fias_id": "aa883f13-ea3c-4b7f-9f3c-309a2b38db04",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 90215501000,
    "oktmo": 90615101001,
    "tax_office": 1514,
    "timezone": "UTC+3",
    "geo_lat": 43.1567628,
    "geo_lon": 44.1549483,
    "population": 10856,
    "foundation_year": 1852
  },
  {
    "address": "Респ Северная Осетия - Алания, г Моздок",
    "postal_code": 363750,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Северная Осетия - Алания",
    "area_type": "р-н",
    "area": "Моздокский",
    "city_type": "г",
    "city": "Моздок",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1500700100000,
    "fias_id": "654ce513-3c99-414d-88b8-846097cc9ae3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 90230501000,
    "oktmo": 90630101001,
    "tax_office": 1510,
    "timezone": "UTC+3",
    "geo_lat": 43.7471342,
    "geo_lon": 44.6569607,
    "population": 38748,
    "foundation_year": 1763
  },
  {
    "address": "Смоленская обл, г Велиж",
    "postal_code": 216290,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Велижский",
    "city_type": "г",
    "city": "Велиж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700200100000,
    "fias_id": "7e0ce062-6798-4091-9b28-d1c30d369a36",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66203501000,
    "oktmo": 66603101001,
    "tax_office": 6713,
    "timezone": "UTC+3",
    "geo_lat": 55.6057916,
    "geo_lon": 31.1856206,
    "population": 7620,
    "foundation_year": 1536
  },
  {
    "address": "Смоленская обл, г Вязьма",
    "postal_code": 215110,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Вяземский",
    "city_type": "г",
    "city": "Вязьма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700300100000,
    "fias_id": "d2fa2230-a9f4-4b75-b21e-059026dd6ce5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66205501000,
    "oktmo": 66605101001,
    "tax_office": 6722,
    "timezone": "UTC+3",
    "geo_lat": 55.2116983,
    "geo_lon": 34.2951663,
    "population": 57103,
    "foundation_year": 1239
  },
  {
    "address": "Смоленская обл, г Гагарин",
    "postal_code": 215010,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Гагаринский",
    "city_type": "г",
    "city": "Гагарин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700400100000,
    "fias_id": "84f1f1f9-34a5-4a9f-9a0b-a2ce6a0fe242",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66208501000,
    "oktmo": 66608101001,
    "tax_office": 6722,
    "timezone": "UTC+3",
    "geo_lat": 55.5525228,
    "geo_lon": 34.9950502,
    "population": 31721,
    "foundation_year": 1705
  },
  {
    "address": "Смоленская обл, г Демидов",
    "postal_code": 216240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Демидовский",
    "city_type": "г",
    "city": "Демидов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700600100000,
    "fias_id": "ab27d164-4b35-48ca-95ba-66abb8977a76",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66211501000,
    "oktmo": 66611101001,
    "tax_office": 6713,
    "timezone": "UTC+3",
    "geo_lat": 55.2682105,
    "geo_lon": 31.5062809,
    "population": 7333,
    "foundation_year": 1499
  },
  {
    "address": "Смоленская обл, г Десногорск",
    "postal_code": 216400,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Десногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700000200000,
    "fias_id": "5e2b3d5b-cd14-4116-8837-8878b5ccc057",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 66410000000,
    "oktmo": 66710000001,
    "tax_office": 6725,
    "timezone": "UTC+3",
    "geo_lat": 54.146457,
    "geo_lon": 33.2833222,
    "population": 29677,
    "foundation_year": 1974
  },
  {
    "address": "Смоленская обл, г Дорогобуж",
    "postal_code": 215710,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Дорогобужский",
    "city_type": "г",
    "city": "Дорогобуж",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700700100000,
    "fias_id": "1c5ce84f-3f65-4b10-ac4d-2b44144cc234",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66214501000,
    "oktmo": 66614101001,
    "tax_office": 6726,
    "timezone": "UTC+3",
    "geo_lat": 54.9136959,
    "geo_lon": 33.3023162,
    "population": 10720,
    "foundation_year": 1150
  },
  {
    "address": "Смоленская обл, г Духовщина",
    "postal_code": 216200,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Духовщинский",
    "city_type": "г",
    "city": "Духовщина",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700800100000,
    "fias_id": "ccb8ae0f-9d62-4dec-9ab0-0bbb34cb9def",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66216501000,
    "oktmo": 66616101001,
    "tax_office": 6727,
    "timezone": "UTC+3",
    "geo_lat": 55.1950257,
    "geo_lon": 32.401252,
    "population": 4370,
    "foundation_year": 1675
  },
  {
    "address": "Смоленская обл, г Ельня",
    "postal_code": 216330,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Ельнинский",
    "city_type": "г",
    "city": "Ельня",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700900100000,
    "fias_id": "c1e4a479-76a2-4db4-9d24-835d659340a3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66219501000,
    "oktmo": 66619101001,
    "tax_office": 6725,
    "timezone": "UTC+3",
    "geo_lat": 54.5837795,
    "geo_lon": 33.1749867,
    "population": 10095,
    "foundation_year": 1150
  },
  {
    "address": "Смоленская обл, г Починок",
    "postal_code": 216450,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Починковский",
    "city_type": "г",
    "city": "Починок",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6701500100000,
    "fias_id": "323e5d97-d4d9-49b3-91ad-335b165a052a",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66233501000,
    "oktmo": 66633101001,
    "tax_office": 6725,
    "timezone": "UTC+3",
    "geo_lat": 54.406244,
    "geo_lon": 32.4398039,
    "population": 8776,
    "foundation_year": 1868
  },
  {
    "address": "Смоленская обл, г Рославль",
    "postal_code": 216500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Рославльский",
    "city_type": "г",
    "city": "Рославль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6701600100000,
    "fias_id": "2cbbc92b-0657-4d26-addb-0b2ebaf0c397",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66236501000,
    "oktmo": 66636101001,
    "tax_office": 6725,
    "timezone": "UTC+3",
    "geo_lat": 53.9449558,
    "geo_lon": 32.8480258,
    "population": 54898,
    "foundation_year": 1137
  },
  {
    "address": "Смоленская обл, г Рудня",
    "postal_code": 216790,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Руднянский",
    "city_type": "г",
    "city": "Рудня",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6701700100000,
    "fias_id": "d0f2d596-2e23-4072-b6c2-7adbb6c77d4b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66238501000,
    "oktmo": 66638101001,
    "tax_office": 6713,
    "timezone": "UTC+3",
    "geo_lat": 54.9441093,
    "geo_lon": 31.0794806,
    "population": 10029,
    "foundation_year": 1363
  },
  {
    "address": "Смоленская обл, г Сафоново",
    "postal_code": 215500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Сафоновский",
    "city_type": "г",
    "city": "Сафоново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6701800100000,
    "fias_id": "91fa6249-0711-454a-bf0d-341629234858",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66241501000,
    "oktmo": 66641101001,
    "tax_office": 6726,
    "timezone": "UTC+3",
    "geo_lat": 55.1199661,
    "geo_lon": 33.2336988,
    "population": 46116,
    "foundation_year": 1859
  },
  {
    "address": "г Смоленск",
    "postal_code": 214000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Смоленск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6700000300000,
    "fias_id": "d414a2e8-9e1e-48c1-94a4-7308d5608177",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 66401000000,
    "oktmo": 66701000001,
    "tax_office": 6732,
    "timezone": "UTC+3",
    "geo_lat": 54.782635,
    "geo_lon": 32.045251,
    "population": 326863,
    "foundation_year": 863
  },
  {
    "address": "Смоленская обл, г Сычевка",
    "postal_code": 215279,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Сычевский",
    "city_type": "г",
    "city": "Сычевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6701900100000,
    "fias_id": "b3522b83-3f83-4145-892f-5767415d82d3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66246501000,
    "oktmo": 66646101001,
    "tax_office": 6722,
    "timezone": "UTC+3",
    "geo_lat": 55.8308821,
    "geo_lon": 34.2778793,
    "population": 8111,
    "foundation_year": 1488
  },
  {
    "address": "Смоленская обл, г Ярцево",
    "postal_code": 215800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Смоленская",
    "area_type": "р-н",
    "area": "Ярцевский",
    "city_type": "г",
    "city": "Ярцево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6702500100000,
    "fias_id": "84588847-9e01-42c0-86cc-5ee2c819aeaf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 66258501000,
    "oktmo": 66658101001,
    "tax_office": 6727,
    "timezone": "UTC+3",
    "geo_lat": 55.0564732,
    "geo_lon": 32.6902302,
    "population": 47853,
    "foundation_year": 1610
  },
  {
    "address": "Ставропольский край, г Благодарный",
    "postal_code": 356420,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Благодарненский",
    "city_type": "г",
    "city": "Благодарный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600600100000,
    "fias_id": "0e527607-a1cd-462c-9b85-f33ddea64ad0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07210501000",
    "oktmo": "07705000001",
    "tax_office": 2646,
    "timezone": "UTC+3",
    "geo_lat": 45.0989782,
    "geo_lon": 43.4306455,
    "population": 32736,
    "foundation_year": 1782
  },
  {
    "address": "Ставропольский край, г Буденновск",
    "postal_code": 356800,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Буденновский",
    "city_type": "г",
    "city": "Буденновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600700100000,
    "fias_id": "bcca8fda-282d-4a90-9303-7a37a8197242",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07402000000",
    "oktmo": "07512000001",
    "tax_office": 2646,
    "timezone": "UTC+3",
    "geo_lat": 44.7816067,
    "geo_lon": 44.1650339,
    "population": 64628,
    "foundation_year": 1799
  },
  {
    "address": "Ставропольский край, г Георгиевск",
    "postal_code": 357820,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Георгиевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000900000,
    "fias_id": "ea88bcba-c562-438a-9115-8b32426b0c8e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07407000000",
    "oktmo": "07707000001",
    "tax_office": 2625,
    "timezone": "UTC+3",
    "geo_lat": 44.1485694,
    "geo_lon": 43.4739851,
    "population": 72126,
    "foundation_year": 1777
  },
  {
    "address": "Ставропольский край, г Ессентуки",
    "postal_code": 357600,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ессентуки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000200000,
    "fias_id": "0ca8280c-89cb-4c81-8a99-ae10a98416bc",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07410000000",
    "oktmo": "07710000001",
    "tax_office": 2650,
    "timezone": "UTC+3",
    "geo_lat": 44.0446186,
    "geo_lon": 42.8588653,
    "population": 100969,
    "foundation_year": 1825
  },
  {
    "address": "Ставропольский край, г Железноводск",
    "postal_code": 357400,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Железноводск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000300000,
    "fias_id": "f231a6e9-5631-44a6-80bf-585e9491165e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07412000000",
    "oktmo": "07712000001",
    "tax_office": 2649,
    "timezone": "UTC+3",
    "geo_lat": 44.1320568,
    "geo_lon": 43.0306461,
    "population": 24496,
    "foundation_year": 1810
  },
  {
    "address": "Ставропольский край, г Зеленокумск",
    "postal_code": 357910,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Советский",
    "city_type": "г",
    "city": "Зеленокумск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2602300100000,
    "fias_id": "dcfa008b-e058-4be1-a409-18fc2b2e570f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07250501000",
    "oktmo": "07735000001",
    "tax_office": 2641,
    "timezone": "UTC+3",
    "geo_lat": 44.4032668,
    "geo_lon": 43.8841877,
    "population": 35790,
    "foundation_year": 1781
  },
  {
    "address": "Ставропольский край, г Изобильный",
    "postal_code": 356140,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Изобильненский",
    "city_type": "г",
    "city": "Изобильный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601000100000,
    "fias_id": "82d67979-76e6-4c48-b5b0-18c0b969114c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07220501000",
    "oktmo": "07713000001",
    "tax_office": 2644,
    "timezone": "UTC+3",
    "geo_lat": 45.3684296,
    "geo_lon": 41.708702,
    "population": 40546,
    "foundation_year": 1895
  },
  {
    "address": "Ставропольский край, г Ипатово",
    "postal_code": 356630,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Ипатовский",
    "city_type": "г",
    "city": "Ипатово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601100100000,
    "fias_id": "589a16ac-fb2e-46f9-b59a-98c18d34d908",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07222501000",
    "oktmo": "07714000001",
    "tax_office": 2643,
    "timezone": "UTC+3",
    "geo_lat": 45.7181751,
    "geo_lon": 42.8970206,
    "population": 26055,
    "foundation_year": 1860
  },
  {
    "address": "Ставропольский край, г Кисловодск",
    "postal_code": 357700,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кисловодск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000400000,
    "fias_id": "82551e9e-14a4-4414-ad60-6cc944763ff3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07415000000",
    "oktmo": "07715000001",
    "tax_office": 2628,
    "timezone": "UTC+3",
    "geo_lat": 43.9052044,
    "geo_lon": 42.7168721,
    "population": 128502,
    "foundation_year": 1803
  },
  {
    "address": "Ставропольский край, г Лермонтов",
    "postal_code": 357340,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лермонтов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000500000,
    "fias_id": "3808db7f-f793-40d0-ad74-691c158bc2de",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07418000000",
    "oktmo": "07718000001",
    "tax_office": 2649,
    "timezone": "UTC+3",
    "geo_lat": 44.1054107,
    "geo_lon": 42.973135,
    "population": 22540,
    "foundation_year": 1953
  },
  {
    "address": "Ставропольский край, г Минеральные Воды",
    "postal_code": 357200,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Минераловодский",
    "city_type": "г",
    "city": "Минеральные Воды",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601700200000,
    "fias_id": "0be84f75-bbb5-4471-9d0c-dfa932f5df7d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07421000000",
    "oktmo": "07721000001",
    "tax_office": 2649,
    "timezone": "UTC+3",
    "geo_lat": 44.2087273,
    "geo_lon": 43.138408,
    "population": 76715,
    "foundation_year": 1878
  },
  {
    "address": "Ставропольский край, г Михайловск",
    "postal_code": 356240,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Шпаковский",
    "city_type": "г",
    "city": "Михайловск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2602700100000,
    "fias_id": "51007e5c-e430-4f18-912c-f88c7e73698d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07258501000",
    "oktmo": "07558000001",
    "tax_office": 2645,
    "timezone": "UTC+3",
    "geo_lat": 45.1297323,
    "geo_lon": 42.0288443,
    "population": 71018,
    "foundation_year": 1784
  },
  {
    "address": "Ставропольский край, г Невинномысск",
    "postal_code": 357100,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Невинномысск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000600000,
    "fias_id": "ff894fb7-acb4-4edf-bb48-c5349d89579b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07424000000",
    "oktmo": "07724000001",
    "tax_office": 2648,
    "timezone": "UTC+3",
    "geo_lat": 44.6226031,
    "geo_lon": 41.9476723,
    "population": 118351,
    "foundation_year": 1825
  },
  {
    "address": "Ставропольский край, г Нефтекумск",
    "postal_code": 356880,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Нефтекумский",
    "city_type": "г",
    "city": "Нефтекумск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601800100000,
    "fias_id": "47a5b97a-5cc1-4fa4-893f-0041590dd823",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07241501000",
    "oktmo": "07725000001",
    "tax_office": 2646,
    "timezone": "UTC+3",
    "geo_lat": 44.7544552,
    "geo_lon": 44.9865347,
    "population": 27700,
    "foundation_year": 1958
  },
  {
    "address": "Ставропольский край, г Новоалександровск",
    "postal_code": 356000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Новоалександровский",
    "city_type": "г",
    "city": "Новоалександровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601900100000,
    "fias_id": "a0194045-d9a6-4593-9502-133cae983a3f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07243501000",
    "oktmo": "07726000001",
    "tax_office": 2644,
    "timezone": "UTC+3",
    "geo_lat": 45.4932733,
    "geo_lon": 41.2153996,
    "population": 26759,
    "foundation_year": 1804
  },
  {
    "address": "Ставропольский край, г Новопавловск",
    "postal_code": 357300,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Кировский",
    "city_type": "г",
    "city": "Новопавловск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2601200100000,
    "fias_id": "d72c4e6c-59e6-4c02-9372-61f980f79cbd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07225501000",
    "oktmo": "07716000001",
    "tax_office": 2641,
    "timezone": "UTC+3",
    "geo_lat": 43.9617097,
    "geo_lon": 43.6342865,
    "population": 26556,
    "foundation_year": 1777
  },
  {
    "address": "Ставропольский край, г Пятигорск",
    "postal_code": 357500,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пятигорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000700000,
    "fias_id": "9b0efbd0-22bb-400d-86db-ddc69b9939d9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "07427000000",
    "oktmo": "07727000001",
    "tax_office": 2632,
    "timezone": "UTC+3",
    "geo_lat": 44.041091,
    "geo_lon": 43.0661553,
    "population": 142397,
    "foundation_year": 1780
  },
  {
    "address": "Ставропольский край, г Светлоград",
    "postal_code": 356530,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "р-н",
    "area": "Петровский",
    "city_type": "г",
    "city": "Светлоград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2602100100000,
    "fias_id": "019979ae-6056-4ed8-98ef-48bded318dd2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "07246501000",
    "oktmo": "07731000001",
    "tax_office": 2643,
    "timezone": "UTC+3",
    "geo_lat": 45.328659,
    "geo_lon": 42.8565714,
    "population": 38520,
    "foundation_year": 1786
  },
  {
    "address": "г Ставрополь",
    "postal_code": 355000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "край",
    "region": "Ставропольский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ставрополь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2600000100000,
    "fias_id": "2a1c7bdb-05ea-492f-9e1c-b3999f79dcbc",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "07401000000",
    "oktmo": "07701000001",
    "tax_office": 2600,
    "timezone": "UTC+3",
    "geo_lat": 45.044516,
    "geo_lon": 41.9689655,
    "population": 398266,
    "foundation_year": 1777
  },
  {
    "address": "Тамбовская обл, г Жердевка",
    "postal_code": 393670,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "р-н",
    "area": "Жердевский",
    "city_type": "г",
    "city": "Жердевка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800400100000,
    "fias_id": "97034a8c-c36f-4e7b-880b-436ce8fec1be",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 68204501000,
    "oktmo": 68604101001,
    "tax_office": 6820,
    "timezone": "UTC+3",
    "geo_lat": 51.8422192,
    "geo_lon": 41.4617687,
    "population": 15211,
    "foundation_year": 1745
  },
  {
    "address": "Тамбовская обл, г Кирсанов",
    "postal_code": 393360,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кирсанов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000500000,
    "fias_id": "24694c2c-bab5-4fc7-9666-5ad984536465",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 68405000000,
    "oktmo": 68705000001,
    "tax_office": 6828,
    "timezone": "UTC+3",
    "geo_lat": 52.6506335,
    "geo_lon": 42.7286445,
    "population": 17240,
    "foundation_year": 1702
  },
  {
    "address": "Тамбовская обл, г Котовск",
    "postal_code": 393190,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Котовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000200000,
    "fias_id": "1cc2ab3c-1791-4771-b332-d2d96f52788e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 68410000000,
    "oktmo": 68710000001,
    "tax_office": 6820,
    "timezone": "UTC+3",
    "geo_lat": 52.5924489,
    "geo_lon": 41.5101237,
    "population": 31851,
    "foundation_year": 1940
  },
  {
    "address": "Тамбовская обл, г Мичуринск",
    "postal_code": 393760,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мичуринск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000600000,
    "fias_id": "9bacca6d-4ed5-4fae-a76d-d281ce884d04",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 68415000000,
    "oktmo": 68715000,
    "tax_office": 6827,
    "timezone": "UTC+3",
    "geo_lat": 52.8912389,
    "geo_lon": 40.5104443,
    "population": 98758,
    "foundation_year": 1635
  },
  {
    "address": "Тамбовская обл, г Моршанск",
    "postal_code": 393950,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Моршанск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000300000,
    "fias_id": "88bf324d-7303-45c3-ba7f-695f2528490b",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 68420000000,
    "oktmo": 68720000001,
    "tax_office": 6820,
    "timezone": "UTC+3",
    "geo_lat": 53.4436216,
    "geo_lon": 41.8115478,
    "population": 41550,
    "foundation_year": 1623
  },
  {
    "address": "Тамбовская обл, г Рассказово",
    "postal_code": 393250,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Рассказово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000700000,
    "fias_id": "f3d1ea5a-4478-4589-bec0-47c558577739",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 68425000000,
    "oktmo": 68725000001,
    "tax_office": 6828,
    "timezone": "UTC+3",
    "geo_lat": 52.6538833,
    "geo_lon": 41.874285,
    "population": 45484,
    "foundation_year": 1698
  },
  {
    "address": "г Тамбов",
    "postal_code": 392000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тамбов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000400000,
    "fias_id": "ea2a1270-1e19-4224-b1a0-4228b9de3c7a",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 68401000000,
    "oktmo": 68701000001,
    "tax_office": 6800,
    "timezone": "UTC+3",
    "geo_lat": 52.7213154,
    "geo_lon": 41.452264,
    "population": 280457,
    "foundation_year": 1636
  },
  {
    "address": "Тамбовская обл, г Уварово",
    "postal_code": 393460,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тамбовская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Уварово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6800000800000,
    "fias_id": "5a54fa19-c17f-40a8-9d81-0fc826cba781",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 68430000000,
    "oktmo": 68730000001,
    "tax_office": 6828,
    "timezone": "UTC+3",
    "geo_lat": 51.9767841,
    "geo_lon": 42.2529799,
    "population": 26829,
    "foundation_year": 1699
  },
  {
    "address": "Респ Татарстан, г Агрыз",
    "postal_code": 422230,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Агрызский",
    "city_type": "г",
    "city": "Агрыз",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1600200100000,
    "fias_id": "9f936889-a757-419e-890a-d4cc1fa95e93",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92201501000,
    "oktmo": 92601101001,
    "tax_office": 1674,
    "timezone": "UTC+3",
    "geo_lat": 56.5232864,
    "geo_lon": 52.9943775,
    "population": 19299,
    "foundation_year": 1646
  },
  {
    "address": "Респ Татарстан, г Азнакаево",
    "postal_code": 423330,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Азнакаевский",
    "city_type": "г",
    "city": "Азнакаево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1600300100000,
    "fias_id": "17296190-84f5-4afd-93c5-2b79cfc8bc12",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92403000000,
    "oktmo": 92602101001,
    "tax_office": 1689,
    "timezone": "UTC+3",
    "geo_lat": 54.8598642,
    "geo_lon": 53.0745527,
    "population": 34859,
    "foundation_year": 1762
  },
  {
    "address": "Респ Татарстан, г Альметьевск",
    "postal_code": 423403,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Альметьевский",
    "city_type": "г",
    "city": "Альметьевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1600800100000,
    "fias_id": "1ca4e6ce-2bc8-40a9-bdf5-113449c3a172",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92405000000,
    "oktmo": 92608101001,
    "tax_office": 1644,
    "timezone": "UTC+3",
    "geo_lat": 54.9014619,
    "geo_lon": 52.2970467,
    "population": 146309,
    "foundation_year": 1700
  },
  {
    "address": "Респ Татарстан, г Арск",
    "postal_code": 422000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Арский",
    "city_type": "г",
    "city": "Арск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601000100000,
    "fias_id": "d2c6e970-af35-41fe-9ba6-dc44a4187c67",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92212501000,
    "oktmo": 92612151001,
    "tax_office": 1685,
    "timezone": "UTC+3",
    "geo_lat": 56.0912567,
    "geo_lon": 49.877067,
    "population": 18114,
    "foundation_year": 1200
  },
  {
    "address": "Респ Татарстан, г Бавлы",
    "postal_code": 423930,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Бавлинский",
    "city_type": "г",
    "city": "Бавлы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601200100000,
    "fias_id": "2a7d9c47-fc56-4167-a5fc-5e40a8a5f5d5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92408000000,
    "oktmo": 92614101001,
    "tax_office": 1689,
    "timezone": "UTC+3",
    "geo_lat": 54.4062891,
    "geo_lon": 53.2458065,
    "population": 22109,
    "foundation_year": 1658
  },
  {
    "address": "Респ Татарстан, г Болгар",
    "postal_code": 422840,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Спасский",
    "city_type": "г",
    "city": "Болгар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1603800100000,
    "fias_id": "724d41e3-f0ac-4254-a207-897ea3d42076",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92232501000,
    "oktmo": 92632101001,
    "tax_office": 1677,
    "timezone": "UTC+3",
    "geo_lat": 54.974891,
    "geo_lon": 49.0303882,
    "population": 8650,
    "foundation_year": 1781
  },
  {
    "address": "Респ Татарстан, г Бугульма",
    "postal_code": 423230,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Бугульминский",
    "city_type": "г",
    "city": "Бугульма",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601400100000,
    "fias_id": "2c857ac6-3fb8-421d-bfd4-deeffa96df8f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92410000000,
    "oktmo": 92617101001,
    "tax_office": 1689,
    "timezone": "UTC+3",
    "geo_lat": 54.5363495,
    "geo_lon": 52.7895849,
    "population": 89144,
    "foundation_year": 1736
  },
  {
    "address": "Респ Татарстан, г Буинск",
    "postal_code": 422430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Буинский",
    "city_type": "г",
    "city": "Буинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601500100000,
    "fias_id": "58e5a396-77c4-4ab6-b235-afe364c0580f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92412000000,
    "oktmo": 92618101001,
    "tax_office": 1673,
    "timezone": "UTC+3",
    "geo_lat": 54.9641538,
    "geo_lon": 48.2901209,
    "population": 20342,
    "foundation_year": 1691
  },
  {
    "address": "Респ Татарстан, г Елабуга",
    "postal_code": 423600,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Елабужский",
    "city_type": "г",
    "city": "Елабуга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601900100000,
    "fias_id": "82d4c783-5080-4faa-9cec-ba9152bc9fc8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92415000000,
    "oktmo": 92626101001,
    "tax_office": 1600,
    "timezone": "UTC+3",
    "geo_lat": 55.7567107,
    "geo_lon": 52.0543794,
    "population": 70750,
    "foundation_year": 1000
  },
  {
    "address": "Респ Татарстан, г Заинск",
    "postal_code": 423520,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Заинский",
    "city_type": "г",
    "city": "Заинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602000100000,
    "fias_id": "098deac5-b810-4584-b277-10f8b5afe51d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92417000000,
    "oktmo": 92627101001,
    "tax_office": 1651,
    "timezone": "UTC+3",
    "geo_lat": 55.299053,
    "geo_lon": 52.0062972,
    "population": 41798,
    "foundation_year": 1656
  },
  {
    "address": "Респ Татарстан, г Зеленодольск",
    "postal_code": 422521,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Зеленодольский",
    "city_type": "г",
    "city": "Зеленодольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602100100000,
    "fias_id": "1e90ec38-0f85-442b-ac1b-ca687fa91d88",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92420000000,
    "oktmo": 92628101001,
    "tax_office": 1673,
    "timezone": "UTC+3",
    "geo_lat": 55.8466651,
    "geo_lon": 48.5010954,
    "population": 97651,
    "foundation_year": 1865
  },
  {
    "address": "Респ Татарстан, Верхнеуслонский р-н, г Иннополис",
    "postal_code": 420500,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Верхнеуслонский",
    "city_type": "г",
    "city": "Иннополис",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1601600100000,
    "fias_id": "98da33d3-9455-497a-9703-a0f89c95a901",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 92220509000,
    "oktmo": 92620109001,
    "tax_office": 1683,
    "timezone": "UTC+3",
    "geo_lat": 55.7521699,
    "geo_lon": 48.7446846,
    "population": 96,
    "foundation_year": 2012
  },
  {
    "address": "г Казань",
    "postal_code": 420000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Казань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1600000100000,
    "fias_id": "93b3df57-4c89-44df-ac42-96f05e9cd3b9",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 92401000000,
    "oktmo": 92701000001,
    "tax_office": 1600,
    "timezone": "UTC+3",
    "geo_lat": 55.7943584,
    "geo_lon": 49.1114975,
    "population": 1216965,
    "foundation_year": 1005
  },
  {
    "address": "Респ Татарстан, г Кукмор",
    "postal_code": 422110,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Кукморский",
    "city_type": "г",
    "city": "Кукмор",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602400200000,
    "fias_id": "840033db-f0cb-4783-b0e0-8c9235d64948",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92233501000,
    "oktmo": 92633101001,
    "tax_office": 1675,
    "timezone": "UTC+3",
    "geo_lat": 56.1861392,
    "geo_lon": 50.8970238,
    "population": 17700,
    "foundation_year": 1741
  },
  {
    "address": "Респ Татарстан, г Лаишево",
    "postal_code": 422610,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Лаишевский",
    "city_type": "г",
    "city": "Лаишево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602500100000,
    "fias_id": "72197ca2-8209-49d6-ba10-7796b2e2e852",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92234501000,
    "oktmo": 92634101001,
    "tax_office": 1684,
    "timezone": "UTC+3",
    "geo_lat": 55.4042867,
    "geo_lon": 49.5499838,
    "population": 7735,
    "foundation_year": 1557
  },
  {
    "address": "Респ Татарстан, г Лениногорск",
    "postal_code": 423250,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Лениногорский",
    "city_type": "г",
    "city": "Лениногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602600100000,
    "fias_id": "e3a88932-c76e-4158-842c-d710a9856df1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92425000000,
    "oktmo": 92636101001,
    "tax_office": 1689,
    "timezone": "UTC+3",
    "geo_lat": 54.5967034,
    "geo_lon": 52.4431906,
    "population": 64145,
    "foundation_year": 1795
  },
  {
    "address": "Респ Татарстан, г Мамадыш",
    "postal_code": 422190,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Мамадышский",
    "city_type": "г",
    "city": "Мамадыш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602700100000,
    "fias_id": "1d9bbe0d-7086-4fd0-b91c-ed6d196cb818",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92238501000,
    "oktmo": 92638101001,
    "tax_office": 1675,
    "timezone": "UTC+3",
    "geo_lat": 55.7150413,
    "geo_lon": 51.4129016,
    "population": 14432,
    "foundation_year": 1391
  },
  {
    "address": "Респ Татарстан, г Менделеевск",
    "postal_code": 423650,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Менделеевский",
    "city_type": "г",
    "city": "Менделеевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602800100000,
    "fias_id": "e23b4fcb-782b-4d5c-b962-94fd6018fef6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92239501000,
    "oktmo": 92639101001,
    "tax_office": 1674,
    "timezone": "UTC+3",
    "geo_lat": 55.895169,
    "geo_lon": 52.3143347,
    "population": 22075,
    "foundation_year": 1868
  },
  {
    "address": "Респ Татарстан, г Мензелинск",
    "postal_code": 423700,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Мензелинский",
    "city_type": "г",
    "city": "Мензелинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1602900100000,
    "fias_id": "d1d11ebe-b022-44aa-a211-3472483a4974",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92240501000,
    "oktmo": 92640101001,
    "tax_office": 1674,
    "timezone": "UTC+3",
    "geo_lat": 55.7270698,
    "geo_lon": 53.1003968,
    "population": 16474,
    "foundation_year": 1586
  },
  {
    "address": "Респ Татарстан, г Набережные Челны",
    "postal_code": 423800,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Набережные Челны",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1600000200000,
    "fias_id": "748d7afa-7407-4876-9f40-764ecdd09bbd",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 92430000000,
    "oktmo": 92730000001,
    "tax_office": 1650,
    "timezone": "UTC+3",
    "geo_lat": 55.7434619,
    "geo_lon": 52.3959165,
    "population": 513242,
    "foundation_year": 1626
  },
  {
    "address": "Респ Татарстан, г Нижнекамск",
    "postal_code": 423570,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Нижнекамский",
    "city_type": "г",
    "city": "Нижнекамск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1603100100000,
    "fias_id": "6b849aa8-0782-4c26-b7ac-a0f413f306c0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92435000000,
    "oktmo": 92644101001,
    "tax_office": 1651,
    "timezone": "UTC+3",
    "geo_lat": 55.6313609,
    "geo_lon": 51.8144669,
    "population": 234108,
    "foundation_year": 1961
  },
  {
    "address": "Респ Татарстан, г Нурлат",
    "postal_code": 423040,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Нурлатский",
    "city_type": "г",
    "city": "Нурлат",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1603300100000,
    "fias_id": "1f708d3c-4d86-4ab7-afdf-5741fe661744",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92437000000,
    "oktmo": 92646101001,
    "tax_office": 1677,
    "timezone": "UTC+3",
    "geo_lat": 54.4281461,
    "geo_lon": 50.8049337,
    "population": 32600,
    "foundation_year": 1905
  },
  {
    "address": "Респ Татарстан, г Тетюши",
    "postal_code": 422370,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Тетюшский",
    "city_type": "г",
    "city": "Тетюши",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1603900100000,
    "fias_id": "e277c80f-b3ce-40d5-9a46-8c1e3a8dd1ba",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92255501000,
    "oktmo": 92655101001,
    "tax_office": 1683,
    "timezone": "UTC+3",
    "geo_lat": 54.936516,
    "geo_lon": 48.8314533,
    "population": 11596,
    "foundation_year": 1578
  },
  {
    "address": "Респ Татарстан, г Чистополь",
    "postal_code": 422951,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Татарстан",
    "area_type": "р-н",
    "area": "Чистопольский",
    "city_type": "г",
    "city": "Чистополь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1604300100000,
    "fias_id": "c3c7b150-1b5b-49a6-ace2-a24b403b3b94",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 92440000000,
    "oktmo": 92659101001,
    "tax_office": 1677,
    "timezone": "UTC+3",
    "geo_lat": 55.3699139,
    "geo_lon": 50.6285784,
    "population": 60703,
    "foundation_year": 1700
  },
  {
    "address": "Тверская обл, г Андреаполь",
    "postal_code": 172800,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Андреаполь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900001000000,
    "fias_id": "0c193436-6308-407a-afbb-973a8c075630",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28202501000,
    "oktmo": 28502000001,
    "tax_office": 6913,
    "timezone": "UTC+3",
    "geo_lat": 56.6506724,
    "geo_lon": 32.2620163,
    "population": 8265,
    "foundation_year": 1907
  },
  {
    "address": "Тверская обл, г Бежецк",
    "postal_code": 171980,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Бежецкий",
    "city_type": "г",
    "city": "Бежецк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900300100000,
    "fias_id": "a8a1798c-3aa9-4716-82a1-51ec18e23c04",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28405000000,
    "oktmo": 28604101001,
    "tax_office": 6906,
    "timezone": "UTC+3",
    "geo_lat": 57.7860089,
    "geo_lon": 36.6904983,
    "population": 24517,
    "foundation_year": 1137
  },
  {
    "address": "Тверская обл, г Белый",
    "postal_code": 172530,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Бельский",
    "city_type": "г",
    "city": "Белый",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900400100000,
    "fias_id": "e429f385-b7f5-41be-8c7f-3b8ce47f00b2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28206501000,
    "oktmo": 28606101001,
    "tax_office": 6912,
    "timezone": "UTC+3",
    "geo_lat": 55.8339056,
    "geo_lon": 32.9389741,
    "population": 3771,
    "foundation_year": 1350
  },
  {
    "address": "Тверская обл, г Бологое",
    "postal_code": 171070,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Бологовский",
    "city_type": "г",
    "city": "Бологое",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900500100000,
    "fias_id": "01fecded-f136-4c58-986b-2eef124c8290",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28408000000,
    "oktmo": 28608101001,
    "tax_office": 6908,
    "timezone": "UTC+3",
    "geo_lat": 57.8855767,
    "geo_lon": 34.0537771,
    "population": 23499,
    "foundation_year": 1495
  },
  {
    "address": "Тверская обл, г Весьегонск",
    "postal_code": 171720,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Весьегонск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900001100000,
    "fias_id": "1e4608bf-7b7d-41a8-b6e3-78010ee9ce80",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28210501000,
    "oktmo": 28510000001,
    "tax_office": 6906,
    "timezone": "UTC+3",
    "geo_lat": 58.6582598,
    "geo_lon": 37.2567558,
    "population": 7330,
    "foundation_year": 1564
  },
  {
    "address": "Тверская обл, г Вышний Волочек",
    "postal_code": 171150,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Вышний Волочек",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000600000,
    "fias_id": "ac45dd91-33c1-4689-a48c-226c75dc752d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 28414000000,
    "oktmo": 28714000001,
    "tax_office": 6908,
    "timezone": "UTC+3",
    "geo_lat": 57.568302,
    "geo_lon": 34.5404016,
    "population": 52326,
    "foundation_year": 1471
  },
  {
    "address": "Тверская обл, г Западная Двина",
    "postal_code": 172610,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Западная Двина",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900001300000,
    "fias_id": "89a1b73c-6058-49e0-bdba-e7f10c2342dd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28216501000,
    "oktmo": 28516000001,
    "tax_office": 6912,
    "timezone": "UTC+3",
    "geo_lat": 56.2566492,
    "geo_lon": 32.0805315,
    "population": 9376,
    "foundation_year": 1900
  },
  {
    "address": "Тверская обл, г Зубцов",
    "postal_code": 172332,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Зубцовский",
    "city_type": "г",
    "city": "Зубцов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6901000100000,
    "fias_id": "7f0cc277-4994-4c14-abe9-bbb776a8bcfc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28218501000,
    "oktmo": 28618101001,
    "tax_office": 6914,
    "timezone": "UTC+3",
    "geo_lat": 56.1760868,
    "geo_lon": 34.5825515,
    "population": 6937,
    "foundation_year": 1216
  },
  {
    "address": "Тверская обл, г Калязин",
    "postal_code": 171571,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Калязинский",
    "city_type": "г",
    "city": "Калязин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6901100100000,
    "fias_id": "fe762e07-f90b-4758-9816-54112f02740e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28222501000,
    "oktmo": 28622101001,
    "tax_office": 6910,
    "timezone": "UTC+3",
    "geo_lat": 57.2579478,
    "geo_lon": 37.7819693,
    "population": 13870,
    "foundation_year": 1434
  },
  {
    "address": "Тверская обл, г Кашин",
    "postal_code": 171640,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кашин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000900000,
    "fias_id": "d0c58ce3-d21c-441f-be50-92de7f5bcc83",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28420000000,
    "oktmo": 28758000001,
    "tax_office": 6910,
    "timezone": "UTC+3",
    "geo_lat": 57.360194,
    "geo_lon": 37.6119436,
    "population": 16174,
    "foundation_year": 1238
  },
  {
    "address": "Тверская обл, г Кимры",
    "postal_code": 171500,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кимры",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000500000,
    "fias_id": "2c02d3e2-f65d-4d7f-b1a3-ffe55d2d4508",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 28426000000,
    "oktmo": 28726000001,
    "tax_office": 6910,
    "timezone": "UTC+3",
    "geo_lat": 56.8733213,
    "geo_lon": 37.3556605,
    "population": 49623,
    "foundation_year": 1546
  },
  {
    "address": "Тверская обл, г Конаково",
    "postal_code": 171250,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Конаковский",
    "city_type": "г",
    "city": "Конаково",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6901500100000,
    "fias_id": "8f171e04-35f6-4c4d-bd95-97a5601b0d71",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28430000000,
    "oktmo": 28630101001,
    "tax_office": 6949,
    "timezone": "UTC+3",
    "geo_lat": 56.7275204,
    "geo_lon": 36.8012716,
    "population": 41303,
    "foundation_year": 1806
  },
  {
    "address": "Тверская обл, г Красный Холм",
    "postal_code": 171660,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Красный Холм",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900001200000,
    "fias_id": "b62cf2b9-b222-434b-89ea-19e58c73b406",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28232501000,
    "oktmo": 28532000001,
    "tax_office": 6906,
    "timezone": "UTC+3",
    "geo_lat": 58.0571446,
    "geo_lon": 37.1126156,
    "population": 5608,
    "foundation_year": 1518
  },
  {
    "address": "Тверская обл, г Кувшиново",
    "postal_code": 172110,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Кувшиновский",
    "city_type": "г",
    "city": "Кувшиново",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6901700100000,
    "fias_id": "1370dc6a-9c3c-428b-b8ff-17fe39ee7654",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28234501000,
    "oktmo": 28634101001,
    "tax_office": 6915,
    "timezone": "UTC+3",
    "geo_lat": 57.0265168,
    "geo_lon": 34.1676009,
    "population": 10008,
    "foundation_year": 1910
  },
  {
    "address": "Тверская обл, г Лихославль",
    "postal_code": 171210,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Лихославльский",
    "city_type": "г",
    "city": "Лихославль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6901900100000,
    "fias_id": "65ff289f-75f8-4a63-8d7a-7126496de247",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28238501000,
    "oktmo": 28638101001,
    "tax_office": 6915,
    "timezone": "UTC+3",
    "geo_lat": 57.1221304,
    "geo_lon": 35.4667605,
    "population": 12259,
    "foundation_year": 1624
  },
  {
    "address": "Тверская обл, г Нелидово",
    "postal_code": 172520,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нелидово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000400000,
    "fias_id": "a38751f3-98fe-4d8d-b9bc-2a4705b887ec",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28435000000,
    "oktmo": 28759000001,
    "tax_office": 6912,
    "timezone": "UTC+3",
    "geo_lat": 56.2232566,
    "geo_lon": 32.7767459,
    "population": 22886,
    "foundation_year": 1900
  },
  {
    "address": "Тверская обл, г Осташков",
    "postal_code": 172730,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Осташков",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000800000,
    "fias_id": "bfe4c46e-0487-48ba-aea3-be0d909fca67",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28440000000,
    "oktmo": 28752000001,
    "tax_office": 6913,
    "timezone": "UTC+3",
    "geo_lat": 57.1456744,
    "geo_lon": 33.1115372,
    "population": 18073,
    "foundation_year": 1300
  },
  {
    "address": "Тверская обл, г Ржев",
    "postal_code": 172380,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ржев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000300000,
    "fias_id": "2553804d-14b9-4f5b-8bd0-25c0fc7315ae",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 28445000000,
    "oktmo": 28745000001,
    "tax_office": 6914,
    "timezone": "UTC+3",
    "geo_lat": 56.262881,
    "geo_lon": 34.3291002,
    "population": 62026,
    "foundation_year": 1216
  },
  {
    "address": "Тверская обл, г Старица",
    "postal_code": 171360,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Старицкий",
    "city_type": "г",
    "city": "Старица",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6903200100000,
    "fias_id": "44d646fd-0ad2-49d7-ad37-41aacd6f1aee",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28253501000,
    "oktmo": 28653101001,
    "tax_office": 6914,
    "timezone": "UTC+3",
    "geo_lat": 56.514876,
    "geo_lon": 34.9336396,
    "population": 8610,
    "foundation_year": 1297
  },
  {
    "address": "г Тверь",
    "postal_code": 170000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тверь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000100000,
    "fias_id": "c52ea942-555e-45c6-9751-58897717b02f",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 28401000000,
    "oktmo": 28701000001,
    "tax_office": 6900,
    "timezone": "UTC+3",
    "geo_lat": 56.8586059,
    "geo_lon": 35.9116761,
    "population": 403726,
    "foundation_year": 1135
  },
  {
    "address": "Тверская обл, г Торжок",
    "postal_code": 172000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Торжок",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000200000,
    "fias_id": "4b7047b6-a656-48e7-a247-1433d4a7fb82",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 28450000000,
    "oktmo": 28750000001,
    "tax_office": 6915,
    "timezone": "UTC+3",
    "geo_lat": 57.04133,
    "geo_lon": 34.9602344,
    "population": 47702,
    "foundation_year": 900
  },
  {
    "address": "Тверская обл, г Торопец",
    "postal_code": 172842,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "р-н",
    "area": "Торопецкий",
    "city_type": "г",
    "city": "Торопец",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6903400100000,
    "fias_id": "7e35bc70-aa2f-4be7-b34f-8a9c18d48b76",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28255501000,
    "oktmo": 28655101001,
    "tax_office": 6912,
    "timezone": "UTC+3",
    "geo_lat": 56.5012188,
    "geo_lon": 31.6355466,
    "population": 13018,
    "foundation_year": 1074
  },
  {
    "address": "Тверская обл, г Удомля",
    "postal_code": 171841,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тверская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Удомля",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 6900000700000,
    "fias_id": "5bbf246a-6ff4-4050-9ea6-6d0b0a746651",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 28455000000,
    "oktmo": 28751000001,
    "tax_office": 6908,
    "timezone": "UTC+3",
    "geo_lat": 57.8787314,
    "geo_lon": 35.0167348,
    "population": 31048,
    "foundation_year": 1478
  },
  {
    "address": "Томская обл, г Асино",
    "postal_code": 636840,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "р-н",
    "area": "Асиновский",
    "city_type": "г",
    "city": "Асино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000300100000,
    "fias_id": "8540435c-3516-4d00-a7e5-213165da85e5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 69208501000,
    "oktmo": 69608101001,
    "tax_office": 7025,
    "timezone": "UTC+7",
    "geo_lat": 56.9907085,
    "geo_lon": 86.1765257,
    "population": 25614,
    "foundation_year": 1896
  },
  {
    "address": "Томская обл, г Кедровый",
    "postal_code": 636615,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кедровый",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000000200000,
    "fias_id": "59cb7e41-b681-40c8-97f2-13374a397867",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 69407000000,
    "oktmo": 69707000001,
    "tax_office": 7026,
    "timezone": "UTC+7",
    "geo_lat": 57.561869,
    "geo_lon": 79.5677821,
    "population": 2451,
    "foundation_year": 1982
  },
  {
    "address": "Томская обл, г Колпашево",
    "postal_code": 636460,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "р-н",
    "area": "Колпашевский",
    "city_type": "г",
    "city": "Колпашево",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000900100000,
    "fias_id": "c29a885f-a8c8-43b4-aecd-d439d36f5e47",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 69232501000,
    "oktmo": 69632101001,
    "tax_office": 7025,
    "timezone": "UTC+7",
    "geo_lat": 58.3114253,
    "geo_lon": 82.9025829,
    "population": 24126,
    "foundation_year": 1600
  },
  {
    "address": "Томская обл, г Северск",
    "postal_code": 636000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Северск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000000300000,
    "fias_id": "53c22352-eb6f-4163-a4d1-385d64561d2f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 69541000000,
    "oktmo": 69741000001,
    "tax_office": 7024,
    "timezone": "UTC+7",
    "geo_lat": 56.6031285,
    "geo_lon": 84.8809926,
    "population": 108466,
    "foundation_year": 1949
  },
  {
    "address": "Томская обл, г Стрежевой",
    "postal_code": 636780,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Стрежевой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000000400000,
    "fias_id": "0a0fe1fc-8aab-4ff3-a2a3-971ee4fcd27f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 69410000000,
    "oktmo": 69710000001,
    "tax_office": 7014,
    "timezone": "UTC+7",
    "geo_lat": 60.732895,
    "geo_lon": 77.604122,
    "population": 42216,
    "foundation_year": 1966
  },
  {
    "address": "г Томск",
    "postal_code": 634000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "обл",
    "region": "Томская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Томск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7000000100000,
    "fias_id": "e3b0eae8-a4ce-4779-ae04-5c0797de66be",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 69401000000,
    "oktmo": 69701000001,
    "tax_office": 7017,
    "timezone": "UTC+7",
    "geo_lat": 56.4845804,
    "geo_lon": 84.9481582,
    "population": 522940,
    "foundation_year": 1604
  },
  {
    "address": "Тульская обл, г Алексин",
    "postal_code": 301352,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Алексинский",
    "city_type": "г",
    "city": "Алексин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100200100000,
    "fias_id": "b9688874-4981-4b22-a930-2521c04beb3c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70202501000,
    "oktmo": 70706000001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.5083349,
    "geo_lon": 37.0478067,
    "population": 61738,
    "foundation_year": 1348
  },
  {
    "address": "Тульская обл, г Белев",
    "postal_code": 301530,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Белевский",
    "city_type": "г",
    "city": "Белев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100400100000,
    "fias_id": "a9d553c1-2d2e-422a-afba-96b3b34755e1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70206501000,
    "oktmo": 70606101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.8114179,
    "geo_lon": 36.1382247,
    "population": 13918,
    "foundation_year": 1147
  },
  {
    "address": "Тульская обл, г Богородицк",
    "postal_code": 301830,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Богородицкий",
    "city_type": "г",
    "city": "Богородицк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100500100000,
    "fias_id": "e80009cb-c8c9-4dda-90cb-082b6e0d5d8c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70208501000,
    "oktmo": 70608101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.7701014,
    "geo_lon": 38.1225152,
    "population": 31897,
    "foundation_year": 1663
  },
  {
    "address": "Тульская обл, Киреевский р-н, г Болохово",
    "postal_code": 301280,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Киреевский",
    "city_type": "г",
    "city": "Болохово",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101400200000,
    "fias_id": "0e4f8d4e-bc37-4c6e-b46f-a448a0bcb84a",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 70228508000,
    "oktmo": 70628108001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.0820349,
    "geo_lon": 37.826724,
    "population": 9619,
    "foundation_year": 1500
  },
  {
    "address": "Тульская обл, г Венев",
    "postal_code": 301320,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Веневский",
    "city_type": "г",
    "city": "Венев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100600100000,
    "fias_id": "6fef4448-fd64-4669-8f49-866718b3ec5c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70212501000,
    "oktmo": 70612101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.3542315,
    "geo_lon": 38.2642236,
    "population": 15220,
    "foundation_year": 1371
  },
  {
    "address": "Тульская обл, г Донской",
    "postal_code": 301760,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Донской",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100000200000,
    "fias_id": "dc8fbc8d-da2e-4bbb-a55c-94446fa7ad77",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 70412000000,
    "oktmo": 70712000001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9678944,
    "geo_lon": 38.3371824,
    "population": 64561,
    "foundation_year": 1773
  },
  {
    "address": "Тульская обл, г Ефремов",
    "postal_code": 301840,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Ефремовский",
    "city_type": "г",
    "city": "Ефремов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101000100000,
    "fias_id": "a6fad096-bd8a-49b8-ad26-78d892c208fd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70220501000,
    "oktmo": 70714000001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.1464766,
    "geo_lon": 38.0921657,
    "population": 42350,
    "foundation_year": 1637
  },
  {
    "address": "Тульская обл, г Кимовск",
    "postal_code": 301720,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Кимовский",
    "city_type": "г",
    "city": "Кимовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101300100000,
    "fias_id": "1b04dfd7-7752-4c40-ae60-9fae9cb95237",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70226501000,
    "oktmo": 70626101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9698378,
    "geo_lon": 38.5380808,
    "population": 28493,
    "foundation_year": 1600
  },
  {
    "address": "Тульская обл, г Киреевск",
    "postal_code": 301260,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Киреевский",
    "city_type": "г",
    "city": "Киреевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101400100000,
    "fias_id": "7cf29293-e9ab-4c7a-98ec-30d0c48ea104",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70228501000,
    "oktmo": 70628101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9319555,
    "geo_lon": 37.9220351,
    "population": 25585,
    "foundation_year": 1762
  },
  {
    "address": "Тульская обл, Киреевский р-н, г Липки",
    "postal_code": 301264,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Киреевский",
    "city_type": "г",
    "city": "Липки",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101400300000,
    "fias_id": "ff4e0e65-f17d-4ba6-832d-d1bd12859e00",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 70228513000,
    "oktmo": 70628113001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9417551,
    "geo_lon": 37.7020148,
    "population": 8741,
    "foundation_year": 1600
  },
  {
    "address": "Тульская обл, г Новомосковск",
    "postal_code": 301650,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Новомосковский",
    "city_type": "г",
    "city": "Новомосковск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101700100000,
    "fias_id": "0b6e326e-74ca-4e24-93d4-2c385c292bb5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70234501000,
    "oktmo": 70724000001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.0109075,
    "geo_lon": 38.2914024,
    "population": 131227,
    "foundation_year": 1930
  },
  {
    "address": "Тульская обл, г Плавск",
    "postal_code": 301470,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Плавский",
    "city_type": "г",
    "city": "Плавск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7101900100000,
    "fias_id": "3381114c-df5f-4966-8e74-b2e63d1e71bb",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70238501000,
    "oktmo": 70638101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.7096415,
    "geo_lon": 37.2862352,
    "population": 16248,
    "foundation_year": 1563
  },
  {
    "address": "Тульская обл, Щекинский р-н, г Советск",
    "postal_code": 301205,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Щекинский",
    "city_type": "г",
    "city": "Советск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102400200000,
    "fias_id": "20bde9d4-4295-417f-beba-6210f2e6b5d9",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 70248504000,
    "oktmo": 70648104001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9338874,
    "geo_lon": 37.6316141,
    "population": 7537,
    "foundation_year": 1949
  },
  {
    "address": "Тульская обл, г Суворов",
    "postal_code": 301430,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Суворовский",
    "city_type": "г",
    "city": "Суворов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102000100000,
    "fias_id": "3e63d801-f22b-4ad2-bca2-29f73b0388dc",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70240501000,
    "oktmo": 70640101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.1343585,
    "geo_lon": 36.4807419,
    "population": 18975,
    "foundation_year": 1949
  },
  {
    "address": "г Тула",
    "postal_code": 300000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тула",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7100000100000,
    "fias_id": "b2601b18-6da2-4789-9fbe-800dde06a2bb",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 70401000000,
    "oktmo": 70701000001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.1920559,
    "geo_lon": 37.6153842,
    "population": 501129,
    "foundation_year": 1146
  },
  {
    "address": "Тульская обл, г Узловая",
    "postal_code": 301600,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Узловский",
    "city_type": "г",
    "city": "Узловая",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102200100000,
    "fias_id": "d3f84234-d9f9-4bd9-ae29-459fb22c5433",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70244501000,
    "oktmo": 70644101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 53.9730452,
    "geo_lon": 38.1763201,
    "population": 55282,
    "foundation_year": 1873
  },
  {
    "address": "Тульская обл, Суворовский р-н, г Чекалин",
    "postal_code": 301414,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Суворовский",
    "city_type": "г",
    "city": "Чекалин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102000200000,
    "fias_id": "de83a84d-6526-46dc-9501-82a4542e5abb",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 70240508000,
    "oktmo": 70640108001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.0984438,
    "geo_lon": 36.2474142,
    "population": 994,
    "foundation_year": 1565
  },
  {
    "address": "Тульская обл, г Щекино",
    "postal_code": 301240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Щекинский",
    "city_type": "г",
    "city": "Щекино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102400100000,
    "fias_id": "2711a4bb-c464-405f-8b8b-cac284c9ae72",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70248501000,
    "oktmo": 70648101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.0020652,
    "geo_lon": 37.5176288,
    "population": 58154,
    "foundation_year": 1870
  },
  {
    "address": "Тульская обл, г Ясногорск",
    "postal_code": 301030,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Тульская",
    "area_type": "р-н",
    "area": "Ясногорский",
    "city_type": "г",
    "city": "Ясногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7102500100000,
    "fias_id": "87c99d23-260a-4a6e-8f69-f8a3dc131c3b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 70250501000,
    "oktmo": 70650101001,
    "tax_office": 7100,
    "timezone": "UTC+3",
    "geo_lat": 54.4795484,
    "geo_lon": 37.6896048,
    "population": 16804,
    "foundation_year": 1578
  },
  {
    "address": "Респ Тыва, г Ак-Довурак",
    "postal_code": 668050,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Тыва",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ак-Довурак",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1700000200000,
    "fias_id": "7e4932c1-2db8-4fee-9997-655d837e70e1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 93403000000,
    "oktmo": 93703000001,
    "tax_office": 1700,
    "timezone": "UTC+7",
    "geo_lat": 51.178452,
    "geo_lon": 90.5985129,
    "population": 13469,
    "foundation_year": 1964
  },
  {
    "address": "г Кызыл",
    "postal_code": 667000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Тыва",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кызыл",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1700000100000,
    "fias_id": "8df8c56f-a46e-438f-a85b-a9b18ad4fc77",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 93401000000,
    "oktmo": 93701000001,
    "tax_office": 1700,
    "timezone": "UTC+7",
    "geo_lat": 51.7191047,
    "geo_lon": 94.4376882,
    "population": 109906,
    "foundation_year": 1914
  },
  {
    "address": "Респ Тыва, г Туран",
    "postal_code": 668510,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Тыва",
    "area_type": "р-н",
    "area": "Пий-Хемский",
    "city_type": "г",
    "city": "Туран",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1700900100000,
    "fias_id": "224d11db-b006-4774-92eb-7fa5b870acbe",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 93235501000,
    "oktmo": 93635101001,
    "tax_office": 1700,
    "timezone": "UTC+7",
    "geo_lat": 52.1449619,
    "geo_lon": 93.9173396,
    "population": 4988,
    "foundation_year": 1885
  },
  {
    "address": "Респ Тыва, г Чадан",
    "postal_code": 668110,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Тыва",
    "area_type": "р-н",
    "area": "Дзун-Хемчикский",
    "city_type": "г",
    "city": "Чадан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1700400100000,
    "fias_id": "2f1dec2a-2206-47fa-9620-1db182968112",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 93215501000,
    "oktmo": 93615101001,
    "tax_office": 1700,
    "timezone": "UTC+7",
    "geo_lat": 51.2844502,
    "geo_lon": 91.5788609,
    "population": 9037,
    "foundation_year": 1873
  },
  {
    "address": "Респ Тыва, г Шагонар",
    "postal_code": 668210,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Тыва",
    "area_type": "р-н",
    "area": "Улуг-Хемский",
    "city_type": "г",
    "city": "Шагонар",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1701400100000,
    "fias_id": "a2095706-c501-479c-903a-695b78af1f36",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 93254501000,
    "oktmo": 93654101001,
    "tax_office": 1700,
    "timezone": "UTC+7",
    "geo_lat": 51.5346393,
    "geo_lon": 92.9199675,
    "population": 10958,
    "foundation_year": 1888
  },
  {
    "address": "Тюменская обл, г Заводоуковск",
    "postal_code": 627140,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Тюменская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Заводоуковск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7200000400000,
    "fias_id": "10c58caf-f8b8-44b6-99b9-5cf25f681fcf",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 71403000000,
    "oktmo": 71703000001,
    "tax_office": 7207,
    "timezone": "UTC+5",
    "geo_lat": 56.5027463,
    "geo_lon": 66.5513613,
    "population": 25657,
    "foundation_year": 1729
  },
  {
    "address": "Тюменская обл, г Ишим",
    "postal_code": 627705,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Тюменская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ишим",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7200000300000,
    "fias_id": "248fbf02-0ba3-4208-8478-ba3def019844",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 71405000000,
    "oktmo": 71705000001,
    "tax_office": 7200,
    "timezone": "UTC+5",
    "geo_lat": 56.1104858,
    "geo_lon": 69.4795776,
    "population": 69567,
    "foundation_year": 1687
  },
  {
    "address": "Тюменская обл, г Тобольск",
    "postal_code": 626150,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Тюменская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тобольск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7200000200000,
    "fias_id": "6d7c3e9e-8d6d-490a-a650-8257dbf5ec36",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71410000000,
    "oktmo": 71710000001,
    "tax_office": 7206,
    "timezone": "UTC+5",
    "geo_lat": 58.2017299,
    "geo_lon": 68.2538558,
    "population": 99698,
    "foundation_year": 1587
  },
  {
    "address": "г Тюмень",
    "postal_code": 625000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Тюменская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Тюмень",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7200000100000,
    "fias_id": "9ae64229-9f7b-4149-b27a-d1f6ec74b5ce",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 71401000000,
    "oktmo": 71701000001,
    "tax_office": 7200,
    "timezone": "UTC+5",
    "geo_lat": 57.1529744,
    "geo_lon": 65.5344099,
    "population": 581758,
    "foundation_year": 1586
  },
  {
    "address": "Тюменская обл, г Ялуторовск",
    "postal_code": 627010,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Тюменская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ялуторовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7200000500000,
    "fias_id": "2771f01d-b335-40ba-ac30-11a7d3b43c26",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 71415000000,
    "oktmo": 71715000001,
    "tax_office": 7207,
    "timezone": "UTC+5",
    "geo_lat": 56.6547289,
    "geo_lon": 66.3122992,
    "population": 36494,
    "foundation_year": 1659
  },
  {
    "address": "Респ Удмуртская, г Воткинск",
    "postal_code": 427430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Воткинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1800000300000,
    "fias_id": "d948319d-b151-48f5-9791-22ccfeeabfe7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 94410000000,
    "oktmo": 94710000001,
    "tax_office": 1828,
    "timezone": "UTC+4",
    "geo_lat": 57.0518149,
    "geo_lon": 53.9873096,
    "population": 100034,
    "foundation_year": 1759
  },
  {
    "address": "Респ Удмуртская, г Глазов",
    "postal_code": 427620,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Глазов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1800000400000,
    "fias_id": "afeea607-0207-467f-8c59-562fc634f924",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 94420000000,
    "oktmo": 94720000001,
    "tax_office": 1837,
    "timezone": "UTC+4",
    "geo_lat": 58.1359233,
    "geo_lon": 52.6635038,
    "population": 95835,
    "foundation_year": 1678
  },
  {
    "address": "г Ижевск",
    "postal_code": 426000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ижевск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1800000100000,
    "fias_id": "deb1d05a-71ce-40d1-b726-6ba85d70d58f",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 94401000000,
    "oktmo": 94701000001,
    "tax_office": 1800,
    "timezone": "UTC+4",
    "geo_lat": 56.852738,
    "geo_lon": 53.2114896,
    "population": 628117,
    "foundation_year": 1760
  },
  {
    "address": "Респ Удмуртская, г Камбарка",
    "postal_code": 427950,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "р-н",
    "area": "Камбарский",
    "city_type": "г",
    "city": "Камбарка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1801100100000,
    "fias_id": "b3bb969f-79ee-46fb-b23c-42a7b19fe8e1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 94220501000,
    "oktmo": 94620101001,
    "tax_office": 1838,
    "timezone": "UTC+4",
    "geo_lat": 56.2659916,
    "geo_lon": 54.193374,
    "population": 11028,
    "foundation_year": 1767
  },
  {
    "address": "Респ Удмуртская, г Можга",
    "postal_code": 427759,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Можга",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1800000500000,
    "fias_id": "7096d8a5-2b41-47b1-95ae-35efcbc07dee",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 94430000000,
    "oktmo": 94730000001,
    "tax_office": 1839,
    "timezone": "UTC+4",
    "geo_lat": 56.4427774,
    "geo_lon": 52.2137886,
    "population": 47959,
    "foundation_year": 1835
  },
  {
    "address": "Респ Удмуртская, г Сарапул",
    "postal_code": 427960,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Респ",
    "region": "Удмуртская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сарапул",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1800000200000,
    "fias_id": "e69a280f-9064-490e-bae0-8bd39527872f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 94440000000,
    "oktmo": 94740000001,
    "tax_office": 1838,
    "timezone": "UTC+4",
    "geo_lat": 56.4615767,
    "geo_lon": 53.8037657,
    "population": 101390,
    "foundation_year": 1621
  },
  {
    "address": "Ульяновская обл, Барышский р-н, г Барыш",
    "postal_code": 433750,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "р-н",
    "area": "Барышский",
    "city_type": "г",
    "city": "Барыш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7300300100000,
    "fias_id": "52cccd1a-84fd-448a-a91e-36f113b9946f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 73204501000,
    "oktmo": 73604101001,
    "tax_office": 7309,
    "timezone": "UTC+4",
    "geo_lat": 53.6533992,
    "geo_lon": 47.1181134,
    "population": 17149,
    "foundation_year": 1600
  },
  {
    "address": "Ульяновская обл, г Димитровград",
    "postal_code": 433500,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Димитровград",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7300000200000,
    "fias_id": "73b29372-242c-42c5-89cd-8814bc2368af",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 73405000000,
    "oktmo": 73705000001,
    "tax_office": 7329,
    "timezone": "UTC+4",
    "geo_lat": 54.2167926,
    "geo_lon": 49.6262585,
    "population": 122549,
    "foundation_year": 1698
  },
  {
    "address": "Ульяновская обл, г Инза",
    "postal_code": 433030,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "р-н",
    "area": "Инзенский",
    "city_type": "г",
    "city": "Инза",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7300500100000,
    "fias_id": "a23672b1-86cf-4b07-b1a1-cf0b58e7419b",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 73210501000,
    "oktmo": 73610101001,
    "tax_office": 7309,
    "timezone": "UTC+4",
    "geo_lat": 53.8549647,
    "geo_lon": 46.3533459,
    "population": 18547,
    "foundation_year": 1897
  },
  {
    "address": "Ульяновская обл, г Новоульяновск",
    "postal_code": 433300,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новоульяновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7300000400000,
    "fias_id": "c21a50ef-de67-477c-887b-a1202730ee8e",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 73415000000,
    "oktmo": 73715000001,
    "tax_office": 7321,
    "timezone": "UTC+4",
    "geo_lat": 54.1447956,
    "geo_lon": 48.3910789,
    "population": 16032,
    "foundation_year": 1957
  },
  {
    "address": "Ульяновская обл, г Сенгилей",
    "postal_code": 433380,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "р-н",
    "area": "Сенгилеевский",
    "city_type": "г",
    "city": "Сенгилей",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7301500100000,
    "fias_id": "b9e9ca48-b953-4999-bf0e-608c57c0d0a0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 73236501000,
    "oktmo": 73636101001,
    "tax_office": 7321,
    "timezone": "UTC+4",
    "geo_lat": 53.958964,
    "geo_lon": 48.7768269,
    "population": 6959,
    "foundation_year": 1666
  },
  {
    "address": "г Ульяновск",
    "postal_code": 432000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "обл",
    "region": "Ульяновская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ульяновск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7300000100000,
    "fias_id": "bebfd75d-a0da-4bf9-8307-2e2c85eac463",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 73401000000,
    "oktmo": 73701000001,
    "tax_office": 7300,
    "timezone": "UTC+4",
    "geo_lat": 54.3079415,
    "geo_lon": 48.3748487,
    "population": 613793,
    "foundation_year": 1648
  },
  {
    "address": "Хабаровский край, г Амурск",
    "postal_code": 682640,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Амурск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000300000,
    "fias_id": "d3c4b43d-3e19-4454-939b-d92ef3d6c875",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "08403000000",
    "oktmo": "08603101001",
    "tax_office": 2728,
    "timezone": "UTC+10",
    "geo_lat": 50.2344147,
    "geo_lon": 136.8792444,
    "population": 42977,
    "foundation_year": 1958
  },
  {
    "address": "Хабаровский край, г Бикин",
    "postal_code": 682970,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Бикин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000400000,
    "fias_id": "70dc89f8-f067-482a-bd50-4fb2782463e7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "08406000000",
    "oktmo": "08609101001",
    "tax_office": 2720,
    "timezone": "UTC+10",
    "geo_lat": 46.8185743,
    "geo_lon": 134.2550718,
    "population": 17156,
    "foundation_year": 1895
  },
  {
    "address": "Хабаровский край, г Вяземский",
    "postal_code": 682950,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "р-н",
    "area": "Вяземский",
    "city_type": "г",
    "city": "Вяземский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700700100000,
    "fias_id": "6dbd1c8b-7c73-4ff7-9cbd-7062ac0cc88d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": "08217501000",
    "oktmo": "08617101001",
    "tax_office": 2720,
    "timezone": "UTC+10",
    "geo_lat": 47.5353379,
    "geo_lon": 134.7553856,
    "population": 14556,
    "foundation_year": 1894
  },
  {
    "address": "Хабаровский край, г Комсомольск-на-Амуре",
    "postal_code": 681000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Комсомольск-на-Амуре",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000500000,
    "fias_id": "a29c5b20-5056-412b-9af6-7b805aa3ea72",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "08409000000",
    "oktmo": "08709000001",
    "tax_office": 2703,
    "timezone": "UTC+10",
    "geo_lat": 50.5498936,
    "geo_lon": 137.0079408,
    "population": 263906,
    "foundation_year": 1932
  },
  {
    "address": "Хабаровский край, г Николаевск-на-Амуре",
    "postal_code": 682460,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Николаевск-на-Амуре",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000600000,
    "fias_id": "7a58fb7c-6d03-46e4-b5fc-3f5b587c09be",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "08414000000",
    "oktmo": "08631101001",
    "tax_office": 2705,
    "timezone": "UTC+10",
    "geo_lat": 53.1460657,
    "geo_lon": 140.7111367,
    "population": 22773,
    "foundation_year": 1850
  },
  {
    "address": "Хабаровский край, г Советская Гавань",
    "postal_code": 682800,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Советская Гавань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000700000,
    "fias_id": "64f0ebe7-00e4-40a0-9dd9-15f9293632ae",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": "08418000000",
    "oktmo": "08642101001",
    "tax_office": 2709,
    "timezone": "UTC+10",
    "geo_lat": 48.9664966,
    "geo_lon": 140.285174,
    "population": 27712,
    "foundation_year": 1853
  },
  {
    "address": "г Хабаровск",
    "postal_code": 680000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "край",
    "region": "Хабаровский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Хабаровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2700000100000,
    "fias_id": "a4859da8-9977-4b62-8436-4e1b98c5d13f",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": "08401000000",
    "oktmo": "08701000001",
    "tax_office": 2700,
    "timezone": "UTC+10",
    "geo_lat": 48.4647258,
    "geo_lon": 135.0598942,
    "population": 577668,
    "foundation_year": 1858
  },
  {
    "address": "Респ Хакасия, г Абаза",
    "postal_code": 655750,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Хакасия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Абаза",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1900000400000,
    "fias_id": "a369b520-09b4-49b2-b28c-61c9146153e6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 95402000000,
    "oktmo": 95702000001,
    "tax_office": 1900,
    "timezone": "UTC+7",
    "geo_lat": 52.6516647,
    "geo_lon": 90.0885686,
    "population": 17111,
    "foundation_year": 1867
  },
  {
    "address": "г Абакан",
    "postal_code": 655000,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Хакасия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Абакан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1900000100000,
    "fias_id": "42a02e11-a337-4d50-8596-fc76dae7c62a",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 95401000000,
    "oktmo": 95701000001,
    "tax_office": 1900,
    "timezone": "UTC+7",
    "geo_lat": 53.7223325,
    "geo_lon": 91.4436721,
    "population": 165183,
    "foundation_year": 1734
  },
  {
    "address": "Респ Хакасия, г Саяногорск",
    "postal_code": 655602,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Хакасия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Саяногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1900000200000,
    "fias_id": "f89db729-bbfa-4d64-b8d5-ecafbec0733d",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 95408000000,
    "oktmo": 95708000001,
    "tax_office": 1900,
    "timezone": "UTC+7",
    "geo_lat": 53.1008083,
    "geo_lon": 91.4122454,
    "population": 49889,
    "foundation_year": 1975
  },
  {
    "address": "Респ Хакасия, г Сорск",
    "postal_code": 655111,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Хакасия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1900000500000,
    "fias_id": "bf626532-9e6c-4365-801e-c1d297ab40f0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 95409000000,
    "oktmo": 95709000001,
    "tax_office": 1900,
    "timezone": "UTC+7",
    "geo_lat": 54.0002888,
    "geo_lon": 90.2594446,
    "population": 12140,
    "foundation_year": 1940
  },
  {
    "address": "Респ Хакасия, г Черногорск",
    "postal_code": 655151,
    "country": "Россия",
    "federal_district": "Сибирский",
    "region_type": "Респ",
    "region": "Хакасия",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Черногорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1900000300000,
    "fias_id": "2e3ec6d8-3835-4c57-9fbc-7e61b58a03a3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 95415000000,
    "oktmo": 95715000001,
    "tax_office": 1900,
    "timezone": "UTC+7",
    "geo_lat": 53.8259342,
    "geo_lon": 91.3260229,
    "population": 72117,
    "foundation_year": 1936
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Белоярский",
    "postal_code": 628160,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Белоярский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001300000,
    "fias_id": "db0b028c-2da0-4fb9-af00-6fed7a7644a0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71181000000,
    "oktmo": 71811151001,
    "tax_office": 8611,
    "timezone": "UTC+5",
    "geo_lat": 63.7121099,
    "geo_lon": 66.6772226,
    "population": 20283,
    "foundation_year": 1969
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Когалым",
    "postal_code": 628481,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Когалым",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000200000,
    "fias_id": "5a08166f-cfaa-4e95-8233-f0d473883bd3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71183000000,
    "oktmo": 71883000001,
    "tax_office": 8617,
    "timezone": "UTC+5",
    "geo_lat": 62.2639527,
    "geo_lon": 74.4829794,
    "population": 58192,
    "foundation_year": 1975
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Лангепас",
    "postal_code": 628671,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лангепас",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000300000,
    "fias_id": "149e651b-5dd3-480f-a372-5174576609f6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71132000000,
    "oktmo": 71872000001,
    "tax_office": 8607,
    "timezone": "UTC+5",
    "geo_lat": 61.2536939,
    "geo_lon": 75.1807763,
    "population": 41675,
    "foundation_year": 1980
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, Сургутский р-н, г Лянтор",
    "postal_code": 628449,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "р-н",
    "area": "Сургутский",
    "city_type": "г",
    "city": "Лянтор",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600900200000,
    "fias_id": "e7e90d82-aecc-4e9c-a373-3a7afcd5fed3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71126605000,
    "oktmo": 71826105001,
    "tax_office": 8617,
    "timezone": "UTC+5",
    "geo_lat": 61.6392863,
    "geo_lon": 72.179409,
    "population": 38922,
    "foundation_year": 1932
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Мегион",
    "postal_code": 628680,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Мегион",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000400000,
    "fias_id": "d9c157ca-fd05-4efc-ae0c-16927612a0c8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71133000000,
    "oktmo": 71873000001,
    "tax_office": 8607,
    "timezone": "UTC+5",
    "geo_lat": 61.0318712,
    "geo_lon": 76.1025878,
    "population": 49471,
    "foundation_year": 1810
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Нефтеюганск",
    "postal_code": 628301,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нефтеюганск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001400000,
    "fias_id": "45906532-143b-48c2-9af3-f480dc19c7bf",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71134000000,
    "oktmo": 71874000001,
    "tax_office": 8619,
    "timezone": "UTC+5",
    "geo_lat": 61.0882676,
    "geo_lon": 72.6164079,
    "population": 123276,
    "foundation_year": 1961
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Нижневартовск",
    "postal_code": 628600,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нижневартовск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001100000,
    "fias_id": "0bf0f4ed-13f8-446e-82f6-325498808076",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71135000000,
    "oktmo": 71875000001,
    "tax_office": 8603,
    "timezone": "UTC+5",
    "geo_lat": 60.9396698,
    "geo_lon": 76.5696184,
    "population": 251860,
    "foundation_year": 1909
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Нягань",
    "postal_code": 628181,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нягань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000500000,
    "fias_id": "06157075-a993-404d-b940-0a103131dc66",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71139000000,
    "oktmo": 71879000001,
    "tax_office": 8610,
    "timezone": "UTC+5",
    "geo_lat": 62.1454701,
    "geo_lon": 65.3946047,
    "population": 54903,
    "foundation_year": 1965
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Покачи",
    "postal_code": 628660,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Покачи",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000600000,
    "fias_id": "8bac4b94-1d16-42b5-b5c6-211aa52f3216",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71184000000,
    "oktmo": 71884000001,
    "tax_office": 8607,
    "timezone": "UTC+5",
    "geo_lat": 61.7422169,
    "geo_lon": 75.5941517,
    "population": 17053,
    "foundation_year": 1984
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Пыть-Ях",
    "postal_code": 628380,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Пыть-Ях",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000700000,
    "fias_id": "130857a0-7059-4f18-9a13-c17ef6c4f9ca",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71185000000,
    "oktmo": 71885000001,
    "tax_office": 8619,
    "timezone": "UTC+5",
    "geo_lat": 60.7585833,
    "geo_lon": 72.8365617,
    "population": 41453,
    "foundation_year": 1968
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Радужный",
    "postal_code": 628461,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Радужный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001500000,
    "fias_id": "394a840f-9502-406f-a8be-3a2aa9e8f075",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71137000000,
    "oktmo": 71877000001,
    "tax_office": 8603,
    "timezone": "UTC+5",
    "geo_lat": 62.1342888,
    "geo_lon": 77.4584094,
    "population": 43394,
    "foundation_year": 1973
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Советский",
    "postal_code": 628240,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "р-н",
    "area": "Советский",
    "city_type": "г",
    "city": "Советский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600800100000,
    "fias_id": "b2487322-b3b1-48fc-a462-cf06c36fac91",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 71124604000,
    "oktmo": 71824104001,
    "tax_office": 8600,
    "timezone": "UTC+5",
    "geo_lat": 61.3706913,
    "geo_lon": 63.5667222,
    "population": 26434,
    "foundation_year": 1963
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Сургут",
    "postal_code": 628400,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Сургут",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001000000,
    "fias_id": "f1eb1809-47d4-4f0b-9a74-fa416e9d3df2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71136000000,
    "oktmo": 71876000001,
    "tax_office": 8602,
    "timezone": "UTC+5",
    "geo_lat": 61.2541083,
    "geo_lon": 73.3961587,
    "population": 306703,
    "foundation_year": 1594
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Урай",
    "postal_code": 628280,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Урай",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000900000,
    "fias_id": "610abc14-c127-4d7c-8697-31cb5c7c47f2",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71138000000,
    "oktmo": 71878000001,
    "tax_office": 8606,
    "timezone": "UTC+5",
    "geo_lat": 60.1296954,
    "geo_lon": 64.8038508,
    "population": 39435,
    "foundation_year": 1922
  },
  {
    "address": "г Ханты-Мансийск",
    "postal_code": 628000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ханты-Мансийск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600000100000,
    "fias_id": "d680d1a9-ff89-42c0-b39f-143d2ffb520a",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 71131000000,
    "oktmo": 71871000001,
    "tax_office": 8601,
    "timezone": "UTC+5",
    "geo_lat": 61.0023984,
    "geo_lon": 69.0184798,
    "population": 79410,
    "foundation_year": 1582
  },
  {
    "address": "Ханты-Мансийский Автономный округ - Югра АО, г Югорск",
    "postal_code": 628260,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ханты-Мансийский Автономный округ - Югра",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Югорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8600001600000,
    "fias_id": "abb05e81-bd8b-4e44-abf7-384c9eba3407",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71187000000,
    "oktmo": 71887000001,
    "tax_office": 8622,
    "timezone": "UTC+5",
    "geo_lat": 61.3123568,
    "geo_lon": 63.3365484,
    "population": 34066,
    "foundation_year": 1962
  },
  {
    "address": "Челябинская обл, г Аша",
    "postal_code": 456015,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Ашинский",
    "city_type": "г",
    "city": "Аша",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400200300000,
    "fias_id": "c1c960ba-28ed-4a23-9663-668a10e8dbc1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75403000000,
    "oktmo": 75609101001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.9906527,
    "geo_lon": 57.2783953,
    "population": 31916,
    "foundation_year": 1898
  },
  {
    "address": "Челябинская обл, Саткинский р-н, г Бакал",
    "postal_code": 456900,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Саткинский",
    "city_type": "г",
    "city": "Бакал",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7401700100000,
    "fias_id": "f0eff629-59f9-46e3-a3e1-70003376fbd8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75249503000,
    "oktmo": 75649103001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.9406399,
    "geo_lon": 58.8051698,
    "population": 20953,
    "foundation_year": 1757
  },
  {
    "address": "Челябинская обл, г Верхнеуральск",
    "postal_code": 457670,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Верхнеуральский",
    "city_type": "г",
    "city": "Верхнеуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7402900100000,
    "fias_id": "e4a3d40b-d937-4eef-900f-e16a2244a4c1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75217501000,
    "oktmo": 75617101001,
    "tax_office": 7455,
    "timezone": "UTC+5",
    "geo_lat": 53.8760961,
    "geo_lon": 59.2169852,
    "population": 9459,
    "foundation_year": 1734
  },
  {
    "address": "Челябинская обл, г Верхний Уфалей",
    "postal_code": 456800,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Верхний Уфалей",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000200000,
    "fias_id": "03aa453a-c7d6-4567-9a59-34a9e9cc1eac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75406000000,
    "oktmo": 75706000001,
    "tax_office": 7459,
    "timezone": "UTC+5",
    "geo_lat": 56.0487158,
    "geo_lon": 60.2318886,
    "population": 30504,
    "foundation_year": 1761
  },
  {
    "address": "Челябинская обл, г Еманжелинск",
    "postal_code": 456580,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Еманжелинский",
    "city_type": "г",
    "city": "Еманжелинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7404400100000,
    "fias_id": "450daf49-c6ce-4bbd-9e3e-350e6ad3bc6c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75409000000,
    "oktmo": 75619101001,
    "tax_office": 7430,
    "timezone": "UTC+5",
    "geo_lat": 54.7554548,
    "geo_lon": 61.3243477,
    "population": 30218,
    "foundation_year": 1931
  },
  {
    "address": "Челябинская обл, г Златоуст",
    "postal_code": 456200,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Златоуст",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000400000,
    "fias_id": "110c731e-d72b-4c37-91cb-03ce33d9e727",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75412000000,
    "oktmo": 75712000001,
    "tax_office": 7404,
    "timezone": "UTC+5",
    "geo_lat": 55.1714905,
    "geo_lon": 59.6725549,
    "population": 174985,
    "foundation_year": 1754
  },
  {
    "address": "Челябинская обл, г Карабаш",
    "postal_code": 456140,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Карабаш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000500000,
    "fias_id": "dbca0fdd-89a6-43ad-9bd0-9796236917d7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75415000000,
    "oktmo": 75715000001,
    "tax_office": 7400,
    "timezone": "UTC+5",
    "geo_lat": 55.4852323,
    "geo_lon": 60.2358881,
    "population": 13151,
    "foundation_year": 1822
  },
  {
    "address": "Челябинская обл, г Карталы",
    "postal_code": 457353,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Карталинский",
    "city_type": "г",
    "city": "Карталы",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400700100000,
    "fias_id": "8de6102a-50a8-4bd4-9552-a265cb82b57f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75418000000,
    "oktmo": 75623101001,
    "tax_office": 7458,
    "timezone": "UTC+5",
    "geo_lat": 53.0536197,
    "geo_lon": 60.6478408,
    "population": 29136,
    "foundation_year": 1810
  },
  {
    "address": "Челябинская обл, г Касли",
    "postal_code": 456833,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Каслинский",
    "city_type": "г",
    "city": "Касли",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400900100000,
    "fias_id": "9db9997b-8945-4ae2-9d14-6dfe0dd01c7e",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75421000000,
    "oktmo": 75626101001,
    "tax_office": 7459,
    "timezone": "UTC+5",
    "geo_lat": 55.8868784,
    "geo_lon": 60.7421663,
    "population": 16998,
    "foundation_year": 1747
  },
  {
    "address": "Челябинская обл, г Катав-Ивановск",
    "postal_code": 456110,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Катав-Ивановский",
    "city_type": "г",
    "city": "Катав-Ивановск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7401000200000,
    "fias_id": "5c0f59dc-daeb-4891-a620-900a59d8cabd",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75424000000,
    "oktmo": 75629101001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.7521438,
    "geo_lon": 58.1983648,
    "population": 17640,
    "foundation_year": 1755
  },
  {
    "address": "Челябинская обл, г Копейск",
    "postal_code": 456600,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Копейск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000600000,
    "fias_id": "69462f61-d1da-4e61-a970-3b71c6623002",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75428000000,
    "oktmo": 75728000001,
    "tax_office": 7430,
    "timezone": "UTC+5",
    "geo_lat": 55.116665,
    "geo_lon": 61.6179185,
    "population": 137604,
    "foundation_year": 1907
  },
  {
    "address": "Челябинская обл, г Коркино",
    "postal_code": 456550,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Коркинский",
    "city_type": "г",
    "city": "Коркино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7404500100000,
    "fias_id": "34bc4096-0b85-4b90-bac1-2539cb030041",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75431000000,
    "oktmo": 75633101001,
    "tax_office": 7430,
    "timezone": "UTC+5",
    "geo_lat": 54.8903147,
    "geo_lon": 61.4034576,
    "population": 38950,
    "foundation_year": 1795
  },
  {
    "address": "Челябинская обл, г Куса",
    "postal_code": 456940,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Кусинский",
    "city_type": "г",
    "city": "Куса",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7403400100000,
    "fias_id": "94812112-feed-454d-a3d2-45ba7bc691d0",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75238501000,
    "oktmo": 75638101001,
    "tax_office": 7404,
    "timezone": "UTC+5",
    "geo_lat": 55.3386053,
    "geo_lon": 59.4385778,
    "population": 18792,
    "foundation_year": 1778
  },
  {
    "address": "Челябинская обл, г Кыштым",
    "postal_code": 456870,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Кыштым",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000800000,
    "fias_id": "969d3bb6-4d8e-4130-902b-b70571090302",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75434000000,
    "oktmo": 75734000001,
    "tax_office": 7413,
    "timezone": "UTC+5",
    "geo_lat": 55.7061276,
    "geo_lon": 60.5563781,
    "population": 38950,
    "foundation_year": 1757
  },
  {
    "address": "Челябинская обл, г Магнитогорск",
    "postal_code": 455000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Магнитогорск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000900000,
    "fias_id": "988157bf-d6d5-4c2a-80ec-4ad531eea056",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75438000000,
    "oktmo": 75738000001,
    "tax_office": 7400,
    "timezone": "UTC+5",
    "geo_lat": 53.4072153,
    "geo_lon": 58.9791437,
    "population": 408401,
    "foundation_year": 1929
  },
  {
    "address": "Челябинская обл, г Миасс",
    "postal_code": 456300,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Миасс",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001000000,
    "fias_id": "f2976e80-32e1-4284-8eda-06cf19239cd1",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75442000000,
    "oktmo": 75742000001,
    "tax_office": 7415,
    "timezone": "UTC+5",
    "geo_lat": 55.0456457,
    "geo_lon": 60.1077572,
    "population": 151812,
    "foundation_year": 1773
  },
  {
    "address": "Челябинская обл, Ашинский р-н, г Миньяр",
    "postal_code": 456007,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Ашинский",
    "city_type": "г",
    "city": "Миньяр",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400200100000,
    "fias_id": "855ef067-23aa-425a-aa9b-cadbfb1f9fff",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75209503000,
    "oktmo": 75609103001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 55.0709557,
    "geo_lon": 57.548478,
    "population": 10195,
    "foundation_year": 1771
  },
  {
    "address": "Челябинская обл, г Нязепетровск",
    "postal_code": 456971,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Нязепетровский",
    "city_type": "г",
    "city": "Нязепетровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7403600100000,
    "fias_id": "e42b53fb-fed8-4ee7-94f3-6190e179d249",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75244501000,
    "oktmo": 75644101001,
    "tax_office": 7459,
    "timezone": "UTC+5",
    "geo_lat": 56.0536895,
    "geo_lon": 59.6097202,
    "population": 12452,
    "foundation_year": 1747
  },
  {
    "address": "Челябинская обл, г Озерск",
    "postal_code": 456780,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Озерск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001100000,
    "fias_id": "8192c863-edbf-4825-8523-0612ae80e5a7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75543000000,
    "oktmo": 75743000001,
    "tax_office": 7413,
    "timezone": "UTC+5",
    "geo_lat": 55.763154,
    "geo_lon": 60.7076198,
    "population": 82268,
    "foundation_year": 1945
  },
  {
    "address": "Челябинская обл, г Пласт",
    "postal_code": 457020,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Пластовский",
    "city_type": "г",
    "city": "Пласт",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7404600100000,
    "fias_id": "4828bba2-6758-4e7b-a7ef-ff07f83e9563",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75445000000,
    "oktmo": 75648101001,
    "tax_office": 7424,
    "timezone": "UTC+5",
    "geo_lat": 54.3692764,
    "geo_lon": 60.8151894,
    "population": 17344,
    "foundation_year": 1940
  },
  {
    "address": "Челябинская обл, г Сатка",
    "postal_code": 456913,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Саткинский",
    "city_type": "г",
    "city": "Сатка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7401700200000,
    "fias_id": "8bcc16d4-ead7-4938-944c-862c7e6c67aa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 75448000000,
    "oktmo": 75649101001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 55.0405288,
    "geo_lon": 59.0288975,
    "population": 45465,
    "foundation_year": 1758
  },
  {
    "address": "Челябинская обл, Ашинский р-н, г Сим",
    "postal_code": 456020,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Ашинский",
    "city_type": "г",
    "city": "Сим",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400200200000,
    "fias_id": "b23193d5-43a7-4663-8fba-7ee34ae4db77",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75209505000,
    "oktmo": 75609105001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.9907827,
    "geo_lon": 57.6900155,
    "population": 14465,
    "foundation_year": 1759
  },
  {
    "address": "Челябинская обл, г Снежинск",
    "postal_code": 456770,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Снежинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001300000,
    "fias_id": "08aad8a8-0067-4161-8389-b75d1da72866",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75545000000,
    "oktmo": 75746000001,
    "tax_office": 7459,
    "timezone": "UTC+5",
    "geo_lat": 56.0851495,
    "geo_lon": 60.7324914,
    "population": 48896,
    "foundation_year": 1957
  },
  {
    "address": "Челябинская обл, г Трехгорный",
    "postal_code": 456080,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Трехгорный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001400000,
    "fias_id": "097d2fcb-d22a-4fbd-89bf-27950b7116a3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75507000000,
    "oktmo": 75707000001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.8178249,
    "geo_lon": 58.4464194,
    "population": 33678,
    "foundation_year": 1952
  },
  {
    "address": "Челябинская обл, г Троицк",
    "postal_code": 457100,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Троицк",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400003600000,
    "fias_id": "b5ad78c2-cc8f-4769-9a9f-75743d8da02f",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75452000000,
    "oktmo": 75752000001,
    "tax_office": 7424,
    "timezone": "UTC+5",
    "geo_lat": 54.0843745,
    "geo_lon": 61.5586831,
    "population": 78637,
    "foundation_year": 1743
  },
  {
    "address": "Челябинская обл, г Усть-Катав",
    "postal_code": 456040,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Усть-Катав",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001500000,
    "fias_id": "327f11c6-474f-44f9-aff3-8ba1780fcaf0",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75455000000,
    "oktmo": 75755000001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.9260812,
    "geo_lon": 58.152805,
    "population": 23586,
    "foundation_year": 1758
  },
  {
    "address": "Челябинская обл, г Чебаркуль",
    "postal_code": 456439,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чебаркуль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400003500000,
    "fias_id": "1fe59b79-b019-45d7-bfd9-03b3b2d49cb6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75458000000,
    "oktmo": 75758000001,
    "tax_office": 7415,
    "timezone": "UTC+5",
    "geo_lat": 54.9818567,
    "geo_lon": 60.3773121,
    "population": 43405,
    "foundation_year": 1736
  },
  {
    "address": "г Челябинск",
    "postal_code": 454000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Челябинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400000100000,
    "fias_id": "a376e68d-724a-4472-be7c-891bdb09ae32",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 75401000000,
    "oktmo": 75701000001,
    "tax_office": 7400,
    "timezone": "UTC+5",
    "geo_lat": 55.1602624,
    "geo_lon": 61.4008078,
    "population": 1130273,
    "foundation_year": 1736
  },
  {
    "address": "Челябинская обл, г Южноуральск",
    "postal_code": 457040,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Южноуральск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7400001600000,
    "fias_id": "2ac904d8-365e-4f49-8ff9-f14f88609535",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75464000000,
    "oktmo": 75764000001,
    "tax_office": 7424,
    "timezone": "UTC+5",
    "geo_lat": 54.448927,
    "geo_lon": 61.2581158,
    "population": 37890,
    "foundation_year": 1948
  },
  {
    "address": "Челябинская обл, Катав-Ивановский р-н, г Юрюзань",
    "postal_code": 456120,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "обл",
    "region": "Челябинская",
    "area_type": "р-н",
    "area": "Катав-Ивановский",
    "city_type": "г",
    "city": "Юрюзань",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7401000100000,
    "fias_id": "162b9632-9ebb-4143-80fd-2d0c4a978243",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 75229516000,
    "oktmo": 75629116001,
    "tax_office": 7457,
    "timezone": "UTC+5",
    "geo_lat": 54.854662,
    "geo_lon": 58.4226698,
    "population": 12568,
    "foundation_year": 1758
  },
  {
    "address": "Респ Чеченская, г Аргун",
    "postal_code": "",
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Аргун",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2000000200000,
    "fias_id": "3672454d-2697-4ce1-9666-cc9c744ba5c5",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 96402000000,
    "oktmo": 96702000001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.2916774,
    "geo_lon": 45.8723105,
    "population": 29528,
    "foundation_year": 1700
  },
  {
    "address": "г Грозный",
    "postal_code": 364000,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Грозный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2000000100000,
    "fias_id": "a2072dc5-45be-4db3-ab13-10784ba8b2ae",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 96401360000,
    "oktmo": 96701000001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.3180145,
    "geo_lon": 45.698291,
    "population": 271596,
    "foundation_year": 1818
  },
  {
    "address": "Респ Чеченская, г Гудермес",
    "postal_code": 366200,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "р-н",
    "area": "Гудермесский",
    "city_type": "г",
    "city": "Гудермес",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2000500100000,
    "fias_id": "ab370c6d-8fd0-4e6b-9491-d83daaa829c9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 96404000000,
    "oktmo": 96610101001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.3519142,
    "geo_lon": 46.1035645,
    "population": 45643,
    "foundation_year": 1800
  },
  {
    "address": "Респ Чеченская, г Курчалой",
    "postal_code": 366314,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "р-н",
    "area": "Курчалоевский",
    "city_type": "г",
    "city": "Курчалой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2000600100000,
    "fias_id": "37464bf9-053a-4c84-bc16-b9f202749794",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 96212501000,
    "oktmo": 96612101001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.2046547,
    "geo_lon": 46.0889364,
    "population": 25672,
    "foundation_year": 1860
  },
  {
    "address": "Респ Чеченская, г Урус-Мартан",
    "postal_code": 366500,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "р-н",
    "area": "Урус-Мартановский",
    "city_type": "",
    "city": "Урус-Мартан",
    "settlement_type": "г",
    "settlement": "Урус-Мартан",
    "kladr_id": 2001000000100,
    "fias_id": "eb208b95-1f50-4290-a9c9-46909aea49a0",
    "fias_level": 6,
    "capital_marker": 1,
    "okato": 96234501000,
    "oktmo": 96634101001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.120175,
    "geo_lon": 45.539276,
    "population": 49071,
    "foundation_year": 1700
  },
  {
    "address": "Респ Чеченская, г Шали",
    "postal_code": 366300,
    "country": "Россия",
    "federal_district": "Северо-Кавказский",
    "region_type": "Респ",
    "region": "Чеченская",
    "area_type": "р-н",
    "area": "Шалинский",
    "city_type": "г",
    "city": "Шали",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2001200100000,
    "fias_id": "f206e5a7-d074-44cf-a0f3-a9ca855969d2",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 96237501000,
    "oktmo": 96637101001,
    "tax_office": 2000,
    "timezone": "UTC+3",
    "geo_lat": 43.1488691,
    "geo_lon": 45.9009629,
    "population": 47715,
    "foundation_year": 1378
  },
  {
    "address": "Чувашская Республика - Чувашия, г Алатырь",
    "postal_code": 429820,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Алатырь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100002200000,
    "fias_id": "a699e866-12f0-42c3-94fd-67ca44468805",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 97404000000,
    "oktmo": 97704000001,
    "tax_office": 2131,
    "timezone": "UTC+3",
    "geo_lat": 54.8397989,
    "geo_lon": 46.5721997,
    "population": 38202,
    "foundation_year": 1552
  },
  {
    "address": "Чувашская Республика - Чувашия, г Канаш",
    "postal_code": 429330,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Канаш",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100002300000,
    "fias_id": "6edd2a33-d03a-4d59-83c3-e14de6890a49",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 97407000000,
    "oktmo": 97707000001,
    "tax_office": 2134,
    "timezone": "UTC+3",
    "geo_lat": 55.507,
    "geo_lon": 47.4918273,
    "population": 45608,
    "foundation_year": 1892
  },
  {
    "address": "Чувашская Республика - Чувашия, г Козловка",
    "postal_code": 429430,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "р-н",
    "area": "Козловский",
    "city_type": "г",
    "city": "Козловка",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100800100000,
    "fias_id": "e4cdd522-24f9-4fa7-a900-7bd35be4ada5",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 97219501000,
    "oktmo": 97619101001,
    "tax_office": 2137,
    "timezone": "UTC+3",
    "geo_lat": 55.8406025,
    "geo_lon": 48.2577735,
    "population": 10355,
    "foundation_year": 1671
  },
  {
    "address": "Чувашская Республика - Чувашия, г Мариинский Посад",
    "postal_code": 429570,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "р-н",
    "area": "Мариинско-Посадский",
    "city_type": "г",
    "city": "Мариинский Посад",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2101200100000,
    "fias_id": "a3514c10-c53a-4c6a-a945-c1a9b7bc6d31",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 97229501000,
    "oktmo": 97629101001,
    "tax_office": 2135,
    "timezone": "UTC+3",
    "geo_lat": 56.111923,
    "geo_lon": 47.7142942,
    "population": 10186,
    "foundation_year": 1620
  },
  {
    "address": "Чувашская Республика - Чувашия, г Новочебоксарск",
    "postal_code": 429950,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новочебоксарск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100002400000,
    "fias_id": "32599307-3fe7-4cf4-8fee-640044422d68",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 97410000000,
    "oktmo": 97710000001,
    "tax_office": 2124,
    "timezone": "UTC+3",
    "geo_lat": 56.1094977,
    "geo_lon": 47.4791113,
    "population": 124094,
    "foundation_year": 1960
  },
  {
    "address": "Чувашская Республика - Чувашия, г Цивильск",
    "postal_code": 429900,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "р-н",
    "area": "Цивильский",
    "city_type": "г",
    "city": "Цивильск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2101600100000,
    "fias_id": "927d7cf4-f2e6-4617-9cf6-e73edf9fd9ad",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 97241501000,
    "oktmo": 97641101001,
    "tax_office": 2137,
    "timezone": "UTC+3",
    "geo_lat": 55.8650213,
    "geo_lon": 47.4729349,
    "population": 13478,
    "foundation_year": 1589
  },
  {
    "address": "г Чебоксары",
    "postal_code": 428000,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Чебоксары",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100000100000,
    "fias_id": "dd8caeab-c685-4f2a-bf5f-550aca1bbc48",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 97401000000,
    "oktmo": 97701000001,
    "tax_office": 2130,
    "timezone": "UTC+3",
    "geo_lat": 56.1438298,
    "geo_lon": 47.2489782,
    "population": 447929,
    "foundation_year": 1469
  },
  {
    "address": "Чувашская Республика - Чувашия, г Шумерля",
    "postal_code": 429120,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Шумерля",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2100002500000,
    "fias_id": "068dfc2d-3a52-4d7f-93bc-797c286e66ac",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 97413000000,
    "oktmo": 97713000001,
    "tax_office": 2138,
    "timezone": "UTC+3",
    "geo_lat": 55.4962415,
    "geo_lon": 46.4182681,
    "population": 33412,
    "foundation_year": 1916
  },
  {
    "address": "Чувашская Республика - Чувашия, г Ядрин",
    "postal_code": 429061,
    "country": "Россия",
    "federal_district": "Приволжский",
    "region_type": "Чувашия",
    "region": "Чувашская Республика",
    "area_type": "р-н",
    "area": "Ядринский",
    "city_type": "г",
    "city": "Ядрин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 2102000100000,
    "fias_id": "49bf68fa-6f47-4281-9c0f-b2ff8fa96cf6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 97253501000,
    "oktmo": 97653101001,
    "tax_office": 2138,
    "timezone": "UTC+3",
    "geo_lat": 55.9406974,
    "geo_lon": 46.2020896,
    "population": 9614,
    "foundation_year": 1590
  },
  {
    "address": "г Анадырь",
    "postal_code": 689000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "АО",
    "region": "Чукотский",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Анадырь",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8700000100000,
    "fias_id": "7fad3a21-06b4-4af3-9657-bf1521714952",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 77401000000,
    "oktmo": 77701000001,
    "tax_office": 8700,
    "timezone": "UTC+12",
    "geo_lat": 64.7313924,
    "geo_lon": 177.5015421,
    "population": 13053,
    "foundation_year": 1889
  },
  {
    "address": "Чукотский АО, г Билибино",
    "postal_code": 689450,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "АО",
    "region": "Чукотский",
    "area_type": "р-н",
    "area": "Билибинский",
    "city_type": "г",
    "city": "Билибино",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8700300100000,
    "fias_id": "c012d402-217c-488a-85f3-52b3312ddab6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 77209501000,
    "oktmo": 77609101,
    "tax_office": 8700,
    "timezone": "UTC+12",
    "geo_lat": 68.0584191,
    "geo_lon": 166.4388172,
    "population": 5504,
    "foundation_year": 1955
  },
  {
    "address": "Чукотский АО, г Певек",
    "postal_code": 689400,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "АО",
    "region": "Чукотский",
    "area_type": "р-н",
    "area": "Чаунский",
    "city_type": "г",
    "city": "Певек",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8700600100000,
    "fias_id": "e8a6aabb-9ea3-4862-9bf7-4b35460e98a4",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 77230501000,
    "oktmo": 77705000001,
    "tax_office": 8700,
    "timezone": "UTC+12",
    "geo_lat": 69.7016661,
    "geo_lon": 170.2999022,
    "population": 4161,
    "foundation_year": 1933
  },
  {
    "address": "Респ Саха /Якутия/, г Алдан",
    "postal_code": 678900,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Алданский",
    "city_type": "г",
    "city": "Алдан",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1400300100000,
    "fias_id": "29a64605-5441-48c5-bc08-117a72ebc843",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98203501000,
    "oktmo": 98603101001,
    "tax_office": 1402,
    "timezone": "UTC+9",
    "geo_lat": 58.6094283,
    "geo_lon": 125.3817188,
    "population": 21277,
    "foundation_year": 1924
  },
  {
    "address": "Респ Саха /Якутия/, у Верхоянский, г Верхоянск",
    "postal_code": 678530,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Верхоянский",
    "city_type": "г",
    "city": "Верхоянск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1401000200000,
    "fias_id": "86a078da-13f2-4c47-989a-cce0384a60d3",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 98216503000,
    "oktmo": 98616103,
    "tax_office": 1448,
    "timezone": "UTC+10",
    "geo_lat": 67.5502451,
    "geo_lon": 133.390735,
    "population": 1311,
    "foundation_year": 1638
  },
  {
    "address": "Респ Саха /Якутия/, г Вилюйск",
    "postal_code": 678200,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Вилюйский",
    "city_type": "г",
    "city": "Вилюйск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1401100100000,
    "fias_id": "52297f9a-df7f-4d97-9260-342aed1f0718",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98218501000,
    "oktmo": 98618101001,
    "tax_office": 1445,
    "timezone": "UTC+9",
    "geo_lat": 63.7517616,
    "geo_lon": 121.627284,
    "population": 10233,
    "foundation_year": 1634
  },
  {
    "address": "Респ Саха /Якутия/, г Ленск",
    "postal_code": 678141,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Ленский",
    "city_type": "г",
    "city": "Ленск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1401500100000,
    "fias_id": "fdb008ca-3f90-478f-988f-8ec51fc5cc26",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98227501000,
    "oktmo": 98627101001,
    "tax_office": 1450,
    "timezone": "UTC+9",
    "geo_lat": 60.7276196,
    "geo_lon": 114.9548255,
    "population": 24955,
    "foundation_year": 1663
  },
  {
    "address": "Респ Саха /Якутия/, г Мирный",
    "postal_code": 678170,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Мирнинский",
    "city_type": "г",
    "city": "Мирный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1401700100000,
    "fias_id": "62d403af-d460-4e79-a90b-8b6a351af3b1",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98404000000,
    "oktmo": 98631101001,
    "tax_office": 1436,
    "timezone": "UTC+9",
    "geo_lat": 62.536232,
    "geo_lon": 113.9667728,
    "population": 37179,
    "foundation_year": 1955
  },
  {
    "address": "Респ Саха /Якутия/, г Нерюнгри",
    "postal_code": 678960,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Нерюнгри",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1400000200000,
    "fias_id": "6ece5c38-f802-4f32-ad33-0409538c3562",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 98260501000,
    "oktmo": 98660101001,
    "tax_office": 1434,
    "timezone": "UTC+9",
    "geo_lat": 56.6599953,
    "geo_lon": 124.7202403,
    "population": 61746,
    "foundation_year": 1975
  },
  {
    "address": "Респ Саха /Якутия/, г Нюрба",
    "postal_code": 678450,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Нюрбинский",
    "city_type": "г",
    "city": "Нюрба",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1402100100000,
    "fias_id": "5749064c-e95a-4a2c-a274-9aba7b066003",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98409000000,
    "oktmo": 98626101001,
    "tax_office": 1445,
    "timezone": "UTC+9",
    "geo_lat": 63.2828955,
    "geo_lon": 118.3242437,
    "population": 10156,
    "foundation_year": 1824
  },
  {
    "address": "Респ Саха /Якутия/, г Олекминск",
    "postal_code": 678100,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Олекминский",
    "city_type": "г",
    "city": "Олекминск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1402300100000,
    "fias_id": "c3aba7f3-caf7-443f-a906-8d79e6ec37f6",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98241501000,
    "oktmo": 98641101001,
    "tax_office": 1450,
    "timezone": "UTC+9",
    "geo_lat": 60.3758006,
    "geo_lon": 120.4060878,
    "population": 9487,
    "foundation_year": 1635
  },
  {
    "address": "Респ Саха /Якутия/, г Покровск",
    "postal_code": 678000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Хангаласский",
    "city_type": "г",
    "city": "Покровск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1403200100000,
    "fias_id": "fbe75414-a226-41f0-916b-de889d2c84c8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98420000000,
    "oktmo": 98644101001,
    "tax_office": 1448,
    "timezone": "UTC+9",
    "geo_lat": 61.4843503,
    "geo_lon": 129.1482392,
    "population": 9495,
    "foundation_year": 1682
  },
  {
    "address": "Респ Саха /Якутия/, г Среднеколымск",
    "postal_code": 678790,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Среднеколымский",
    "city_type": "г",
    "city": "Среднеколымск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1402500100000,
    "fias_id": "42bf7165-9170-403c-bf86-ca1c19453e1c",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 98246501000,
    "oktmo": 98646101001,
    "tax_office": 1448,
    "timezone": "UTC+11",
    "geo_lat": 67.4582218,
    "geo_lon": 153.7069425,
    "population": 3525,
    "foundation_year": 1643
  },
  {
    "address": "Респ Саха /Якутия/, у Алданский, г Томмот",
    "postal_code": 678953,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Алданский",
    "city_type": "г",
    "city": "Томмот",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1400300200000,
    "fias_id": "ddf06654-8540-4b55-9a08-45497cc29a75",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 98203505000,
    "oktmo": 98603105001,
    "tax_office": 1402,
    "timezone": "UTC+9",
    "geo_lat": 58.9586859,
    "geo_lon": 126.2875462,
    "population": 8054,
    "foundation_year": 1923
  },
  {
    "address": "Респ Саха /Якутия/, у Мирнинский, г Удачный",
    "postal_code": 678188,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "у",
    "area": "Мирнинский",
    "city_type": "г",
    "city": "Удачный",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1401700200000,
    "fias_id": "e6fde0e4-6124-4e8d-82ce-5f97abe86cc6",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 98231509000,
    "oktmo": 98631109001,
    "tax_office": 1436,
    "timezone": "UTC+9",
    "geo_lat": 66.4071765,
    "geo_lon": 112.3061555,
    "population": 12611,
    "foundation_year": 1967
  },
  {
    "address": "г Якутск",
    "postal_code": 677000,
    "country": "Россия",
    "federal_district": "Дальневосточный",
    "region_type": "Респ",
    "region": "Саха /Якутия/",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Якутск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 1400000100000,
    "fias_id": "884c84a2-0141-4652-962d-8a92989b88f7",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 98401000000,
    "oktmo": 98701000001,
    "tax_office": 1447,
    "timezone": "UTC+9",
    "geo_lat": 62.0281405,
    "geo_lon": 129.7325887,
    "population": 269486,
    "foundation_year": 1632
  },
  {
    "address": "Ямало-Ненецкий АО, г Губкинский",
    "postal_code": 629830,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Губкинский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000200000,
    "fias_id": "0cb9e3a5-20fa-4248-8c1d-b31d5ce7f691",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71172000000,
    "oktmo": 71952000001,
    "tax_office": 8911,
    "timezone": "UTC+5",
    "geo_lat": 64.4457594,
    "geo_lon": 76.4713274,
    "population": 23340,
    "foundation_year": 1986
  },
  {
    "address": "Ямало-Ненецкий АО, г Лабытнанги",
    "postal_code": 629400,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Лабытнанги",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000300000,
    "fias_id": "6552fa92-269f-4b6a-bee5-fe9204463d99",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71173000000,
    "oktmo": 71953000001,
    "tax_office": 8901,
    "timezone": "UTC+5",
    "geo_lat": 66.6592841,
    "geo_lon": 66.3883009,
    "population": 26948,
    "foundation_year": 1890
  },
  {
    "address": "Ямало-Ненецкий АО, г Муравленко",
    "postal_code": 629601,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Муравленко",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000400000,
    "fias_id": "9d4ebe8d-29d8-4ddc-882d-75a5c5aec652",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71175000000,
    "oktmo": 71955000001,
    "tax_office": 8905,
    "timezone": "UTC+5",
    "geo_lat": 63.7940552,
    "geo_lon": 74.4948635,
    "population": 33401,
    "foundation_year": 1984
  },
  {
    "address": "Ямало-Ненецкий АО, г Надым",
    "postal_code": 629734,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Надым",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000500000,
    "fias_id": "24a3f6a2-5994-4ede-90d4-dc7fe64418b8",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71174000000,
    "oktmo": 71936000001,
    "tax_office": 8903,
    "timezone": "UTC+5",
    "geo_lat": 65.5377966,
    "geo_lon": 72.5182736,
    "population": 46550,
    "foundation_year": 1597
  },
  {
    "address": "Ямало-Ненецкий АО, г Новый Уренгой",
    "postal_code": 629300,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Новый Уренгой",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000600000,
    "fias_id": "4136d0c1-3ff7-44c0-aa30-fa2e7eb96ef7",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71176000000,
    "oktmo": 71956000001,
    "tax_office": 8904,
    "timezone": "UTC+5",
    "geo_lat": 66.0839433,
    "geo_lon": 76.6809681,
    "population": 104144,
    "foundation_year": 1975
  },
  {
    "address": "Ямало-Ненецкий АО, г Ноябрьск",
    "postal_code": 629800,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ноябрьск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000700000,
    "fias_id": "131c6e49-9bc5-484d-b65a-ee7f5d250418",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 71178000000,
    "oktmo": 71958000001,
    "tax_office": 8905,
    "timezone": "UTC+5",
    "geo_lat": 63.2018039,
    "geo_lon": 75.4510581,
    "population": 110572,
    "foundation_year": 1976
  },
  {
    "address": "г Салехард",
    "postal_code": 629000,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Салехард",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900000100000,
    "fias_id": "0c1b7f05-9fd9-4ec9-a2cc-5ee2799be1e6",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 71171000000,
    "oktmo": 71951000001,
    "tax_office": 8901,
    "timezone": "UTC+5",
    "geo_lat": 66.5492077,
    "geo_lon": 66.6085318,
    "population": 42494,
    "foundation_year": 1595
  },
  {
    "address": "Ямало-Ненецкий АО, г Тарко-Сале",
    "postal_code": 629850,
    "country": "Россия",
    "federal_district": "Уральский",
    "region_type": "АО",
    "region": "Ямало-Ненецкий",
    "area_type": "р-н",
    "area": "Пуровский",
    "city_type": "г",
    "city": "Тарко-Сале",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 8900400100000,
    "fias_id": "b998fcc0-d770-4754-9133-d669a2b2ba5d",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 71160605000,
    "oktmo": 71940000001,
    "tax_office": 8911,
    "timezone": "UTC+5",
    "geo_lat": 64.9118803,
    "geo_lon": 77.7610236,
    "population": 20372,
    "foundation_year": 1932
  },
  {
    "address": "Ярославская обл, г Гаврилов-Ям",
    "postal_code": 152240,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Гаврилов-Ямский",
    "city_type": "г",
    "city": "Гаврилов-Ям",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600500100000,
    "fias_id": "b94f9367-389f-4825-a837-d6af47171ed8",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78212501000,
    "oktmo": 78612101001,
    "tax_office": 7609,
    "timezone": "UTC+3",
    "geo_lat": 57.3091058,
    "geo_lon": 39.8546444,
    "population": 17792,
    "foundation_year": 1545
  },
  {
    "address": "Ярославская обл, г Данилов",
    "postal_code": 152070,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Даниловский",
    "city_type": "г",
    "city": "Данилов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600600100000,
    "fias_id": "28068176-9b2e-4333-a320-9b748b98b9d3",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78215501000,
    "oktmo": 78615101001,
    "tax_office": 7611,
    "timezone": "UTC+3",
    "geo_lat": 58.1860098,
    "geo_lon": 40.1795067,
    "population": 15861,
    "foundation_year": 1400
  },
  {
    "address": "Ярославская обл, г Любим",
    "postal_code": 152470,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Любимский",
    "city_type": "г",
    "city": "Любим",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600700100000,
    "fias_id": "f81f98ae-4e55-4fc3-8968-6ac6f1118c69",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78218501000,
    "oktmo": 78618101001,
    "tax_office": 7611,
    "timezone": "UTC+3",
    "geo_lat": 58.3620228,
    "geo_lon": 40.686841,
    "population": 5553,
    "foundation_year": 1538
  },
  {
    "address": "Ярославская обл, г Мышкин",
    "postal_code": 152830,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Мышкинский",
    "city_type": "г",
    "city": "Мышкин",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600800100000,
    "fias_id": "e5b1d3f2-b34d-48f7-8b23-1109114de8aa",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78221501000,
    "oktmo": 78621101001,
    "tax_office": 7612,
    "timezone": "UTC+3",
    "geo_lat": 57.7881684,
    "geo_lon": 38.4544224,
    "population": 5932,
    "foundation_year": 1400
  },
  {
    "address": "Ярославская обл, г Переславль-Залесский",
    "postal_code": 152020,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Переславль-Залесский",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600000200000,
    "fias_id": "f147ed70-422e-40d0-9088-810f7b57ed59",
    "fias_level": 4,
    "capital_marker": 0,
    "okato": 78405000000,
    "oktmo": 78705000001,
    "tax_office": 7608,
    "timezone": "UTC+3",
    "geo_lat": 56.7360544,
    "geo_lon": 38.8543617,
    "population": 41923,
    "foundation_year": 1152
  },
  {
    "address": "Ярославская обл, г Пошехонье",
    "postal_code": 152850,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Пошехонский",
    "city_type": "г",
    "city": "Пошехонье",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7601300100000,
    "fias_id": "d1bfddc3-1c78-40af-aeb0-c724ed7a9b4f",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78234501000,
    "oktmo": 78634101,
    "tax_office": 7610,
    "timezone": "UTC+3",
    "geo_lat": 58.5062879,
    "geo_lon": 39.1208434,
    "population": 6085,
    "foundation_year": 1680
  },
  {
    "address": "Ярославская обл, г Ростов",
    "postal_code": 152150,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Ростовский",
    "city_type": "г",
    "city": "Ростов",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7601400100000,
    "fias_id": "ab242d02-4d6c-4a1a-a9ce-ed9db691fc31",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78410000000,
    "oktmo": 78637101001,
    "tax_office": 7609,
    "timezone": "UTC+3",
    "geo_lat": 57.2051315,
    "geo_lon": 39.4378622,
    "population": 31791,
    "foundation_year": 862
  },
  {
    "address": "Ярославская обл, г Рыбинск",
    "postal_code": 152900,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Рыбинский",
    "city_type": "г",
    "city": "Рыбинск",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7601500100000,
    "fias_id": "292c0a6a-47ce-435d-8a75-f80e9ce67fba",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78415000000,
    "oktmo": 78715000001,
    "tax_office": 7610,
    "timezone": "UTC+3",
    "geo_lat": 58.0485495,
    "geo_lon": 38.8584119,
    "population": 200771,
    "foundation_year": 1071
  },
  {
    "address": "Ярославская обл, г Тутаев",
    "postal_code": 152300,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Тутаевский",
    "city_type": "г",
    "city": "Тутаев",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7601600100000,
    "fias_id": "55aa704c-ff9b-45e6-9493-eb10890e2824",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78417000000,
    "oktmo": 78643101001,
    "tax_office": 7611,
    "timezone": "UTC+3",
    "geo_lat": 57.8674993,
    "geo_lon": 39.5369627,
    "population": 41001,
    "foundation_year": 1200
  },
  {
    "address": "Ярославская обл, г Углич",
    "postal_code": 152610,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "р-н",
    "area": "Угличский",
    "city_type": "г",
    "city": "Углич",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7601700100000,
    "fias_id": "bddb02e3-ce5b-4128-8433-b4ad228914b9",
    "fias_level": 4,
    "capital_marker": 1,
    "okato": 78420000000,
    "oktmo": 78646101001,
    "tax_office": 7612,
    "timezone": "UTC+3",
    "geo_lat": 57.5224249,
    "geo_lon": 38.3020044,
    "population": 34505,
    "foundation_year": 1148
  },
  {
    "address": "г Ярославль",
    "postal_code": 150000,
    "country": "Россия",
    "federal_district": "Центральный",
    "region_type": "обл",
    "region": "Ярославская",
    "area_type": "",
    "area": "",
    "city_type": "г",
    "city": "Ярославль",
    "settlement_type": "",
    "settlement": "",
    "kladr_id": 7600000100000,
    "fias_id": "6b1bab7d-ee45-4168-a2a6-4ce2880d90d3",
    "fias_level": 4,
    "capital_marker": 2,
    "okato": 78401000000,
    "oktmo": 78701000001,
    "tax_office": 7600,
    "timezone": "UTC+3",
    "geo_lat": 57.6215477,
    "geo_lon": 39.8977411,
    "population": 591486,
    "foundation_year": 1010
  }
]

const cities = citiesObj.map((city: City) => city.city)

export default cities